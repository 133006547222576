import React, { useState, useEffect } from "react";
import { Button, MenuItem, Select, Tooltip } from "@material-ui/core";
import { getChats, getCountedForms, registerChat } from "../types/api/admin";
import { Form, FormContainer, Section, TextInput } from "./styled";
import { useThrow } from "../catch";
import CloseIcon from "@material-ui/icons/Close";
import {
    Snackbar,
    IconButton,
    SnackbarContent
  } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CountedForm, TelegramChat } from "../types/aamp";

export const RegisterChat = () => {
    const error = useThrow();
    const [chats, updateChats] = useState<TelegramChat[]>([]);
    const [showChats, updateShowChats] = useState<boolean>(false);
    const [id, updateId] = useState<number>();
    const [form_id, updateForm_id] = useState<number>();
    const [ containersList, setContainersList ] = useState<string[]>([]);
    const [ container, setContainer ] = useState<string>("");
    const [resetValue, updateResetValue] = useState<number>(0);
    const [ formsByContainer, setFormsByContainer ] = useState<{[container_name: string]: CountedForm[]}>({});

    
      useEffect(() => {
        const fetchFormData = async () => {
            try {
                const fetchedForms = await getCountedForms();
                setFormsByContainer(fetchedForms);
                const forms: Record<number, CountedForm> = {};
                const containers: Record<number, string> = {};
                for (const [ container_name, containerForms ] of Object.entries(fetchedForms)) {
                    for (const form of containerForms) {
                        forms[form.id] = form;
                        containers[form.id] = container_name;
                        const newContainers = containersList;
                        if(!newContainers.includes(container_name)) newContainers.push(container_name);
                        setContainersList(newContainers);
                    }
                }
                const localContainer = localStorage.getItem("aampContainer");
                if(localContainer && containersList.includes(localContainer)) {
                    setContainer(localContainer);
                }
            } catch (err) {
                error(err);
            }
        };
        fetchFormData();
    }, [error, containersList, resetValue]);
    
    useEffect(() => {
        const getAllChats = async () => {   
            try {
                const allChats = await getChats();
                updateChats(allChats);
            } catch (err) {
                error(err);
            }
        }
        getAllChats();
    }, [error, resetValue])


    const ContainerSelection = () : React.ReactElement => {
        const currentContainerForms = (Object.entries(formsByContainer).find(([containerName]) => containerName === container));
        return (
          <>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
              }}
            >
              <div style={{ width: 400, flexDirection: "column" }}>
                <div style={{ marginLeft: 50 }}>
                  Select a Container
                  <Select
                    value={container}
                    style={{ marginLeft: 20, width: 150 }}
                    onChange={(event) => {
                      setContainer(event.target.value as string);
                      localStorage.setItem(
                        "currentContainer",
                        event.target.value as string
                      );
                    }}
                  >
                    {containersList.map((value: string) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {container !== undefined &&
                  currentContainerForms &&
                  currentContainerForms[1].map((value: CountedForm) => (
                    <MenuItem
                      key={value.id}
                      value={value.id}
                      onClick={() => {
                        if(!value.immediate_public) {
                          updateForm_id(value.id);
                          updateShowChats(true);
                        } else error({message: "Cannot add a chat to an immediate public form."})
                      }}
                      style={{ marginLeft: 50, color: value.immediate_public ? "#AAAAAA" : "default" }}
                    >
                      <div style={{ flexDirection: "column", marginRight: 10 }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {value.name ? "Form " + value.name : "Form " + value.id}
                          {value.immediate_public &&
                          <Tooltip 
                              title="Reports submitted to this form will be displayed publicly as unverified even if they have not yet been approved."
                              enterDelay={0}
                          >
                              <div style={{
                                  color: "orange",
                                  marginLeft: 10
                              }}>
                                  {"(Immediate Public)"}
                              </div>
                          </Tooltip>}
                        </div>
                        {value.chats.length > 0 &&
                          value.chats.map((chatName: string) => (
                            <div key={chatName} style={{ marginLeft: 20 }}>{chatName}</div>
                          ))}
                          
                      </div>
                    </MenuItem>
                  ))}
              </div>
            </div>
          </>
        );};

      const displayChatSelection = (showChats:boolean) => {
          if(showChats) {
          return (
            <Autocomplete
              disableClearable
              id="chat-input"
              key={resetValue}
              options={chats}
              getOptionLabel={(option) => option.title ?? ""}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextInput
                  {...params}
                  required
                  label={"Select Chat to Register"}
                  variant="outlined"
                  value=""
                />
              )}
              onChange={(event, value:TelegramChat) => {
                updateId(value.id);
              }}
            />
          )
            };
      }

      const [openSuccessSnackbar, setOpenSucessSnackbar] = useState(false);
      const displaySuccessSnackbar = () => {
        setOpenSucessSnackbar(true);
      };
      const handleCloseSuccessSnackbar = () => {
        setOpenSucessSnackbar(false);
      };
      // const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
      // const displayErrorSnackbar = () => {
      //   setOpenErrorSnackbar(true);
      // };
      // const handleCloseErrorSnackbar = () => {
      //   setOpenErrorSnackbar(false);
      // };
      // const [errorString, setErrorString] = useState("");
    

    const resetForm = () => {
        updateId(undefined);
        updateForm_id(undefined);
        updateResetValue(resetValue + 1);
        updateShowChats(false);
    };

    const submit = () => {
        console.log(id);
        console.log(form_id);
        if (id && form_id) {
            const registerChatObject = {
                id: Number(id),
                form_id: Number(form_id),
            };

            registerChat(registerChatObject)
                .then(() => {
                    resetForm();
                    displaySuccessSnackbar();
                })
                .catch((error) => {
                    console.warn(error);
                });
        } else {
            error({ message: "Please fill all required fields." });
        }
    };

    return (
      <FormContainer>
        <Form noValidate autoComplete="off">
          <Section>
            {ContainerSelection()}
            {displayChatSelection(showChats)}
          </Section>
          <Button variant="contained" color="primary" onClick={submit}>
            Register Chat
          </Button>
        </Form>
        <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSuccessSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSuccessSnackbar}
        >
          <SnackbarContent
            style={{
              backgroundColor: "#20d420",
              color: "white",
            }}
            message="Form Submitted Successfully!"
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSuccessSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
      </div>
      </FormContainer>
    );
};
