// Colors:
export const boxShadow = "rgba(0, 0, 0, 0.1)";
export const buttonOrange = "#E37134";
export const gradientTopBlue = "#374151";
export const gradientBottomBlue = "#171F2E";
export const gradientTopRed = "#DB002A";
export const gradientBottomRed = "#B70022";
export const fadedRed = "#e85454";
export const lightFadedRed = "rgba(255, 147, 147, 0.37)";

// Notifications
export const confirmationGreen = "#27A953";
export const backlogRed = "#b10000";
export const counterBorderRed = "#ff0000";
export const counterFontRed = "#dc0808";

export const borderBlueGrey = "#6E7E99";
export const activeGreen = "#44F9B8";
export const white = "#FFFFFF";
export const fieldBackgroundDarkBlue = "#1B2028";
export const gradientBottomGrey = "#C5C5C5";

// Cuser panel:
export const CuserPanelBackgroundGradient = "linear-gradient(#374151, #171f2e)";
export const CuserPanelTextColor = "#eeeeee";
