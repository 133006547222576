import ClusterLayer from "../layers/cluster-layer";
import { ScatterplotLayer } from "@deck.gl/layers";
import { getMarkerColor, dataToGeojson, timeFilterMarkers } from "../utils";
import { IconLayer, TextLayer, PathLayer, PolygonLayer } from "@deck.gl/layers";
import { ScenegraphLayer } from "@deck.gl/mesh-layers";
import { GLTFLoader } from "@loaders.gl/gltf";
import { registerLoaders } from "@loaders.gl/core";
import { getScenegraph, getScenegraphSize, getAssetIcon, getScenarioIcon } from "./menu-layers"
import * as d3 from "d3-geo";

export default function renderLayers({ data = [], onClick, filter: { showTimeFilter = false }, colors, timeFilter, allDevices, showText, showAssets, assetData, showAssetMarkers, riskData, showRisks, selectedAsset, selectedRisk, selectedScenario, addRisk, changeGeo}) {
    let clientDevicesText = [];

    const pointsEqual = (point1,point2) => {
        return (Math.abs(point1.coordinates[0] - point2.coordinates[0]) < 0.0005 &&
            Math.abs(point1.coordinates[1] - point2.coordinates[1]) < 0.0005)
    }
    for(let device of allDevices) {
            let sameLocation = clientDevicesText.findIndex((d) => pointsEqual(d.point, device.point));
            if(sameLocation !== -1) {
                clientDevicesText[sameLocation] = 
                    {...clientDevicesText[sameLocation],
                        user_name: clientDevicesText[sameLocation].user_name + "\n" + device.user_name};
            } else clientDevicesText.push(device);
    }
    registerLoaders(GLTFLoader);
    
    var layers = [
        new IconLayer({
            id: `userlayer`,
            data: allDevices,
            getPosition: (d) => d.point.coordinates,
            pickable: true,
            iconAtlas: true 
                ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/user-location-green.png" 
                : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/user-location-red.png",
            iconMapping: {
                endpoint: {
                    x: 0,
                    y: 0,
                    width: 150,
                    height: 150,
                    anchorY: 150,
                    mask: false
                }
            },
            mask: true,
            getIcon: () => "endpoint",
            sizeUnits: "meters",
            sizeMinPixels: 44,
            sizeScale: 1,
            visible: showAssets
        }),
        new TextLayer({
            data: clientDevicesText,
            id: `userTextlayer`,
            getPosition: (d) => d.point.coordinates,
            getText: (d) => {
                return d.user_name
            },
            pickable: true,
            getPixelOffset: (d) => [0,Math.sqrt(d.user_name.length)*6],
            getSize: 20,
            clusterRadius: 13,
            visible: showText && showAssets,
            getColor: [255,255,255,255]
        }),
        new ClusterLayer({
            id: "clusterLayer",
            data,
            pickable: true,
            autoHighlight: false,
            getPosition: (d) => {
                return [d.longitude, d.latitude];
            },
            onClick,
            colors,
            clusterRadius: 40,
            maxZoom: 13,
            minClusterSize: 3,
            transitionRadius: 1300,
            visible: !showTimeFilter
        }),
        new ScatterplotLayer({
            id: "timeFilter-scatterplot",
            data: dataToGeojson(data),
            pickable: true,
            getPosition: (d) => d.geometry.coordinates,
            opacity: 0.5,
            filled: true,
            radiusScale: 10,
            getRadius: 1,
            onClick,
            updateTriggers: {
                getFillColor: [timeFilter]
            },
            visible: showTimeFilter,
            getLineColor: [0, 0, 0],
            getLineWidth: 2,
            radiusMinPixels: 5,
            radiusMaxPixels: 5,
            getFillColor: (d) =>
                timeFilterMarkers(d, timeFilter) ? getMarkerColor(d.properties.category, colors) : [0, 0, 0, 0]
        })
    ];

    for(const risk of riskData) {
        const geography = JSON.parse(JSON.stringify(risk.geography));
        const selected = risk.id === selectedRisk?.id;
        if(geography){
            if(geography.type === "Point") {
                layers.push(new IconLayer({
                    id: `${risk.id}-risk-icon-layer`,
                    data: [risk],
                    getPosition: (d) => JSON.parse(JSON.stringify(d.geography)).coordinates,
                    pickable: true,
                    iconAtlas: "https://orion-assets-s3.s3.us-east-2.amazonaws.com/asset_icons/risk.png",
                    iconMapping: {
                        endpoint: {
                            x: 0,
                            y: 0,
                            width: 150,
                            height: 150,
                            anchorY: 150,
                            mask: false
                        }
                    },
                    onClick,
                    mask: true,
                    getIcon: () => "endpoint",
                    sizeUnits: "meters",
                    sizeMinPixels: selected ? 60 : 44,
                    sizeScale: 1,
                    visible: showRisks
                }));
            } else if(geography.type === "Polygon") {
                layers.push(new PolygonLayer({
                    id: `${risk.id}-polygon-risk-layer`,
                    data: [risk],
                    pickable: true,
                    stroked: true,
                    filled: true,
                    wireframe: true,
                    onClick,
                    lineWidthMinPixels: 1,
                    getPolygon: d => JSON.parse(JSON.stringify(d.geography)).coordinates,
                    getElevation: d => 30,
                    getFillColor: d => [150, 0, 0, 100],
                    getLineColor: selected ? [255, 255, 255] : [80, 80, 80],
                    getLineWidth: 1,
                    visible: showRisks
                }));
            } else if (geography.type === "LineString") {
                //let coordinates = geography.coordinates[Math.floor(geography.coordinates.length/2)];
                layers.push(new PathLayer({
                    id: `${risk.id}-asset-path-layer`,
                    data: [risk],
                    pickable: true,
                    widthScale: selected ? 30 : 20,
                    onClick,
                    widthMinPixels: selected ? 5 : 2,
                    getPath: d => geography.coordinates,
                    getColor: [150,0,0],
                    getWidth: d => 5,
                    visible: showAssets
                }));
            }
        }
    }


    for(const asset of assetData) {
        const geography = JSON.parse(JSON.stringify(asset.geography));
        const selected = asset.id === selectedAsset?.id || asset.id === selectedScenario?.id;;
        if(geography.type === "Point") {
            layers.push(new ScenegraphLayer({
                id: `${asset.id}-scenegraph-asset-layer`,
                data: [asset],
                _animations: {
                    '*': {speed: 5}
                },
                _lighting: 'pbr',
                getOrientation: d => [0, 0, 90],
                getPosition: d => JSON.parse(JSON.stringify(asset.geography)).coordinates,
                loaders: [GLTFLoader],
                scenegraph: getScenegraph(asset.asset_type),
                sizeScale: selected ? 1.5 * getScenegraphSize(asset.asset_type) : getScenegraphSize(asset.asset_type),
                pickable: true,
                onClick,
                visible: showAssets
            }));
            layers.push(new TextLayer({
                data: clientDevicesText,
                id: `${asset.id}-text-asset-layer`,
                getPosition: (d) => asset.geography.coordinates,
                getText: (d) => asset.name,
                pickable: true,
                getPixelOffset: [0,20],
                getPolygonOffset: (layerIndex) => [0, -layerIndex * 1000],
                getSize: 20,
                clusterRadius: 13,
                visible: showText && showAssets && (addRisk !== undefined || (changeGeo && selectedRisk !== undefined)),
                getColor: [255,255,255,255]
            }));
            layers.push(new IconLayer({
                id: `${asset.id}-asset-icon-layer`,
                data: [asset],
                getPosition: (d) => JSON.parse(JSON.stringify(d.geography)).coordinates,
                pickable: true,
                iconAtlas: asset.imaginary ? getScenarioIcon(asset.asset_type) : getAssetIcon(asset.asset_type),
                iconMapping: {
                    endpoint: {
                        x: 0,
                        y: 0,
                        width: 80,
                        height: 80,
                        anchorY: 80,
                        mask: false
                    }
                },
                onClick,
                mask: true,
                getIcon: () => "endpoint",
                sizeUnits: "meters",
                sizeMinPixels: selected ? 60 : 44,
                sizeScale: selected ? 2 : 1,
                visible: showAssets && showAssetMarkers
            }));
        } else if(geography.type === "Polygon") {
            // let geography = JSON.parse(JSON.stringify(asset.geography));
            // let points = 0;
            // let lat = 0;
            // let long = 0;
            // for(let coordinate of geography.coordinates[0]) {
            //     lat += coordinate[0];
            //     long += coordinate[1];
            //     points++;
            // }
            // lat /= points;
            // long /= points;
            //let coordinates = [lat, long];
            layers.push(new PolygonLayer({
                id: `${asset.id}-polygon-asset-layer`,
                data: [asset],
                pickable: true,
                stroked: true,
                filled: true,
                wireframe: true,
                onClick,
                lineWidthMinPixels: 1,
                getPolygon: d => JSON.parse(JSON.stringify(d.geography)).coordinates,
                getElevation: d => 30,
                getFillColor: d => asset.imaginary ? "#65F6F6"  : [80, 0, 100, 100],
                getLineColor: selected ? [255, 255, 255] : [80, 80, 80],
                getLineWidth: 1,
                visible: showAssets
            }));
            layers.push(new TextLayer({
                data: clientDevicesText,
                id: `${asset.id}-text-asset-layer`,
                getPosition: (d) => d3.geoCentroid(asset.geography),
                getText: (d) => asset.name,
                pickable: true,
                getPolygonOffset: (layerIndex) => [0, -layerIndex * 1000],
                getSize: 20,
                clusterRadius: 13,
                visible: showText && showAssets && (addRisk !== undefined || (changeGeo && selectedRisk !== undefined)),
                getColor: [255,255,255,255]
            }));
            // unsure if we really want this
            // layers.push(new IconLayer({
            //     id: `${asset.id}-asset-icon-layer`,
            //     data: [asset],
            //     getPosition: (d) => coordinates,
            //     pickable: true,
            //     iconAtlas: getAssetIcon(asset.asset_type),
            //     iconMapping: {
            //         endpoint: {
            //             x: 0,
            //             y: 0,
            //             width: 80,
            //             height: 80,
            //             anchorY: 80,
            //             mask: false
            //         }
            //     },
            //     mask: true,
            //     getIcon: () => "endpoint",
            //     sizeUnits: "meters",
            //     sizeMinPixels: 44,
            //     sizeScale: 1,
            //     visible: showAssets && !showText
            // }));
        } else if (geography.type === "LineString") {
            let geography = JSON.parse(JSON.stringify(asset.geography));
            //let coordinates = geography.coordinates[Math.floor(geography.coordinates.length/2)];
            layers.push(new PathLayer({
                id: `${asset.id}-asset-path-layer`,
                data: [asset],
                pickable: true,
                widthScale: selected ? 30 : 20,
                onClick,
                widthMinPixels: selected ? 5 : 2,
                getPath: d => geography.coordinates,
                getColor: asset.imaginary ? "#65F6F6": [0, 0, 150],
                getWidth: d => 5,
                visible: showAssets
            }));
            layers.push(new TextLayer({
                data: clientDevicesText,
                id: `${asset.id}-text-asset-layer`,
                getPosition: (d) => asset.geography.coordinates[Math.floor(asset.geography.coordinates.length/2)],
                getText: (d) => asset.name,
                pickable: true,
                getPolygonOffset: (layerIndex) => [0, -layerIndex * 1000],
                getSize: 20,
                clusterRadius: 13,
                visible: showText && showAssets && (addRisk !== undefined || (changeGeo && selectedRisk !== undefined)),
                getColor: [255,255,255,255]
            }));
            // unsure if we really want this
            // layers.push(new IconLayer({
            //     id: `${asset.id}-asset-icon-layer`,
            //     data: [asset],
            //     getPosition: (d) => coordinates,
            //     pickable: true,
            //     iconAtlas: getAssetIcon(asset.asset_type),
            //     iconMapping: {
            //         endpoint: {
            //             x: 0,
            //             y: 0,
            //             width: 80,
            //             height: 80,
            //             anchorY: 80,
            //             mask: false
            //         }
            //     },
            //     mask: true,
            //     getIcon: () => "endpoint",
            //     sizeUnits: "meters",
            //     sizeMinPixels: 44,
            //     sizeScale: 1,
            //     visible: showAssets && !showText
            // }));
        }
    };
    return layers;
}
