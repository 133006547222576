import React from "react";
import { TooltipTitle, GridTooltipContainer, TooltipText } from "./styled";
import { useSelectFromRedux } from "../../utils/_hooks";
import { RISK_INDEX_COLORS } from "../../visualizations/utils";
// import moment from "moment";

export default function GridTooltip() {
    const [{ selectedMarker }] = useSelectFromRedux((state) => [state.map]);
    const colors = useSelectFromRedux((state) => state.color);

    return (
        <GridTooltipContainer>
            <TooltipTitle color={colors.scheme.primaryText} style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                Risk Index: {(selectedMarker.properties.risk_index_tmp + 1)}
                <div style={{
                    background: RISK_INDEX_COLORS[selectedMarker.properties.risk_index_tmp] ??"#FFC300",
                    height: "16px",
                    width: "16px",
                    borderRadius: "4px",
                    marginLeft: "5px"
                }}/>
            </TooltipTitle>
            {/* <TooltipTitle color={colors.scheme.primaryText}>
                Date: {moment(selectedMarker.properties.ra_date).format("LLLL")}
            </TooltipTitle> */}
            <TooltipTitle style={{fontWeight: 400, fontSize: "14px"}} color={colors.scheme.primaryText}>
                MGRS: {selectedMarker.properties.mgrs}
            </TooltipTitle>
            <TooltipText style={{fontWeight: 400, fontSize: "14px"}} color={colors.scheme.primaryText}>
                Safety Score: {(selectedMarker.properties.safety_score * 100).toFixed(2)}% ({selectedMarker.properties.safety_index_label})
            </TooltipText>
        </GridTooltipContainer>
    );
}
