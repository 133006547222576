import React, { useEffect, useState } from "react";
import { Button, FormControl, ButtonGroup, Select, Input, MenuItem, ListItemText, Checkbox, FormLabel, FormGroup, FormControlLabel, TextField } from "@material-ui/core";
import { DataGrid, ColDef, ValueGetterParams } from "@material-ui/data-grid";
import { Form, FormContainer, Section, ToggleBarContainer, StyledSearchBar } from "./styled";
import { getSummaryData, searchNewsDB } from "../types/api/admin";
import { getContainers, getAllContainers } from "../types/api/admin";
import { useThrow } from "../catch";
import { ClientDisplayData, DisplayClient, UserFeatureSelection } from "../types/client";
import { Autocomplete, Alert } from "@material-ui/lab";
import { render } from "@testing-library/react";
import {makeStyles} from '@material-ui/styles';
import Link from "@material-ui/core/Link";
import { Container } from "../types/geo"
import { ConsoleView } from "react-device-detect";
import SearchBar from "material-ui-search-bar";
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  BarSeries,
  LineSeries,
  Legend
} from '@devexpress/dx-react-chart-material-ui';


const styles = makeStyles((theme) => ({
  root: {
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    color: 'black',
    backgroundColor: 'grey'
  },
}));



export const DisplayNews = () => {

  const error = useThrow();

  const classes = styles()

  // Initial States
  const [initialrows, setinitialrows] = useState<any[]>([]);
  const [initialcountrynamelist, setinitialcountrynamelist] = useState<string[]>([]);
  const [initialcountrynamelist_search, setinitialcountrynamelist_search] = useState<string[]>([]);

  // Set Table Rows
  const [tablerows, settablerows] = useState<any[]>([]);

  // *** NEW method of getting container and region names
  const [containers, setcontainers] = useState<any[]>([]);

  // User Input - Custom Timeframe
  const [customtimeframe, setcustomtimeframe] = useState<number>();

  // Query and Filter Container
  const [containerNames, updateContainerNames] = useState<string[]>([]);
  const [containerNames_search, updateContainerNames_search] = useState<string[]>([]);
  const [countrynamelist, setcountrynamelist] = useState<string[]>([]);
  const [countrynamelist_search, setcountrynamelist_search] = useState<string[]>([]);
  const [filteredNames, setFilteredNames] = useState<string[]>([]);
  const [filteredNames_search, setFilteredNames_search] = useState<string[]>([]);

  // Query Date
  const [querydate, setquerydate] = useState<string>(new Date().toISOString().slice(0, 10));

  // Filter Tags
  const [filteredTags, setFilteredTags] = useState<string[]>(['Violent', 'Theft', 'Protest', 'Corruption', 'Gang Activity', 'Drugs']);
  const tagNames: string[] = ['Violent', 'Theft', 'Protest', 'Corruption', 'Gang Activity', 'Drugs']

  // Reset Filter
  const [resetFilterFlag, setResetFilterFlag] = useState<number>(0);

  // Search Bar Functionality
  const [searched, setSearched] = useState<string>("");

  // Manual Date Query
  const [datetextfieldinput, setdatetextfieldinput] = useState<string>()

  // Loading State
  const [loadingstate, setloadingstate] = useState<string>()
  const [tagbarloadingstate, settagbarloadingstate] = useState<string>()
  const [searchlineloadingstate, setsearchlineloadingstate] = useState<string>()

  // Time Scale Toggle
  const [selectedtimeframe, setselectedtimeframe] = useState<number>(7)
  const [tagbarselectedtimeframe, settagbarselectedtimeframe] = useState<string>('Today')
  const [newssearchtimeframe, setnewssearchtimeframe] = useState<number>(7)

  // News Tag Relevancy Line Chart and Bar Chart
  const [newstagrelevancydata, setnewstagrelevancydata] = useState<any[]>([])
  const [newstagbardata, setnewstagbardata] = useState<any[]>([])

  // Entity Selection
  const [selectedentityinfo, setselectedentityinfo] = useState<string>("No Entities Selected")
  const [selectedpeople, setselectedpeople] = useState<string>()
  const [selectedorgs, setselectedorgs] = useState<string>()
  const [selectedlocations, setselectedlocations] = useState<string>()
  const [selecteddates, setselecteddates] = useState<string>()
  const [selectedtimes, setselectedtimes] = useState<string>()

  // News DB Search
  const [searchtextfieldinput, setsearchtextfieldinput] = useState<string>()
  const [searchrows, setsearchrows] = useState<any[]>([]);
  const [initialsearchrows, setinitialsearchrows] = useState<any[]>([]);
  const [enteredsearchinput, setenteredsearchinput] = useState<string>()
  const [historicsearchdata, sethistoricsearchdata] = useState<any[]>([]);


  useEffect(() => {

    const fetchnews = async () => {

      const newsresult = await getSummaryData(querydate, "news");
      setinitialrows(newsresult);
      settablerows(newsresult);

      const containerresponse: any[] = await getAllContainers();

      ///
      setcontainers(containerresponse)
      ///

      const containerresponseNamesArr: string[] = [];
      const containerresponseDetailsArr: any[] = [];

      containerresponse.forEach((fetchedContainer) => {
        containerresponseDetailsArr.push(fetchedContainer);
        containerresponseNamesArr.push(fetchedContainer.name);
      });

      const relevantContainersArr: any[] = [];
      const relevantContainerNames: string[] = [];
      const query_countrynamelist: string[] = [];



      for (let i = 0; i < newsresult.length; i++){
        for (let j = 0; j < containerresponseDetailsArr.length; j++){
          if (newsresult[i].container_id === JSON.stringify(containerresponseDetailsArr[j].id)){
            query_countrynamelist.push(containerresponseDetailsArr[j].name)
            if (relevantContainersArr.includes({'Name': containerresponseDetailsArr[j].name, 'id': containerresponseDetailsArr[j].id}) === false) relevantContainersArr.push({'Name': containerresponseDetailsArr[j].name, 'id': containerresponseDetailsArr[j].id});
            if (relevantContainerNames.includes(containerresponseDetailsArr[j].name) === false) relevantContainerNames.push(containerresponseDetailsArr[j].name)

          }
        }
      }

      setcountrynamelist(query_countrynamelist)
      setinitialcountrynamelist(query_countrynamelist)
      updateContainerNames(relevantContainerNames)
      setFilteredNames(relevantContainerNames)

    };
    fetchnews();
    parsedatatagbar(2);
    parsedatafornewstagrelevancy(7);


  }, [querydate, resetFilterFlag]);

  useEffect(() => {

    parsedatatagbar(2)
    parsedatafornewstagrelevancy(7)

  }, [filteredNames, filteredTags])



  function getContainerName(params: ValueGetterParams) {
    const rowdata = params.data

    const correctcontainer = containers.filter((row) => row.id == rowdata.container_id)

    if (typeof correctcontainer[0] !== 'undefined') return correctcontainer[0].name
    else return ''

  }


  function getTagNames(params: ValueGetterParams) {

    const rowdata = params.data

    const tags: string[] = []

    if (rowdata.is_violent==1)  tags.push("Violent")
    if (rowdata.is_theft==1)  tags.push("Theft")
    if (rowdata.is_protest==1)  tags.push("Protest")
    if (rowdata.is_corruption==1)  tags.push("Corruption")
    if (rowdata.is_gactivity==1)  tags.push("Gang Activity")
    if (rowdata.is_drugs==1)  tags.push("Drugs")

    let tagstring = JSON.stringify(tags)

    tagstring = tagstring.replace('[', '')
    tagstring = tagstring.replace(']', '')

    return tagstring

  }



  const columns: ColDef[] = [
    { field: 'CountryName', width: 150, valueGetter: getContainerName },
    { field: 'Title', headerName: 'Title', width: 400 },
    { field: 'Description', headerName: 'Description', width: 400 },
    {
      field: 'Link', headerName: 'Link', width: 200, renderCell: (params) => (
        <Link href={`mailto:${params.value}`}>{params.value!.toString()}</Link>
      )
    },
    { field: 'Source', headerName: 'Source', width: 150 },
    { field: 'Tags', width: 150, valueGetter: getTagNames },
    { field: 'ENTITIES', renderCell: (cellValues) => {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => selectentities(cellValues)}
        >
          SELECT
        </Button>
      );
    } },
    { field: 'BAD ARTICLE', width: 150, renderCell: (cellValues) => {
      return (
        <div style={{ color: "darkred" }}>
        <Button
          variant="outlined"
          color="inherit"
          //onClick={() => selectentities(cellValues)}
        >
          BAD ARTICLE
        </Button>
        </div>
      );
    } },

    // { field: 'identified_location', headerName: 'Locations', width: 200 },
    // { field: 'identified_people', headerName: 'People', width: 200 },
    // { field: 'identified_orgs', headerName: 'Organizations', width: 200 },
    // { field: 'identified_dates', headerName: 'Dates', width: 200 },
    // { field: 'identified_times', headerName: 'Times', width: 200 },

    // { field: 'is_violent', headerName: 'Violent', width: 100 },
    // { field: 'is_theft', headerName: 'Theft', width: 100 },
    // { field: 'is_protest', headerName: 'Protest', width: 100 },
    // { field: 'is_corruption', headerName: 'Corruption', width: 100 },
    // { field: 'is_gactivity', headerName: 'Gang Activity', width: 100 },
    // { field: 'is_drugs', headerName: 'Drugs', width: 100 }
  ];

  const searchresultcolumns: ColDef[] = [
    { field: 'CountryName', width: 150, valueGetter: getContainerName },
    { field: 'Timestamp', headerName: 'Timestamp', width: 200 },
    { field: 'Title', headerName: 'Title', width: 400 },
    { field: 'Description', headerName: 'Description', width: 400 },
    {
      field: 'Link', headerName: 'Link', width: 200, renderCell: (params) => (
        <Link href={`mailto:${params.value}`}>{params.value!.toString()}</Link>
      )
    },
    { field: 'Source', headerName: 'Source', width: 150 },

    { field: 'identified_location', headerName: 'Locations', width: 200 },
    { field: 'identified_people', headerName: 'People', width: 200 },
    { field: 'identified_orgs', headerName: 'Organizations', width: 200 },
    { field: 'identified_dates', headerName: 'Dates', width: 200 },
    { field: 'identified_times', headerName: 'Times', width: 200 },

    // { field: 'is_violent', headerName: 'Violent', width: 100 },
    // { field: 'is_theft', headerName: 'Theft', width: 100 },
    // { field: 'is_protest', headerName: 'Protest', width: 100 },
    // { field: 'is_corruption', headerName: 'Corruption', width: 100 },
    // { field: 'is_gactivity', headerName: 'Gang Activity', width: 100 },
    // { field: 'is_drugs', headerName: 'Drugs', width: 100 }
  ];



  function filterrows_bycontainer(event: string[]) {

    const newrows: any[] = []
    const newcountrynamelist: string[] = []

    initialrows.forEach((row, index) => {
      const rowcountryname: string = initialcountrynamelist[index]
      const rowtags: string[] = []

      if (row.is_violent == 1) rowtags.push('Violent')
      if (row.is_theft == 1) rowtags.push('Theft')
      if (row.is_protest == 1) rowtags.push('Protest')
      if (row.is_corruption == 1) rowtags.push('Corruption')
      if (row.is_gactivity == 1) rowtags.push('Gang Activity')
      if (row.is_drugs == 1) rowtags.push('Drugs')

      let acceptableflag: Number = 0
      rowtags.forEach((tag) => {
        if (filteredTags.includes(tag)) {
          acceptableflag = 1
        }
      });

      if (event.includes(rowcountryname) && acceptableflag > 0) {
        newrows.push(row)
        newcountrynamelist.push(rowcountryname)
    }});

    setcountrynamelist(newcountrynamelist);
    settablerows(newrows);
    setFilteredNames(event as string[]);
  }


  function filterrows_bytag(event: string[]) {

    const newrows: any[] = []
    const newcountrynamelist: string[] = []

    initialrows.forEach((row, index) => {

      const rowcountryname: string = initialcountrynamelist[index]
      const rowtags: string[] = []

      if (row.is_violent == 1) rowtags.push('Violent')
      if (row.is_theft == 1) rowtags.push('Theft')
      if (row.is_protest == 1) rowtags.push('Protest')
      if (row.is_corruption == 1) rowtags.push('Corruption')
      if (row.is_gactivity == 1) rowtags.push('Gang Activity')
      if (row.is_drugs == 1) rowtags.push('Drugs')

      let acceptableflag: Number = 0
      rowtags.forEach((tag) => {
        if (event.includes(tag)) {
          acceptableflag = 1
        }
      });

      if (acceptableflag > 0 && filteredNames.includes(rowcountryname)) {
        newrows.push(row)
        newcountrynamelist.push(rowcountryname)
      }
    });

    settablerows(newrows)
    setFilteredTags(event as string[])
    setcountrynamelist(newcountrynamelist);

  }


  function filterrows_bysearch(search: string) {

    const newrows: any[] = []
    const newcountrynamelist: string[] = []

    initialrows.forEach((row, index) => {
      const rowcountryname: string = initialcountrynamelist[index]
      const rowtags: string[] = []

      if (row.is_violent == 1) rowtags.push('Violent')
      if (row.is_theft == 1) rowtags.push('Theft')
      if (row.is_protest == 1) rowtags.push('Protest')
      if (row.is_corruption == 1) rowtags.push('Corruption')
      if (row.is_gactivity == 1) rowtags.push('Gang Activity')
      if (row.is_drugs == 1) rowtags.push('Drugs')

      let acceptableflag: Number = 0
      rowtags.forEach((tag) => {
        if (filteredTags.includes(tag)) {
          acceptableflag = 1
        }
      });

      if ((row.Description.toLowerCase().includes(search.toLowerCase()) || row.Title.toLowerCase().includes(search.toLowerCase())) && filteredNames.includes(rowcountryname) && acceptableflag > 0) {
        newrows.push(row)
        newcountrynamelist.push(rowcountryname)
    }});

    setcountrynamelist(newcountrynamelist);
    settablerows(newrows);
  }



  function resetfilter() {
    setFilteredTags(tagNames)
    setResetFilterFlag(resetFilterFlag+1)
  }

  function querydatefunc(direction: String) {

    function WithoutTime(dateTime: Date) {
      var date = new Date(dateTime.getTime());
      date.setHours(0, 0, 0, 0);
      return date;
  }

    const olddate = WithoutTime(new Date(querydate))
    if (direction === "back") {
      const newdate = WithoutTime(new Date(olddate.setDate(olddate.getDate())))
      const finaldate = newdate.toISOString().split('T')[0]
      setquerydate(finaldate.toString())
    }
    if (direction === "forward") {
      const newdate = WithoutTime(new Date(olddate.setDate(olddate.getDate()+2)))
      const finaldate = newdate.toISOString().split('T')[0]
      setquerydate(finaldate.toString())
    }

  }


  const cancelSearch = () => {
    setSearched("");
    filterrows_bysearch(searched);
  };

  function manualdateinput(datestring: any) {
    if (typeof datestring === 'string') {
      setquerydate(datestring)
    }
  }

  async function newssearchinput(searchstring: any) {
    if (typeof searchstring === 'string' && searchstring != '' && searchstring != ' ') {
      const relevantrows = await searchNewsDB(searchstring)

      const containerresponse: any[] = await getAllContainers();
      const containerresponseNamesArr: string[] = [];
      const containerresponseDetailsArr: any[] = [];

      containerresponse.forEach((fetchedContainer) => {
        containerresponseDetailsArr.push(fetchedContainer);
        containerresponseNamesArr.push(fetchedContainer.name);
      });

      const relevantContainersArr: any[] = [];
      const relevantContainerNames: string[] = [];
      const query_countrynamelist: string[] = [];



      for (let i = 0; i < relevantrows.length; i++){
        for (let j = 0; j < containerresponseDetailsArr.length; j++){
          if (relevantrows[i].container_id === JSON.stringify(containerresponseDetailsArr[j].id)){
            query_countrynamelist.push(containerresponseDetailsArr[j].name)
            if (relevantContainersArr.includes({'Name': containerresponseDetailsArr[j].name, 'id': containerresponseDetailsArr[j].id}) === false) relevantContainersArr.push({'Name': containerresponseDetailsArr[j].name, 'id': containerresponseDetailsArr[j].id});
            if (relevantContainerNames.includes(containerresponseDetailsArr[j].name) === false) relevantContainerNames.push(containerresponseDetailsArr[j].name)

          }
        }
      }

      setcountrynamelist_search(query_countrynamelist)
      setinitialcountrynamelist_search(query_countrynamelist)
      updateContainerNames_search(relevantContainerNames)
      setFilteredNames_search(relevantContainerNames)

      setsearchrows(relevantrows)
      setinitialsearchrows(relevantrows)
      setenteredsearchinput(searchstring)
      parsedataforsearchterm(7, searchstring)

    }
  }


  function WithoutTime(dateTime: Date) {
    var date = new Date(dateTime.getTime());
    date.setHours(0, 0, 0, 0);
    return date;
  }

  async function extendexistingfilter(rows: any[]) {

    function confirmcontainer(rowdata: any) {
      const correctcontainer = containers.filter((row) => row.id == rowdata.container_id)
      let containername;
      if (typeof correctcontainer[0] !== 'undefined') containername = correctcontainer[0].name
      if (filteredNames.includes(containername)) return true
    }

    function confirmtags(rowdata: any) {
      const rowtags: string[] = []
      if (rowdata.is_violent == 1) rowtags.push('Violent')
      if (rowdata.is_theft == 1) rowtags.push('Theft')
      if (rowdata.is_protest == 1) rowtags.push('Protest')
      if (rowdata.is_corruption == 1) rowtags.push('Corruption')
      if (rowdata.is_gactivity == 1) rowtags.push('Gang Activity')
      if (rowdata.is_drugs == 1) rowtags.push('Drugs')

      let acceptableflag: Number = 0
      rowtags.forEach((tag) => {
        if (filteredTags.includes(tag)) {
          acceptableflag = 1
        }
      });
      if (acceptableflag > 0) return true
    }

    const newrows: any = []

    rows.forEach((row) => {
      if (confirmtags(row) == true && confirmcontainer(row) == true) newrows.push(row)
    })

    return newrows
  }


  async function parsedatafornewstagrelevancy(timescaleint: number | undefined) {
    if (typeof timescaleint !== 'undefined'){
    setloadingstate("LOADING....")
    setselectedtimeframe(timescaleint)
    let tagdata = []
    const startingdate = new Date(querydate)
    const adjustedstartingdate = WithoutTime(new Date(startingdate.setDate(startingdate.getDate()+2)))
    for (let t = 0; t < timescaleint; t++){
      const newdate = WithoutTime(new Date(adjustedstartingdate.setDate(adjustedstartingdate.getDate()-1)))
      const finaldate = newdate.toISOString().split('T')[0]
      let articles = await getSummaryData(finaldate, "news")
      articles = await extendexistingfilter(articles)
      const totallength = articles.length

      const violent = (articles.filter((article: any) => article.is_violent > 0).length) / totallength
      const theft = (articles.filter((article: any) => article.is_theft > 0).length) / totallength
      const protest = (articles.filter((article: any) => article.is_protest > 0).length) / totallength
      const corruption = (articles.filter((article: any) => article.is_corruption > 0).length) / totallength
      const gactivity = (articles.filter((article: any) => article.is_gactivity > 0).length) / totallength
      const drugs = (articles.filter((article: any) => article.is_drugs > 0).length) / totallength


      tagdata.push({"date": finaldate, "violent": violent, "theft": theft, "protest": protest, "corruption": corruption, "gactivity": gactivity, "drugs": drugs})
    }

    setloadingstate('')
    setnewstagrelevancydata(tickspacing(tagdata.reverse(), timescaleint))
  }
  }


  async function parsedataforsearchterm(timescaleint: number | undefined, searchstring: string | undefined) {

    if (typeof searchstring === 'string' && typeof timescaleint !== 'undefined') {
    setsearchlineloadingstate("LOADING....")
    setnewssearchtimeframe(timescaleint)

    const allrows = await searchNewsDB(searchstring)

    let historicsearchdata: any = []
    const startingdate = new Date(querydate)
    const adjustedstartingdate = WithoutTime(new Date(startingdate.setDate(startingdate.getDate()+2)))

    for (let t = 0; t < timescaleint; t++){
      const newdate = WithoutTime(new Date(adjustedstartingdate.setDate(adjustedstartingdate.getDate()-1)))
      const finaldate = newdate.toISOString().split('T')[0]

      const numofdailymentions = allrows.filter((row: any) => row.Timestamp.slice(0,10) == finaldate).length

      historicsearchdata.push({"date": finaldate, "daily_mentions": numofdailymentions})

    }

    setsearchlineloadingstate('')
    sethistoricsearchdata(tickspacing(historicsearchdata.reverse(), timescaleint))

  }
}


  async function parsedatatagbar(timescaleint: number | undefined) {

    if (typeof timescaleint !== 'undefined'){
      settagbarloadingstate("LOADING....")

      if (timescaleint <= 2) settagbarselectedtimeframe("Today")
      if (timescaleint > 2) settagbarselectedtimeframe(`Previous ${timescaleint} Days`)

      let tagdata: any = []
      const startingdate = new Date(querydate)
      const adjustedstartingdate = WithoutTime(new Date(startingdate.setDate(startingdate.getDate()+2)))

      for (let t = 0; t < timescaleint; t++){
        const newdate = WithoutTime(new Date(adjustedstartingdate.setDate(adjustedstartingdate.getDate()-1)))
        const finaldate = newdate.toISOString().split('T')[0]
        let articles = await getSummaryData(finaldate, "news")
        articles = await extendexistingfilter(articles)

        articles.forEach((row: any) => {
          tagdata.push(row)
        });
      }

      const bardata = [{"tag": "Violent", "count": tagdata.filter((row: any) => row.is_violent > 0).length},
      {"tag": "Theft", "count": tagdata.filter((row: any) => row.is_theft > 0).length},
      {"tag": "Protest", "count": tagdata.filter((row: any) => row.is_protest > 0).length},
      {"tag": "Corruption", "count": tagdata.filter((row: any) => row.is_corruption > 0).length},
      {"tag": "Gang Activity", "count": tagdata.filter((row: any) => row.is_gactivity > 0).length},
      {"tag": "Drugs", "count": tagdata.filter((row: any) => row.is_drugs > 0).length}]

      settagbarloadingstate('')
      setnewstagbardata(bardata)

    }
  }



  function filterrows_search_bycontainer(event: string[]) {

    const newrows_search: any[] = []
    const newcountrynamelist_search: string[] = []

    initialsearchrows.forEach((row, index) => {
      const rowcountryname: string = initialcountrynamelist_search[index]

      if (event.includes(rowcountryname)) {
        newrows_search.push(row)
        newcountrynamelist_search.push(rowcountryname)
    }});

    setcountrynamelist_search(newcountrynamelist_search);
    setsearchrows(newrows_search);
    setFilteredNames_search(event as string[]);
  }


  function selectentities(cellVals: any) {

    function entityformatter(initialstring: string) {
      let newstring = initialstring.replace('{', '');
      newstring = newstring.replace('}', '');
      newstring = newstring.replace('"', '');
      newstring = newstring.replace('"', '');
      newstring = newstring.replace('GILBERT P. BAYORAN', '');
      return newstring
    }

    const rowdata = cellVals.data
    const people = entityformatter(rowdata.identified_people)
    const orgs = entityformatter(rowdata.identified_orgs)
    const locations = entityformatter(rowdata.identified_location)
    const dates = entityformatter(rowdata.identified_dates)
    const times = entityformatter(rowdata.identified_times)

    setselectedentityinfo("SELECTED ENTITY")
    setselectedpeople(people)
    setselectedorgs(orgs)
    setselectedlocations(locations)
    setselecteddates(dates)
    setselectedtimes(times)

  }

  function tickspacing(inputdata: any[], timeframe: number) { 
    let spacingint = timeframe / 10
    if (timeframe < 15) spacingint = 1
    let emptystr = ''

    inputdata.forEach((row, index) => {
    if (index % spacingint != 0) row.date = emptystr += ' '
    return row
    })

    return inputdata
  }





  return (
    <Section>
      <ToggleBarContainer>
          <Button color="primary" size="large" href={"/news"}>
            NEWS
          </Button>
          <Button size="large" href={"/fusionCenter"}>
            HOME
          </Button>
          <Button size="large" href={"/outliers"}>
            OUTLIERS
          </Button>
        </ToggleBarContainer>
        <ToggleBarContainer>
          <Button size="small" onClick={() => querydatefunc("back")} >
           BACK
          </Button>
          <Button color="secondary" size="medium">
          {querydate}
          </Button>
          <Button size="small" onClick={() => querydatefunc("forward")}>
            FORWARD
          </Button>
        </ToggleBarContainer>
        <ToggleBarContainer>
          <TextField size="small" label="Date Search" margin="dense" helperText="Use YYYY-MM-DD Format" onChange={(e) => setdatetextfieldinput(e.target.value)} InputProps={{endAdornment: <Button onClick={(e) => manualdateinput(datetextfieldinput)} > Enter </Button>}} >
          </TextField>
        </ToggleBarContainer>
        <div style={{height: 50}}>
        </div>
        <ToggleBarContainer>
        <div>
          DISPLAYING SCRAPED NEWS ARTICLES IN: {filteredNames.join(", ")} FOR TAGS: {filteredTags.join(", ")}
        </div>
        </ToggleBarContainer>
        <StyledSearchBar
          value={searched}
          onChange={(search) => filterrows_bysearch(search)}
          onCancelSearch={() => cancelSearch()}
        />
          <div style={{ height: 600, width: '100%'}} >
            <DataGrid rows={tablerows} columns={columns} pageSize={10} rowsPerPageOptions={[5]} className={classes.root} />
          </div>

        <FormContainer>
          <Form>
            <Button onClick={() => resetfilter()}>
            RESET FILTER
            </Button>
            <FormControl component="fieldset" style={{ margin: "20px 0 10px" }} >
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              Filter Container
              </FormLabel>
              <Select
              labelId="filterby_container"
              id="filterby_con"
              multiple
              value={filteredNames}
              onChange={(event) =>
                filterrows_bycontainer(event.target.value as string[])
              }
              input={<Input />}
              renderValue={(selected) => (selected as string[]).join(", ")}
            >
              {containerNames.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={filteredNames.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
            </FormControl>
        <FormControl component="fieldset" style={{ margin: "20px 0 10px" }}>
        <FormLabel component="legend" style={{ margin: "10px 0" }}>
          Filter Tags
        </FormLabel>
        <Select
              labelId="filterby_tags"
              id="filterby_tags"
              multiple
              value={filteredTags}
              onChange={(event) =>
                filterrows_bytag(event.target.value as string[])
              }
              input={<Input />}
              renderValue={(selected) => (selected as string[]).join(", ")}
            >
              {tagNames.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={filteredTags.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
        </FormControl>
        </Form>
        </FormContainer>
        <div style={{ height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            Frequency of Categorical Tags in Filtered News, {tagbarselectedtimeframe}
          </div>
          <div style={{ height: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
            {tagbarloadingstate}
          </div>
          <Chart data={newstagbardata}>
            <ArgumentAxis />
            <ValueAxis />
            <BarSeries valueField="count" argumentField="tag" />
          </Chart>
          <ToggleBarContainer>
        <Button size="small" onClick={() => parsedatatagbar(2)} >
            TODAY
          </Button>
          <Button size="small" onClick={() => parsedatatagbar(7)} >
            7D
          </Button>
          <Button size="small" onClick={() => parsedatatagbar(30)} >
            30D
          </Button>
          <Button size="small" onClick={() => parsedatatagbar(90)} >
            90D
          </Button>
        </ToggleBarContainer>
          <div style={{ height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            % of News Articles by Tag, Previous {selectedtimeframe} Days
          </div>
          <div style={{ height: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
            {loadingstate}
          </div>
          <Chart data={newstagrelevancydata}>
            <ArgumentAxis />
            <ValueAxis />
            <LineSeries name="% Violent" valueField="violent" argumentField="date" />
            <LineSeries name="% Theft" valueField="theft" argumentField="date" />
            <LineSeries name="% Protest" valueField="protest" argumentField="date" />
            <LineSeries name="% Corruption" valueField="corruption" argumentField="date" />
            <LineSeries name="% Gang Activity" valueField="gactivity" argumentField="date" />
            <LineSeries name="% Drugs" valueField="drugs" argumentField="date" />
            <Legend />
          </Chart>
          <ToggleBarContainer>
          <Button size="small" onClick={() => parsedatafornewstagrelevancy(7)} >
            7D
          </Button>
          <Button size="small" onClick={() => parsedatafornewstagrelevancy(30)} >
            30D
          </Button>
          <Button size="small" onClick={() => parsedatafornewstagrelevancy(90)} >
            90D
          </Button>
        </ToggleBarContainer>
        <ToggleBarContainer>
          <TextField size="small" label="Custom Lookback" margin="dense" helperText="Enter number of days" onChange={(e) => setcustomtimeframe(parseInt(e.target.value))} InputProps={{endAdornment: <Button onClick={(e) => parsedatafornewstagrelevancy(customtimeframe)} > Enter </Button>}} >
          </TextField>
        </ToggleBarContainer>
        <div style={{ height: 80}} />
        <div style={{ height: 80, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '40px' }}>
          {selectedentityinfo}
        </div>
        <div style={{ height: 40, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
          PEOPLE: {selectedpeople}
        </div>
        <div style={{ height: 40, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
          ORGANIZATIONS: {selectedorgs}
        </div>
        <div style={{ height: 40, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
          LOCATIONS: {selectedlocations}
        </div>
        <div style={{ height: 40, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
          DATES: {selecteddates}
        </div>
        <div style={{ height: 40, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
          TIMES: {selectedtimes}
        </div>
        <div style={{ height: 80}} />
        <div style={{ height: 40, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '40px' }}>
          ENTIRE NEWS DATABASE SEARCH
        </div>
        <div style={{ height: 30}} />
        <ToggleBarContainer>
          <TextField size="medium" label="Contains..." helperText="Seach News" onChange={(e) => setsearchtextfieldinput(e.target.value)} InputProps={{endAdornment: <ToggleBarContainer> <Button onClick={(e) => newssearchinput(` ${searchtextfieldinput} `)} > Strict Search </Button> <Button onClick={(e) => newssearchinput(`%${searchtextfieldinput}%`)} > Flexible Search </Button> </ToggleBarContainer> }} />
        </ToggleBarContainer>
        <div style={{height: 50}}>
        </div>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 500, width: '100%'}} >
            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
              News Search Results for: "{enteredsearchinput?.replaceAll('%', '')}"
            </div>
            <DataGrid rows={searchrows} columns={searchresultcolumns} pageSize={12} rowsPerPageOptions={[5]} className={classes.root} />
          </div>
          <FormContainer>
          <Form>
            <FormControl component="fieldset" style={{ margin: "20px 0 10px" }} >
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              Filter Container
              </FormLabel>
              <Select
              labelId="filterby_container_search"
              id="filterby_con_search"
              multiple
              value={filteredNames_search}
              onChange={(event) =>
                filterrows_search_bycontainer(event.target.value as string[])
              }
              input={<Input />}
              renderValue={(selected) => (selected as string[]).join(", ")}
            >
              {containerNames_search.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={filteredNames_search.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
            </FormControl>
            </Form>
            </FormContainer>

          <div style={{ height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            Daily Mentions of "{enteredsearchinput?.replaceAll('%', '')}", Previous {newssearchtimeframe} Days
          </div>
          <div style={{ height: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
            {searchlineloadingstate}
          </div>
          <Chart data={historicsearchdata}>
            <ArgumentAxis />
            <ValueAxis />
            <LineSeries name="Number of Daily Mentions" valueField="daily_mentions" argumentField="date" />
            <Legend />
          </Chart>
          <ToggleBarContainer>
          <Button size="small" onClick={() => parsedataforsearchterm(7, enteredsearchinput)} >
            7D
          </Button>
          <Button size="small" onClick={() => parsedataforsearchterm(30, enteredsearchinput)} >
            30D
          </Button>
          <Button size="small" onClick={() => parsedataforsearchterm(90, enteredsearchinput)} >
            90D
          </Button>
          <Button size="small" onClick={() => parsedataforsearchterm(180, enteredsearchinput)} >
            180D
          </Button>
          <Button size="small" onClick={() => parsedataforsearchterm(360, enteredsearchinput)} >
            360D
          </Button>
        </ToggleBarContainer>
        <ToggleBarContainer>
          <TextField size="small" label="Custom Lookback" margin="dense" helperText="Enter number of days" onChange={(e) => setcustomtimeframe(parseInt(e.target.value))} InputProps={{endAdornment: <Button onClick={(e) => parsedataforsearchterm(customtimeframe, enteredsearchinput)} > Enter </Button>}} >
          </TextField>
        </ToggleBarContainer>





      </Section>


  )

} 