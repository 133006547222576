import React, { useState, useEffect } from "react";

// redux
import { useSelectFromRedux, useDispatchPromise } from "../../utils/_hooks";
import { useDispatch } from "react-redux";
import { Filter_TimePeriod, filterActions, getInitialState, FilterState } from "../../state/filter";
import { fetchPublicReportsFromContainer } from "../../state/publicInfo/operations";
import { mapActions } from "../../state/map";

// components
import { Button, StyledTag, ShowScrollbarDiv} from "./styled";
import { Select, MenuItem, Switch, TextField, Paper, Tab, Tabs } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Row, VerticallyCenteredRow } from "../../_shared";
import { SelectValue } from "antd/lib/select";

// icons
import { getContainerViewportFromId, getDefaultRegionViewportFromId, S3Key } from "../../utils";
import { DefaultRegion } from "../../types/geo";
import { AllReportTypes } from "../../types/reports";
import { publicInfoOperations } from "../../state/publicInfo";
import { isEqual } from "lodash";
import { cuserActions } from "../../state/cuser";
// import _ from "lodash";
const CloseWhiteIcon = S3Key + "close-white.png";
const SearchGreyIcon = S3Key + "search-grey.png";
const SearchWhiteIcon = S3Key + "search-white.png";

export interface DefaultRegionNoViewport {
    id: number;
    name: string;
    container_id: 0;
};

export default () => {
    const dispatch = useDispatch();
    const dispatchPromise = useDispatchPromise();

    const [currentFilter, cuser] = useSelectFromRedux((state) => [state.filter, state.cuser]);
    const current_container = useSelectFromRedux((state) => state.cuser.current_container);
    const colors = useSelectFromRedux((state) => state.color);
    const defaultRegions = useSelectFromRedux((state) => state.cuser.defaultRegions);
    const containers = useSelectFromRedux((state) => state.cuser.containers);
    const tooltipOpen = useSelectFromRedux((state) => state.map.showTooltip);
    const content = useSelectFromRedux((state) => state.map.content);
    const {showAssets, showRisks, filterScenarios} = useSelectFromRedux((state) => state.map);

    // Front-end options for each dropdown – reflect filterState but with 'All' instead of null for display
    const timePeriodOptions = ["All Time", "28 Day", "7 Day", "24 Hour"];

    let eventTypeNames = React.useMemo(
        () =>
            Array.from(
                new Set(
                    AllReportTypes.map((reportType: string) => ({
                        value: reportType,
                        key: reportType
                    }))
                )
            ),
        []
    );

    const [regionChange, updateRegionChange] = useState<number>(0);
    const [selectedTimePeriod, updateTimePeriod] = useState<string>(
        currentFilter.timePeriod === null
            ? "All Time"
            : currentFilter.timePeriod === "28 Day"
            ? "28 Day"
            : currentFilter.timePeriod
    );
    const [markerClustering, updateMarkerClustering] = useState<boolean>(currentFilter.markerClustering);

    const [incidentsChecked, updateIncidentsChecked] = useState<boolean>(currentFilter.incidentsIncluded);
    const [sosChecked, updateSosChecked] = useState<boolean>(currentFilter.sosIncluded);
    const [filteredUsers, updateFilteredUsers] = useState<number[]>(currentFilter.filteredUsers);
    const [teamFilter, updateTeamFilter] = useState<number | null>(
        currentFilter.teamFilter ? currentFilter.teamFilter : null
    );
    const [violentChecked, updateViolentChecked] = useState<boolean>(currentFilter.violentIncluded);
    const [nonviolentChecked, updateNonviolentChecked] = useState<boolean>(currentFilter.nonviolentIncluded);
    const [hazardChecked, updateHazardChecked] = useState<boolean>(currentFilter.hazardIncluded);
    const [otherChecked, updateOtherChecked] = useState<boolean>(currentFilter.otherIncluded);

    const [orionSourceChecked, updateOrionSourceChecked] = useState<boolean>(currentFilter.orionSourceIncluded);
    const [orionUserChecked, updateOrionUserChecked] = useState<boolean>(currentFilter.orionUserIncluded);
    const [openSourceChecked, updateOpenSourceChecked] = useState<boolean>(currentFilter.openSourceIncluded);
    const [myOrganizationChecked, updateMyOrganizationChecked] = useState<boolean>(
        currentFilter.myOrganizationIncluded
    );
    const [myReportsChecked, updateMyReportsChecked] = useState<boolean>(currentFilter.myReportsIncluded);
    const [verifiedChecked, updateVerifiedChecked] = useState<boolean>(currentFilter.verifiedIncluded);
    const [unverifiedChecked, updateUnverifiedChecked] = useState<boolean>(currentFilter.verifiedIncluded);

    const [filterChanged, updateFilterChanged] = useState<boolean>(false);

    const [filterChangedFromDefault, updateFilterChangedFromDefault] = useState<boolean>(false);
    const [eventTypeSearchOpen, updateEventTypeSearchOpen] = useState<boolean>(false);
    const [selectedEventTypes, updateSelectedEventTypes] = useState<string[]>(currentFilter.eventTypeApplied ?? []);

    const [eventChecked, updateEventChecked] = useState<boolean>(false);

    const [myTeamChecked, updateMyTeamChecked] = useState<boolean>(
        currentFilter.myTeamIncluded ? currentFilter.myTeamIncluded : false
    );

    const initCurrentContainer = cuser.current_container;
    if (!initCurrentContainer) {
        console.error("Could not get current container in drawer.");
        return null;
    }
    const [currentContainer, updateCurrentContainer] = useState<number>(initCurrentContainer);
    const availableRegions = defaultRegions.filter((region) => region.container_id === currentContainer);
    if (!availableRegions || !current_container) {
        console.error("Container has no regions? Returning");
        return null;
    }
    const defaultAllRegion: DefaultRegionNoViewport = {
        id: 0,
        name: "All Areas",
        container_id: 0
    };
    const regionSelections = [defaultAllRegion, ...availableRegions];
    const [selectedRegion, updateSelectedRegion] = useState<number>(currentFilter.selectedRegion);

    useEffect(() => {
        if (current_container) {
            updateCurrentContainer(current_container);
        }
    }, [current_container]);

    const clearPostedBy = async () => {
        updateMyReportsChecked(false);
        updateOrionSourceChecked(false);
        updateOrionUserChecked(false);
        updateOpenSourceChecked(false);
        updateMyOrganizationChecked(false);
        updateFilteredUsers(getInitialState().filteredUsers);
        updateTeamFilter(null);
    };
    useEffect(() => {
        const timePeriod = selectedTimePeriod === "All Time" ? null : selectedTimePeriod;

        const changedFilter: FilterState = {
            ...currentFilter,
            timePeriod: timePeriod === "28 Day" ? "28 Day" : (timePeriod as Filter_TimePeriod),
            markerClustering: markerClustering,
            incidentsIncluded: incidentsChecked,
            sosIncluded: sosChecked,
            myOrganizationIncluded: myOrganizationChecked,
            myReportsIncluded: myReportsChecked,
            violentIncluded: violentChecked,
            nonviolentIncluded: nonviolentChecked,
            hazardIncluded: hazardChecked,
            otherIncluded: otherChecked,
            orionSourceIncluded: orionSourceChecked,
            orionUserIncluded: orionUserChecked,
            openSourceIncluded: openSourceChecked,
            selectedRegion: selectedRegion,
            verifiedIncluded: verifiedChecked,
            unverifiedIncluded: unverifiedChecked,
            teamFilter: teamFilter,
            myTeamIncluded: myTeamChecked,
            eventTypeApplied: selectedEventTypes
        };
        updateFilterChangedFromDefault(!isEqual(changedFilter, getInitialState()));
    }, [selectedTimePeriod, markerClustering, incidentsChecked, sosChecked, myOrganizationChecked, myReportsChecked, violentChecked, nonviolentChecked, hazardChecked, otherChecked, orionSourceChecked, orionUserChecked, openSourceChecked, selectedRegion, filteredUsers, verifiedChecked, unverifiedChecked, currentFilter, teamFilter, myTeamChecked, selectedEventTypes]);

    useEffect(() => {
        const timePeriod = selectedTimePeriod === "All Time" ? null : selectedTimePeriod;

        const changedFilter: FilterState = {
            ...currentFilter,
            timePeriod: timePeriod === "28 Day" ? "28 Day" : (timePeriod as Filter_TimePeriod),
            markerClustering: markerClustering,
            incidentsIncluded: incidentsChecked,
            sosIncluded: sosChecked,
            myOrganizationIncluded: myOrganizationChecked,
            myReportsIncluded: myReportsChecked,
            violentIncluded: violentChecked,
            nonviolentIncluded: nonviolentChecked,
            hazardIncluded: hazardChecked,
            otherIncluded: otherChecked,
            orionSourceIncluded: orionSourceChecked,
            orionUserIncluded: orionUserChecked,
            openSourceIncluded: openSourceChecked,
            selectedRegion: selectedRegion,
            verifiedIncluded: verifiedChecked,
            unverifiedIncluded: unverifiedChecked,
            teamFilter: teamFilter,
            myTeamIncluded: myTeamChecked,
            eventTypeApplied: selectedEventTypes
        };
        updateFilterChanged(!isEqual(changedFilter, currentFilter) || currentContainer !== current_container);
    }, [selectedTimePeriod, markerClustering, incidentsChecked, sosChecked, myOrganizationChecked, myReportsChecked, violentChecked, nonviolentChecked, hazardChecked, otherChecked, orionSourceChecked, orionUserChecked, openSourceChecked, selectedRegion, filteredUsers, currentFilter, currentContainer, current_container, verifiedChecked, unverifiedChecked, teamFilter, myTeamChecked, selectedEventTypes]);

    const updateFilter = () => {
        if (filterChanged) {
            const timePeriod = selectedTimePeriod === "All Time" ? null : selectedTimePeriod;
            if (tooltipOpen) {
                dispatch(mapActions.toggleTooltip());
            }

            dispatch(
                filterActions.updateFilter({
                    timePeriod: timePeriod === "28 Day" ? "28 Day" : (timePeriod as Filter_TimePeriod),
                    markerClustering: markerClustering,
                    incidentsIncluded: incidentsChecked,
                    sosIncluded: sosChecked,
                    myOrganizationIncluded: myOrganizationChecked,
                    myReportsIncluded: myReportsChecked,
                    violentIncluded: violentChecked,
                    nonviolentIncluded: nonviolentChecked,
                    hazardIncluded: hazardChecked,
                    otherIncluded: otherChecked,
                    orionSourceIncluded: orionSourceChecked,
                    orionUserIncluded: orionUserChecked,
                    openSourceIncluded: openSourceChecked,
                    selectedRegion: selectedRegion,
                    filteredUsers: filteredUsers,
                    verifiedIncluded: verifiedChecked,
                    unverifiedIncluded: unverifiedChecked,
                    teamFilter: teamFilter,
                    myTeamIncluded: myTeamChecked,
                    eventTypeApplied: selectedEventTypes
                })
            );

            if (currentContainer !== current_container && regionChange === selectedRegion) {
                setCurrentContainer(currentContainer);
            }

            if (regionChange !== selectedRegion) {
                setSelectedRegion(selectedRegion);
                updateRegionChange(selectedRegion);
            }
        }
    };

    const availableContainers = useSelectFromRedux((state) => state.cuser.containers);
    if (!availableContainers) {
        console.error("Could not get containers in drawer.");
        return null;
    }

    const setCurrentContainer = (value: SelectValue) => {
        if (typeof value !== "number") {
            console.error("Invalid container id selected: " + value);
        }
        const container_id = value as number;
        dispatchPromise(fetchPublicReportsFromContainer(container_id))
            .then(() => {
                updateCurrentContainer(container_id);
                const containerViewportPoint = getContainerViewportFromId(container_id, availableContainers);
                dispatch(cuserActions.updateCurrentContainer(container_id));
                dispatch(
                    mapActions.flyToPoint([
                        containerViewportPoint.longitude,
                        containerViewportPoint.latitude,
                        containerViewportPoint.zoom
                    ])
                );
            })
            .catch((err: Error) => {
                console.error(err, "Error on updating container");
            });
    };

    const setSelectedRegion = (value: number) => {
        if (value !== 0) {
            const regionViewportInfo = getDefaultRegionViewportFromId(value as number, availableRegions);
            dispatch(
                mapActions.flyToPoint([
                    regionViewportInfo.longitude,
                    regionViewportInfo.latitude,
                    regionViewportInfo.zoom
                ])
            );
        } else {
            if (!current_container || !containers) {
                return;
            }
            const containerViewportInfo = getContainerViewportFromId(current_container, containers);
            dispatch(
                mapActions.flyToPoint([
                    containerViewportInfo.longitude,
                    containerViewportInfo.latitude,
                    containerViewportInfo.zoom
                ])
            );
        }
        // TEMPORARY FIX: flyTo seems to interfere with filter, and this solves it
        dispatch(publicInfoOperations.fetchPublicReportsFromContainer());
    };

    const resetFilter = () => {
        updateTimePeriod("28 Day");
        updateRegionChange(0);
        updateSelectedRegion(0);

        // TODO SB-232 the above fields should also be set based on the filter's initial state
        updateMarkerClustering(getInitialState().markerClustering);
        updateSosChecked(getInitialState().sosIncluded);
        updateIncidentsChecked(getInitialState().incidentsIncluded);
        updateMyOrganizationChecked(getInitialState().myOrganizationIncluded);
        updateMyReportsChecked(getInitialState().myReportsIncluded);
        updateViolentChecked(getInitialState().violentIncluded);
        updateNonviolentChecked(getInitialState().nonviolentIncluded);
        updateHazardChecked(getInitialState().hazardIncluded);
        updateOtherChecked(getInitialState().otherIncluded);
        updateOrionSourceChecked(getInitialState().orionSourceIncluded);
        updateOrionUserChecked(getInitialState().orionUserIncluded);
        updateOpenSourceChecked(getInitialState().openSourceIncluded);
        updateFilteredUsers(getInitialState().filteredUsers);
        updateVerifiedChecked(getInitialState().verifiedIncluded);
        updateUnverifiedChecked(getInitialState().unverifiedIncluded);
        updateSelectedEventTypes([]);
        updateEventTypeSearchOpen(false);
        updateTeamFilter(null);
        updateMyTeamChecked(false);

        dispatch(filterActions.resetFilter());
    };

    const setSelectedEventTypes = (value: string, operation: string) => {
        if (operation === "add") {
            if (!selectedEventTypes.includes(value)) {
                const newSelectedEventTypes = selectedEventTypes.concat(value);
                updateSelectedEventTypes(newSelectedEventTypes);
            }
        } else if (operation === "delete") {
            if (selectedEventTypes.includes(value)) {
                const selectedEventTypesCopy = [...selectedEventTypes];
                const index = selectedEventTypesCopy.indexOf(value);
                selectedEventTypesCopy.splice(index, 1);
                updateSelectedEventTypes(selectedEventTypesCopy);
            }
        } else if (operation === "clear") {
            updateSelectedEventTypes([]);
        }
    };

    useEffect(() => {
        if (selectedEventTypes.length > 0) {
            updateViolentChecked(false);
            updateNonviolentChecked(false);
            updateHazardChecked(false);
            updateOtherChecked(false);
        }
    }, [selectedEventTypes, updateViolentChecked, updateNonviolentChecked, updateHazardChecked, updateOtherChecked]);

    useEffect(() => {
        if (violentChecked === true || nonviolentChecked === true || hazardChecked === true || otherChecked === true) {
            setSelectedEventTypes("all", "clear");
            updateEventTypeSearchOpen(false);
        }
        // don't want it to update every time setSelectedEventTypes does
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [violentChecked, nonviolentChecked, hazardChecked, otherChecked]);

    return (
        <div style={{width: "100%"}}>
            <div style={{
                display: "flex", 
                flexDirection: "row", 
                width: "100%",
                justifyContent: "space-evenly",
                marginLeft: "40px",
                paddingRight: "40px",
                alignItems: "right"
            }}>
                <div style={{flex: 1, flexDirection: "row", display: "flex"}}>
                    <span style={{ color: colors.scheme.primaryText, marginTop: "13px", fontWeight: "bold" }}>
                        Content
                    </span>
                    <VerticallyCenteredRow style={{ maxWidth: "500px", minWidth: "200px", marginLeft: "30px" }}>
                        <Paper square>
                            <Tabs
                                defaultValue="menu"
                                value={content}
                                onChange={(event, newValue) => dispatch(mapActions.setContent(newValue))}
                                style={{ width: "320px" }}
                            >
                                <Tab
                                    value="menu"
                                    label="Normal"
                                    style={{minWidth: "80px", maxWidth: "120px", marginRight: "0px"}}
                                />
                                <Tab
                                    value="visualizations"
                                    label="Forecasting"
                                    style={{minWidth: "110px", maxWidth: "120px", marginRight: "0px"}}
                                />
                                <Tab
                                    value="analytics"
                                    label="Time Range"
                                    style={{minWidth: "110px", maxWidth: "120px", marginRight: "0px"}}
                                />
                            </Tabs>
                        </Paper>
                    </VerticallyCenteredRow>
                </div>
                {content === "menu" &&
                <div style={{flex: 1, flexDirection: "row", display: "flex", marginTop: "3px", marginLeft: "30px"}}>
                    <span style={{ color: colors.scheme.primaryText, marginTop: "10px", fontWeight: "bold", width: "80px" }}>
                        Marker Clustering
                    </span>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            maxWidth: "80px",
                            minWidth: "30px"
                        }}
                    >
                        <Switch
                            checked={currentFilter.markerClustering}
                            onChange={(event) => dispatch(filterActions.updateFilter({...currentFilter, markerClustering: event.target.checked}))}
                            name={"clusteringChecked"}
                        />
                    </div>
                </div>}
                <div style={{flex: 1, flexDirection: "row", display: "flex", marginTop: "3px", marginLeft: "30px"}}>
                    <span style={{ color: colors.scheme.primaryText, marginTop: "10px", fontWeight: "bold", width: "80px" }}>
                        Show Assets
                    </span>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            maxWidth: "80px",
                            minWidth: "30px"
                        }}
                    >
                        <Switch
                            checked={showAssets}
                            onChange={(event) => dispatch(mapActions.updateShowAssets(event.target.checked))}
                            name={"showAssetsChecked"}
                        />
                    </div>
                </div>
                <div style={{flex: 1, flexDirection: "row", display: "flex", marginTop: "3px", marginLeft: "30px"}}>
                    <span style={{ color: colors.scheme.primaryText, marginTop: "10px", fontWeight: "bold", width: "80px" }}>
                        Show Risks
                    </span>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            maxWidth: "80px",
                            minWidth: "30px"
                        }}
                    >
                        <Switch
                            checked={showRisks}
                            onChange={(event) => dispatch(mapActions.updateShowRisks(event.target.checked))}
                            name={"showRisksChecked"}
                        />
                    </div>
                </div>
                <div style={{flex: 1, flexDirection: "row", display: "flex", marginTop: "3px", marginLeft: "30px"}}>
                    <span style={{ color: colors.scheme.primaryText, marginTop: "10px", fontWeight: "bold", width: "80px" }}>
                        Show Scenarios
                    </span>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            maxWidth: "80px",
                            minWidth: "30px"
                        }}
                    >
                        <Switch
                            checked={!filterScenarios}
                            onChange={(event) => dispatch(mapActions.setFilterScenarios(!event.target.checked))}
                            name={"showScenariosChecked"}
                        />
                    </div>
                </div>
                <div
                    style={{
                        maxWidth: "400px",
                        minWidth: "200px",
                        alignSelf: "center",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        background: colors.scheme.modalBottomBar,
                        margin: "0px 40px 8px -20px",
                        borderRadius: "14px"
                    }}
                >
                    <Button
                        onClick={() => {
                            updateFilter();
                        }}
                        active={filterChanged}
                        color={filterChanged ? colors.scheme.submitButtonText : "#4C4C4C"}
                        background={
                            filterChanged ? colors.scheme.submitButtonBackground : colors.scheme.filterUnselectedTag
                        }
                    >
                        Apply
                    </Button>
                    <Button
                        onClick={() => {
                            resetFilter();
                        }}
                        active={filterChangedFromDefault}
                        color={filterChangedFromDefault ? colors.scheme.submitButtonText : "#4C4C4C"}
                        background={
                            filterChangedFromDefault
                                ? colors.scheme.submitButtonBackground
                                : colors.scheme.filterUnselectedTag
                        }
                    >
                        Reset
                    </Button>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    width: "100%",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    paddingLeft: "3%",
                    paddingRight: "3%",
                    marginTop: "10px",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}
                >
                    <div style={{flexDirection: "column"}}>
                        <div style={{ color: colors.scheme.primaryText, fontWeight: "bold" }}>Region</div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingBottom: "10px"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    borderBottom: "1px solid ".concat(colors.scheme.secondaryHeaderText)
                                }}
                            >
                                <Select
                                    defaultValue={selectedRegion || undefined}
                                    value={selectedRegion}
                                    onChange={(event) => updateSelectedRegion(event.target.value as number)}
                                >
                                    {regionSelections.map((region: DefaultRegion | DefaultRegionNoViewport) => {
                                        return (
                                            <MenuItem key={region.id} value={region.id}>
                                                {region.name}{" "}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                        <span style={{ color: colors.scheme.primaryText, fontWeight: "bold", marginTop: "5px" }}>
                            Time Period
                        </span>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingBottom: "10px"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    textAlign: "left",
                                    borderBottom: "1px solid ".concat(colors.scheme.secondaryHeaderText)
                                }}
                            >
                                <Select
                                    onChange={(event) => {
                                        updateTimePeriod(event.target.value as string);
                                    }}
                                    value={selectedTimePeriod}
                                    defaultValue={selectedTimePeriod}
                                >
                                        {timePeriodOptions.map((el, index) => (
                                                <MenuItem value={el} key={index}>
                                                    {el}
                                                </MenuItem>
                                        ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div style={{flexDirection: "column", marginLeft: 10, marginRight: 10}}>
                        <Row style={{ justifyContent: "space-between" }}>
                            <div style={{ color: colors.scheme.primaryText, fontWeight: "bold", margin: "5px 0" }}>
                                Posted by
                            </div>
                            <div
                                onClick={clearPostedBy}
                                style={{
                                    position: "relative",
                                    color: colors.scheme.secondaryHeaderText,
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    marginTop: "10px",
                                    marginRight: "14px",
                                    textTransform: "uppercase",
                                    fontSize: "12px"
                                }}
                            >
                                Clear
                            </div>
                        </Row>
                        <ShowScrollbarDiv
                            color={colors.scheme.primaryText}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingBottom: "5px",
                                width: "100%",
                                maxHeight: "153px",
                                overflowY: "scroll",
                                scrollSnapType: "y mandatory",
                                scrollSnapStop: "always"
                            }}
                        >
                            <StyledTag
                                fontcolor={orionSourceChecked ? "#FFFFFF" : "#4C4C4C"}
                                background={
                                    orionSourceChecked ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag
                                }
                                checked={orionSourceChecked}
                                onChange={(checked) => {
                                    updateOrionSourceChecked(!orionSourceChecked);
                                }}
                            >
                                <div>Orion Asset</div>
                                {orionSourceChecked && (
                                    <img
                                        src={CloseWhiteIcon}
                                        alt={"close"}
                                        style={{
                                            position: "relative",
                                            marginTop: "3px",
                                            height: "12px",
                                            zIndex: 100,
                                            justifySelf: "right",
                                            marginRight: "8px"
                                        }}
                                    />
                                )}
                            </StyledTag>
                            <StyledTag
                                fontcolor={orionUserChecked ? "#FFFFFF" : "#4C4C4C"}
                                background={
                                    orionUserChecked ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag
                                }
                                checked={orionUserChecked}
                                onChange={(checked) => {
                                    updateOrionUserChecked(!orionUserChecked);
                                }}
                            >
                                <div>Orion User</div>
                                {orionUserChecked && (
                                    <img
                                        src={CloseWhiteIcon}
                                        alt={"close"}
                                        style={{
                                            position: "relative",
                                            marginTop: "3px",
                                            height: "12px",
                                            zIndex: 100,
                                            justifySelf: "right",
                                            marginRight: "8px"
                                        }}
                                    />
                                )}
                            </StyledTag>
                            <StyledTag
                                fontcolor={openSourceChecked ? "#FFFFFF" : "#4C4C4C"}
                                background={
                                    openSourceChecked ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag
                                }
                                checked={openSourceChecked}
                                onChange={(checked) => {
                                    updateOpenSourceChecked(!openSourceChecked);
                                }}
                            >
                                <div>Open Source</div>
                                {openSourceChecked && (
                                    <img
                                        src={CloseWhiteIcon}
                                        alt={"close"}
                                        style={{
                                            position: "relative",
                                            marginTop: "3px",
                                            height: "12px",
                                            zIndex: 100,
                                            justifySelf: "right",
                                            marginRight: "8px"
                                        }}
                                    />
                                )}
                            </StyledTag>
                            <StyledTag
                                fontcolor={
                                    orionSourceChecked && orionUserChecked && openSourceChecked ? "#FFFFFF" : "#4C4C4C"
                                }
                                background={
                                    orionSourceChecked && orionUserChecked && openSourceChecked
                                        ? colors.scheme.filterSelectedTag
                                        : colors.scheme.filterUnselectedTag
                                }
                                checked={orionSourceChecked && orionUserChecked && openSourceChecked}
                                onChange={(checked) => {
                                    updateOrionUserChecked(!orionUserChecked);
                                    updateOpenSourceChecked(!openSourceChecked);
                                    updateOrionSourceChecked(!orionSourceChecked);
                                }}
                            >
                                <div>Public Reports</div>
                                {orionSourceChecked && orionUserChecked && openSourceChecked && (
                                    <img
                                        src={CloseWhiteIcon}
                                        alt={"close"}
                                        style={{
                                            position: "relative",
                                            marginTop: "3px",
                                            height: "12px",
                                            zIndex: 100,
                                            justifySelf: "right",
                                            marginRight: "8px"
                                        }}
                                    />
                                )}
                            </StyledTag>
                        </ShowScrollbarDiv>
                    </div>
                    <div style={{flexDirection: "column", marginLeft: 10, marginRight: 10}}>
                        <div style={{ color: colors.scheme.primaryText, fontWeight: "bold", margin: "5px 0" }}>
                            Category of Incident
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingBottom: "5px",
                                width: "100%"
                            }}
                        >
                            <StyledTag
                                fontcolor={violentChecked ? "#FFFFFF" : "#4C4C4C"}
                                background={
                                    violentChecked ? colors.scheme.filterViolentTag : colors.scheme.filterUnselectedTag
                                }
                                checked={violentChecked}
                                onChange={(checked) => updateViolentChecked(!violentChecked)}
                            >
                                <div>Violent</div>
                                {violentChecked && (
                                    <img
                                        src={CloseWhiteIcon}
                                        alt={"close"}
                                        style={{
                                            position: "relative",
                                            marginTop: "3px",
                                            height: "12px",
                                            zIndex: 100,
                                            justifySelf: "right",
                                            marginRight: "8px"
                                        }}
                                    />
                                )}
                            </StyledTag>
                            <StyledTag
                                fontcolor={nonviolentChecked ? "#FFFFFF" : "#4C4C4C"}
                                background={
                                    nonviolentChecked
                                        ? colors.scheme.filterNonViolentTag
                                        : colors.scheme.filterUnselectedTag
                                }
                                checked={nonviolentChecked}
                                onChange={(checked) => updateNonviolentChecked(!nonviolentChecked)}
                            >
                                <div>Nonviolent</div>
                                {nonviolentChecked && (
                                    <img
                                        src={CloseWhiteIcon}
                                        alt={"close"}
                                        style={{
                                            position: "relative",
                                            marginTop: "3px",
                                            height: "12px",
                                            zIndex: 100,
                                            justifySelf: "right",
                                            marginRight: "8px"
                                        }}
                                    />
                                )}
                            </StyledTag>
                            <StyledTag
                                fontcolor={hazardChecked ? "#FFFFFF" : "#4C4C4C"}
                                background={
                                    hazardChecked ? colors.scheme.filterHazardTag : colors.scheme.filterUnselectedTag
                                }
                                checked={hazardChecked}
                                onChange={(checked) => updateHazardChecked(!hazardChecked)}
                            >
                                <div>Hazard</div>
                                {hazardChecked && (
                                    <img
                                        src={CloseWhiteIcon}
                                        alt={"close"}
                                        style={{
                                            position: "relative",
                                            marginTop: "3px",
                                            height: "12px",
                                            zIndex: 100,
                                            justifySelf: "right",
                                            marginRight: "8px"
                                        }}
                                    />
                                )}
                            </StyledTag>
                            <StyledTag
                                fontcolor={otherChecked ? "#FFFFFF" : "#4C4C4C"}
                                background={
                                    otherChecked ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag
                                }
                                checked={otherChecked}
                                onChange={(checked) => updateOtherChecked(!otherChecked)}
                            >
                                <div>Other</div>
                                {otherChecked && (
                                    <img
                                        src={CloseWhiteIcon}
                                        alt={"close"}
                                        style={{
                                            position: "relative",
                                            marginTop: "3px",
                                            height: "12px",
                                            zIndex: 100,
                                            justifySelf: "right",
                                            marginRight: "8px"
                                        }}
                                    />
                                )}
                            </StyledTag>
                        </div>
                    </div>
                    <div style={{flexDirection: "column", marginLeft: 10, marginRight: 10}}>
                        <div style={{ color: colors.scheme.primaryText, fontWeight: "bold", margin: "5px 0" }}>
                            Type of Event
                        </div>
                        {eventTypeSearchOpen && (
                            <Autocomplete
                                id="event-type-selector"
                                options={eventTypeNames}
                                getOptionLabel={(option: { value: any; }) => option.value}
                                renderInput={(params) => <TextField {...params} label="Event Type" variant="outlined" />}
                                onChange={(event, newValue) => setSelectedEventTypes(newValue?.value as string, "add")}
                            />
                        )}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingBottom: "5px",
                                width: "100%"
                            }}
                        >
                            {selectedEventTypes.length === 0 && (
                                <StyledTag
                                    fontcolor={"#FFFFFF"}
                                    background={colors.scheme.filterSelectedTag}
                                    checked={eventTypeSearchOpen}
                                    onChange={(checked) => updateEventTypeSearchOpen(!eventTypeSearchOpen)}
                                    style={{width: "200px"}}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        Search Event Types
                                        <img
                                            src={colors.mode === "light" ? SearchGreyIcon : SearchWhiteIcon}
                                            alt={"search"}
                                            style={{
                                                height: "12px",
                                                marginLeft: "5px"
                                            }}
                                        />
                                    </div>
                                </StyledTag>
                            )}
                            {selectedEventTypes.map((eventType) => (
                                <StyledTag
                                    fontcolor={"#FFFFFF"}
                                    background={colors.scheme.filterSelectedTag}
                                    checked={eventChecked}
                                    key={eventType}
                                    onChange={() => {
                                        updateEventChecked(!eventChecked);
                                        setSelectedEventTypes(eventType, "delete");
                                    }}
                                    style={{width: "200px"}}
                                >
                                    <div>{eventType}</div>
                                    <img
                                        src={CloseWhiteIcon}
                                        alt={"close"}
                                        style={{
                                            position: "relative",
                                            marginTop: "3px",
                                            height: "12px",
                                            zIndex: 100,
                                            justifySelf: "right",
                                            marginRight: "8px"
                                        }}
                                    />
                                </StyledTag>
                            ))}
                        </div>
                    </div>
                    <div style={{flexDirection: "column", marginLeft: 10, marginRight: 10}}>
                        <div style={{ color: colors.scheme.primaryText, fontWeight: "bold", margin: "5px 0" }}>
                            Verification
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingBottom: "5px"
                            }}
                        >
                            <StyledTag
                                fontcolor={verifiedChecked ? "#FFFFFF" : "#4C4C4C"}
                                background={
                                    verifiedChecked ? colors.general.standardGreen : colors.scheme.filterUnselectedTag
                                }
                                checked={verifiedChecked}
                                onChange={(checked) => updateVerifiedChecked(!verifiedChecked)}
                            >
                                <div>Verified</div>
                                {verifiedChecked && (
                                    <img
                                        src={CloseWhiteIcon}
                                        alt={"close"}
                                        style={{
                                            position: "relative",
                                            marginTop: "3px",
                                            height: "12px",
                                            zIndex: 100,
                                            justifySelf: "right",
                                            marginRight: "8px"
                                        }}
                                    />
                                )}
                            </StyledTag>
                            <StyledTag
                                fontcolor={unverifiedChecked ? "#FFFFFF" : "#4C4C4C"}
                                background={unverifiedChecked ? colors.general.amber : colors.scheme.filterUnselectedTag}
                                checked={unverifiedChecked}
                                onChange={(checked) => updateUnverifiedChecked(!unverifiedChecked)}
                            >
                                <div>Unverified</div>
                                {unverifiedChecked && (
                                    <img
                                        src={CloseWhiteIcon}
                                        alt={"close"}
                                        style={{
                                            position: "relative",
                                            marginTop: "3px",
                                            height: "12px",
                                            zIndex: 100,
                                            justifySelf: "right",
                                            marginRight: "8px"
                                        }}
                                    />
                                )}
                            </StyledTag>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
