import React, { createContext, useContext } from "react";

const CatchContext = createContext<(error: any) => void>((error) => console.error(error));

export const useThrow = () => useContext(CatchContext);

interface CatchProps {
    children: (error: any, clearError: () => void) => React.ReactNode
}

export default class Catch extends React.Component<CatchProps, { error: any }> {
    constructor(props: CatchProps) {
        super(props);
        this.state = { error: undefined };
    }

    static getDerivedStateFromError(error: any): { error: any } {
        return { error };
    }

    error = (error: any) => {
        this.setState(Catch.getDerivedStateFromError(error));
    }

    clearError = () => {
        this.setState({ error: undefined });
    }

    render() {
        return (
            <CatchContext.Provider value={this.error}>
              {this.props.children(this.state.error, this.clearError)}
            </CatchContext.Provider>
        );
    }
};
