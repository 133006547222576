import { produce } from "immer";
import { payloadAction, actionFactory, ActionUnion } from "reductser";
import moment from "moment";

const actionMap = {
    updateTimeFilter: payloadAction<number[]>()
};

export const timeFilterActions = actionFactory(actionMap, "TIME_FILTER");

export type TimeFilterAction = ActionUnion<typeof timeFilterActions>;

export interface TimeFilterState {
    timeFilter: number[];
}

export const getInitialState = (): TimeFilterState => ({
    timeFilter: [moment().subtract(7, "d").valueOf(), moment().valueOf()]
});

const TimeFilterReducer = (state = getInitialState(), action: TimeFilterAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "TIME_FILTER") {
            switch (action.type) {
                case "updateTimeFilter":
                    draftState.timeFilter = action.payload;
                    return;
                default:
                    return;
            }
        }
    });

export default TimeFilterReducer;
