import { default as React,
         useState,
         useEffect,
        //  useMemo,
         useRef } from "react";
import { default as MaterialTable,
         MaterialTableProps,
         EditComponentProps,
         Column } from "material-table";
import * as MapboxGl from "mapbox-gl";
import { default as ReactMapboxGl,
         ZoomControl,
         GeoJSONLayer } from "react-mapbox-gl";
// import { default as DrawControl,
//          DrawControlProps } from "react-mapbox-gl-draw";
import { useCookies } from "react-cookie";
import { Icon,
         IconProps,
         Popover,
         Button,
         TextField } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { Polygon, MultiPolygon } from "geojson";
// import { Feature } from "geojson"

import { Container,
         DefaultRegion,
         ViewportBase } from "../types/geo";
import { useThrow } from "../catch";
import adjustColor from "./adjustColor";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import {
  createContainer,
  createDefaultRegion,
  getAllContainers,
  getAllDefaultRegions,
  updateContainer,
  updateDefaultRegion} from "../types/api/admin";
import {NewContainer, NewDefaultRegion} from "../types/admin";
import { useSelectFromRedux } from "../utils/_hooks";

interface DisplayContainer extends Container {
    type: "Container";
}

interface DisplayDefaultRegion extends DefaultRegion {
    type: "DefaultRegion";
}

type RowData = DisplayContainer | DisplayDefaultRegion;

const Map = ReactMapboxGl({
    accessToken: "pk.eyJ1IjoiYXRlbmt1bWFyIiwiYSI6ImNrYjlrcno3NTBmM3kzMm8wbWh4cjl3cWcifQ.vAoeZsaclJ5JD9Hr49jTDA"
});

const ReadonlyMap = ReactMapboxGl({
    accessToken: "pk.eyJ1IjoiYXRlbmt1bWFyIiwiYSI6ImNrYjlrcno3NTBmM3kzMm8wbWh4cjl3cWcifQ.vAoeZsaclJ5JD9Hr49jTDA",
    interactive: false
});

const FILL_PAINT = {
    "fill-color": "#f44336",
    "fill-opacity": 0.5
};

const PopoutButton = (props: any) => (
    <Button {...props} variant="contained" color="primary" />
);

const PopoutLink = styled(Button)`
    ${({ theme }) => `
    border-bottom: 2px dashed ${theme.palette.text.secondary};
    border-radius: 0;
    background-color: transparent !important;

    &:hover {
        border-bottom-style: solid;
        border-bottom-color: ${theme.palette.text.primary};
    }
    `}
`;

const Popout = ({
    title,
    button = false,
    children,
    ...rest
}: {
    title: string,
    button?: boolean,
    children: React.ReactNode,
    [propName: string]: any
}) => {
    const [ anchor, setAnchor ] = useState<Element | null>(null);

    const onOpen  = (event: React.SyntheticEvent) => setAnchor(event.currentTarget);
    const onClose = () => setAnchor(null);

    const Clickable = button ? PopoutButton : PopoutLink;
    return (
        <>
          <Clickable onClick={onOpen} {...rest}>{title}</Clickable>
          <Popover id={anchor ? "popover" : undefined}
                   open={Boolean(anchor)}
                   anchorEl={anchor}
                   onClose={onClose}
                   anchorOrigin={{
                       vertical: "top",
                       horizontal: "center"
                   }}
                   transformOrigin={{
                       vertical: "bottom",
                       horizontal: "center"
                   }}>
            {children}
          </Popover>
        </>
    );
};

const ViewportSelector = ({
    value,
    area,
    onChange
}: {
    value?: ViewportBase,
    area?: Polygon | MultiPolygon,
    onChange?: (newValue: ViewportBase) => void
}) => {
    const mapDragged = (map: MapboxGl.Map) => {
        const { lng, lat } = map.getCenter();
        onChange?.({
            longitude: lng,
            latitude: lat,
            zoom: map.getZoom()
        });
    };

    if (!value) {
        value = {
            latitude: 25,
            longitude: 15,
            zoom: 0
        };
    }

    const bottomBar = (
        <div style={{
                 display: "flex",
                 flex: 0,
                 justifyContent: "baseline",
                 flexDirection: "row",
                 padding: "1rem",
                 alignItems: "flex-end"
             }}>
          <TextField
            label="Latitude"
            type="number"
            value={value.latitude}
            onChange={(event) => onChange!({
                ...value!,
                latitude: Number(event.target.value)
            })}
            style={{ flex: 1 }} />
            {", "}
          <TextField
            label="Longitude"
            type="number"
            value={value.longitude}
            onChange={(event) => onChange!({
                ...value!,
                longitude: Number(event.target.value)
            })}
            style={{
                flex: 1,
                margin: "0 8pt"
            }} />
        </div>
    );

    const RenderedMap = onChange ? Map : ReadonlyMap;

    return (
        <div style={{
                 minHeight: 400,
                 minWidth: 400,
                 display: "flex",
                 flexDirection: "column"
             }}>
          <RenderedMap
            // eslint-disable-next-line
            style="mapbox://styles/mapbox/satellite-streets-v9"
            containerStyle={{ flex: "1 1 0" }}
            center={[value.longitude, value.latitude]}
            zoom={[value.zoom]}
            onDrag={mapDragged}
            onZoom={mapDragged}
            onStyleLoad={(map: MapboxGl.Map) => map.resize()}>
            <img style={{
                     position: "absolute",
                     top: "50%",
                     left: "50%",
                     height: "40px",
                     width: "auto",
                     transform: "translate(-50%, -100%)",
                     zIndex: 1000
                 }}
                 src="/location-marker-orange.png"
                 alt="Location" />
            {onChange && <ZoomControl />}
            {area && (
                <GeoJSONLayer data={{
                                  type: "Feature",
                                  geometry: area
                              }}
                              fillPaint={FILL_PAINT}>
                </GeoJSONLayer>
            )}
        </RenderedMap>
            {onChange && bottomBar}
        </div>
    );
};

// const DefaultRegionSelector = ({
//     rowData,
//     value,
//     onChange,
//     containers
// }: EditComponentProps<RowData> & {
//     containers?: Record<number, Container>
// }) => {
//     const drawRef = useRef<any>();

//     const viewport: ViewportBase = rowData.viewport_web ? {
//         ...rowData.viewport_web,
//         zoom: rowData.viewport_web.zoom - 2.25
//     } : {
//         latitude: 25,
//         longitude: 15,
//         zoom: 0
//     };

//     const drawProps = {
//         ref: (drawControl: DrawControl | null) => {
//             drawRef.current = drawControl?.draw;
//             if (!drawControl?.draw || !value) {
//                 return;
//             }

//             const draw: any = drawControl.draw;
//             draw.set({
//                 type: "FeatureCollection",
//                 features: [{
//                     type: "Feature",
//                     properties: {},
//                     id: "default-region",
//                     geometry: value
//                 }]
//             });
//             draw.changeMode("direct_select", {
//                 featureId: "default-region"
//             });
//         },
//         onDrawUpdate: ({ features }: { features: Feature[] }) => {
//             onChange(features[0].geometry);
//         },
//         onDrawCreate: ({ features }: { features: Feature[] }) => {
//             onChange(features[0].geometry);

//             if (drawRef.current) {
//                 drawRef.current.set({
//                     type: "FeatureCollection",
//                     features: [features[0]]
//                 })
//                 drawRef.current.changeMode("direct_select", {
//                     featureId: features[0].id
//                 });
//             }
//         },
//         defaultMode: "draw_polygon",
//         controls: {
//             polygon: true
//         },
//         displayControlsDefault: false
//     } as DrawControlProps;

//     let geoJSONLayer: React.ReactElement = <></>;
//     if (containers) {
//         geoJSONLayer = (
//             <GeoJSONLayer data={{
//                               type: "Feature",
//                               geometry: containers[(rowData as DisplayDefaultRegion).container_id].area
//                           }}
//                           fillPaint={FILL_PAINT}>
//             </GeoJSONLayer>
//         );
//     }

//     return (
//         <Map
//           // eslint-disable-next-line
//           style="mapbox://styles/mapbox/satellite-streets-v9"
//           containerStyle={{
//               minHeight: 400,
//               minWidth: 400
//           }}
//           center={[viewport.longitude, viewport.latitude]}
//           zoom={[viewport.zoom]}>
//           {geoJSONLayer}
//           <DrawControl {...drawProps} />
//           <ZoomControl />
//         </Map>
//     );
// };

const JSONTextArea = styled.textarea`
    ${({ theme }) => `
    background-color: ${theme.palette.background.paper};
    color: ${theme.palette.text.primary};
    width: 400px;
    height: 400px;
    outline: none;
    border: none;
    `}
`;

export default () => {
    const error = useThrow();

    // const [ { token } ] = useCookies(["verstaanToken"]);
    const token = useSelectFromRedux((state) => state.cuser.token);

    const theme = useTheme();

    const [ data, setData ] = useState<RowData[]  | undefined>(undefined);
    useEffect(() => {(async () => {
        try {
            setData((await getAllContainers()).map((container: Container): RowData => {
                const rowData: RowData = container as RowData;
                rowData.type = "Container";
                return rowData;
            }).concat((await getAllDefaultRegions()).map((defaultRegion: DefaultRegion): RowData => {
                const rowData: RowData = defaultRegion as RowData;
                rowData.type = "DefaultRegion";
                return rowData;
            })));
        } catch (err) {
            error(err);
        }
    })();}, [error, token]);

    // const containers = useMemo<Record<number, Container>>((): Record<number, Container> => {
    //     const result: Record<number, Container> = {}
    //     if (data) {
    //         for (const rowData of data!) {
    //             if (rowData.type === "Container") {
    //                 result[rowData.id] = rowData;
    //             }
    //         }
    //     }
    //     return result;
    // }, [data]);

    const sanitizeRowData = (newRow: RowData) => {
        const update = { ...newRow };
        delete update.type;
        if (update.area) {
            if (typeof update.area === "string") {
                update.area = JSON.parse(update.area);
            }
            if (update.area?.type !== "MultiPolygon" && update.area?.type !== "Polygon") {
                throw new Error("Area must be either a GeoJSON Polygon or MultiPolygon");
            }
        }
        return update;
    };

    const tableRef = useRef<any>();

    const [ initialFormData, setInitialFormData ] = useState<Partial<RowData>>({
        type: "Container"
    });

    const props: MaterialTableProps<RowData> = {
        title: "Containers",
        tableRef,
        initialFormData,
        columns: [
            {
                title: "ID",
                field: "id",
                type: "numeric",
                width: 0,
                editable: "never",
                defaultSort: "asc"
            },
            {
                title: "Name",
                field: "name"
            },
            {
                title: "Abbreviation",
                field: "abbreviation",
                editable: (columnDef: Column<RowData>, rowData: RowData) => rowData.type === "Container"
            },
            {
                title: "Area",
                field: "area",
                render: (data: RowData) => (
                    <Popout title="Area">
                      <Map
                        // eslint-disable-next-line
                        style="mapbox://styles/mapbox/satellite-streets-v9"
                        containerStyle={{
                            minHeight: 400,
                            minWidth: 400
                        }}
                        center={[data.viewport_web.longitude, data.viewport_web.latitude]}
                        zoom={[data.viewport_web.zoom - 2.25]}>
                        <GeoJSONLayer data={{
                                          type: "Feature",
                                          geometry: data.area
                                      }}
                                      fillPaint={FILL_PAINT}>
                        </GeoJSONLayer>
                        <ZoomControl />
                      </Map>
                    </Popout>
                ),
                editComponent: (props: EditComponentProps<RowData>) =>
                    (props.rowData.type === "DefaultRegion") ? (
                        <Popout button={true} title="Area">
                          {/* <DefaultRegionSelector {...props} containers={containers} /> */}
                          {/* Map vs Plain JSON */}
                          <JSONTextArea
                            onChange={(event) => {
                                let value = event.target.value;
                                try {
                                    value = JSON.parse(value);
                                } catch (err) {}
                                props.onChange(value);
                            }}
                            value={(typeof props.value === "string") ? props.value : JSON.stringify(props.value, null, 2)} />
                        </Popout>
                    ) : (
                        <Popout button={true} title="Area">
                          <JSONTextArea
                            onChange={(event) => {
                                let value = event.target.value;
                                try {
                                    value = JSON.parse(value);
                                } catch (err) {}
                                props.onChange(value);
                            }}
                            value={(typeof props.value === "string") ? props.value : JSON.stringify(props.value, null, 2)} />
                        </Popout>
                )
            },
            {
                title: "Viewport",
                field: "viewport",
                render: (data: RowData) => (
                    <div style={{
                             marginRight: "-1em",
                             marginBottom: "-0.5em"
                         }}>
                      <Popout title="Web"
                              style={{
                                  marginRight: "1em",
                                  marginBottom: "0.5em"
                              }}>
                        <ViewportSelector value={data.viewport_web} area={data.area} />
                      </Popout>
                      <Popout title="Mobile"
                              style={{
                                  marginRight: "1em",
                                  marginBottom: "0.5em"
                              }}>
                        <ViewportSelector value={data.viewport_mobile} area={data.area} />
                      </Popout>
                    </div>
                ),
                editComponent: ({ rowData, onRowDataChange }: EditComponentProps<RowData>) => (
                    <div style={{
                             marginRight: "-1em",
                             marginBottom: "-0.5em"
                         }}>
                      <Popout button={true}
                              title="Web"
                              style={{
                                  marginRight: "1em",
                                  marginBottom: "0.5em"
                              }}>
                        <ViewportSelector value={rowData.viewport_web}
                                          area={rowData.area}
                                          onChange={(newValue: ViewportBase) => onRowDataChange({ ...rowData, viewport_web: newValue })} />
                      </Popout>
                      <Popout button={true}
                              title="Mobile"
                              style={{
                                  marginRight: "1em",
                                  marginBottom: "0.5em"
                              }}>
                        <ViewportSelector value={rowData.viewport_mobile}
                                          area={rowData.area}
                                          onChange={(newValue: ViewportBase) => onRowDataChange({ ...rowData, viewport_mobile: newValue })} />
                      </Popout>
                    </div>
                )
            }
        ],
        data: data ?? [],
        isLoading: !data,
        style: {
            borderRadius: 0
        },
        options: {
            paging: false,
            thirdSortClick: false,
            toolbarButtonAlignment: "left",
            rowStyle: (_data, _index, level: number) => ({
                backgroundColor: adjustColor(theme.palette.background.paper, -10 * level)
            })
        },
        actions: [
            (rowData: RowData) => ({
                icon: "add_circle",
                tooltip: "Add Default Region",
                onClick: (_event, rowData: RowData) => {
                    const table = tableRef.current;
                    setInitialFormData({
                        type: "DefaultRegion",
                        container_id: rowData.id
                    });
                    table.dataManager.changeRowEditing();
                    table.setState({
                        ...table.dataManager.getRenderState(),
                        showAddRow: true
                    });
                },
                hidden: rowData.type !== "Container"
            }),
            (rowData: RowData) => ({
                icon: "maps_ugc",
                tooltip: "Add Custom Region",
                onClick: () => {
                    alert("Custom Region creation is not yet supported by Jarvis.");
                },
                hidden: rowData.type !== "Container"
            })
        ],
        parentChildData: (rowData: RowData, data: RowData[]) => {
            switch (rowData.type) {
            case "DefaultRegion":
                return data.find((candidate: RowData) =>
                                 candidate.type === "Container"
                                 && candidate.id === rowData.container_id);
            }
        },
        editable: {
            onRowUpdate: async (updatedRow: RowData) => {
                try {
                    const newData: RowData[] = [...data!];
                    newData[newData.findIndex((candidate: RowData) =>
                                              candidate.type === updatedRow.type
                                              && candidate.id === updatedRow.id)] = updatedRow;

                    if (updatedRow.type === "Container") {
                      await updateContainer(sanitizeRowData(updatedRow));
                    } else if (updatedRow.type === "DefaultRegion") {
                      await updateDefaultRegion(sanitizeRowData(updatedRow));
                    } else {
                      throw new Error("Row type not valid");
                  }

                    setData(newData);
                } catch (err) {
                    error(err);
                    throw err;
                }
            },
            onRowAdd: async (newData: RowData) => {
                try {
                    // newData.id = await request<number>({
                    //     method: "POST",
                    //     url: "/admin/create" + newData.type,
                    //     headers: {
                    //         Authorization: `Bearer ${token}`
                    //     },
                    //     data: sanitizeRowData(newData)
                    // });
                    if (newData.type === "Container") {
                      newData.id = await createContainer(sanitizeRowData(newData) as NewContainer);
                    } else if (newData.type === "DefaultRegion") {
                      newData.id = await createDefaultRegion(sanitizeRowData(newData) as NewDefaultRegion);
                    } else {
                      throw new Error("new row data type not valid");
                    }

                    setData([...(data ?? []), newData]);
                    setInitialFormData({
                        type: "Container"
                    });
                } catch (err) {
                    error(err);
                    throw err;
                }
            },
            // Handle container/region creation (on cancel or update)
            onRowAddCancelled: (newData: RowData) => {
                setInitialFormData({
                    type: "Container"
                });
            },
            onRowUpdateCancelled: (newData: RowData) => {
                setInitialFormData({
                    type: "Container"
                });
            }
        },
        icons: {
            Add: React.forwardRef((props: IconProps, ref: any) => <Icon {...props} ref={ref}>add_circle_outline</Icon>)
        },
        localization: {
            header: {
                actions: ""
            }
        }
    } as MaterialTableProps<RowData>;

    return <MaterialTable {...props} />;
};
