import { default as React, useState, useEffect, useRef } from "react";

import { Switch, Route, Redirect } from "react-router-dom";
import {
  Drawer,
  ListItem,
  ListItemText,
  ListItemIcon,
  Icon,
  Collapse,
  List
} from "@material-ui/core";

import Admin from "./admin";
import Client from "./client";
import Areas from "./areas";
import Logs from "./logs";
import Aamp from "../aamp";
import Map from "../map/map";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import "./index.css";
import { Line } from "./styled";
import { getAdminProfile, getAllClients, getContainers, getDevices } from "../types/api/admin";
import { AdminUserProfile } from "../types/admin";
import { useThrow } from "../catch";
import { CreateClient } from "./createClient";
import { CreateUser } from "./createUser";
import { UpdateClient } from "./updateClient";
import { NewAamp } from "../new-aamp";
import { useDispatch } from "react-redux";
import { CreateForm } from "./createForm";
import { RegisterChat } from "./registerChat";
import { publicInfoOperations } from "../state/publicInfo";
import { cuserActions } from "../state/cuser";
import Visualizations from "../visualizations";
import { useSelectFromRedux } from "../utils/_hooks";
import { mapActions } from "../state/map";
import { Device } from "../types/auth";
import { CreateAsset } from "./createAsset";
import { CreateAssetType } from "./createAssetType";
import { UpdateAssetType } from "./updateAssetType";
import { auth } from "./../types/firebase";
import { DisplayFusionCenter } from "./fusionCenter";
import { DisplayOutliers } from "./outliers";
import { DisplayNews } from "./news";
import { DisplayUserMonitoring } from "./userMonitoring";
import { DisplayTestUserNotifs } from "./testUserNotifs";
import { DisplayNotifMessaging, DisplayNotifMessagingTEST, DisplayCustomUserNotifMessaging } from "./notifMessaging";
import { DisplayTravelApp } from "./travelApp";
import { DisplayTravelPendingTrips } from "./travelPendingTrips";
import { DisplayTravelActiveTrips } from "./travelActiveTrips";
import { DisplayTravelAreas } from "./travelAreas";

const DRAWER_WIDTH = 240;

export default () => {
  const dispatch = useDispatch();
  const error = useThrow();

  const content = useSelectFromRedux((state) =>  state.map.content);
  const profile = useSelectFromRedux((state) => state.cuser.profile);

  useEffect(() => {
    (async () => {
      try {
        const profile = await getAdminProfile();
        dispatch(cuserActions.setProfile(profile));
      } catch(err) {
        error(err)
      }
    })();
  }, [error, profile]);

  const source = useRef<any>(null);

  const lastContainer = Number.parseInt(localStorage.getItem("containerID") ?? "1");

  useEffect(() => {
    if ((profile?.privileges ?? 0) > 0) {
      (async () => {
        try {
          const devices = await getDevices();
          let filteredDevices: Device[] = [];
          const clients = await getAllClients();
          devices.forEach((device: Device) => {
            if (device.point) {
              const corrClient = clients.find((client) => client.id === device.client_id);
              if (!filteredDevices.find((d: Device) => d.user_id === device.user_id)) filteredDevices.push(
                  {
                    ...device,
                    user_name: corrClient?.name
                  }
              )
              else {
                const otherDevice = filteredDevices.find((d: Device) => d.user_id === device.user_id);
                if (new Date(device.modified_at ?? 0).getTime() > new Date(otherDevice?.modified_at ?? 0).getTime()) {
                  filteredDevices[filteredDevices.findIndex((dev: Device) => dev.user_id === device.user_id)] = {
                    ...device,
                    user_name: corrClient?.name
                  }
                }
              }
            }
          });
          dispatch(mapActions.loadDevices(filteredDevices))
          dispatch(cuserActions.successfulGetContainers(await getContainers()));
          dispatch(publicInfoOperations.fetchPublicReportsFromContainer(lastContainer));
        } catch (err) {
          console.warn("Failed to fetch some client info: " + err);
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const logout = () => {
    auth.signOut().then((result) => {
        localStorage.removeItem("verstaanToken");
        dispatch(cuserActions.signOut());
    }).catch((err) => {
        console.log("error signing out, please try again")
        console.log(err)
    })
  };

  const [page, setPage] = useState<"Map" | "Client" | "Admin" | "AAMP" | "Areas" | "Logs" | undefined>();

  useEffect(() => {
    if(window.location.pathname === "/map") setPage("Map");
    else if(window.location.pathname === "client") setPage("Client");
    else if(window.location.pathname === "/aamp") setPage("AAMP");
  },  []);

  const handleClick = (openwindow : "Map" | "Client" | "Admin" | "AAMP" | "Areas" | "Logs") => {
    if(page === openwindow) {
      setPage(undefined);
    } else {
      setPage(openwindow);
    }
  }

  return (
    <div style={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        anchor="left"
        style={{
          flexShrink: 0,
          width: DRAWER_WIDTH,
        }}
        PaperProps={{
          style: {
            width: DRAWER_WIDTH,
          },
        }}
      >
        {/*profile?.privileges === 1 && (
          <ListItem button component="a" href="/map" onClick={() => setPage("Map")}>
            <ListItemIcon>
              <Icon>map</Icon>
            </ListItemIcon>
            <ListItemText primary="Nimbus" />
          </ListItem>
        )*/}
        {profile?.privileges === 1 && (
          <ListItem button component="a" href="/fusionCenter">
            <ListItemIcon>
              <Icon>public</Icon>
            </ListItemIcon>
            <ListItemText
              primary="Fusion Center"
            />
          </ListItem>
        )}
        {profile?.privileges === 1 && (
          <ListItem button component="a" href="/travelApp">
            <ListItemIcon>
              <Icon>flighttakeoff</Icon>
            </ListItemIcon>
            <ListItemText
              primary="Jett Admin"
            />
          </ListItem>
        )}
        {((profile?.privileges === 1) || (profile?.email === "kenwechuli@gmail.com")) && (
          <ListItem button component="a" href="/userMonitoring">
            <ListItemIcon>
              <Icon>monitor_heart</Icon>
            </ListItemIcon>
            <ListItemText
              primary="CAK Monitoring"
            />
          </ListItem>
        )}
        {/*{(profile?.privileges === 1) && (
          <ListItem button component="a" href="/testUserNotifs">
            <ListItemText
              primary="Test User Report Notifications"
            />
            </ListItem>
        )}*/}
        {(profile?.privileges === 1) && (
          <ListItem button component="a" href="/notifMessaging">
            <ListItemIcon>
              <Icon>update</Icon>
            </ListItemIcon>
            <ListItemText
              primary="Schedule Notifications/Bulletins"
            />
          </ListItem>
        )}
        {profile?.features?.client !== false && profile?.privileges === 1 && (
          <ListItem button component="a" href="/client">
            <ListItemIcon>
              <Icon>account_circle</Icon>
            </ListItemIcon>
            <ListItemText
              primary="Client"
              secondary="For clients, users, and teams"
            />
          </ListItem>
        )}
        {profile?.features?.aamp !== false && (
          <ListItem button component="a" href="/aamp">
            <ListItemIcon>
              <Icon>receipt_long</Icon>
            </ListItemIcon>
            <ListItemText primary="AAMP" />
          </ListItem>
        )}
        {profile?.features?.areas !== false && profile?.privileges === 1 && (
          <ListItem button component="a" href="/areas">
            <ListItemIcon>
              <Icon>public</Icon>
            </ListItemIcon>
            <ListItemText
              primary="Areas"
              secondary="For containers, default&nbsp;regions, and custom&nbsp;regions"
            />
          </ListItem>
        )}
        {profile?.features?.admin !== false && profile?.privileges === 1 && (
          <ListItem button component="a" href="/admin">
            <ListItemIcon>
              <Icon>admin_panel_settings</Icon>
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </ListItem>
        )}
        {profile?.features?.logs !== false && profile?.privileges === 1 && (
          <ListItem button component="a" href="/logs">
            <ListItemIcon>
              <Icon>receipt_long</Icon>
            </ListItemIcon>
            <ListItemText primary="Logs" />
          </ListItem>
        )}
        <Line />
        {profile?.privileges === 1 && (
            <>
            {/*}
            <ListItem button component="a" onClick={() => handleClick("Map")} href="/map">
              <ListItemIcon>
                <Icon>map</Icon>
              </ListItemIcon>
              <ListItemText primary="Nimbus" disableTypography={true}
                            style={{ fontWeight: "bold", fontSize: "17px" }} />
              {page === "Map" ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          <Collapse in={page==="Map"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <ListItem button component="a" href="/createAssetType">
          <ListItemIcon>
          <Icon>business</Icon>
          </ListItemIcon>
          <ListItemText primary="Create Asset Type" />
          </ListItem>
          <ListItem button component="a" href="/updateAssetType">
          <ListItemIcon>
          <Icon>build</Icon>
          </ListItemIcon>
          <ListItemText primary="Modify Asset Type" />
          </ListItem>
          <ListItem button component="a" href="/createAsset">
          <ListItemIcon>
          <Icon>local_shipping</Icon>
          </ListItemIcon>
          <ListItemText primary="Create Asset" />
          </ListItem>
          </List>
          </Collapse>
        */}
          <ListItem button component="a" onClick={() => handleClick("Client")}>
          <ListItemIcon>
          <Icon>account_circle</Icon>
          </ListItemIcon>
          <ListItemText primary="Client" disableTypography={true} style={{fontWeight: "bold", fontSize: "17px"}}/>
        {page === "Client" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={page==="Client"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
        {profile?.privileges === 1 &&
          <ListItem button component="a" href="/createUser">
          <ListItemIcon>
          <Icon>person_add</Icon>
          </ListItemIcon>
          <ListItemText primary="Create User" />
          </ListItem>
        }
        {profile?.privileges === 1 &&
          <ListItem button component="a" href="/createClient">
          <ListItemIcon>
          <Icon>group_add</Icon>
          </ListItemIcon>
          <ListItemText primary="Create Client" />
          </ListItem>
        }
        {profile?.privileges === 1 &&
          <ListItem button component="a" href="/updateClient">
          <ListItemIcon>
          <Icon>update</Icon>
          </ListItemIcon>
          <ListItemText primary="Modify Client" />
          </ListItem>
        }
          </List>
          </Collapse>
          <ListItem button component="a" onClick={() => handleClick("AAMP")}>
          <ListItemIcon>
          <Icon>receipt_long</Icon>
          </ListItemIcon>
          <ListItemText primary="AAMP" disableTypography={true} style={{fontWeight: "bold", fontSize: "17px"}}/>
        {page === "AAMP" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={page==="AAMP"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <ListItem button component="a" href="/createForm">
          <ListItemIcon>
          <Icon>assignment</Icon>
          </ListItemIcon>
          <ListItemText primary="Manage Forms" />
          </ListItem>
          <ListItem button component="a" href="/registerChat">
          <ListItemIcon>
          <Icon>forum</Icon>
          </ListItemIcon>
          <ListItemText primary="Manage Chats" />
          </ListItem>
          </List>
          </Collapse>
          </>
            )}
        <ListItem
          button
          style={{
            color: "white",
            height: "6%"
          }}
          onClick={logout}
        >
          <ListItemIcon>
            <Icon>exit_to_app</Icon>
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </Drawer>
      {content === "visualizations" && (
          <Visualizations/>
      )}
      <main style={{ flexGrow: 1 }}>
        <Switch>
          <Route path="/admin" component={Admin} />
          <Route path="/client" component={Client} />
          <Route path="/areas" component={Areas} />
          <Route path="/logs" component={Logs} />
          <Route path="/map" component={Map} source={source}/>
          <Route path="/aamp" component={Aamp} />
          <Route path="/new-aamp" component={NewAamp} />
          <Route path="/createClient" component={CreateClient} />
          <Route path="/fusionCenter" component={DisplayFusionCenter} />
          <Route path="/travelApp" component={DisplayTravelApp} />
          <Route path="/travelPendingTrips" component={DisplayTravelPendingTrips} />
          <Route path="/travelAreas" component={DisplayTravelAreas} />
          <Route path="/travelActiveTrips" component={DisplayTravelActiveTrips} />
          <Route path="/userMonitoring" component={DisplayUserMonitoring} />
          <Route path="/testUserNotifs" component={DisplayTestUserNotifs} />
          <Route path="/notifMessaging" component={DisplayNotifMessaging} />
          <Route path="/notifMessagingTEST" component={DisplayNotifMessagingTEST} />
          <Route path="/customUserNotifMessaging" component={DisplayCustomUserNotifMessaging} />
          <Route path="/outliers" component={DisplayOutliers} />
          <Route path="/news" component={DisplayNews} />
          <Route path="/createUser" component={CreateUser} />
          <Route path="/updateClient" component={UpdateClient} />
          <Route path="/createForm" component={CreateForm} props={profile?.privileges} />
          <Route path="/registerChat" component={RegisterChat} />
          <Route path="/createAsset" component={CreateAsset} />
          <Route path="/createAssetType" component={CreateAssetType} />
          <Route path="/updateAssetType" component={UpdateAssetType} />
          {profile?.privileges === 1 && <Redirect exact from="/" to="/admin" />}
          {profile?.privileges === 0 && <Redirect exact from="/" to="/aamp" />}
        </Switch>
      </main>
    </div>
  );
};
