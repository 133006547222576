import styled from "styled-components";
import { Snackbar } from "@material-ui/core";

import { buttonOrange } from "./colors";

export default styled(Snackbar)`
    .MuiSnackbarContent-root {
        background: ${buttonOrange} !important;
        color: white;
    }
`;
