import styled from "styled-components";

import { gradientTopBlue, gradientBottomBlue } from "./colors";

export default styled.div`
    background: linear-gradient(${gradientTopBlue}, ${gradientBottomBlue});
    height: 100%;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
`;
