import React, { useEffect, useState } from "react";
import { Button, FormControl, InputLabel, Select, Input, MenuItem, ListItemText, Checkbox, FormLabel, TextField, Box, IconButton } from "@material-ui/core";
import { DataGrid, ColDef, ValueGetterParams, CellParams } from "@material-ui/data-grid";
import { Form, FormContainer, Section, ToggleBarContainer,  } from "./styled";
import { getAllDefaultRegions, getSummaryData } from "../types/api/admin";
import { getContainers, getAllContainers } from "../types/api/admin";
import { useThrow } from "../catch";
import { ClientDisplayData, DisplayClient, UserFeatureSelection } from "../types/client";
import { Autocomplete, Alert } from "@material-ui/lab";
import { render } from "@testing-library/react";
import {makeStyles} from '@material-ui/styles';
import Link from "@material-ui/core/Link";
import { Container } from "../types/geo"
import { ConsoleView } from "react-device-detect";
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  LineSeries,
} from '@devexpress/dx-react-chart-material-ui';
import { testUserNotifs } from "../types/api/admin";



const styles = makeStyles((theme) => ({
    root: {
      borderLeft: 0,
      borderRight: 0,
      borderBottom: 0,
      color: 'black',
      backgroundColor: 'grey'
    },
  }));




export const DisplayTestUserNotifs = () => {

    const [userId, setUserId] = useState<number>();
    const [reportId, setReportId] = useState<number>();

    

    async function handleSubmitTestNotif() {
        if (userId && reportId) {
            if (userId > 0 && reportId > 0) testUserNotifs(userId, reportId)
        }
    }





    return (
        <div>
            <div style={{ height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
              Send Test Notification to User
            </div>
            <TextField size="small" label="Enter Users ID" margin="dense" onChange={(e) => setUserId(parseInt(e.target.value))} >
          </TextField>
          <TextField size="small" label="Enter Report ID" margin="dense" onChange={(e) => setReportId(parseInt(e.target.value))} >
          </TextField>
          <div>
          <Button onClick={() => {handleSubmitTestNotif()}}>
            SEND
        </Button>
        </div>
      </div>

    )
}

