import * as Redux from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ActionCreator } from "redux";

// import { default as someOtherModel, SomeOtherModelState, SomeOtherModelAction } from "./some-other-model";
import { default as map, MapState } from "./map";
import { default as publicInfo, PublicInfoState, PublicInfoActions } from "./publicInfo";
import { default as filter, FilterState, FilterActions } from "./filter";
import { default as visualizations, VisualizationsState, VisualizationsActions } from "./visualizations";
import { default as timeFilter, TimeFilterState, TimeFilterAction } from "./time-filter";
import { default as color, ColorState, ColorAction } from "./color";
import { CuserAction, CuserState, default as cuser } from "./cuser";
// import { default as someOtherModel, SomeOtherModelState, SomeOtherModelAction } from "./some-other-model";

export type CustomAction =
    | PublicInfoActions
    | FilterActions
    | VisualizationsActions
    | ColorAction
    | TimeFilterAction
    | CuserAction
// | SomeOtherModelAction

export interface AppState {
    map: MapState;
    publicInfo: PublicInfoState;
    filter: FilterState;
    visualizations: VisualizationsState;
    color: ColorState;
    timeFilter: TimeFilterState;
    cuser: CuserState;
    // someOtherModel: SomeOtherModelState;
}

const appReducer = Redux.combineReducers({
    map,
    publicInfo,
    filter,
    timeFilter,
    visualizations,
    color,
    cuser,
});

export const rootReducer = (state: any, action: any) => {
    if (action.type === "RESET_APP") {
        state = undefined;
    }

    return appReducer(state, action);
};

export const unreachableCode = (never: never): never => {
    throw new Error("This code should NEVER be called.");
};

export type AsyncAction = ThunkAction<void, AppState, never, CustomAction>;
// operations are async action creators
export type Operation = ActionCreator<AsyncAction>;

export type AsyncPromiseAction<T> = ThunkAction<Promise<T>, AppState, never, CustomAction>;
export type PromiseOperation<T> = ActionCreator<AsyncPromiseAction<T>>;
export type PromiseDispatch = ThunkDispatch<AppState, never, CustomAction>;
