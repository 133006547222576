import styled from "styled-components";

export default styled.input`
    font-family: IBM Plex Sans;
    width: 254px;
    height: 37px;
    background-color: #8a8c96;
    color: white;
    border: none;
    border-radius: 18px;
    margin: 10px;
    text-align: center;
    font-size: 14px;
    outline: none;

    ::placeholder {
        color: white;
        letter-spacing: 1px;
    }
`;
