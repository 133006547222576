import React, { useEffect, useState, useRef } from "react";
import { Button, FormControl, InputLabel, Select, Input, MenuItem,Checkbox, FormLabel, TextField, Box, IconButton, Zoom } from "@material-ui/core";
import { DataGrid, ColDef, ValueGetterParams, CellParams } from "@material-ui/data-grid";
import { Form, FormContainer, Section, ToggleBarContainer,  } from "./styled";
import {makeStyles} from '@material-ui/styles';
import { stringify } from "querystring";
import { DeckGL } from "@deck.gl/react";
import { StaticMap } from "react-map-gl";
import ViewStateProps from "@deck.gl/core/lib/deck";
import ReactMapboxGl, { GeoJSONLayer, Marker, Source, Layer } from 'react-mapbox-gl';
import DrawControl from 'react-mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { Point, Polygon, LineString, MultiPolygon, Feature, FeatureCollection } from "geojson";
import { TextInput, RoundButton, SearchButtonContainer } from "./styled";
import { fp64LowPart, GeoJsonLayer } from "deck.gl";
import { IconLayer, HeatmapLayer, PolygonLayer } from "deck.gl";
import { Position } from "geojson";
//import { TravelUniversalGeometry } from "../types/travelApp";
import { getPendingTrips, insertUniversalGeometry, fetchUniversalGeometry, deleteUniversalGeometryById, fetchAllCities, renderOsintData, submitSafetyInsightEdits, submitSafetyVendorEdits, fetchAllCountries, submitIndexEdits, queryPlacesByPoint } from "../types/api/admin";
import { TravelCityProfile, TravelCountryProfile, TravelIndex } from "../types/travelApp";
import Slider from "@material-ui/core/Slider";
import { CheckInput } from "./styled";
import {
    FormControlLabel,
    FormGroup
} from "@material-ui/core";
import { GeoJSONSource } from "mapbox-gl";
import { editUniversalGeometry } from "../types/api/admin";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { S3Key } from "../utils";


const Geosuggest = require("react-geosuggest").default;


const styles = makeStyles((theme) => ({
  root: {
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    color: 'black',
    backgroundColor: 'grey'
  },
}));



export const DisplayTravelAreas = () => {

    const classes = styles()

    
    const [areaMode, setAreaMode] = useState<string>("assets");
    const [refreshGeomFlag, setRefreshGeomFlag] = useState<string>("");

    const [universalGeom, setUniversalGeom] = useState<any[]>([]);
    const [openGeomInfo, setOpenGeomInfo] = useState<number>(0);
    const [geomInfo, setGeomInfo] = useState<string[]>([]);

    const [menuOpen, setMenuOpen] = useState<number>(0);
    const [addFormTitle, setAddFormTitle] = useState<string>("");
    const [addFormType, setAddFormType] = useState<string>("");
    const [newsSchema, enableNewsSchema] = useState<number>(0);

    const [shapes, setShapes] = useState<any>([]);
    const [selectedFeatureIndexes] = useState([]);
    const [radiusVal, setRadiusVal] = useState<number>(0.75);
    const [recompileLoading, setRecompileLoading] = useState<string>("");

    // const [kdeNonviol, setKdeNonviol] = useState<FeatureCollection>();
    // const [kdeViol, setKdeViol] = useState<FeatureCollection>();
    const [osintViol, setOsintViol] = useState<any[]>([]);
    const [osintNonViol, setOsintNonViol] = useState<any[]>([]);
    const [kdeMode, setKdeMode] = useState<string>("viol");
    const [osintMode, setOsintMode] = useState<string>("viol");
    const [dataDisplayMode, setDataDisplayMode] = useState<string>("heatmap")

    const [openReportInfo, setOpenReportInfo] = useState<number>(0);
    const [reportInfo, setReportInfo] = useState<string[]>([]);

    const [onEdit, setOnEdit] = useState<number>(0);
    const [selectedFeat, setSelectedFeat] = useState<Feature>();

    const [editDesc, setEditDesc] = useState<string>("")
    const [editMemo, setEditMemo] = useState<string>("")
    const [editCateg, setEditCateg] = useState<string>("")
    const [editReview, setEditReview] = useState<string>("")
    const [editTags, setEditTags] = useState<string>("")
    const [editTimeBins, setEditTimeBins] = useState<string>("")
    const [editNewsSource, setEditNewsSource] = useState<string>("")
    const [editNewsLink, setEditNewsLink] = useState<string>("")
    const [googlePlaces, setGooglePlaces] = useState<any[]>([])
    const [googlePlaceInfo, setGooglePlaceInfo] = useState<string[]>([])
    const [openGooglePlaceInfo, setOpenGooglePlaceInfo] = useState<number>(0)
    const [showSearchBar, updateShowSearchBar] = useState<boolean>(false);
    const [openAddResult, setOpenAddResult] = useState<number>(0);
    const [searchResLoc, setSearchResLoc] = useState<number[]>([0,0])


    // CITY PROFILES

    const [insightSelectBar, setInsightSelectBar] = useState<number>(0)
    const [insightList, setInsightList] = useState<number>(0)
    const [selectedInsights, setSelectedInsights] = useState<any[]>([])
    const [selectedCityProfile, setSelectedCityProfile] = useState<TravelCityProfile>()
    const [selectedInsight, setSelectedInsight] = useState<any>()
    const [insightEdit, setInsightEdit] = useState<number>(0)
    const [insightEditMode, setInsightEditMode] = useState<string>("")
    const [newTags, setNewTags] = useState<string>("")
    const [newInsightText, setNewInsightText] = useState<string>("")
    const [selectedInsightCateg, setSelectedInsightCateg] = useState<string>("")
    const [insightAdd, setInsightAdd] = useState<number>(0)
    const [refetchFlag, setRefetchFlag] = useState<string>("")

    const [vendorList, setVendorList] = useState<number>(0)
    const [selectedVendors, setSelectedVendors] = useState<any[]>([])
    const [vendorAdd, setVendorAdd] = useState<number>(0)
    const [newVendorNames, setNewVendorNames] = useState<string>("")
    const [newVendorContact, setNewVendorContact] = useState<string>("")
    const [newVendorDesc, setNewVendorDesc] = useState<string>("")
    const [vendorEdit, setVendorEdit] = useState<number>(0)
    const [selectedVendor, setSelectedVendor] = useState<any>()

    // COUNTRY PROFILES

    const [countries, setCountries] = useState<TravelCountryProfile[]>([])
    const [selectedCountryProf, setSelectedCountryProf] = useState<TravelCountryProfile>()
    const [selectedIndices, setSelectedIndices] = useState<TravelIndex[]>([])
    const [indexList, setIndexList] = useState<number>(0)
    const [indexAdd, setIndexAdd] = useState<number>(0)
    const [newIndexName, setIndexName] = useState<string>("")
    const [newIndexFullName, setIndexFullName] = useState<string>("")
    const [newIndexScore, setIndexScore] = useState<string>("")
    const [newIndexSeverity, setIndexSeverity] = useState<string>("")
    const [newIndexDescription, setIndexDescription] = useState<string>("")
    const [newIndexSource, setIndexSource] = useState<string>("")
    const [indexEdit, setIndexEdit] = useState<number>(0)
    const [selectedIndex, setSelectedIndex] = useState<TravelIndex>()


    

    const [initialViewState, setInitialViewState] = useState({
        longitude: 9.533802457923096,
        latitude: 32.591153863146296,
        zoom: 0.908387181524258,
        pitch: 0,
        bearing: 0
    })

    const [cities, setCities] = useState<TravelCityProfile[]>([])

    const viewportState = useRef<ViewStateProps>({
        // @ts-ignore
        width: 998,
        height: 600,
        latitude: 32.591153863146296,
        longitude: 9.533802457923096,
        zoom: 0.908387181524258,
        bearing: 0,
        pitch: 0,
        altitude: 1.5,
        maxZoom: 20,
        minZoom: 0,
        maxPitch: 60,
        minPitch: 0
    });

    let drawControlRef = useRef<DrawControl>(null);


    const MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoidmVyc3RhYW4iLCJhIjoiY2s0YW12d3lsMDVjczNtcW5ramN0YzZ5byJ9.LaDF5JL90rFiTxgMerv_Yg";

    const ICON_MAPPING = {
        marker: {x: 0, y: 0, width: 128, height: 128, mask: true}
      };

    const Map = ReactMapboxGl({
        accessToken: MAPBOX_ACCESS_TOKEN
      });

    async function getFillColor(type: string) {

        if (type === "safety_polygon" || type === "safety_point") return [0,255,0]
        else if (type === "violent_polygon" || type === "violent_point" ) return [255,0,0]
        else if (type === "nonviolent_polygon" || type === "nonviolent_point" ) return [0,0,255]
        else if (type === "geonews_point" ) return [50,50,100]
        else return [50,50,50]
    }

    let layers: any[] = []

    useEffect(() => {

        async function fetchData() {
            const univGeom: Feature[] = await fetchUniversalGeometry()

            setUniversalGeom(univGeom)

            const cities: TravelCityProfile[] = await fetchAllCities()
            setCities(cities)

            const countries: TravelCountryProfile[] = await fetchAllCountries()
            setCountries(countries)
        }

        fetchData();
  
      }, [menuOpen, refreshGeomFlag]);

    useEffect(() => {

        async function refetchData() {
            const cities: TravelCityProfile[] = await fetchAllCities()
            const city = cities.filter((city) => city.name === selectedCityProfile?.name)[0]
            let insights: any[] = [];

            switch (selectedInsightCateg) {
                case "emergency_services":
                    insights = city.safety_insights.emergency_services as any[]
                    break
                case "transportation":
                    insights = city.safety_insights.transportation as any[]
                    break
                case "communication":
                    insights = city.safety_insights.communication as any[]
                    break
                case "scams":
                    insights = city.safety_insights.scams as any[]
                    break
                case "finances":
                    insights = city.safety_insights.finances as any[]
                    break
                case "women":
                    insights = city.safety_insights.women as any[]
                    break
                case "general_security":
                    insights = city.safety_insights.general_security as any[]
                    break
            }

            let safety_vendors: any[] = []

            try {
                safety_vendors = city.safety_vendors
            } catch {
                
            }

            setSelectedInsights(insights)
            setSelectedVendors(safety_vendors)

        }

        refetchData();

    }, [refetchFlag]);


      if (typeof universalGeom !== "undefined") {

        layers.push(new GeoJsonLayer({
            id: 'polys',
            data: universalGeom as Feature[],
            getLineWidth: 0,
            getFillColor: (geom: any) => {
                if (geom.properties.type === "safety_polygon" || geom.properties.type === "safety_point") return [0,255,0]
                else if (geom.properties.type === "violent_polygon" || geom.properties.type === "violent_point" ) return [255,0,0]
                else if (geom.properties.type === "nonviolent_polygon" || geom.properties.type === "nonviolent_point" ) return [0,0,255]
                else if (geom.properties.type === "geonews_point" ) return [50,50,100]
                else return [50,50,50]
            },
            opacity: .3,
            pickable: true,
            onClick: (info, event) => {
                // @ts-ignore
                const feat = info.object
                // @ts-ignore
                const props = info.object.properties
                handleGeomClick(props.id.toString(), props.type, props.category, props.description, props.tags, props.time_bins, props.internal_memo, props.local_review, feat as Feature)

        }}))

        layers.push(new IconLayer({
            id: 'points',
            data: universalGeom.filter((geom) => (geom.properties.type === "safety_point" || geom.properties.type === "violent_point" || geom.properties.type === "nonviolent_point" || geom.properties.type === "geonews_point" || geom.properties.type === "analystnote_point")),
            pickable: true,
            // iconAtlas and iconMapping are required
            // getIcon: return a string
            iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
            iconMapping: ICON_MAPPING,
            getIcon: d => 'marker',
            sizeScale: 15,
            // @ts-ignore
            getPosition: d => d.geometry.coordinates,
            getSize: 5,
            getColor: (geom: any) => {
                if (geom.properties.type === "safety_polygon" || geom.properties.type === "safety_point") return [0,255,0]
                else if (geom.properties.type === "violent_polygon" || geom.properties.type === "violent_point" ) return [255,0,0]
                else if (geom.properties.type === "nonviolent_polygon" || geom.properties.type === "nonviolent_point" ) return [0,0,255]
                else if (geom.properties.type === "geonews_point" ) return [50,50,100]
                else return [50,50,50]
            },
            onClick: (info, event) => {
                // @ts-ignore
                const feat = info.object
                // @ts-ignore
                const props = info.object.properties
                handleGeomClick(props.id.toString(), props.type, props.category, props.description, props.tags, props.time_bins, props.internal_memo, props.local_review, feat as Feature)
            }
            }))
        }

    // editLayer.push(new EditableGeoJsonLayer({
    //     id: 'polys-edit',
    //     data: [selectedFeat as Feature],
    //     // @ts-ignore
    //     mode: RotateMode,
    //     opacity: .3,
    //     //pickable: true
    // }))


    if (dataDisplayMode === "heatmap") {
        layers.push(new HeatmapLayer({
            id: 'heatmapLayer',
            data: ((osintMode === "nonviol") ? osintNonViol : osintViol),
            getPosition: d => d.point.coordinates,
            //getWeight: d => d.WEIGHT,
            //aggregation: 'SUM'
            opacity: .3
        }))
    } else if (dataDisplayMode === "points") {
        layers.push(new IconLayer({
            id: 'icon-layer',
            data: ((osintMode === "nonviol") ? osintNonViol : osintViol),
            pickable: true,
            // iconAtlas and iconMapping are required
            // getIcon: return a string
            iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
            iconMapping: ICON_MAPPING,
            getIcon: d => 'marker',
            sizeScale: 15,
            // @ts-ignore
            getPosition: d => d.point.coordinates,
            getSize: 5,
            getColor: ((osintMode === "nonviol") ? [0,0,100] : [100,0,0]),
            //onClick: d => handleReportClick(d.id.toString(), d.report_type_v2, d.description, d.source)
          }))
    }

    layers.push(new IconLayer({
        id: 'gplaces',
        data: googlePlaces,
        pickable: true,
        // iconAtlas and iconMapping are required
        // getIcon: return a string
        iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
        iconMapping: ICON_MAPPING,
        opacity: .2,
        getIcon: d => 'marker',
        sizeScale: 15,
        getPosition: d => [d.geometry.location.lng, d.geometry.location.lat],
        getSize: 5,
        getColor: d => [27,255,20],
        onClick: (info, event) => {
            const clicked: any = info.object
            selectGooglePlace(clicked.name, clicked.types, clicked.location)
        }
        }))

    layers.push(new IconLayer({
        id: 'searchRes',
        data: (openAddResult == 1) ? searchResLoc : [],
        pickable: true,
        // iconAtlas and iconMapping are required
        // getIcon: return a string
        iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
        iconMapping: ICON_MAPPING,
        opacity: .2,
        getIcon: d => 'marker',
        sizeScale: 15,
        // @ts-ignore
        getPosition: searchResLoc,
        getSize: 5,
        getColor: d => [27,255,20],
        onClick: (info, event) => {
            // const clicked: any = info.object
            // selectGooglePlace(clicked.name, clicked.types, clicked.location)
        }
        }))

    const formDesc = useRef("");
    const formCat = useRef("");
    const formInternalMemo = useRef("");

    const formReview = useRef("");

    const formNewsSource = useRef("");
    const formNewsLink = useRef("");

    const formTags = useRef("");
    const formTimeBins = useRef(["morning", "afternoon", "evening"]);

    async function handleMenu(mode: string) {

        switch (mode) {
            case "safety_polygon":
                enableNewsSchema(0)
                setOpenGeomInfo(0)
                setAddFormTitle("Add a Safety Polygon")
                setAddFormType(mode)
                setMenuOpen(2)
                break;

            case "violent_polygon":
                enableNewsSchema(0)
                setOpenGeomInfo(0)
                setAddFormTitle("Add a Violent Polygon")
                setAddFormType(mode)
                setMenuOpen(2)
                break;

            case "nonviolent_polygon":
                enableNewsSchema(0)
                setOpenGeomInfo(0)
                setAddFormTitle("Add a Non-Violent Polygon")
                setAddFormType(mode)
                setMenuOpen(2)
                break;
            case "safety_point":
                enableNewsSchema(0)
                setOpenGeomInfo(0)
                setAddFormTitle("Add a Safety Point")
                setAddFormType(mode)
                setMenuOpen(1)
                break;
            case "violent_point":
                enableNewsSchema(0)
                setOpenGeomInfo(0)
                setAddFormTitle("Add a Violent Point")
                setAddFormType(mode)
                setMenuOpen(1)
                break;
            case "nonviolent_point":
                enableNewsSchema(0)
                setOpenGeomInfo(0)
                setAddFormTitle("Add a Non-Violent Point")
                setAddFormType(mode)
                setMenuOpen(1)
                break;
            case "analystnote_point":
                enableNewsSchema(0)
                setOpenGeomInfo(0)
                setAddFormTitle("Add an Analyst Note Point")
                setAddFormType(mode)
                setMenuOpen(1)
                break;
            case "geonews_point":
                setOpenGeomInfo(0)
                setAddFormTitle("Add a Geolocated News Point")
                setAddFormType(mode)
                enableNewsSchema(1)
                setMenuOpen(1)
                break;
        }
    }

    async function handleGeomClick(id: string, type: string, category: string, desc: string, tags: string, time_bins: string, internal_memo: string, local_review: string, feat: Feature) {

        setOpenReportInfo(0)
        setSelectedFeat(feat)
        setGeomInfo([id, type, category, desc, tags, time_bins, internal_memo, local_review])
        setOpenGeomInfo(1)

    }

    async function handleReportClick(id: string, reporttype: string, description: string, source: string) {

        setOpenGeomInfo(0)
        setReportInfo([id, reporttype, description, source])
        setOpenReportInfo(1)

    }

    async function closeInfoText() {
        setOpenReportInfo(0)
        setOpenGeomInfo(0)
    }

    async function handleDeleteGeom(id: string) {

        const geomid: number = parseInt(id)

        await deleteUniversalGeometryById(geomid)

        setRefreshGeomFlag(refreshGeomFlag + ".")

        setOpenGeomInfo(0)

    }

    async function submitShape() {

        const formattedSearchRes: FeatureCollection = {
            "type" : "FeatureCollection",
            "features" : [{ 
                "type" : "Feature", 
                "properties" : {}, 
                "geometry" : { 
                    "type" : "Point", 
                    "coordinates" : searchResLoc,
                }
            }]
        }

        const newShape = {
            shapeType: (openAddResult == 1) ? "safety_point" : addFormType,
            // @ts-ignore
            geometry: (openAddResult == 1) ? formattedSearchRes : drawControlRef?.current?.draw?.getAll(),
            // @ts-ignore
            description: formDesc.current.value,
            // @ts-ignore
            internal_memo: formInternalMemo.current.value,
            // @ts-ignore
            local_review: formReview.current.value,
            color: await getFillColor((openAddResult == 1) ? "safety_point" : addFormType)
        }

        const newGeom = {
            type: (openAddResult == 1) ? "safety_point" : addFormType,
            // @ts-ignore
            geometry: (openAddResult == 1) ? formattedSearchRes : drawControlRef?.current?.draw?.getAll(),
            // @ts-ignore
            category: formCat.current.value,
            // @ts-ignore
            description: formDesc.current.value,
            // @ts-ignore
            local_review: formReview.current.value,
            // @ts-ignore
            tags: (formTags.current.value).split(","),
            // @ts-ignore
            time_bins: formTimeBins.current,
            // @ts-ignore
            news_source: formNewsSource.current.value,
            // @ts-ignore
            news_link: formNewsLink.current.value,
            // @ts-ignore
            internal_memo: formInternalMemo.current.value
        }

        await insertUniversalGeometry(newGeom)

        setShapes([...shapes, newShape])
        setAddFormTitle("")
        setAddFormType("")
        enableNewsSchema(0)
        setMenuOpen(0)
        setOpenAddResult(0)
        setSearchResLoc([0,0])
        setRefreshGeomFlag(refreshGeomFlag + ".")
        
    }

    async function closeMenu() {
        // @ts-ignore
        formTimeBins.current = ["morning", "afternoon", "evening"]
        setAddFormTitle("")
        setAddFormType("")
        enableNewsSchema(0)
        setMenuOpen(0)
        setOpenAddResult(0)
        setSearchResLoc([0,0])
    }

    async function closeEditMenu() {
        setEditCateg("")
        setEditDesc("")
        setEditReview("")
        setEditMemo("")
        setEditNewsLink("")
        setEditNewsSource("")
        setEditTags("")
        enableNewsSchema(0)
        setOnEdit(0)
    }

    async function flyToCity(cellValues: any) {

        const data = cellValues.data;

        // @ts-ignore
        viewportState.current.latitude = data.center_coords[0]

        // @ts-ignore
        viewportState.current.longitude = data.center_coords[1]

        // @ts-ignore
        viewportState.current.zoom = 10

        setInitialViewState({
            longitude: data.center_coords[1],
            latitude: data.center_coords[0],
            zoom: 10,
            pitch: 0,
            bearing: 0
        })

    }


    async function renderOsintMapData(latitude: number, longitude: number, radius: number) {

        const output = await renderOsintData(latitude, longitude, 10)

        console.log(output)

        // if (typeof output.kde_viol === "string") setKdeViol(JSON.parse(output.kde_viol));
        // if (typeof output.kde_nonviol === "string") setKdeNonviol(JSON.parse(output.kde_nonviol));
        setOsintViol(output.data_viol)
        setOsintNonViol(output.data_nonviol)

    }


    async function handleEditAsset(feat: Feature) {

        console.log("EDIT")
        console.log(feat)

        const props = feat.properties

        if (props) {

            setOpenGeomInfo(0)

            setEditCateg(props.category)
            setEditMemo(props.internal_memo)
            setEditReview(props.local_review)
            setEditDesc(props.description)
            setEditTags(props.tags.join(','))

            if (props.type === "geonews_point") {
                setEditNewsLink(props.news_link)
                setEditNewsSource(props.news_source)
                enableNewsSchema(1)
            }

            setOnEdit(1)

        }

    }


    async function submitEdits() {

        if (selectedFeat && selectedFeat.properties) {

            const asset_id: number = selectedFeat.properties.id

            await editUniversalGeometry(asset_id, editCateg, editDesc, editTags, editNewsLink, editNewsSource, editReview);

            setRefreshGeomFlag(refreshGeomFlag + ".")

            await closeEditMenu()

        }

    }


    async function searchGooglePlaces() {

        // @ts-ignore
        const coords: number[] = [viewportState.current.longitude, viewportState.current.latitude]

        const point: Point = {type: 'Point', coordinates: coords}

        const res = await queryPlacesByPoint(point, 200000, "restaurant")

        setGooglePlaces(res)

    }

    
    async function selectGooglePlace(name: string, tags: string[], location: {lat: number, lng: number}) {

        setGooglePlaceInfo([name, tags.join(", "), JSON.stringify(location)])

        setOpenGooglePlaceInfo(1)

    }


    const flytocolumns: ColDef[] = [
        { field: 'name', headerName: 'City Name', width: 200 },
        { field: 'country_abbr', headerName: 'Country', width: 100 },
        { field: 'center_coords', headerName: 'Coordinates', width: 100},
        { field: 'Fly To', renderCell: (cellValues) => {
            return (
              <Button
                variant="contained"
                color="secondary"
                onClick={async() => await flyToCity(cellValues)}
              >
                FLY
              </Button>
            );
          } },
      ];

    const handleSliderChange = (event: any, newValue: any) => {
        setRadiusVal(newValue);
    };


    const cityprofilecolumns: ColDef[] = [
        { field: 'name', headerName: 'City Name', width: 200 },
        { field: 'country_abbr', headerName: 'Country', width: 100 },
        { field: 'center_coords', headerName: 'Coordinates', width: 100},
        { field: 'Safety Insights', width: 200, renderCell: (cellValues) => {
            return (
              <Button
                variant="contained"
                color="secondary"
                onClick={async() => await handleEditCityInsights(cellValues)}
              >
                Edit Insights
              </Button>
            );
          } },
        { field: 'Safety Vendors', width: 200, renderCell: (cellValues) => {
        return (
            <Button
            variant="contained"
            color="secondary"
            onClick={async() => await handleEditCityVendors(cellValues)}
            >
            Edit Vendors
            </Button>
        );
        } },
      ];

      const countryprofilecolumns: ColDef[] = [
        { field: 'abbr', headerName: 'Abbreviation', width: 100 },
        { field: 'name', headerName: 'Name', width: 300 },
        // { field: 'center_coords', headerName: 'Coordinates', width: 100},
        { field: 'Select', width: 100, renderCell: (cellValues) => {
            return (
              <Button
                variant="contained"
                color="secondary"
                onClick={async() => await selectCountry(cellValues)}
              >
                Select
              </Button>
            );
          } },
      ];

      const selectedindicescolumns: ColDef[] = [
        { field: 'fullname', headerName: 'Full Name', width: 300 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'score', headerName: 'Score', width: 100 },
        { field: 'severity', headerName: 'Score/10', width: 100 },
        { field: 'Edit', width: 100, renderCell: (cellValues) => {
            return (
              <Button
                variant="contained"
                color="secondary"
                onClick={async() => await editIndex(cellValues)}
              >
                Edit
              </Button>
            );
          } },
        { field: 'Delete', width: 100, renderCell: (cellValues) => {
        return (
            <div style={{color: "red"}}>
            <Button
            variant="outlined"
            color="inherit"
            onClick={async() => await deleteIndex(cellValues)}
            >
            Delete
            </Button>
            </div>
        );
        } },
        { field: 'source', headerName: 'Source', width: 300 },
      ];

      const selectedinsightscolumns: ColDef[] = [
        { field: 'text', headerName: 'Text', width: 400 },
        { field: 'tags', headerName: 'Tags', width: 200 },
        { field: 'Select', width: 100, renderCell: (cellValues) => {
            return (
              <Button
                variant="contained"
                color="secondary"
                onClick={async() => await selectInsight(cellValues)}
              >
                Edit
              </Button>
            );
          } },
        { field: 'Delete', width: 100, renderCell: (cellValues) => {
        return (
            <div style={{color: "red"}}>
            <Button
            variant="outlined"
            color="inherit"
            onClick={async() => await deleteSafetyInsight(cellValues)}
            >
            Delete
            </Button>
            </div>
        );
        } },
      ];

      const selectedvendorscolumns: ColDef[] = [
        { field: 'names', headerName: 'Name(s)', width: 400 },
        { field: 'contact_info', headerName: 'Contact Information', width: 200 },
        { field: 'description', headerName: 'Description', width: 200 },
        { field: 'Select', width: 100, renderCell: (cellValues) => {
            return (
              <Button
                variant="contained"
                color="secondary"
                onClick={async() => await editVendor(cellValues)}
              >
                Edit
              </Button>
            );
          } },
        { field: 'Delete', width: 100, renderCell: (cellValues) => {
        return (
            <div style={{color: "red"}}>
            <Button
            variant="outlined"
            color="inherit"
            onClick={async() => await deleteVendor(cellValues)}
            >
            Delete
            </Button>
            </div>
        );
        } },
      ];


    async function openInsightForm(insights: any[]) {

        console.log("there")

        console.log(insights)

        setSelectedInsights(insights)

        setInsightList(1)

    }
    
    async function handleEditCityInsights(cellValues: any) {

        setInsightList(0)

        const data = cellValues.data;

        console.log("data")
        console.log(data)

        if (data.safety_insights) {

            setSelectedCityProfile(data)

            setInsightSelectBar(1)

        }

    }


    async function selectInsight(cellValues: any) {

        console.log(cellValues.data)

        setInsightEditMode("")

        setInsightEdit(1)

        setNewTags(cellValues.data.tags)
        setNewInsightText(cellValues.data.text)

        setSelectedInsight(cellValues.data)
        
    }

    async function submitNewTags() {
        
        const insightIndex = selectedInsights.indexOf(selectedInsight)

        let newInsights = selectedInsights

        newInsights[insightIndex].tags = newTags.split(",")

        await submitSafetyInsightEdits(newInsights, selectedInsightCateg, selectedCityProfile?.name!)

        setRefetchFlag(refetchFlag + ".")

        setNewTags("")
        setNewInsightText("")
        // setSelectedInsightCateg("")
        // setSelectedInsight(undefined)
        setInsightEditMode("")
        // setInsightList(0)

    }

    async function submitNewInsightText() {

        const insightIndex = selectedInsights.indexOf(selectedInsight)

        let newInsights = selectedInsights

        newInsights[insightIndex].text = newInsightText

        await submitSafetyInsightEdits(newInsights, selectedInsightCateg, selectedCityProfile?.name!)

        setRefetchFlag(refetchFlag + ".")

        setNewTags("")
        setNewInsightText("")
        // setSelectedInsightCateg("")
        // setSelectedInsight(undefined)
        setInsightEditMode("")
        // setInsightList(0)

    }

    async function addSafetyInsight() {

        let newInsight = {
            text: newInsightText,
            tags: newTags
        }

        const newInsights = [...selectedInsights, newInsight]

        await submitSafetyInsightEdits(newInsights, selectedInsightCateg, selectedCityProfile?.name!)

        setRefetchFlag(refetchFlag + ".")

        setInsightAdd(0)

    }

    async function deleteSafetyInsight(cellValues: any) {

        const deleteInsight = cellValues.data

        const newInsights = selectedInsights.filter((insight) => insight != deleteInsight)

        await submitSafetyInsightEdits(newInsights, selectedInsightCateg, selectedCityProfile?.name!)

        setRefetchFlag(refetchFlag + ".")

    }

    async function handleEditCityVendors(cellValues: any) {

        setSelectedCityProfile(cellValues.data)

        const vendors = cellValues.data.safety_vendors

        if (vendors) {

            setSelectedVendors(vendors)

        } else {

            setSelectedVendors([])
        }

        setVendorList(1)

    }

    async function submitNewVendor() {

        setVendorEdit(0)

        const newVendor = {
            names: newVendorNames,
            contact_info: newVendorContact,
            description: newVendorDesc
        }

        const newVendors = [...selectedVendors, newVendor]

        console.log(newVendors)
        console.log(selectedCityProfile?.name!)

        await submitSafetyVendorEdits(newVendors, selectedCityProfile?.name!)

        setVendorAdd(0)

        setRefetchFlag(refetchFlag + ".")

    }

    async function editVendor(cellValues: any) {

        const data = cellValues.data

        setNewVendorNames(data.names)
        setNewVendorContact(data.contact_info)
        setNewVendorDesc(data.description)

        setSelectedVendor(data)

        setVendorEdit(1)

    }

    async function submitVendorEdits() {

        setVendorAdd(0)

        const rest = selectedVendors.filter((vendor) => vendor != selectedVendor)

        const newVendor = {
            names: newVendorNames,
            contact_info: newVendorContact,
            description: newVendorDesc
        }

        const newVendors = [...rest, newVendor]

        await submitSafetyVendorEdits(newVendors, selectedCityProfile?.name!)

        setVendorEdit(0)

        setRefetchFlag(refetchFlag + ".")

    }

    async function deleteVendor(cellValues: any) {

        const vendorDel = cellValues.data

        const newVendors = selectedVendors.filter((vendor) => vendor != vendorDel)

        await submitSafetyVendorEdits(newVendors, selectedCityProfile?.name!)

        setRefetchFlag(refetchFlag + ".")

    }

    async function selectCountry(cellValues: any) {

        const countryProf: TravelCountryProfile = cellValues.data

        console.log(countryProf)

        setSelectedCountryProf(countryProf)

        setSelectedIndices(countryProf.indices)

        setIndexList(1)

    }

    async function addNewIndex() {

        setIndexEdit(0)

        setIndexAdd(1)
    }

    async function submitNewIndex() {

        const newIndex: TravelIndex = {
            name: newIndexName,
            fullname: newIndexFullName,
            score: newIndexScore,
            severity: parseInt(newIndexSeverity),
            source: newIndexSource,
            description: newIndexDescription,
            links: []
        }

        const newIndices: TravelIndex[] = [...selectedIndices, newIndex]

        await submitIndexEdits(newIndices, selectedCountryProf?.abbr!)

        setSelectedIndices(newIndices)

        setIndexAdd(0)

    }

    async function editIndex(cellValues: any) {

        setIndexAdd(0)

        const index: TravelIndex = cellValues.data

        setSelectedIndex(index)

        setIndexFullName(index.fullname)
        setIndexName(index.name)
        setIndexScore(index.score)
        setIndexSeverity(index.severity.toString())
        setIndexSource(index.source)
        setIndexDescription(index.description)

        setIndexEdit(1)

    }

    async function submitIndEdits() {

        const rest: TravelIndex[] = selectedIndices.filter((index) => index != selectedIndex)

        const newIndex: TravelIndex = {
            fullname: newIndexFullName,
            name: newIndexName,
            score: newIndexScore,
            severity: parseInt(newIndexSeverity),
            source: newIndexSource,
            description: newIndexDescription,
            links: selectedIndex!.links
        }

        const newIndices: TravelIndex[] = [...rest, newIndex]

        await submitIndexEdits(newIndices, selectedCountryProf?.abbr!)

        setSelectedIndices(newIndices)

        setIndexEdit(0)

    }

    async function deleteIndex(cellValues: any) {

        const indexDel: TravelIndex = cellValues.data

        const newIndices: TravelIndex[] = selectedIndices.filter((index) => index != indexDel)

        await submitIndexEdits(newIndices, selectedCountryProf?.abbr!)

        setSelectedIndices(newIndices)

    }

    const CloseWhiteIcon = S3Key + "close-white.png";
    const SearchWhiteIcon = S3Key + "search-white.png";
    const geosuggestEl = useRef<any>(null);


    const onSuggestSelect = (e: any) => {

        if (e && e.location) {

            const location = e.location

            setSearchResLoc([location.lng, location.lat])

            // @ts-ignore
            viewportState.current.latitude = location.lat

            // @ts-ignore
            viewportState.current.longitude = location.lng

            // @ts-ignore
            viewportState.current.zoom = 10

            setInitialViewState({
                longitude: location.lng,
                latitude: location.lat,
                zoom: 14,
                pitch: 0,
                bearing: 0
            })

            geosuggestEl.current.clear();
            geosuggestEl.current.blur();
            updateShowSearchBar(false);

            setOpenAddResult(1)
        }

    }
    

  return (

    <div>
        <ToggleBarContainer>
            <Button size="large" href="/travelPendingTrips">
                Pending Trips
            </Button>
            <Button size="large" href="/travelApp" >
                Home
            </Button>
            <Button size="large" color="primary" >
                Countries & Cities
            </Button>
            <Button size="large" href="/travelActiveTrips" >
                Active Trips
            </Button>
        </ToggleBarContainer>
        <ToggleBarContainer>
            <Button size="large" onClick={() => setAreaMode("countries")} color={((areaMode === "countries") ? 'primary' : 'default')} >
                Country Profiles
            </Button>
            <Button size="large" onClick={() => setAreaMode("cities")} color={((areaMode === "cities") ? 'primary' : 'default')} >
                City Profiles
            </Button>
            <Button size="large" onClick={() => {
                setAreaMode("assets")
                }} color={((areaMode === "assets") ? 'primary' : 'default')} >
                World Assets
            </Button>
        </ToggleBarContainer>
        <div>
            {areaMode === "assets" && <div style={{ fontSize: '20px', height: '600px'}} >
                
                <div style={{ alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', position: 'relative' }} >
                <DeckGL
                    height="100%"
                    width={(menuOpen > 0) ? '65%' : '100%'}
                    controller
                    //layers={(onEdit == 0) ? layers : editLayer}
                    layers={(menuOpen > 0) ? [] : layers}
                    initialViewState={initialViewState}
                    // @ts-ignore
                    viewState={viewportState.current.value}
                    onViewStateChange={(viewState) => {
                    viewportState.current = viewState.viewState;
                }}
                >

                { menuOpen > 0 && <Map
                    // eslint-disable-next-line react/style-prop-object
                    style="mapbox://styles/mapbox/satellite-streets-v9"
                    containerStyle={{
                        display: "flex",
                        width: '100%',
                        height: "100%",
                        position: "sticky",
                        right: 10,
                        top: 80,
                        bottom: 10,
                        borderRadius: 10
                    }}
                    // @ts-ignore
                    center={[viewportState.current.longitude as number, viewportState.current.latitude as number]}
                    //onStyleLoad={onStyleLoad}
                    // @ts-ignore
                    zoom={[viewportState.current.zoom]}
                    >
                        
                {<DrawControl 
                    ref={drawControlRef}
                    userProperties={true}
                    //onDrawCreate={onDrawCreate}
                    //onDrawUpdate={onDrawUpdate}
                    controls={{
                    "point": (menuOpen == 1) ? true : false,
                    "line_string": false, 
                    "polygon": (menuOpen == 2) ? true : false,
                    "trash": (menuOpen > 0) ? true : false,
                    "combine_features": false,
                    "uncombine_features": false
                    }}
                />}

                </Map>}

                { menuOpen == 0 && 
                <StaticMap width="100%" height="100%" mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle={"mapbox://styles/mapbox/satellite-streets-v9"} />
                }
                <SearchButtonContainer>
                    <RoundButton onClick={(e) => updateShowSearchBar(!showSearchBar)}>
                        {showSearchBar ? (
                            <img src={CloseWhiteIcon} alt="Close" width="18px" />
                        ) : (
                            <img src={SearchWhiteIcon} alt="Search" width="18px" />
                        )}
                    </RoundButton>
                </SearchButtonContainer>
                {showSearchBar && (
                    <div>
                    {/* <script src={`https://maps.googleapis.com/maps/api/js?key=${PLACES_API_KEY}&libraries=places`}></script> */}
                    <Geosuggest ref={geosuggestEl} placeholder="Search for Places" onSuggestSelect={(e: any) => onSuggestSelect(e)} />
                    </div>
                )}

                </DeckGL>
                {menuOpen > 0 && <div style={{float: "right"}}>
                    <div style={{ alignItems: 'left', display: 'flex', justifyContent: 'left', fontSize: '20px', height: '50px'}} >
                    {addFormTitle}
                    </div>

                    <FormControl>
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                      Description
                      </FormLabel>
                      <TextInput
                          id="geomdesc"
                          variant="outlined"
                          inputRef={formDesc}
                          //value={addFormDesc}
                          //onChange={(e) => setAddFormDesc(e.target.value)}
                      />
                      <FormLabel component="legend" style={{ margin: "10px 0" }}>
                      Internal Memo (only Arcturus staff can see)
                      </FormLabel>
                      <TextInput
                          id="geominternalmemo"
                          variant="outlined"
                          inputRef={formInternalMemo}
                          //value={addFormDesc}
                          //onChange={(e) => setAddFormDesc(e.target.value)}
                      />
                      <FormLabel component="legend" style={{ margin: "10px 0" }}>
                      {(newsSchema == 0) ? "Category (ex. Hotel, Restaurant)" : "News Headline" }
                      </FormLabel>
                      <TextInput
                          id="geomcateg"
                          variant="outlined"
                          inputRef={formCat}
                          //value={geomcateg}
                          //onChange={(e) => updateMessageText(e.target.value)}
                        />
                        {/* <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Time Bins
                        </FormLabel>
                        <TextInput
                            id="geomdesc"
                            variant="outlined"
                            inputRef={formDesc}
                            //value={addFormDesc}
                            //onChange={(e) => setAddFormDesc(e.target.value)}
                      /> */}
                      <FormLabel component="legend" style={{ margin: "10px 0" }}>
                      {"Tags (Separate with a comma)"}
                      </FormLabel>
                      <TextInput
                          id="geomdesc"
                          variant="outlined"
                          inputRef={formTags}
                          //value={addFormDesc}
                          //onChange={(e) => setAddFormDesc(e.target.value)}
                      />
                      <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Time Bins
                        </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                            control={
                            <CheckInput
                                name="morning"
                                defaultChecked={true}
                                onChange={(e) => {
                                    // @ts-ignore
                                    if ((formTimeBins.current).includes("morning") == false) {
                                        // @ts-ignore
                                        formTimeBins.current = [...formTimeBins.current, "morning"]
                                    } else {
                                        const newarr = (formTimeBins.current).filter((bin) => bin !== "morning")
                                        formTimeBins.current = newarr
                                    }
                                }}
                            />
                            }
                            label="Morning"
                        />
                        <FormControlLabel
                            control={
                            <CheckInput
                                name="afternoon"
                                defaultChecked={true}
                                onChange={(e) => {
                                    // @ts-ignore
                                    if ((formTimeBins.current).includes("afternoon") == false) {
                                        // @ts-ignore
                                        formTimeBins.current = [...formTimeBins.current, "afternoon"]
                                    } else {
                                        const newarr = (formTimeBins.current).filter((bin) => bin !== "afternoon")
                                        formTimeBins.current = newarr
                                    }
                                }}
                            />
                            }
                            label="Afternoon"
                        />
                        <FormControlLabel
                            control={
                            <CheckInput
                                name="evening"
                                defaultChecked={true}
                                onChange={(e) => {
                                    // @ts-ignore
                                    if ((formTimeBins.current).includes("evening") == false) {
                                        // @ts-ignore
                                        formTimeBins.current = [...formTimeBins.current, "evening"]
                                    } else {
                                        const newarr = (formTimeBins.current).filter((bin) => bin !== "evening")
                                        formTimeBins.current = newarr
                                    }
                                }}
                            />
                            }
                            label="Evening"
                        />
                        </FormGroup>
                        <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Enter a Recent Review (optional)
                        </FormLabel>
                        <TextInput
                            id="geomreview"
                            variant="outlined"
                            inputRef={formReview}
                            //value={addFormDesc}
                            //onChange={(e) => setAddFormDesc(e.target.value)}
                        />
                      { newsSchema == 1 && <div>
                      <FormLabel component="legend" style={{ margin: "10px 0" }}>
                      News Source
                      </FormLabel>
                      <TextInput
                          id="geomsource"
                          variant="outlined"
                          inputRef={formNewsSource}
                          //value={geomcateg}
                          //onChange={(e) => updateMessageText(e.target.value)}
                        />
                        <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        News Link
                        </FormLabel>
                        <TextInput
                            id="geomnewslink"
                            variant="outlined"
                            inputRef={formNewsLink}
                            //value={geomcateg}
                            //onChange={(e) => updateMessageText(e.target.value)}
                            />
                        </div>}
                        <Button onClick={() => {submitShape()}}>
                        SUBMIT
                    </Button>
                    <Button>

                    </Button>
                    <Button onClick={async() => {await closeMenu()}} >
                        Close
                    </Button>
                    </FormControl>
                    
                    
                </div>}
                </div>
                <div style={{height: '10px'}}/>
                {onEdit == 1 && 
                <FormControl>
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                  Description
                  </FormLabel>
                  <TextInput
                      id="geomdesc-edit"
                      variant="outlined"
                      //inputRef={formDesc}
                      value={editDesc}
                      onChange={(e) => setEditDesc(e.target.value)}
                  />
                  <FormLabel component="legend" style={{ margin: "10px 0" }}>
                  Internal Memo (only Arcturus Staff can see)
                  </FormLabel>
                  <TextInput
                      id="geommemo-edit"
                      variant="outlined"
                      //inputRef={formDesc}
                      value={editMemo}
                      onChange={(e) => setEditMemo(e.target.value)}
                  />
                  <FormLabel component="legend" style={{ margin: "10px 0" }}>
                  {(newsSchema == 0) ? "Category (ex. Hotel, Restaurant)" : "News Headline" }
                  </FormLabel>
                  <TextInput
                      id="geomcateg-edit"
                      variant="outlined"
                      //inputRef={formCat}
                      value={editCateg}
                      onChange={(e) => setEditCateg(e.target.value)}
                    />
                  <FormLabel component="legend" style={{ margin: "10px 0" }}>
                  {"Tags (Separate with a comma)"}
                  </FormLabel>
                  <TextInput
                      id="tags-edit"
                      variant="outlined"
                      //inputRef={formTags}
                      value={editTags}
                      onChange={(e) => setEditTags(e.target.value)}
                  />
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        New/Edit Local Review
                        </FormLabel>
                        <TextInput
                            id="geomrev"
                            variant="outlined"
                            //inputRef={formDesc}
                            value={editReview}
                            onChange={(e) => setEditReview(e.target.value)}
                        />
                  { newsSchema == 1 && <div>
                  <FormLabel component="legend" style={{ margin: "10px 0" }}>
                  News Source
                  </FormLabel>
                  <TextInput
                      id="geomsource-edit"
                      variant="outlined"
                      //inputRef={formNewsSource}
                      value={editNewsSource}
                      onChange={(e) => setEditNewsSource(e.target.value)}
                    />
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    News Link
                    </FormLabel>
                    <TextInput
                        id="geomnewslink-edit"
                        variant="outlined"
                        //inputRef={formNewsLink}
                        value={editNewsLink}
                        onChange={(e) => setEditNewsLink(e.target.value)}
                        />
                    </div>}
                    <Button onClick={() => {submitEdits()}}>
                    SUBMIT EDITS
                </Button>
                <Button>

                </Button>
                <Button onClick={async() => {await closeEditMenu()}} >
                    Close
                </Button>
                <Button>

                </Button>
                </FormControl>
                }
                {openAddResult == 1 && 
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center'}} >
                <FormControl>
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                Description
                </FormLabel>
                <TextInput
                    id="geomdesc"
                    variant="outlined"
                    inputRef={formDesc}
                    //value={addFormDesc}
                    //onChange={(e) => setAddFormDesc(e.target.value)}
                />
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                Internal Memo (only Arcturus staff can see)
                </FormLabel>
                <TextInput
                    id="geominternalmemo"
                    variant="outlined"
                    inputRef={formInternalMemo}
                    //value={addFormDesc}
                    //onChange={(e) => setAddFormDesc(e.target.value)}
                />
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                {(newsSchema == 0) ? "Category (ex. Hotel, Restaurant)" : "News Headline" }
                </FormLabel>
                <TextInput
                    id="geomcateg"
                    variant="outlined"
                    inputRef={formCat}
                    //value={geomcateg}
                    //onChange={(e) => updateMessageText(e.target.value)}
                    />
                    {/* <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Time Bins
                    </FormLabel>
                    <TextInput
                        id="geomdesc"
                        variant="outlined"
                        inputRef={formDesc}
                        //value={addFormDesc}
                        //onChange={(e) => setAddFormDesc(e.target.value)}
                /> */}
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                {"Tags (Separate with a comma)"}
                </FormLabel>
                <TextInput
                    id="geomdesc"
                    variant="outlined"
                    inputRef={formTags}
                    //value={addFormDesc}
                    //onChange={(e) => setAddFormDesc(e.target.value)}
                />
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Time Bins
                    </FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={
                        <CheckInput
                            name="morning"
                            defaultChecked={true}
                            onChange={(e) => {
                                // @ts-ignore
                                if ((formTimeBins.current).includes("morning") == false) {
                                    // @ts-ignore
                                    formTimeBins.current = [...formTimeBins.current, "morning"]
                                } else {
                                    const newarr = (formTimeBins.current).filter((bin) => bin !== "morning")
                                    formTimeBins.current = newarr
                                }
                            }}
                        />
                        }
                        label="Morning"
                    />
                    <FormControlLabel
                        control={
                        <CheckInput
                            name="afternoon"
                            defaultChecked={true}
                            onChange={(e) => {
                                // @ts-ignore
                                if ((formTimeBins.current).includes("afternoon") == false) {
                                    // @ts-ignore
                                    formTimeBins.current = [...formTimeBins.current, "afternoon"]
                                } else {
                                    const newarr = (formTimeBins.current).filter((bin) => bin !== "afternoon")
                                    formTimeBins.current = newarr
                                }
                            }}
                        />
                        }
                        label="Afternoon"
                    />
                    <FormControlLabel
                        control={
                        <CheckInput
                            name="evening"
                            defaultChecked={true}
                            onChange={(e) => {
                                // @ts-ignore
                                if ((formTimeBins.current).includes("evening") == false) {
                                    // @ts-ignore
                                    formTimeBins.current = [...formTimeBins.current, "evening"]
                                } else {
                                    const newarr = (formTimeBins.current).filter((bin) => bin !== "evening")
                                    formTimeBins.current = newarr
                                }
                            }}
                        />
                        }
                        label="Evening"
                    />
                    </FormGroup>
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Enter a Recent Review (optional)
                    </FormLabel>
                    <TextInput
                        id="geomreview"
                        variant="outlined"
                        inputRef={formReview}
                        //value={addFormDesc}
                        //onChange={(e) => setAddFormDesc(e.target.value)}
                    />
                { newsSchema == 1 && <div>
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                News Source
                </FormLabel>
                <TextInput
                    id="geomsource"
                    variant="outlined"
                    inputRef={formNewsSource}
                    //value={geomcateg}
                    //onChange={(e) => updateMessageText(e.target.value)}
                    />
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    News Link
                    </FormLabel>
                    <TextInput
                        id="geomnewslink"
                        variant="outlined"
                        inputRef={formNewsLink}
                        //value={geomcateg}
                        //onChange={(e) => updateMessageText(e.target.value)}
                        />
                    </div>}
                    <Button onClick={() => {submitShape()}}>
                    SUBMIT
                </Button>
                <Button onClick={async() => {await closeMenu()}} >
                    Close
                </Button>
                </FormControl>
                </div>

                }
                {openGeomInfo == 1 && 
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', whiteSpace: 'pre-line', color: 'yellow', height: '220px'}} >
                <ToggleBarContainer>
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', whiteSpace: 'pre-line', color: 'yellow'}} >
                Clicked Polygon Info {'\n'} ID: {geomInfo[0]} {'\n'} {geomInfo[1]} {'\n'} Category: {geomInfo[2]} {'\n'} Description: {geomInfo[3]} {'\n'} Internal Memo: {geomInfo[6]} {'\n'} Tags: {geomInfo[4]} {'\n'} Time Bins: {geomInfo[5]} {'\n'} Local Review: {geomInfo[7]}
                </div>
                <Button onClick={() => {closeInfoText()}}>
                    Close
                </Button>
                <Button onClick={() => { if (typeof selectedFeat !== "undefined") handleEditAsset(selectedFeat)}} >
                    Edit This Asset
                </Button>
                <Button>

                </Button>
                <Button onClick={() => {handleDeleteGeom(geomInfo[0])}} >
                    Delete This Asset
                </Button>
                </ToggleBarContainer>
                </div>
                }
                {openReportInfo == 1 && 
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', whiteSpace: 'pre-line', color: 'light purple', height: '110px'}} >
                <ToggleBarContainer>
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', whiteSpace: 'pre-line', color: 'light purple'}} >
                Clicked OSINT Report Info {'\n'} ID: {reportInfo[0]} {'\n'} {reportInfo[1]} {'\n'} {reportInfo[2]} {'\n'} {reportInfo[3]}
                </div>
                <Button onClick={() => {closeInfoText()}}>
                    Close
                </Button>
                </ToggleBarContainer>
                </div>
                }
                {openGooglePlaceInfo == 1 &&
                <ToggleBarContainer>
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', whiteSpace: 'pre-line', color: 'orange'}} >
                Clicked Google Place Info {'\n'} Name: {googlePlaceInfo[0]} {'\n'} {googlePlaceInfo[1]} {'\n'} {googlePlaceInfo[2]}
                </div>
                <Button onClick={() => {setOpenGooglePlaceInfo(0)}} >
                    Close
                </Button>
                </ToggleBarContainer>
                }
                <div style={{alignItems: 'center', display: 'flex',justifyContent: 'center'}}>
                <Button onClick={() => setOsintMode("viol")} color={((osintMode === "viol") ? 'primary' : 'default')} >
                Violent
                </Button>
                <Button onClick={() => setOsintMode("nonviol")} color={((osintMode === "nonviol") ? 'primary' : 'default')} >
                NonViolent
                </Button>
                <Button>

                </Button>
                <Button onClick={() => setDataDisplayMode("heatmap")} color={((dataDisplayMode === "heatmap") ? 'primary' : 'default')} >
                Heatmap
                </Button>
                <Button onClick={() => setDataDisplayMode("points")} color={((dataDisplayMode === "points") ? 'primary' : 'default')} >
                Reports
                </Button>
                <Button>

                </Button>
                <Button 
                // @ts-ignore
                onClick={() => {renderOsintMapData(viewportState.current.longitude as number, viewportState.current.latitude as number, radiusVal)}}
                >
                  Search reports
                </Button>
                <Button onClick={() => {
                    // setKdeViol(undefined)
                    // setKdeNonviol(undefined)
                    setOsintNonViol([])
                    setOsintViol([])
                }}>
                    Clean reports
                </Button>
                <Button>

                </Button>
                <Button onClick={async() => {
                    await searchGooglePlaces()
                }} >
                    Search GPlaces
                </Button>
                <Button onClick={() => {
                    setGooglePlaces([])
                }} >
                    Clean GPlaces
                </Button>
                </div>
                <div>
                <ToggleBarContainer>
                    <Button onClick={async() => await handleMenu("safety_polygon")} >
                        Safety Polygon
                    </Button>
                    <Button onClick={async() => await handleMenu("violent_polygon")} >
                        Violent Risk Polygon
                    </Button>
                    <Button onClick={async() => await handleMenu("nonviolent_polygon")} >
                        Non-Violent Risk Polygon
                    </Button>
                </ToggleBarContainer>
                </div>
                <div>
                <ToggleBarContainer>
                    <Button onClick={async() => await handleMenu("safety_point")} >
                        Safety Point
                    </Button>
                    <Button onClick={async() => await handleMenu("violent_point")} >
                        Violent Risk Point
                    </Button>
                    <Button onClick={async() => await handleMenu("nonviolent_point")} >
                        Non-Violent Risk Point
                    </Button>
                    <Button onClick={async() => await handleMenu("analystnote_point")} >
                        Analyst Note Point
                    </Button>
                    <Button onClick={async() => await handleMenu("geonews_point")} >
                        Geolocated News
                    </Button>
                </ToggleBarContainer>
                </div>
                <div>
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px', whiteSpace: 'pre-line', color: 'cyan', height: '50px'}} >
                FLY TO CITY
                </div>
                <div style={{height: 500, width: '65%'}}>
                <DataGrid rows={cities} columns={flytocolumns} pageSize={50} rowsPerPageOptions={[5]} className={classes.root} />
                </div>
                </div>
            </div>}

            {areaMode === "cities" && <div style={{ fontSize: '20px', height: '600px'}} >

            <DataGrid rows={cities} columns={cityprofilecolumns} pageSize={50} rowsPerPageOptions={[5]} className={classes.root} />

            <div style={{height: 35}} />

            {insightSelectBar == 1 && <div>
            <ToggleBarContainer>
                <Button onClick={async() => {
                    setSelectedInsightCateg("communication")
                    await openInsightForm(selectedCityProfile?.safety_insights.communication || [])}} >
                    Communications
                </Button>
                <Button onClick={async() => {
                    setSelectedInsightCateg("emergency_services")
                    await openInsightForm(selectedCityProfile?.safety_insights.emergency_services || [])}} >
                    Emergency Services
                </Button>
                <Button onClick={async() => {
                    setSelectedInsightCateg("finances")
                    await openInsightForm(selectedCityProfile?.safety_insights.finances || [])}} >
                    Finances
                </Button>
                <Button onClick={async() => {
                    setSelectedInsightCateg("general_security")
                    console.log(selectedCityProfile)
                    await openInsightForm(selectedCityProfile?.safety_insights.general_security || [])}} >
                    General Security
                </Button>
            </ToggleBarContainer>
            <ToggleBarContainer>
                <Button onClick={async() => {
                    setSelectedInsightCateg("scams")
                    await openInsightForm(selectedCityProfile?.safety_insights.scams || [])}} >
                    Scams
                </Button>
                <Button onClick={async() => {
                    setSelectedInsightCateg("transportation")
                    await openInsightForm(selectedCityProfile?.safety_insights.transportation || [])}} >
                    Transportation
                </Button>
                <Button onClick={async() => {
                    setSelectedInsightCateg("women")
                    await openInsightForm(selectedCityProfile?.safety_insights.women || [])}} >
                    Women
                </Button>
            </ToggleBarContainer>
            </div>}
            
            {insightList == 1 && <div style={{height: 500, width: '100%'}}>

                <DataGrid rows={selectedInsights} columns={selectedinsightscolumns} pageSize={50} rowsPerPageOptions={[5]} className={classes.root} />
                <ToggleBarContainer>
                    <Button onClick={() => setInsightAdd(1)}>
                        Add new safety insight
                    </Button>
                </ToggleBarContainer>
                

            </div>}

            <div style={{height: 50}}/>

            {insightAdd == 1 && <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px'}}><div style={{height: 35}}/>
            
            <FormControl>
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                Add Text
                </FormLabel>
                <TextInput
                    id="addtags"
                    variant="outlined"
                    //inputRef={formDesc}
                    value={newInsightText}
                    onChange={(e) => setNewInsightText(e.target.value)}
                />
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                Add Tags
                </FormLabel>
                <TextInput
                    id="addinsighttext"
                    variant="outlined"
                    //inputRef={formDesc}
                    value={newTags}
                    onChange={(e) => setNewTags(e.target.value)}
                />
                <Button onClick={() => addSafetyInsight()}>
                    Submit Changes
                </Button>
            </FormControl>

            </div>}

            {insightEdit == 1 && <div>
                
                <div style={{height: 35}} />
                <div>
                    {selectedInsight.text.toString()}
                </div>
                <div style={{height: 35}} />
                <div>
                    {JSON.stringify(selectedInsight.tags)}
                </div>
                <div style={{height: 35}} />
                <ToggleBarContainer>
                    <Button onClick={() => setInsightEditMode("text")} >
                        Edit Text
                    </Button>
                    <Button onClick={() => setInsightEditMode("tags")} >
                        Edit Tags
                    </Button>
                </ToggleBarContainer>

            </div>}

            {insightEditMode == "tags" && <div>

            <FormControl>
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                Edit Tags (comma separated)
                </FormLabel>
                <TextInput
                    id="newtags"
                    variant="outlined"
                    //inputRef={formDesc}
                    value={newTags}
                    onChange={(e) => setNewTags(e.target.value)}
                />
                <Button onClick={() => submitNewTags()}>
                    Submit Changes
                </Button>
            </FormControl>

            </div>}

            {insightEditMode == "text" && <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px'}}>

            <FormControl>
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                Edit Text
                </FormLabel>
                <TextInput
                    id="newinsighttext"
                    variant="outlined"
                    //inputRef={formDesc}
                    value={newInsightText}
                    onChange={(e) => setNewInsightText(e.target.value)}
                />
                <Button onClick={() => submitNewInsightText()}>
                    Submit Changes
                </Button>
            </FormControl>

            </div>}

            {vendorList == 1 && <div style={{height: 500, width: '100%'}}>

                <DataGrid rows={selectedVendors} columns={selectedvendorscolumns} pageSize={50} rowsPerPageOptions={[5]} className={classes.root} />
                <ToggleBarContainer>
                    <Button onClick={() => setVendorAdd(1)}>
                        Add new safety vendor
                    </Button>
                </ToggleBarContainer>
                

            </div>}

            <div style={{height: 50}}/>

            {vendorAdd == 1 && <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px'}}>

                <FormControl>
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Add Name(s)
                    </FormLabel>
                    <TextInput
                        id="addvendornames"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newVendorNames}
                        onChange={(e) => setNewVendorNames(e.target.value)}
                    />
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Add Contact Information
                    </FormLabel>
                    <TextInput
                        id="addvendorcontact"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newVendorContact}
                        onChange={(e) => setNewVendorContact(e.target.value)}
                    />
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Add Description
                    </FormLabel>
                    <TextInput
                        id="addvendordesc"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newVendorDesc}
                        onChange={(e) => setNewVendorDesc(e.target.value)}
                    />
                    <Button onClick={() => submitNewVendor()}>
                        Submit Vendor
                    </Button>
                </FormControl>

            </div>}

            {vendorEdit == 1 && <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px'}}>

                <FormControl>
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Edit Name(s)
                    </FormLabel>
                    <TextInput
                        id="editvendornames"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newVendorNames}
                        onChange={(e) => setNewVendorNames(e.target.value)}
                    />
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Edit Contact Information
                    </FormLabel>
                    <TextInput
                        id="editvendorcontact"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newVendorContact}
                        onChange={(e) => setNewVendorContact(e.target.value)}
                    />
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Edit Description
                    </FormLabel>
                    <TextInput
                        id="editvendordesc"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newVendorDesc}
                        onChange={(e) => setNewVendorDesc(e.target.value)}
                    />
                    <Button onClick={() => submitVendorEdits()}>
                        Submit Changes
                    </Button>
                </FormControl>

            </div>}

            </div>}

            {areaMode === "countries" && <div style={{ fontSize: '20px', height: '600px'}} >

                <DataGrid rows={countries} columns={countryprofilecolumns} pageSize={9} rowsPerPageOptions={[5]} className={classes.root} />

                <div style={{height: 35}} />

            {indexList == 1 && <div style={{height: 500, width: '100%'}}>

                <DataGrid 
                getRowId={(row: any) => row.name}
                // @ts-ignore
                rows={selectedIndices} 
                columns={selectedindicescolumns} 
                pageSize={50} rowsPerPageOptions={[5]} className={classes.root} />

                <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px'}} >
                <Button size="large" onClick={async() => await addNewIndex()} >
                    Add New Index
                </Button>
                </div>

                <div style={{height: 35}} />

            </div>}

            {indexAdd == 1 && <div style={{height: 500, width: '100%'}}>

                <FormControl>
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Enter Unique Index Identifier
                    </FormLabel>
                    <TextInput
                        id="addindexname"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newIndexName}
                        onChange={(e) => setIndexName(e.target.value)}
                    />
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Enter Index Name
                    </FormLabel>
                    <TextInput
                        id="addindexfullname"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newIndexFullName}
                        onChange={(e) => setIndexFullName(e.target.value)}
                    />
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Enter Index Score
                    </FormLabel>
                    <TextInput
                        id="addindexscore"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newIndexScore}
                        onChange={(e) => setIndexScore(e.target.value)}
                    />
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Enter Index Score ( number 1 - 10)
                    </FormLabel>
                    <TextInput
                        id="addindexseverity"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newIndexSeverity}
                        onChange={(e) => setIndexSeverity(e.target.value)}
                    />
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Enter Index Source (URL)
                    </FormLabel>
                    <TextInput
                        id="addindexsource"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newIndexSource}
                        onChange={(e) => setIndexSource(e.target.value)}
                    />
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Edit Index Description
                    </FormLabel>
                    <TextInput
                        id="addindexsource"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newIndexDescription}
                        onChange={(e) => setIndexDescription(e.target.value)}
                    />
                    <Button onClick={async() => await submitNewIndex()}>
                        Add Index
                    </Button>
                </FormControl>

            </div>}

            {indexEdit == 1 && <div style={{height: 500, width: '100%'}}>

                <FormControl>
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Edit Index Intentifier
                    </FormLabel>
                    <TextInput
                        id="editindexname"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newIndexName}
                        onChange={(e) => setIndexName(e.target.value)}
                    />
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Edit Index Name
                    </FormLabel>
                    <TextInput
                        id="editindexfullname"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newIndexFullName}
                        onChange={(e) => setIndexFullName(e.target.value)}
                    />
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Edit Index Score
                    </FormLabel>
                    <TextInput
                        id="editindexscore"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newIndexScore}
                        onChange={(e) => setIndexScore(e.target.value)}
                    />
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Edit Index Score ( number 1 - 10)
                    </FormLabel>
                    <TextInput
                        id="editindexseverity"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newIndexSeverity}
                        onChange={(e) => setIndexSeverity(e.target.value)}
                    />
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Edit Index Source (URL)
                    </FormLabel>
                    <TextInput
                        id="editindexsource"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newIndexSource}
                        onChange={(e) => setIndexSource(e.target.value)}
                    />
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Edit Index Description
                    </FormLabel>
                    <TextInput
                        id="editindexsource"
                        variant="outlined"
                        //inputRef={formDesc}
                        value={newIndexDescription}
                        onChange={(e) => setIndexDescription(e.target.value)}
                    />
                    <Button onClick={async() => await submitIndEdits()}>
                        Submit Edits
                    </Button>
                </FormControl>

            </div>}

            </div>}

        </div>
    </div>

  )

}