import { Checkbox, TextField } from "@material-ui/core";
import styled from "styled-components";
import SearchBar from "material-ui-search-bar";

export const FormContainer = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    align-items: center;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding: 30px 0;
`;

export const TextInput = styled(TextField)`
    margin: 10px 0;
    width: 400px;
`;

export const CheckInput = styled(Checkbox)`
    margin: 0 10px;
`;

export const Section = styled.div`
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
`;

export const Line = styled.div`
    margin: 10px 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid grey;
`;

export const ToggleBarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
`;

export const StyledSearchBar = styled(SearchBar)`
  background-color: darkred;
  color: black
`;

export const RoundButton = styled.div`
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background: ${({ theme }) => theme.primaryText};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

export const SearchButtonContainer = styled.div`
    position: fixed;
    top: 22px;
    right: 75px;
    z-index: 20;
`;