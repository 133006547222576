import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelectFromRedux } from "../../utils/_hooks";
import { getTimeWindow } from "../../visualizations/utils";

import * as analytics from "../../types/api/analytics";
import { visualizationsActions } from "../../state/visualizations";
import moment from "moment";


export default function VisApiComponent({ setGridData, setZoneData }: any) {
    const env = process.env.REACT_APP_ENV!.toLowerCase();
    const dispatch = useDispatch();

    const hasStartedForecasting = useSelectFromRedux((state) => state.visualizations.hasStartedForecasting);
    const loading = useSelectFromRedux((state) => state.visualizations.loadingMessage);
    const selectedRegion = useSelectFromRedux((state) => state.filter.selectedRegion);
    const modalContent = useSelectFromRedux((state) => state.map.content);

    useEffect(() => {
        // axios({
        //     method: "POST",
        //     url: `https://sherlock.arcturus.us.com/baker/hotspot_analysis/zone`,
        //     headers: {
        //         "Content-Type": "application/json",
        //         Accept: "application/json"
        //     },
        //     data: {
        //         key:
        //             "02ef54f180d8869a58676e2df71c9d6db2987ab5ead51b012212f5abef03db2b09a1e9b2a2dcfab7762426f39af7f929ddd2f20dede04b37152e1d6cc95f0644",
        //         container_id: currentContainer,
        //         db: env,
        //         day_of_week: "all"
        //     }
        // })
        //     .then((res) => {
        //         if (res.data.features) {
        //             setZoneData(res.data.features);
        //         }
        //     })
        //     .catch((err) => {
        //         console.log("ERROR BOI")
        //         console.log(err)
        //     });

        if (hasStartedForecasting && selectedRegion > 0 && modalContent === "visualizations" && loading === null) {

            console.log("Fetching crime grid...");
            dispatch(visualizationsActions.updateLoadingRiskForecastsMessage("Calculating risk forecast..."));
            setGridData([]);

            analytics.adminCrimeGrid(selectedRegion)
                .then((res) => {
                    if (res.geoforecast_data.features) {
                        const data = res.geoforecast_data.features;

                        const gridData = new Array(7);

                        for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
                            gridData[dayIndex] = new Array(6);
                            for (let timeIndex = 0; timeIndex < 6; timeIndex++) {
                                gridData[dayIndex][timeIndex] = [];
                            }
                        }

                        data.forEach((d: any) => {

                            const time = getTimeWindow(moment(d.properties.date_time + "+00:00").hour());
                            const day = moment(d.properties.date_time + "+00:00").day();

                            d.properties.risk_index_tmp = getRiskIndex(d);

                            gridData[day][time].push(d);
                        });

                        setGridData(gridData);
                        dispatch(visualizationsActions.updateLoadingRiskForecastsMessage(null));
                    }
                })
                .catch((err) => {
                    dispatch(visualizationsActions.updateLoadingRiskForecastsMessage(null));
                    dispatch(visualizationsActions.updateHasStartedForecasting(false));
                    console.log("Error fetching crime grid: " + err.message);
                });
        }

    // Don't re-fetch on changes to modalContent or loadingMessage, would result in redundant fetches
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedRegion, hasStartedForecasting, setGridData, setZoneData, env]);

    return null;
}

const getRiskIndex = (d: any): number => {
    if (d.properties.risk_score) {
        const risk_score = Number(d.properties.risk_score);
        if (!isNaN(risk_score)) {
            if (risk_score >= 0 && risk_score < 0.1) {
                return 0;
            } else if (risk_score >= 0.1 && risk_score < 0.2) {
                return 1;
            } else if (risk_score >= 0.2 && risk_score < 0.3) {
                return 2;
            } else if (risk_score >= 0.3 && risk_score < 0.4) {
                return 3;
            } else if (risk_score >= 0.4 && risk_score < 0.5) {
                return 4;
            } else if (risk_score >= 0.5) {
                return 5;
            }
        }
    }

    return 0;
}
