import { Slider, Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogContent, DialogTitle, Icon, Paper, Tab, Tabs, TextField, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import error from "../error";
import { mapActions } from "../state/map";
import { useSelectFromRedux } from "../utils/_hooks";
import { AssetModalContainer, ExitButton, ModalTitle, ModalAddress, ModalText, EditButton, HoverDiv, FieldGroup } from "./styled";
import { Autocomplete } from "@material-ui/lab";
import { TextInput } from "../admin/styled";
import { AdminUserProfile } from "../types/admin";
import { AllAssetConcerns, AssetConcern, Asset, Consequence, Risk, RiskUpdate } from "../types/case";
import { getAllAdminUsers, getAssets, getRiskEdits, getUserProfileByID, updateAssetRisk } from "../types/api/admin";
import moment from "moment";
import { AllReportTypes } from "../types/reports";
import { VerticallyCenteredRow } from "../_shared";
import * as _ from "lodash";
import { LineString, Polygon, Point } from "geojson";
import * as d3 from "d3-geo";
import * as d3poly from "d3-polygon";

export interface FormattedAsset {
    thisOne: Asset;
    scenarios: Asset[];
    unincluded: Asset[];
    variant: "scenario" | "all" | "some";
    assetName?: string;
}

export interface ConsequenceFormattedAsset {
    consequenceIndex: number,
    fAssets: FormattedAsset[]
}

export interface RiskEditHistory {
    payload: {
        asset_ids: number[];
        name: string;
        relevant_event_types: string[];
        risk_interval: string;
        created_at?: Date;
        severity: number;
        chance: number;
        updates: RiskUpdate[];
        geography?: Point | LineString | Polygon;
        concerns: AssetConcern[]; // "Profitability" (See below definition)
        consequences: Consequence[]; // see below definition
        is_obsolete: boolean // "true" indicates this risk is no longer relevant
        container_ids?: Number[]; // Applicable container(s)
        default_region_ids?: Number[]; // Applicable region(s)
        suggestions: string;
        comments: string;
    },
    timestamp: string,
    admin_user_id?: number |  null,
    user_id?: number | null
}

export default ({props, getRiskData} : {props: any, getRiskData: () => Promise<void>}) => {
    const [
        { selectedRisk, changeGeo, geography, drawMode, selectedAsset, selectedScenario },
        colors
    ] = useSelectFromRedux((state) => [
        state.map,
        state.color
    ]);

    const [risk, setRisk] = useState<Risk | undefined>(selectedRisk);
    const [editing, setEditing] = useState<boolean>(false);
    const [editRisk, setEditRisk] = useState<Risk | undefined>(selectedRisk);
    const [assets, setAssets] = useState<Asset[]>([]);
    const [allAssets, setAllAssets] = useState<Asset[]>([]);
    const [changed, setChanged] = useState<boolean>(false);
    const [formattedAssets, setFormattedAssets] = useState<FormattedAsset[]>([]);
    const [consequenceFormattedAssets, setConsequenceFormattedAssets] = useState<ConsequenceFormattedAsset[]>([]);
    const [editHistoryState, setEditHistoryState] = useState<string[]>([]);
    const [toggleHistory, setToggleHistory] = useState(false);


    useEffect(() => {
        let result : FormattedAsset[] = [];
        for(const a of assets) {
            if(a.imaginary) {
                let included : boolean = false;
                for(const r of result)  {
                    if(r.scenarios.includes(a)) included = true;
                }
                let corrAsset = allAssets.find(al => al.id === a.scenario_asset_id);
                if(!included) result.push({thisOne: a, scenarios: [], unincluded: [], variant: "scenario", assetName: corrAsset ? corrAsset.name : undefined});
            }
            else {
                let allScenarios = allAssets.filter((asset) => asset.scenario_asset_id === a.id);
                let presentScenarios = assets.filter((asset) => asset.scenario_asset_id === a.id);
                let unincludedScenarios : Asset[] = [];
                let all : boolean = true;
                for(const a of allScenarios)  {
                    if(presentScenarios.findIndex((asset) => asset.id === a.id) === -1) {
                        all = false;
                        unincludedScenarios.push(a);
                    }
                };
                result.push({
                    thisOne: a,
                    scenarios: presentScenarios,
                    variant: all ? "all" : "some",
                    unincluded: unincludedScenarios
                });
            }
        }
        setFormattedAssets(result);
    }, [allAssets, assets]);

    useEffect(() => {
        if(geography) {
            if(geography.type === "Polygon") {
                const centroid = d3.geoCentroid(geography);
                let assetsToAdd : number[] = [];
                for(const a of allAssets) {
                    if(a.geography.type === "Point") {
                        if(d3.geoContains(geography, a.geography.coordinates) && a.id) {
                            if(!assetsToAdd.includes(a.id) && d3.geoDistance(centroid, a.geography.coordinates) < 2) assetsToAdd.push(a.id);
                        }
                    } else if(a.geography.type === "LineString") {
                        for (const point of a.geography.coordinates) {
                            if(d3.geoContains(geography, point) && a.id) {
                                if(!assetsToAdd.includes(a.id) && d3poly.polygonArea(geography.coordinates[0]) > -0.006)  assetsToAdd.push(a.id);
                            }
                        }
                    } else if(a.geography.type === "Polygon") {
                        for (const point of a.geography.coordinates[0]) {
                            if(d3.geoContains(geography, point) && a.id) {
                                if(!assetsToAdd.includes(a.id) && d3poly.polygonArea(geography.coordinates[0]) > -0.006)  assetsToAdd.push(a.id);
                            }
                        }
                    }
                }
                let newAssetIDs = risk?.asset_ids ?? [];
                for(const i of assetsToAdd) if(!risk?.asset_ids.includes(i)) newAssetIDs.push(i);
                if(risk) setRisk({...risk, asset_ids: newAssetIDs});
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geography])


    const getFormattedAssets = useCallback((assetIDs : number[]): FormattedAsset[] => {
        let result : FormattedAsset[] = [];
        let assetArray = assets.filter((a) => a.id && assetIDs.includes(a.id));
        for(const a of assetArray) {
            if(a.imaginary) {
                let included : boolean = false;
                for(const r of result)  {
                    if(r.scenarios.includes(a)) included = true;
                }
                let corrAsset = allAssets.find(al => al.id === a.scenario_asset_id);
                if(!included) result.push({thisOne: a, scenarios: [], unincluded: [], variant: "scenario", assetName: corrAsset ? corrAsset.name : undefined});
            }
            else {
                let allScenarios = assets.filter((asset) => asset.scenario_asset_id === a.id);
                let presentScenarios = assetArray.filter((asset) => asset.scenario_asset_id === a.id);
                let unincludedScenarios : Asset[] = [];
                let all : boolean = true;
                for(const a of allScenarios)  {
                    if(presentScenarios.findIndex((asset) => asset.id === a.id) === -1) {
                        all = false;
                        unincludedScenarios.push(a);
                    }
                };
                result.push({
                    thisOne: a,
                    scenarios: presentScenarios,
                    variant: all ? "all" : "some",
                    unincluded: unincludedScenarios
                });
            }
            
        }
        return result;
    }, [allAssets, assets])

    useEffect(() => {
        let result : ConsequenceFormattedAsset[] = [];
        for(let i = 0; i < (risk?.consequences.length ?? 0); i++) {
            if(risk?.consequences[i]) {
                result.push({
                    consequenceIndex: i,
                    fAssets: getFormattedAssets(risk?.consequences[i].asset_ids ?? [])
                })
            }
        }
        setConsequenceFormattedAssets(result);
    }, [getFormattedAssets, risk]);

    useEffect(() => {
        setChanged(!_.isEqual(risk, selectedRisk))
    }, [risk, selectedRisk])

    useEffect(() =>  {
        setRisk(_.cloneDeep(selectedRisk));
        setEditRisk(selectedRisk);
    }, [selectedRisk]);

    const getData = useCallback(async () => {
        const allFetchedAssets = await getAssets();
        setAllAssets(allFetchedAssets);
        setAssets(allFetchedAssets.filter((asset: Asset) => risk?.asset_ids.includes(asset.id ?? -1)).sort((a,b) => (a.id ?? 0) - (b.id ?? 0)));
    }, [risk]);

    useEffect(() => {
        try {
            getData();
        } catch (err) {
            error(err);
            throw err;
        }
    },[getData, risk]);

    const dispatch = useDispatch();

    const sameAssets = (cAssets : number[]) : boolean => {
        for(const a of assets) {
            if(a.id && !cAssets.includes(a.id)) return false;
        }
        return true;
    }

    const getRiskDiff = (newRisk:any, oldRisk:any) => {
        const diff = Object.keys(newRisk).reduce((result, key) => {
            if (!oldRisk.hasOwnProperty(key)) {
                result.push(key);
            } else if (_.isEqual(newRisk[key], oldRisk[key])) {
                const resultKeyIndex = result.indexOf(key);
                result.splice(resultKeyIndex, 1);
            }
            return result;
        }, Object.keys(oldRisk));
    
        return diff;
    }

    const fetchAndRenderEditHistory = async () => {
        const obj: RiskEditHistory[] = await getRiskEdits(selectedRisk?.id as number);
        const edits: string[] = [];

        for (let i = 0; i < obj.length; i++){

            let user;
            let action = " changed ";
            if (obj[i].admin_user_id !== null && obj[i].admin_user_id !== undefined){
                let adminUsers: AdminUserProfile[] = await getAllAdminUsers();
                for (let j = 0; j < adminUsers.length; j++){
                    if (adminUsers[j].id === obj[i].admin_user_id){
                        if (adminUsers[j].privileges === 1){
                            user = adminUsers[j].first_name + " " + adminUsers[j].last_name + " (admin) ";
                        } else {
                            user = adminUsers[j].first_name + " " + adminUsers[j].last_name + " (manager) ";
                        }
                    }
                }
            } else if (obj[i].user_id !== null && obj[i].user_id !== undefined){
                let userProfile = await getUserProfileByID(obj[i].user_id!);
                user = userProfile.first_name + " " + userProfile.last_name;
            } else {
                user = "Reporter";
                action = " set ";
            }

            const timestampPrettified = moment(obj[i].timestamp).format("YYYY-MM-DD hh:mm A");

            if(obj[i].payload.hasOwnProperty("asset_ids")){
                const assetNames = [];
                for (let j = 0; j < allAssets.length; j++){
                    if (obj[i].payload.asset_ids.includes(allAssets[j].id as number)){
                        assetNames.push(allAssets[j].name);
                    }
                }
                edits.push(timestampPrettified + ": " + user + action + "\"relevant assets\" field to: " + assetNames.join(", "));
            } if(obj[i].payload.hasOwnProperty("name")){
                edits.push(timestampPrettified + ": " + user + action + "\"name\" field to: " + obj[i].payload.name);
            } if(obj[i].payload.hasOwnProperty("relevant_event_types")){
                edits.push(timestampPrettified + ": " + user + action + "\"relevant event types\" field to: " + obj[i].payload.relevant_event_types.join(", "));
            } if(obj[i].payload.hasOwnProperty("risk_interval")){
                edits.push(timestampPrettified + ": " + user + action + "\"risk interval\" field to: " + obj[i].payload.risk_interval);
            } if(obj[i].payload.hasOwnProperty("severity")){
                edits.push(timestampPrettified + ": " + user + action + "\"severity\" field to: " + obj[i].payload.severity);
            } if(obj[i].payload.hasOwnProperty("chance")){
                edits.push(timestampPrettified + ": " + user + action + "\"chance\" field to: " + obj[i].payload.chance);
            } if(obj[i].payload.hasOwnProperty("suggestions")){
                edits.push(timestampPrettified + ": " + user + action + "\"suggestions\" field to: " + obj[i].payload.suggestions);
            } if(obj[i].payload.hasOwnProperty("geography")){
                edits.push(timestampPrettified + ": " + user + action + "\"geography\" field.");
            } if(obj[i].payload.hasOwnProperty("concerns")){
                edits.push(timestampPrettified + ": " + user + action + "\"concerns\" field to: " + obj[i].payload.concerns.join(", "));
            } if(obj[i].payload.hasOwnProperty("comments")){
                edits.push(timestampPrettified + ": " + user + action + "\"comments\" field to: " + obj[i].payload.comments);
            } if(obj[i].payload.hasOwnProperty("is_obsolete")){
                if (obj[i].payload.is_obsolete === true){
                    edits.push(timestampPrettified + ": " + user + " marked this risk as obsolete.");
                } if (obj[i].payload.is_obsolete === false){
                    edits.push(timestampPrettified + ": " + user + " marked this risk as relevant.");
                }
            } if(obj[i].payload.hasOwnProperty("consequences")){
                const consequencesStrings = [];
                for (let j = 0; j < obj[i].payload.consequences.length; j++){
                    const assetNamesArr = [];
                    for (let k = 0; k < allAssets.length; k++){
                        if (obj[i].payload.asset_ids.includes(allAssets[k].id as number)){
                            assetNamesArr.push(allAssets[j].name);
                        }
                    }
                    const assetNames = assetNamesArr.join(", ")
                    const percent = parseFloat(obj[i].payload.consequences[j].probability.toString()) * 100;
                    consequencesStrings.push(
                        obj[i].payload.consequences[j].concern + ": For " + assetNames + 
                        ", there is a " + percent + "% chance of losing $"
                         + obj[i].payload.consequences[j].magnitude + " over the timeframe of a " 
                         + obj[i].payload.consequences[j].timeframe 

                    );
                }
                edits.push(timestampPrettified + ": " + user + action + `"consequences" field to: 
                
                ` + 
                consequencesStrings.join(`
                `));
            }
        }
        setEditHistoryState(edits);
        setToggleHistory(true);
    }
    
    const zoomTo  = (geography: LineString | Polygon | Point) => {
        if(geography.type === "Point") {
            let coordinates:[number, number] = [geography.coordinates[0], geography.coordinates[1]];
            dispatch(mapActions.flyToPoint([...coordinates,14]));
        } else if (geography.type === "Polygon") {
            let points = 0;
            let lat = 0;
            let long = 0;
            for(let coordinate of geography.coordinates[0]) {
                lat += coordinate[0];
                long += coordinate[1];
                points++;
            }
            lat /= points;
            long /= points;
            dispatch(mapActions.flyToPoint([lat,long, 12]))
        } else if (geography.type === "LineString") {
            dispatch(mapActions.flyToPoint([...geography.coordinates[Math.floor(geography.coordinates.length/2)], 12]));
        }
    }

    const updateThisRisk = async () => {
        try {
            if(selectedRisk && risk && risk.id) {  

                const changes = getRiskDiff(editRisk, risk);

                updateAssetRisk(risk.id, risk, changes).then(() => {
                        getRiskData();
                        dispatch(mapActions.setRisk(risk))
                        dispatch(mapActions.setGeography(undefined));
                        dispatch(mapActions.setChangeGeo(false));
                    }
                )
            }
        } catch (err) {
            console.log(err.message);
            error(err);
            throw err;
        }
    }

    if(selectedRisk && risk){ 
        return (
            <div>
            <AssetModalContainer background={colors.general.sosReportModal} secondary={selectedAsset !== undefined || selectedScenario !== undefined} tertiary={selectedAsset !== undefined && selectedScenario !== undefined}>
                <div style={{position: "sticky", right: "10px", top:"0px", zIndex: 2000}}>
                    <ExitButton onClick={() => {
                        if(changed) {
                            getData();
                            setRisk(_.cloneDeep(selectedRisk));
                        } else {
                            dispatch(mapActions.setRisk(undefined));
                            dispatch(mapActions.setGeography(undefined));
                            dispatch(mapActions.setChangeGeo(false));
                        }
                    }}>
                        <Icon style={{color: changed ? "red" : undefined}}>close</Icon>
                    </ExitButton>
                    <EditButton onClick={() => {
                        if(editing && changed)  updateThisRisk();
                        setEditing(!editing);
                    }}>
                        <Icon style={{color: changed && editing ? "#00ff00" : undefined}}>{editing && changed ? "check" : "edit"}</Icon>
                    </EditButton>
                </div>
                {editing &&
                <>
                    <ModalTitle color={colors.scheme.primaryText}>
                        <TextField
                            multiline={false}
                            style={{width: "300px"}}
                            value={risk.name}
                            onChange={(event) => setRisk({...risk, name: event.target.value as string})}
                        />
                    </ModalTitle>
                    <FieldGroup background={colors.general.sosSelectorBackground}>
                        <>
                            <ModalText color={colors.scheme.secondaryHeaderText}>
                                Consequences
                            </ModalText>
                            <ModalAddress color={colors.scheme.primaryText}>
                                {risk.consequences.map((consequence : Consequence, index: number) => (
                                    <div key={index}>
                                        <ModalText color={colors.scheme.selectorLightText} style={{fontSize: "16px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <Autocomplete
                                                disableClearable
                                                id={`${index}-consequence-concern`}
                                                options={AllAssetConcerns}
                                                value={consequence.concern}
                                                renderInput={(params) => (
                                                <TextInput
                                                    {...params}
                                                    required
                                                    label="Choose Concern"
                                                    variant="outlined"
                                                    style={{ width: 340 }}
                                                />
                                                )}
                                                onChange={(event, value) => {
                                                    let newConsequences = risk.consequences;
                                                    newConsequences[index] = {...newConsequences[index], concern: value}
                                                    setRisk({...risk, consequences: newConsequences})
                                                }}
                                            />
                                            <HoverDiv onClick={() => {
                                                let newConsequences = risk.consequences;
                                                newConsequences.splice(index,1);
                                                setRisk({...risk, consequences: newConsequences})
                                            }}
                                            style={{marginRight: "20px"}}>
                                                <Icon>close</Icon>
                                            </HoverDiv>
                                        </ModalText>
                                        <ModalAddress color={colors.scheme.primaryText} style={{marginLeft: "10px"}}>
                                            <Autocomplete
                                                disableClearable
                                                id={`${index}-consequence-asset-edit`}
                                                options={assets.filter((asset) => !asset.imaginary)}
                                                getOptionLabel={(asset: Asset) => asset.name}
                                                getOptionSelected={(asset: Asset) => assets.findIndex((a : Asset) => a.name === asset.name) !== -1}
                                                renderInput={(params) => (
                                                <TextInput
                                                    {...params}
                                                    required
                                                    label="Add Relevant Asset"
                                                    variant="outlined"
                                                    style={{ width: 350 }}
                                                />
                                                )}
                                                onChange={(event, value) => {
                                                    let newAssetIDs = consequence.asset_ids ?? [];
                                                    let allScenarios = allAssets.filter((asset) => asset.scenario_asset_id === value.id);
                                                    for(const s of allScenarios) if(s.id && !newAssetIDs.includes(s.id)) newAssetIDs.push(s.id);
                                                    if(value.id && !newAssetIDs.includes(value.id)) newAssetIDs.push(value.id);
                                                    let newConsequences = risk.consequences;
                                                    newConsequences[index] = {...newConsequences[index], asset_ids: newAssetIDs};
                                                    setRisk({...risk, consequences: newConsequences});
                                                }}
                                            />
                                            {consequence.asset_ids && consequenceFormattedAssets.find((c) => c.consequenceIndex === index)?.fAssets.map((ca : FormattedAsset, cIndex: number) => {
                                                const cAsset = ca.thisOne;
                                                if(cAsset){
                                                    return(
                                                    <div key={ca.thisOne.id}>
                                                        <div key={ca.thisOne.id} style={{display: "flex", flexDirection: "row"}}>
                                                            <HoverDiv onClick={() => {
                                                                if(ca.variant === "scenario") dispatch(mapActions.setScenario(ca.thisOne));
                                                                else dispatch(mapActions.setAsset(ca.thisOne));
                                                            }}>
                                                                {cAsset.name}
                                                            </HoverDiv>
                                                            <HoverDiv onClick={() => {
                                                                let newAssetIDs = consequence.asset_ids;
                                                                let bindex = newAssetIDs.findIndex((num) => num === cAsset.id);
                                                                newAssetIDs.splice(bindex,1);
                                                                let newConsequences = risk.consequences;
                                                                newConsequences[index] = {...newConsequences[index], asset_ids: newAssetIDs}
                                                                setRisk({...risk, consequences: newConsequences})
                                                            }}>
                                                                <Icon>close</Icon>
                                                            </HoverDiv>
                                                        </div>
                                                        {ca.scenarios.length > 0 && ca.scenarios.map((asset: Asset) => (
                                                            <div key={"sc" + asset.id} style={{display: "flex", flexDirection: "row", marginLeft: "10px"}}>
                                                                <HoverDiv onClick={() => {
                                                                dispatch(mapActions.setScenario(asset));
                                                                }}>
                                                                    {asset.name}
                                                                </HoverDiv>
                                                                <HoverDiv onClick={() => {
                                                                    let newAssetIDs = consequence.asset_ids;
                                                                    let bindex = newAssetIDs.findIndex((num) => num === asset.id);
                                                                    newAssetIDs.splice(bindex,1);
                                                                    let newConsequences = risk.consequences;
                                                                    newConsequences[index] = {...newConsequences[index], asset_ids: newAssetIDs};
                                                                    setRisk({...risk, consequences: newConsequences});
                                                                }}>
                                                                    <Icon>close</Icon>
                                                                </HoverDiv>
                                                            </div>
                                                        ))}
                                                        {ca.unincluded.length > 0 && ca.unincluded.map((asset: Asset) => (
                                                            <div key={"un" + asset.id} style={{display: "flex", flexDirection: "row", marginLeft: "10px"}}>
                                                                <HoverDiv onClick={() => {
                                                                dispatch(mapActions.setScenario(asset));
                                                                }}>
                                                                    {asset.name}
                                                                </HoverDiv>
                                                                <HoverDiv onClick={() => {
                                                                    let newConsequences = risk.consequences;
                                                                    let newAssetIDs = consequence.asset_ids;
                                                                    if(asset.id) newAssetIDs.push(asset.id);
                                                                    newConsequences[index] = {...newConsequences[index], asset_ids: newAssetIDs};
                                                                    setRisk({...risk, consequences: newConsequences});
                                                                }}>
                                                                    <Icon>add</Icon>
                                                                </HoverDiv>
                                                            </div>
                                                        ))}
                                                    </div>)
                                                };
                                                return <></>;
                                            })}
                                        </ModalAddress>
                                        <ModalAddress color={colors.scheme.primaryText} style={{marginLeft: "10px"}}>
                                            <TextField
                                                multiline={false}
                                                style={{width: "340px"}}
                                                value={consequence.magnitude}
                                                type="number"
                                                label={"Change Magnitude" + (risk.consequences[index].concern ==="Damage/Loss" ? " (units lost)" :
                                                    risk.consequences[index].concern ==="Profitability" ? " ($)" :
                                                    risk.consequences[index].concern ==="Safety/Harm" ? " (casualties)" :
                                                    risk.consequences[index].concern ==="Operations/Downtime" ? " (hours)" : "")}
                                                onChange={(event) => {
                                                    let newConsequences = risk.consequences;
                                                    newConsequences[index] = {...newConsequences[index], magnitude: parseFloat(event.target.value)}
                                                    setRisk({...risk, consequences: newConsequences})
                                                }}
                                            />
                                        </ModalAddress>
                                        <ModalAddress color={colors.scheme.primaryText} style={{marginLeft: "10px"}}>
                                            <Autocomplete
                                                disableClearable
                                                id={`${index}-consequence-time-frame`}
                                                options={["weekly", "yearly", "monthly"]}
                                                renderInput={(params) => (
                                                <TextInput
                                                    {...params}
                                                    required
                                                    label="Change Time Frame"
                                                    variant="outlined"
                                                    style={{ width: 350 }}
                                                />
                                                )}
                                                onChange={(event, value) => {
                                                    let newConsequences = risk.consequences;
                                                    newConsequences[index] = {...newConsequences[index], timeframe: value}
                                                    setRisk({...risk, consequences: newConsequences})
                                                }}
                                            />
                                        </ModalAddress>
                                        <ModalAddress color={colors.scheme.primaryText} style={{marginLeft: "10px"}}>
                                            <TextField
                                                multiline={false}
                                                style={{width: "340px"}}
                                                value={consequence.probability}
                                                type="number"
                                                label="Change Probability"
                                                onChange={(event) => {
                                                    let newConsequences = risk.consequences;
                                                    newConsequences[index] = {...newConsequences[index], probability: parseFloat(event.target.value)}
                                                    setRisk({...risk, consequences: newConsequences})
                                                }}
                                            />
                                        </ModalAddress>
                                    </div>
                                ))}
                                <Button onClick={() => {
                                    let newConsequences = risk.consequences;
                                    newConsequences.push({asset_ids: [], concern: "Profitability", magnitude: 0, timeframe: "weekdays", probability: 0.0});
                                    setRisk({...risk, consequences: newConsequences})
                                }}>Create New Consequence</Button>
                            </ModalAddress>
                        </>
                    </FieldGroup>
                    <FieldGroup background={colors.general.sosSelectorBackground}>
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Concerns
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            <Autocomplete
                                disableClearable
                                id="concerns"
                                options={AllAssetConcerns}
                                renderInput={(params) => (
                                <TextInput
                                    {...params}
                                    required
                                    label="Add Concern"
                                    variant="outlined"
                                    style={{ width: 360 }}
                                />
                                )}
                                onChange={(event, value) => {
                                    let newConcerns= risk.concerns;
                                    if(!newConcerns.includes(value)) newConcerns.push(value);
                                    setRisk({...risk, concerns: newConcerns});
                                }}
                            />
                            {risk.concerns.map((concern: AssetConcern) => (
                                <div key={concern} style={{display: "flex", flexDirection: "row"}}>
                                    {concern}
                                    <HoverDiv onClick={() => {
                                        let newConcerns = risk.concerns;
                                        let index = newConcerns.findIndex((c) => c === concern);
                                        newConcerns.splice(index,1);
                                        setRisk({...risk, concerns: newConcerns})
                                    }}>
                                        <Icon>close</Icon>
                                    </HoverDiv>
                                </div>
                            ))}
                        </ModalAddress>
                    </>
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Relevant Event Types
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            <Autocomplete
                                disableClearable
                                id="EventTypes"
                                options={AllReportTypes}
                                renderInput={(params) => (
                                <TextInput
                                    {...params}
                                    required
                                    label="Add Relevant Event Type"
                                    variant="outlined"
                                    style={{ width: 360 }}
                                />
                                )}
                                onChange={(event, value) => {
                                    let newEvents= risk.relevant_event_types;
                                    if(!newEvents.includes(value)) newEvents.push(value);
                                    setRisk({...risk, relevant_event_types: newEvents});
                                }}
                            />
                            {risk.relevant_event_types.map((event) => (
                                <div key={event} style={{display: "flex", flexDirection: "row"}}>
                                    {event}
                                    <HoverDiv onClick={() => {
                                        let newEvents = risk.relevant_event_types;
                                        let index = newEvents.findIndex((c) => c === event);
                                        newEvents.splice(index,1);
                                        setRisk({...risk, relevant_event_types: newEvents})
                                    }}>
                                        <Icon>close</Icon>
                                    </HoverDiv>
                                </div>
                            ))}
                        </ModalAddress>
                    </>
                    </FieldGroup>
                    <FieldGroup background={colors.general.sosSelectorBackground}>
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Relevant Assets
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                        <Autocomplete
                            disableClearable
                            id="assets"
                            options={allAssets.filter((asset => !asset.imaginary))}
                            getOptionLabel={(asset: Asset) => asset.name}
                            getOptionSelected={(asset: Asset) => allAssets.findIndex((a : Asset) => a.name === asset.name) !== -1}
                            renderInput={(params) => (
                            <TextInput
                                {...params}
                                required
                                label="Add Relevant Asset"
                                variant="outlined"
                                style={{ width: 360 }}
                            />
                            )}
                            onChange={(event, value) => {
                                let newAssetIDs = risk.asset_ids;
                                let allScenarios = allAssets.filter((asset) => asset.scenario_asset_id === value.id);
                                if(value.id && !newAssetIDs.includes(value.id)) newAssetIDs.push(value.id);
                                for(const s of allScenarios) if(s.id && !newAssetIDs.includes(s.id)) newAssetIDs.push(s.id);
                                setRisk({...risk, asset_ids: newAssetIDs});
                            }}
                        />
                        {formattedAssets.map((asset: FormattedAsset) => (
                        <div key={"fm" + asset.thisOne.id}>
                            <div key={asset.thisOne.id} style={{display: "flex", flexDirection: "row"}}>
                                <HoverDiv onClick={() => {
                                    if(asset.variant === "scenario") dispatch(mapActions.setScenario(asset.thisOne));
                                    else dispatch(mapActions.setAsset(asset.thisOne));
                                }}>
                                    {asset.thisOne.name} {asset.assetName ?  "(scenario for " + asset.assetName + ")" : ""}
                                </HoverDiv>
                                <HoverDiv onClick={() => {
                                    let newAssetIDs = risk.asset_ids;
                                    let index = newAssetIDs.findIndex((num) => num === asset.thisOne.id);
                                    newAssetIDs.splice(index,1);
                                    let newConsequences = risk.consequences;
                                    for(let i = 0; i < newConsequences.length; i++) {
                                        const index = newConsequences[i].asset_ids.findIndex((n) => n === asset.thisOne.id)
                                        if(index !== -1) {
                                            newConsequences[i].asset_ids.splice(index, 1);
                                        }
                                    }
                                    setRisk({...risk, asset_ids: newAssetIDs, consequences: newConsequences})
                                }}>
                                    <Icon>close</Icon>
                                </HoverDiv>
                            </div>
                            {asset.scenarios.length > 0 && asset.scenarios.map((asset: Asset) => (
                                <div key={"sc" + asset.id} style={{display: "flex", flexDirection: "row", marginLeft: "10px"}}>
                                    <HoverDiv onClick={() => {
                                       dispatch(mapActions.setScenario(asset));
                                    }}>
                                        {asset.name}
                                    </HoverDiv>
                                    <HoverDiv onClick={() => {
                                        let newAssetIDs = risk.asset_ids;
                                        let index = newAssetIDs.findIndex((num) => num === asset.id);
                                        newAssetIDs.splice(index,1);
                                        let newConsequences = risk.consequences;
                                        for(let i = 0; i < newConsequences.length; i++) {
                                            const index = newConsequences[i].asset_ids.findIndex((n) => n === asset.id)
                                            if(index !== -1) {
                                                newConsequences[i].asset_ids.splice(index, 1);
                                            }
                                        }
                                        setRisk({...risk, asset_ids: newAssetIDs, consequences: newConsequences})
                                    }}>
                                        <Icon>close</Icon>
                                    </HoverDiv>
                                </div>
                            ))}
                            {asset.unincluded.length > 0 && asset.unincluded.map((asset: Asset) => (
                                <div key={"un" + asset.id} style={{display: "flex", flexDirection: "row", marginLeft: "10px"}}>
                                    <HoverDiv onClick={() => {
                                       dispatch(mapActions.setScenario(asset));
                                    }}>
                                        {asset.name}
                                    </HoverDiv>
                                    <HoverDiv onClick={() => {
                                        let newAssetIDs = risk.asset_ids;
                                        if(asset.id) newAssetIDs.push(asset.id);
                                        setRisk({...risk, asset_ids: newAssetIDs});
                                    }}>
                                        <Icon>add</Icon>
                                    </HoverDiv>
                                </div>
                            ))}
                        </div>
                        ))}
                        </ModalAddress>
                    </>
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Geography
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            {risk.geography?.type ? <Button onClick={() => {
                                zoomTo(risk.geography as LineString | Polygon | Point);
                            }}>{risk.geography.type}</Button> :  "No Geography"}
                        </ModalAddress>
                        {changeGeo &&
                        <VerticallyCenteredRow style={{ width: "360px"}}>
                            <Paper square>
                                <Tabs
                                    value={drawMode}
                                    onChange={(event, newValue) => dispatch(mapActions.setDrawMode(newValue))}
                                    style={{ width: "360px" }}
                                >
                                    <Tab
                                        style={{minWidth: "120px", maxWidth: "120px", marginRight: "0px"}}
                                        value="point"
                                        label="Point"
                                        disableRipple
                                    />
                                    <Tab
                                        style={{minWidth: "120px", maxWidth: "120px", marginRight: "0px"}}
                                        value="polygon"
                                        label="Polygon"
                                    />
                                    <Tab
                                        style={{minWidth: "120px", maxWidth: "120px", marginRight: "0px"}}
                                        value="line"
                                        label="Line"
                                    />
                                </Tabs>
                            </Paper>
                        </VerticallyCenteredRow>}
                        <Button onClick={() => {
                                if(changeGeo && geography) {
                                    setRisk({...risk, geography: geography});
                                    dispatch(mapActions.setChangeGeo(false));
                                } else {
                                    dispatch(mapActions.setChangeGeo(true));
                                    dispatch(mapActions.setDrawMode(
                                        risk.geography?.type === "LineString" ? "line" :
                                        risk.geography?.type === "Polygon" ?  "polygon" : "point"));
                                }
                        }}>{changeGeo ? "Submit Geography Change" : risk.geography ? "Change Geography" : "Add Geography"}</Button>
                    </>
                    </FieldGroup>
                    <FieldGroup background={colors.general.sosSelectorBackground}>
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Risk Interval
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            <TextField
                                multiline={false}
                                style={{width: "360px"}}
                                value={risk.risk_interval}
                                onChange={(event) => setRisk({...risk, risk_interval: event.target.value as string})}
                            />
                        </ModalAddress>
                    </>
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Created At
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            {moment(risk.created_at).format("YYYY-MM-DD hh:mm A")}
                        </ModalAddress>
                    </>
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Severity
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            <Slider
                                value={risk.severity}
                                onChange={(event, value) => setRisk({...risk, severity: value as number})}
                                step={1}
                                max={6}
                                min={0}
                                valueLabelDisplay={"auto"}
                                style={{marginTop: "15px"}}
                            />
                        </ModalAddress>
                    </>
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Chance
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            <TextField
                                multiline={false}
                                style={{width: "360px"}}
                                value={risk.chance}
                                type="number"
                                onChange={(event) => setRisk({...risk, chance: parseFloat(event.target.value)})}
                            />
                        </ModalAddress>
                    </>
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Suggestions
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            <TextField
                                multiline={true}
                                style={{width: "360px"}}
                                value={risk.suggestions}
                                onChange={(event) => setRisk({...risk, suggestions: event.target.value as string})}
                            />
                        </ModalAddress>
                    </>
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Comments
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            <TextField
                                multiline={true}
                                style={{width: "360px"}}
                                value={risk.comments}
                                onChange={(event) => setRisk({...risk, comments: event.target.value as string})}
                            />
                        </ModalAddress>
                    </>
                    </FieldGroup>
                    <Button 
                        onClick={() => setRisk({...risk, is_obsolete: !risk.is_obsolete})}
                    >{risk.is_obsolete ? "Re-activate" : "Mark Obsolete"}</Button>
                </>}
                {!editing &&
                <>
                    <ModalTitle color={colors.scheme.primaryText} style={{display: "flex", flexDirection: "row"}}>
                        {risk.needs_review &&
                            <Icon style={{color: "red", marginRight: "5px"}}>error</Icon>
                        }
                        {risk.name ?? "Unnamed Risk"} {risk.is_obsolete ? "(obsolete)" : ""}
                    </ModalTitle>
                    <FieldGroup background={colors.general.sosSelectorBackground}>
                    {risk?.consequences &&
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Consequences
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            {risk.consequences.map((consequence : Consequence, index: number) => (
                                <div key={index} style={{marginLeft: "10px"}}>
                                    <ModalText color={colors.scheme.selectorLightText} style={{fontSize: "16px"}}>
                                        -{consequence.concern}
                                    </ModalText>
                                    {consequence.asset_ids && consequence.asset_ids.length > 0 && !sameAssets(consequence.asset_ids)  &&
                                    <ModalAddress color={colors.scheme.primaryText} style={{marginLeft: "10px"}}>
                                        Relevant Assets:
                                        {consequence.asset_ids && consequenceFormattedAssets.find((c) => c.consequenceIndex === index)?.fAssets.map((asset: FormattedAsset) => (
                                            <div key={"a" + asset.thisOne.id}>
                                                <HoverDiv onClick={() => {
                                                    if(asset.variant === "scenario") dispatch(mapActions.setScenario(asset.thisOne));
                                                    else dispatch(mapActions.setAsset(asset.thisOne));
                                                }} style={{marginLeft: "10px"}} key={asset.thisOne.id}>{asset.thisOne.name} {asset.variant ===  "all" ? "(All Scenarios)" : asset.variant === "scenario" && asset.assetName ? "(scenario for " + asset.assetName + ")" : ""}</HoverDiv>
                                                {asset.scenarios.length > 0 && asset.variant === "some" && asset.scenarios.map((scenario) => (
                                                    <HoverDiv onClick={() => dispatch(mapActions.setScenario(scenario))} style={{marginLeft: "20px"}} key={"sc" + scenario.id}>-{scenario.name}</HoverDiv>
                                                ))}
                                            </div>
                                        ))}
                                    </ModalAddress>}
                                    {(!consequence.asset_ids || (consequence.asset_ids && consequence.asset_ids.length === 0)) &&
                                    <ModalAddress color={colors.scheme.primaryText} style={{marginLeft: "10px"}}>
                                        No Relevant Assets
                                    </ModalAddress>}
                                    <ModalAddress color={colors.scheme.primaryText} style={{marginLeft: "10px"}}>
                                        Magnitude : {risk.consequences[index].concern ==="Profitability" ? "$" : ""} {consequence.magnitude + 
                                                    (risk.consequences[index].concern ==="Damage/Loss" ? " units lost" :
                                                    risk.consequences[index].concern ==="Safety/Harm" ? " casualties" :
                                                    risk.consequences[index].concern ==="Operations/Downtime" ? " hours" : "")}
                                    </ModalAddress>
                                    <ModalAddress color={colors.scheme.primaryText} style={{marginLeft: "10px"}}>
                                        Time Frame: {consequence.timeframe}
                                    </ModalAddress>
                                    <ModalAddress color={colors.scheme.primaryText} style={{marginLeft: "10px"}}>
                                        Probability: {consequence.probability}
                                    </ModalAddress>
                                </div>
                            ))}
                        </ModalAddress>
                    </>
                    }
                    </FieldGroup>
                    <FieldGroup background={colors.general.sosSelectorBackground}>
                    {risk?.concerns &&
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Concerns
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            {risk.concerns.map((concern : AssetConcern) => (
                                <div style={{marginLeft: "10px"}} key={concern}>-{concern}</div>
                            ))}
                        </ModalAddress>
                    </>
                    }
                    {risk.relevant_event_types &&
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Relevant Event Types
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            {risk.relevant_event_types.map((type: string) => (
                                <div style={{marginLeft: "10px"}} key={type}>-{type}</div>
                            ))}
                        </ModalAddress>
                    </>
                    }
                    </FieldGroup>
                    <FieldGroup background={colors.general.sosSelectorBackground}>
                    {formattedAssets &&
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Relevant Assets
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                        {formattedAssets.map((asset: FormattedAsset) => (
                            <div key={"a" + asset.thisOne.id}>
                                <HoverDiv onClick={() => {
                                    if(asset.variant === "scenario") dispatch(mapActions.setScenario(asset.thisOne));
                                    else dispatch(mapActions.setAsset(asset.thisOne));
                                }} style={{marginLeft: "10px"}} key={asset.thisOne.id}>{asset.thisOne.name} {asset.variant ===  "all" ? "(All Scenarios)" : asset.variant === "scenario" && asset.assetName ? "(scenario for " + asset.assetName + ")" : ""}</HoverDiv>
                                {asset.scenarios.length > 0 && asset.variant === "some" && asset.scenarios.map((scenario) => (
                                    <HoverDiv onClick={() => dispatch(mapActions.setScenario(scenario))} style={{marginLeft: "20px"}} key={"sc" + scenario.id}>-{scenario.name}</HoverDiv>
                                ))}
                            </div>
                        ))}
                        </ModalAddress>
                    </>
                    }
                    {risk.geography && risk.geography.type &&
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Geography
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            {risk.geography?.type ? <Button onClick={() => {
                                zoomTo(risk.geography as LineString | Polygon | Point);
                            }}>{risk.geography.type}</Button> :  "No Geography"}
                        </ModalAddress>
                    </>
                    }
                    </FieldGroup>
                    <FieldGroup background={colors.general.sosSelectorBackground}>
                    {risk.risk_interval &&
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Risk Interval
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            {risk.risk_interval}
                        </ModalAddress>
                    </>
                    }
                    {risk.created_at &&
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Created At
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            {moment(risk.created_at).format("YYYY-MM-DD hh:mm A")}
                        </ModalAddress>
                    </>
                    }
                    {risk.severity !== undefined &&
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Severity
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            {risk.severity}
                        </ModalAddress>
                    </>
                    }
                    {risk.chance &&
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Chance
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            {risk.chance}
                        </ModalAddress>
                    </>
                    }
                    {risk.suggestions &&
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Suggestions
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            {risk.suggestions}
                        </ModalAddress>
                    </>
                    }
                    {risk.comments &&
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Comments
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                            {risk.comments}
                        </ModalAddress>
                    </>
                    }
                    </FieldGroup>
                    <Button
                        variant="outlined"
                        onClick={fetchAndRenderEditHistory}
                        style={{ flex: "0 0 auto"}}
                    >
                        Edit History
                    </Button>
                </>}
            </AssetModalContainer>
            <Dialog open={toggleHistory}
                onClose={(e) => setToggleHistory(false)}>
            <DialogTitle>
                Edit History
            </DialogTitle>
            <DialogContent>
                {editHistoryState.map((element) => {
                    if (element.length >= 80) {
                        return (
                        <Accordion key= {editHistoryState.indexOf(element).toString()}>
                            <AccordionSummary>
                                <Typography>{element.substring(0, 80) + "..."}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                                {element}
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        )} else {
                        return (
                        <Accordion key= {(editHistoryState.indexOf(element) * Date.now()).toString()}>
                            <AccordionSummary>
                                <Typography>{element}</Typography>
                            </AccordionSummary>
                        </Accordion>
                        )}
                    })}
                </DialogContent>
            </Dialog>
        </div>
    )} else return (<></>)
}
