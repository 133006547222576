import { default as React, useMemo } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "styled-components";
import { createMuiTheme, ThemeProvider as MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import store from "./state/store";

import "./index.css";
import * as serviceWorker from "./serviceWorker";
import Authenticated from "./authenticated";
import Admin from "./admin";
import Login from "./login";
import Error from "./error";
import Catch from "./catch";
import { Provider } from "react-redux";

const App = () => {
    const theme = useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: "dark",
                    primary: { main: "#0A84FF" },
                    secondary: { main: "#0A84FF" },
                },
            }),
        []
    );

    return (
        <StylesProvider injectFirst>
            <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Catch>
                        {(error, clearError) => (
                            <>
                                <BrowserRouter>
                                    <Switch>
                                        <Route path="/login">
                                            <Login />
                                        </Route>
                                        <Authenticated>
                                            <Route>
                                                <Admin />
                                            </Route>
                                        </Authenticated>
                                    </Switch>
                                </BrowserRouter>
                                {error && (
                                    <Error
                                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                        open={true}
                                        onClose={clearError}
                                        message={error.message}
                                        autoHideDuration={400000}
                                    />
                                )}
                            </>
                        )}
                    </Catch>
                </ThemeProvider>
            </MuiThemeProvider>
        </StylesProvider>
    );
};

ReactDOM.render(
    <Provider store={store}>
        <CookiesProvider>
            <App />
        </CookiesProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
