import { css } from "styled-components";
import { DIMENSIONS } from "../constants/default-settings";

export const transition = "all .4s ease";
export const transitionFast = "all .2s ease";
export const transitionSlow = "all .8s ease";

export const boxShadow = "0 1px 2px 0 rgba(0,0,0,0.10)";
export const boxSizing = "border-box";
export const borderRadius = "1px";
export const borderColor = "#3A414C";
export const borderColorLT = "#F1F1F1";

// TEXT
export const fontFamily = `ff-clan-web-pro, 'Helvetica Neue', Helvetica, sans-serif`;
export const fontWeight = 400;
export const fontSize = "0.875em";
export const lineHeight = 1.71429;
export const labelColor = "#6A7485";
export const labelHoverColor = "#C6C6C6";
export const labelColorLT = "#6A7485";

export const textColor = "#A0A7B4";
export const textColorLT = "#3A414C";
export const titleColorLT = "#29323C";

export const subtextColor = "#6A7485";
export const subtextColorLT = "#A0A7B4";
export const subtextColorActive = "#FFFFFF";

export const titleTextColor = "#FFFFFF";
export const textColorHl = "#F0F0F0";
export const textColorHlLT = "#000000";
export const activeColor = "#1FBAD6";
export const activeColorLT = "#2473BD";
export const activeColorHover = "#108188";
export const errorColor = "#F9042C";
export const logoColor = activeColor;

// Button
export const primaryBtnBgd = "#0F9668";
export const primaryBtnActBgd = "#13B17B";
export const primaryBtnColor = "#FFFFFF";
export const primaryBtnActColor = "#FFFFFF";
export const primaryBtnBgdHover = "#13B17B";
export const primaryBtnRadius = "2px";

export const secondaryBtnBgd = "#6A7485";
export const secondaryBtnActBgd = "#A0A7B4";
export const secondaryBtnColor = "#FFFFFF";
export const secondaryBtnActColor = "#FFFFFF";
export const secondaryBtnBgdHover = "#A0A7B4";

export const linkBtnBgd = "transparent";
export const linkBtnActBgd = linkBtnBgd;
export const linkBtnColor = "#A0A7B4";
export const linkBtnActColor = "#F1F1F1";
export const linkBtnActBgdHover = linkBtnBgd;

export const negativeBtnBgd = errorColor;
export const negativeBtnActBgd = "#FF193E";
export const negativeBtnBgdHover = "#FF193E";
export const negativeBtnColor = "#FFFFFF";
export const negativeBtnActColor = "#FFFFFF";

export const floatingBtnBgd = "#29323C";
export const floatingBtnActBgd = "#3A4552";
export const floatingBtnBgdHover = "#3A4552";
export const floatingBtnColor = subtextColor;
export const floatingBtnActColor = subtextColorActive;

// Input
export const inputBoxHeight = "34px";
export const inputBoxHeightSmall = "24px";
export const inputBoxHeightTiny = "18px";
export const inputPadding = "4px 10px";
export const inputPaddingSmall = "4px 6px";
export const inputPaddingTiny = "2px 4px";
export const inputFontSize = "11px";
export const inputFontSizeSmall = "10px";
export const inputFontWeight = 500;
export const inputBgd = "#29323C";
export const inputBgdHover = "#3A414C";
export const inputBgdActive = "#3A414C";
export const inputBorderColor = "#29323C";
export const inputBorderHoverColor = "#3A414C";
export const inputBorderActiveColor = "#D3D8E0";
export const inputColor = "#A0A7B4";
export const inputBorderRadius = "1px";
export const inputPlaceholderColor = "#6A7485";
export const inputPlaceholderFontWeight = 400;

export const secondaryInputBgd = "#242730";
export const secondaryInputBgdHover = "#3A414C";
export const secondaryInputBgdActive = "#3A414C";
export const secondaryInputColor = "#A0A7B4";
export const secondaryInputBorderColor = "#242730";
export const secondaryInputBorderActiveColor = "#D3D8E0";

// Select
export const selectColor = inputColor;
export const selectColorLT = titleColorLT;

export const selectActiveBorderColor = "#D3D8E0";
export const selectFontSize = "11px";
export const selectFontWeight = "400";
export const selectFontWeightBold = "500";

export const selectColorPlaceHolder = "#6A7485";
export const selectBackground = inputBgd;
export const selectBackgroundHover = inputBgdHover;
export const selectBackgroundLT = "#FFFFFF";
export const selectBackgroundHoverLT = "#F8F8F9";
export const selectBorderColor = "#D3D8E0";
export const selectBorderColorLT = "#D3D8E0";
export const selectBorderRadius = "1px";
export const selectBorder = 0;

export const dropdownListHighlightBg = "#6A7485";
export const dropdownListHighlightBgLT = "#F8F8F9";
export const dropdownListShadow = "0 6px 12px 0 rgba(0,0,0,0.16)";
export const dropdownListBgd = "#3A414C";
export const dropdownListBgdLT = "#FFFFFF";
export const dropdownListBorderTop = "#242730";
export const dropdownListBorderTopLT = "#D3D8E0";
export const dropdownWrapperZ = 100;
// Switch
export const switchWidth = 24;
export const switchHeight = 12;
export const switchLabelMargin = 12;

export const switchTrackBgd = "#29323C";
export const switchTrackBgdActive = activeColor;
export const switchTrackBorderRadius = "1px";
export const switchBtnBgd = "#6A7485";
export const switchBtnBgdActive = "#D3D8E0";
export const switchBtnBoxShadow = "0 2px 4px 0 rgba(0,0,0,0.40)";
export const switchBtnBorderRadius = "0";
export const switchBtnWidth = "12px";
export const switchBtnHeight = "12px";

export const secondarySwitchTrackBgd = "#242730";
export const secondarySwitchBtnBgd = "#3A414C";

// Checkbox
export const checkboxWidth = 16;
export const checkboxHeight = 16;
export const checkboxMargin = 12;
export const checkboxBorderColor = selectBorderColor;
export const checkboxBorderRadius = "2px";
export const checkboxBorderColorLT = selectBorderColorLT;
export const checkboxBoxBgd = "white";
export const checkboxBoxBgdChecked = primaryBtnBgd;

// Side Panel
export const sidePanelHeaderBg = "#29323C";
export const sidePanelInnerPadding = 16;
export const sidePanelBg = "#242730";
export const sidePanelScrollBarWidth = 10;
export const sidePanelScrollBarHeight = 10;
export const sideBarCloseBtnBgd = secondaryBtnBgd;
export const sideBarCloseBtnColor = "#29323C";
export const sideBarCloseBtnBgdHover = secondaryBtnActBgd;

export const panelBackground = "#29323C";
export const panelBackgroundHover = "#3A4552";
export const panelActiveBg = "#3A4552";
export const panelActiveBgLT = "#6A7485";
export const panelHeaderIcon = "#6A7485";
export const panelHeaderIconActive = "#A0A7B4";
export const panelHeaderHeight = 48;
export const panelBoxShadow = "0 6px 12px 0 rgba(0,0,0,0.16)";
export const panelBorderRadius = "2px";
export const panelBackgroundLT = "#F8F8F9";

export const panelBorderColor = "#3A414C";
export const panelBorder = `1px solid ${borderColor}`;
export const panelBorderLT = `1px solid ${borderColorLT}`;

export const mapPanelBackgroundColor = "#242730";
export const mapPanelHeaderBackgroundColor = "#29323C";
export const tooltipBg = "#F8F8F9";
export const tooltipColor = "#333334";

export const layerTypeIconSizeL = 50;
export const layerTypeIconPdL = 12;
export const layerTypeIconSizeSM = 28;

// Bottom Panel
export const bottomInnerPdSide = 32;
export const bottomInnerPdVert = 6;
export const bottomPanelGap = 20;
export const bottomWidgetPaddingTop = 20;
export const bottomWidgetPaddingRight = 20;
export const bottomWidgetPaddingBottom = 30;
export const bottomWidgetPaddingLeft = 20;

// Modal
export const modalTitleColor = "#3A414C";
export const modalTitleFontSize = "24px";
export const modalTitleFontSizeSmaller = "18px";
export const modalFooterBgd = "#F8F8F9";
export const modalImagePlaceHolder = "#DDDFE3";
export const modalPadding = "10px 0";
export const modalLateralPadding = "72px";
export const modalPortableLateralPadding = "36px";

export const modalOverLayZ = 1001;
export const modalOverlayBgd = "rgba(0, 0, 0, 0.5)";
export const modalContentZ = 10002;
export const modalFooterZ = 10001;
export const modalTitleZ = 10003;
export const modalButtonZ = 10005;
export const modalDropdownBackground = "#FFFFFF";

// Modal Dialog (Dark)
export const modalDialogBgd = "#3A414C";
export const modalDialogColor = textColorHl;

// Slider
export const sliderBarColor = "#6A7485";
export const sliderBarBgd = "#3A414C";
export const sliderBarHoverColor = "#D3D8E0";
export const sliderBarRadius = "1px";
export const sliderBarHeight = 4;
export const sliderHandleHeight = 12;
export const sliderHandleWidth = 12;
export const sliderHandleColor = "#D3D8E0";
export const sliderHandleHoverColor = "#FFFFFF";
export const sliderHandleShadow = "0 2px 4px 0 rgba(0,0,0,0.40)";
export const sliderInputHeight = 24;
export const sliderInputWidth = 56;
export const sliderMarginTopIsTime = -12;
export const sliderMarginTop = 12;

// Plot
export const rangeBrushBgd = "#3A414C";
export const histogramFillInRange = activeColor;
export const histogramFillOutRange = sliderBarColor;

// Notification
export const notificationColors = {
    info: "#276ef1",
    error: "#f25138",
    success: "#47b275",
    warning: "#ffc043"
};

export const notificationPanelWidth = 240;
export const notificationPanelItemWidth = notificationPanelWidth - 60;
export const notificationPanelItemHeight = 60;

// Data Table
const headerRowHeight = 70;
const rowHeight = 32;
const headerPaddingTop = 6;
const headerPaddingBottom = 8;
const cellPaddingSide = 10;
const edgeCellPaddingSide = 10;
const cellFontSize = 10;
const gridPaddingSide = 24;
const headerCellBackground = "#FFFFFF";
const headerCellBorderColor = "#E0E0E0";
const headerCellIconColor = "#666666";
const cellBorderColor = "#E0E0E0";
const evenRowBackground = "#FFFFFF";
const oddRowBackground = "#F7F7F7";
const optionButtonColor = "#6A7485";
const pinnedGridBorderColor = "#E0E0E0";

// Floating Time display
const timeDisplayBorderRadius = 32;
const timeDisplayHeight = 64;
const timeDisplayMinWidth = 176;
const timeDisplayOpacity = 0.8;
const timeDisplayPadding = "0 24px";

// Export map modal
const exportIntraSectionMargin = "8";

// progress bar
const progressBarColor = primaryBtnBgd;
const progressBarTrackColor = "#E8E8E8";
// Action Panel
export const actionPanelWidth = 110;
export const actionPanelHeight = 32;

export const textTruncate = {
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    wordWrap: "normal"
};

// This breakpoints are used for responsive design
export const breakPoints = {
    palm: 588,
    desk: 768
};

// theme is passed to kepler.gl when it's mounted,
// it is used by styled-components to pass along to
// all child components

const input = css`
    align-items: center;
    background-color: ${(props) => props.theme.inputBgd};
    border: 1px solid
        ${(props) =>
            props.active
                ? props.theme.inputBorderActiveColor
                : props.error
                ? props.theme.errorColor
                : props.theme.inputBgd};
    border-radius: 2px;
    caret-color: ${(props) => props.theme.inputBorderActiveColor};
    color: ${(props) => props.theme.inputColor};
    display: flex;
    font-size: ${(props) =>
        ["small", "tiny"].includes(props.size) ? props.theme.inputFontSizeSmall : props.theme.inputFontSize};
    font-weight: ${(props) => props.theme.inputFontWeight};
    height: ${(props) =>
        props.size === "small"
            ? props.theme.inputBoxHeightSmall
            : props.size === "tiny"
            ? props.theme.inputBoxHeightTiny
            : props.theme.inputBoxHeight};
    justify-content: space-between;
    outline: none;
    overflow: hidden;
    padding: ${(props) =>
        props.size === "small"
            ? props.theme.inputPaddingSmall
            : props.size === "tiny"
            ? props.theme.inputPaddingTiny
            : props.theme.inputPadding};
    text-overflow: ellipsis;
    transition: ${(props) => props.theme.transition};
    white-space: nowrap;
    width: 100%;
    word-wrap: normal;
    pointer-events: ${(props) => (props.disabled ? "none" : "all")};
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};

    :hover {
        cursor: ${(props) => (props.type === "number" ? "text" : "pointer")};
        background-color: ${(props) => (props.active ? props.theme.inputBgdActive : props.theme.inputBgdHover)};
        border-color: ${(props) =>
            props.active ? props.theme.inputBorderActiveColor : props.theme.inputBorderHoverColor};
    }

    :active,
    :focus,
    &.focus,
    &.active {
        background-color: ${(props) => props.theme.inputBgdActive};
        border-color: ${(props) => props.theme.inputBorderActiveColor};
    }

    ::placeholder {
        color: ${(props) => props.theme.inputPlaceholderColor};
        font-weight: ${(props) => props.theme.inputPlaceholderFontWeight};
    }

    /* Disable Arrows on Number Inputs */
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const inputLT = css`
  ${input}

  background-color: ${(props) => props.theme.selectBackgroundLT};
  border: 1px solid
  ${(props) =>
      props.active
          ? props.theme.selectActiveBorderColor
          : props.error
          ? props.theme.errorColor
          : props.theme.selectBorderColorLT};
  color: ${(props) => props.theme.selectColorLT};
  caret-color: ${(props) => props.theme.selectColorLT};

  ::-webkit-input-placeholder {
    color: ${(props) => props.theme.subtextColorLT};
    font-weight: 400;
  }

  :active,
  :focus,
  &.focus,
  &.active {
    background-color: ${(props) => props.theme.selectBackgroundLT};
    border-color: ${(props) => props.theme.textColorLT};
  }

  :hover {
    background-color: ${(props) => props.theme.selectBackgroundLT};
    cursor: ${(props) => (["number", "text"].includes(props.type) ? "text" : "pointer")};
    border-color: ${(props) => (props.active ? props.theme.textColorLT : props.theme.subtextColor)};
  }
`;

const secondaryInput = css`
  ${(props) => props.theme.input}
  color: ${(props) => props.theme.secondaryInputColor};
  background-color: ${(props) => props.theme.secondaryInputBgd};
  border: 1px solid
    ${(props) => (props.error ? props.theme.errorColor : props.theme.secondaryInputBorderColor)};

  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.secondaryInputBgdHover};
    border-color: ${(props) => props.theme.secondaryInputBgdHover};
  }

  :active,
  &.active {
    background-color: ${(props) => props.theme.secondaryInputBgdActive};
    border-color: ${(props) => props.theme.secondaryInputBorderActiveColor};
  }
`;

const chickletedInputContainer = css`
    cursor: pointer;
    flex-wrap: wrap;
    height: auto;
    justify-content: start;
    margin-bottom: 2px;
    padding: 0px 7px 0px 4px;
    white-space: normal;

    .chickleted-input__placeholder {
        line-height: 24px;
        margin: 4px;
    }
`;

const chickletedInput = css`
    ${(props) => props.theme.input}
    ${(props) => props.theme.chickletedInputContainer}
`;

const secondaryChickletedInput = css`
    ${(props) => props.theme.secondaryInput}
    ${(props) => props.theme.chickletedInputContainer}
`;

const inlineInput = css`
  ${(props) => props.theme.input} color: ${(props) => props.theme.textColor};
  font-size: 13px;
  letter-spacing: 0.43px;
  line-height: 18px;
  height: 24px;
  font-weight: 400;
  padding-left: 4px;
  margin-left: -4px;
  background-color: transparent;
  border: 1px solid transparent;

  :hover {
    height: 24px;
    cursor: text;
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.labelColor};
  }

  :active,
  .active,
  :focus {
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.inputBorderActiveColor};
  }
`;

const switchTrack = css`
    background: ${(props) => (props.checked ? props.theme.switchTrackBgdActive : props.theme.switchTrackBgd)};
    position: absolute;
    top: 0;
    left: ${(props) => -props.theme.switchLabelMargin}px;
    content: "";
    display: block;
    width: ${(props) => props.theme.switchWidth}px;
    height: ${(props) => props.theme.switchHeight}px;
    border-radius: ${(props) => props.theme.switchTrackBorderRadius};
`;

const switchButton = css`
    transition: ${(props) => props.theme.transition};
    position: absolute;
    top: 0;
    left: ${(props) => (props.checked ? props.theme.switchWidth / 2 : -1) - props.theme.switchLabelMargin}px;
    content: "";
    display: block;
    height: ${(props) => props.theme.switchBtnHeight};
    width: ${(props) => props.theme.switchBtnWidth};
    background: ${(props) => (props.checked ? props.theme.switchBtnBgdActive : props.theme.switchBtnBgd)};
    box-shadow: ${(props) => props.theme.switchBtnBoxShadow};
    border-radius: ${(props) => props.theme.switchBtnBorderRadius};
`;

const inputSwitch = css`
    user-select: none;
    cursor: pointer;
    line-height: 16px;
    font-weight: 500;
    font-size: 12px;
    color: ${(props) => props.theme.labelColor};
    position: relative;
    display: inline-block;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: ${(props) => props.theme.switchWidth}px;

    :before {
        ${(props) => props.theme.switchTrack};
    }

    :after {
        ${(props) => props.theme.switchButton};
    }
`;

// This is a light version checkbox
const checkboxBox = css`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => props.theme.checkboxWidth}px;
    height: ${(props) => props.theme.checkboxHeight}px;
    background: ${(props) => (props.checked ? props.theme.checkboxBoxBgdChecked : props.theme.checkboxBoxBgd)};
    border: 1px solid
        ${(props) => (props.checked ? props.theme.checkboxBoxBgdChecked : props.theme.checkboxBorderColor)};
    border-radius: 2px;
    content: "";
`;

const checkboxCheck = css`
    width: 10px;
    height: 5px;
    border-bottom: 2px solid white;
    border-left: 2px solid white;
    top: 4px;
    left: 3px;
    transform: rotate(-45deg);
    display: block;
    position: absolute;
    opacity: ${(props) => (props.checked ? 1 : 0)};
    content: "";
`;

const inputCheckbox = css`
    display: inline-block;
    position: relative;
    padding-left: 32px;
    margin-bottom: 24px;
    line-height: 20px;
    vertical-align: middle;
    cursor: pointer;
    font-size: 12px;
    color: ${(props) => props.theme.labelColor};
    margin-left: -${(props) => props.theme.switchLabelMargin}px;

    :before {
        ${(props) => props.theme.checkboxBox};
    }

    :after {
        ${(props) => props.theme.checkboxCheck};
    }
`;

const secondarySwitch = css`
  ${(props) => props.theme.inputSwitch}
  :before {
    ${(props) => props.theme.switchTrack} background: ${(props) =>
    props.checked ? props.theme.switchTrackBgdActive : props.theme.secondarySwitchTrackBgd};
  }

  :after {
    ${(props) => props.theme.switchButton}
    background: ${(props) => (props.checked ? props.theme.switchBtnBgdActive : props.theme.secondarySwitchBtnBgd)};
  }
`;

const dropdownScrollBar = css`
  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  ::-webkit-scrollbar-corner {
    background: ${(props) => props.theme.dropdownListBgd};
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.dropdownListBgd};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${(props) => props.theme.labelColor};
    border: 3px solid ${(props) => props.theme.dropdownListBgd};
  };

  :vertical:hover {
    background: ${(props) => props.theme.textColorHl};
    cursor: pointer;
  }
}`;

const dropdownListAnchor = css`
    color: ${(props) => props.theme.selectColor};
    padding-left: 3px;
`;

const dropdownListSize = css`
    font-size: 11px;
    padding: 3px 9px;
    font-weight: 500;
    white-space: nowrap;
`;

const dropdownListItem = css`
    ${dropdownListSize}
    &.hover,
  &:hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.dropdownListHighlightBg};

        .list__item__anchor {
            color: ${(props) => props.theme.textColorHl};
        }
    }
`;

const dropdownListItemLT = css`
  ${dropdownListSize}
  color: ${(props) => props.theme.textColorLT};

  &.hover,
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.textColorHlLT};
    background-color: ${(props) => props.theme.dropdownListHighlightBgLT};

    .list__item__anchor {
      color: ${(props) => props.theme.textColorHlLT};
    }
  }
`;

const dropdownListHeader = css`
    font-size: 11px;
    padding: 5px 9px;
    color: ${(props) => props.theme.labelColor};
`;

const dropdownListSection = css`
    padding: 0 0 4px 0;
    margin-bottom: 4px;
    border-bottom: 1px solid ${(props) => props.theme.labelColor};
`;

const dropdownList = css`
    overflow-y: auto;
    max-height: 280px;
    box-shadow: ${(props) => props.theme.dropdownListShadow};
    border-radius: 2px;

    .list__section {
        ${(props) => props.theme.dropdownListSection};
    }
    .list__header {
        ${(props) => props.theme.dropdownListHeader};
    }

    .list__item {
        ${(props) => props.theme.dropdownListItem};
    }

    .list__item__anchor {
        ${(props) => props.theme.dropdownListAnchor};
    }

    ${(props) => props.theme.dropdownScrollBar};
`;

const dropdownListLT = css`
    ${dropdownList}
    .list__item {
        ${(props) => props.theme.dropdownListItemLT};
    }
`;
const sidePanelScrollBar = css`
  ::-webkit-scrollbar {
    height: ${(props) => props.theme.sidePanelScrollBarHeight}px;
    width: ${(props) => props.theme.sidePanelScrollBarWidth}px;
  }

  ::-webkit-scrollbar-corner {
    background: ${(props) => props.theme.sidePanelBg};
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.sidePanelBg};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${(props) => props.theme.panelBackgroundHover};
    border: 3px solid ${(props) => props.theme.sidePanelBg};

    :hover {
      background: ${(props) => props.theme.labelColor};
      cursor: pointer;
    }
  };
}`;

const panelDropdownScrollBar = css`
    ::-webkit-scrollbar {
        height: 10px;
        width: 10px;
    }

    ::-webkit-scrollbar-corner {
        background: ${(props) => props.theme.panelBackground};
    }

    ::-webkit-scrollbar-track {
        background: ${(props) => props.theme.panelBackground};
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: ${(props) => props.theme.panelBackgroundHover};
        border: 3px solid ${(props) => props.theme.panelBackground};
        :hover {
            background: ${(props) => props.theme.labelColor};
            cursor: pointer;
        }
    }
`;

const scrollBar = css`
  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  ::-webkit-scrollbar-corner {
    background: ${(props) => props.theme.panelBackground};
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.panelBackground};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${(props) => props.theme.labelColor};
    border: 3px solid ${(props) => props.theme.panelBackground}

    :vertical:hover {
      background: ${(props) => props.theme.textColorHl};
      cursor: pointer;
    }

    :horizontal:hover {
      background: ${(props) => props.theme.textColorHl};
      cursor: pointer;
    }
  }
}`;

export const modalScrollBar = css`
    ::-webkit-scrollbar {
        width: 14px;
        height: 16px;
    }

    ::-webkit-scrollbar-track {
        background: white;
    }
    ::-webkit-scrollbar-track:horizontal {
        background: ${(props) => props.theme.textColorHl};
    }
    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.labelColorLT};
        border: 4px solid white;
    }

    ::-webkit-scrollbar-corner {
        background: ${(props) => props.theme.textColorHl};
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #969da9;
    }

    ::-webkit-scrollbar-thumb:vertical {
        border-radius: 7px;
    }

    ::-webkit-scrollbar-thumb:horizontal {
        border-radius: 9px;
        border: 4px solid ${(props) => props.theme.textColorHl};
    }
`;

export const theme = {
    ...DIMENSIONS,
    // templates
    input,
    inputLT,
    inlineInput,
    chickletedInput,
    chickletedInputContainer,
    secondaryChickletedInput,

    borderColor,
    borderColorLT,

    secondaryInput,
    dropdownScrollBar,
    dropdownList,
    dropdownListLT,
    dropdownListItem,
    dropdownListItemLT,
    dropdownListAnchor,
    dropdownListHeader,
    dropdownListSection,
    dropdownListShadow,
    dropdownWrapperZ,
    modalScrollBar,
    scrollBar,
    sidePanelScrollBar,
    inputSwitch,
    secondarySwitch,
    switchTrack,
    switchButton,
    inputCheckbox,
    checkboxBox,
    checkboxCheck,

    // Transitions
    transition,
    transitionFast,
    transitionSlow,

    // styles
    activeColor,
    activeColorHover,
    borderRadius,
    boxShadow,
    errorColor,
    dropdownListHighlightBg,
    dropdownListHighlightBgLT,
    dropdownListBgd,
    dropdownListBgdLT,
    dropdownListBorderTop,
    dropdownListBorderTopLT,

    labelColor,
    labelColorLT,
    labelHoverColor,
    mapPanelBackgroundColor,
    mapPanelHeaderBackgroundColor,

    // Select
    selectActiveBorderColor,
    selectBackground,
    selectBackgroundLT,
    selectBackgroundHover,
    selectBackgroundHoverLT,
    selectBorder,
    selectBorderColor,
    selectBorderRadius,
    selectBorderColorLT,
    selectColor,
    selectColorPlaceHolder,
    selectFontSize,
    selectFontWeight,
    selectColorLT,
    selectFontWeightBold,

    // Input
    inputBgd,
    inputBgdHover,
    inputBgdActive,
    inputBoxHeight,
    inputBoxHeightSmall,
    inputBoxHeightTiny,
    inputBorderColor,
    inputBorderActiveColor,
    inputBorderHoverColor,
    inputBorderRadius,
    inputColor,
    inputPadding,
    inputPaddingSmall,
    inputPaddingTiny,
    inputFontSize,
    inputFontSizeSmall,
    inputFontWeight,
    inputPlaceholderColor,
    inputPlaceholderFontWeight,

    secondaryInputBgd,
    secondaryInputBgdHover,
    secondaryInputBgdActive,
    secondaryInputColor,
    secondaryInputBorderColor,
    secondaryInputBorderActiveColor,

    // Switch
    switchWidth,
    switchHeight,
    switchTrackBgd,
    switchTrackBgdActive,
    switchTrackBorderRadius,
    switchBtnBgd,
    switchBtnBgdActive,
    switchBtnBoxShadow,
    switchBtnBorderRadius,
    switchBtnWidth,
    switchBtnHeight,
    switchLabelMargin,

    secondarySwitchTrackBgd,
    secondarySwitchBtnBgd,

    // Checkbox
    checkboxWidth,
    checkboxHeight,
    checkboxMargin,
    checkboxBorderColor,
    checkboxBorderRadius,
    checkboxBorderColorLT,
    checkboxBoxBgd,
    checkboxBoxBgdChecked,

    // Button
    primaryBtnBgd,
    primaryBtnActBgd,
    primaryBtnColor,
    primaryBtnActColor,
    primaryBtnBgdHover,
    primaryBtnRadius,
    secondaryBtnBgd,
    secondaryBtnActBgd,
    secondaryBtnBgdHover,
    secondaryBtnColor,
    secondaryBtnActColor,

    negativeBtnBgd,
    negativeBtnActBgd,
    negativeBtnBgdHover,
    negativeBtnColor,
    negativeBtnActColor,

    linkBtnBgd,
    linkBtnActBgd,
    linkBtnColor,
    linkBtnActColor,
    linkBtnActBgdHover,

    floatingBtnBgd,
    floatingBtnActBgd,
    floatingBtnBgdHover,
    floatingBtnColor,
    floatingBtnActColor,

    // Modal
    modalTitleColor,
    modalTitleFontSize,
    modalTitleFontSizeSmaller,
    modalFooterBgd,
    modalImagePlaceHolder,
    modalPadding,

    modalDialogBgd,
    modalDialogColor,

    modalLateralPadding,
    modalPortableLateralPadding,
    modalOverLayZ,
    modalOverlayBgd,
    modalContentZ,
    modalFooterZ,
    modalTitleZ,
    modalButtonZ,
    modalDropdownBackground,

    // Side Panel
    sidePanelBg,
    sidePanelInnerPadding,
    sideBarCloseBtnBgd,
    sideBarCloseBtnColor,
    sideBarCloseBtnBgdHover,
    sidePanelHeaderBg,
    sidePanelScrollBarWidth,
    sidePanelScrollBarHeight,

    // Side Panel Panel
    panelActiveBg,
    panelBackground,
    panelBackgroundHover,
    panelBackgroundLT,
    panelBoxShadow,
    panelBorderRadius,
    panelBorder,
    panelBorderColor,
    panelBorderLT,
    panelHeaderIcon,
    panelHeaderIconActive,
    panelHeaderHeight,
    panelDropdownScrollBar,

    layerTypeIconSizeL,
    layerTypeIconPdL,
    layerTypeIconSizeSM,

    // Text
    fontFamily,
    fontWeight,
    fontSize,
    lineHeight,
    textColor,
    textColorLT,
    textColorHl,
    titleTextColor,
    subtextColor,
    subtextColorLT,
    subtextColorActive,
    textTruncate,
    titleColorLT,
    tooltipBg,
    tooltipColor,
    logoColor,

    // Bottom Panel
    bottomInnerPdSide,
    bottomInnerPdVert,
    bottomPanelGap,
    bottomWidgetPaddingTop,
    bottomWidgetPaddingRight,
    bottomWidgetPaddingBottom,
    bottomWidgetPaddingLeft,

    // Slider
    sliderBarColor,
    sliderBarBgd,
    sliderBarHoverColor,
    sliderBarRadius,
    sliderBarHeight,
    sliderHandleHeight,
    sliderHandleWidth,
    sliderHandleColor,
    sliderHandleHoverColor,
    sliderHandleShadow,
    sliderInputHeight,
    sliderInputWidth,
    sliderMarginTopIsTime,
    sliderMarginTop,

    // Plot
    rangeBrushBgd,
    histogramFillInRange,
    histogramFillOutRange,

    // Notifications
    notificationColors,
    notificationPanelWidth,
    notificationPanelItemWidth,
    notificationPanelItemHeight,

    // Data Table
    headerRowHeight,
    rowHeight,
    headerPaddingTop,
    headerPaddingBottom,
    cellPaddingSide,
    edgeCellPaddingSide,
    cellFontSize,
    gridPaddingSide,
    optionButtonColor,
    headerCellBackground,
    headerCellBorderColor,
    headerCellIconColor,
    cellBorderColor,
    evenRowBackground,
    oddRowBackground,
    pinnedGridBorderColor,
    // time display
    timeDisplayBorderRadius,
    timeDisplayHeight,
    timeDisplayMinWidth,
    timeDisplayOpacity,
    timeDisplayPadding,

    // export map
    exportIntraSectionMargin,

    // Action Panel
    actionPanelWidth,
    actionPanelHeight,

    // Breakpoints
    breakPoints,

    // progressbar
    progressBarColor,
    progressBarTrackColor
};

export const themeLT = {
    ...theme,
    // template
    activeColor: activeColorLT,
    input: inputLT,
    textColor: textColorLT,
    sidePanelBg: "#FFFFFF",
    selectColor: selectColorLT,
    titleTextColor: "#000000",
    sidePanelHeaderBg: "#F7F7F7",
    subtextColorActive: activeColorLT,
    tooltipBg: "#1869B5",
    tooltipColor: "#FFFFFF",
    dropdownListBgd: "#FFFFFF",
    textColorHl: activeColorLT,

    inputBgd: "#F7F7F7",
    inputBgdHover: "#FFFFFF",
    inputBgdActive: "#FFFFFF",

    dropdownListHighlightBg: "#F0F0F0",

    panelBackground: "#F7F7F7",
    panelBackgroundHover: "#F7F7F7",
    panelBorderColor: "#D3D8E0",

    sideBarCloseBtnBgd: "#F7F7F7",
    sideBarCloseBtnColor: textColorLT,
    sideBarCloseBtnBgdHover: "#F7F7F7",

    secondaryInputBgd: "#F7F7F7",
    secondaryInputBgdActive: "#F7F7F7",
    secondaryInputBgdHover: "#FFFFFF",
    secondaryInputBorderActiveColor: "#000000",
    secondaryInputBorderColor: "none",
    secondaryInputColor: "#545454",

    panelActiveBg: "#F7F7F7",
    mapPanelBackgroundColor: "#FFFFFF",
    mapPanelHeaderBackgroundColor: "#F7F7F7",

    sliderBarColor: "#A0A7B4",
    sliderBarBgd: "#D3D8E0",
    sliderHandleColor: "#F7F7F7",
    sliderHandleHoverColor: "#F7F7F7",

    subtextColor: subtextColorLT,
    switchBtnBgd: "#F7F7F7",
    secondarySwitchBtnBgd: "#F7F7F7",
    secondarySwitchTrackBgd: "#D3D8E0",
    switchBtnBgdActive: "#F7F7F7",
    switchTrackBgd: "#D3D8E0",
    switchTrackBgdActive: activeColorLT,

    // button switch background and hover color
    primaryBtnBgd: primaryBtnActBgd,
    primaryBtnActBgd: primaryBtnBgd,
    primaryBtnBgdHover: primaryBtnBgd,

    secondaryBtnBgd: secondaryBtnActBgd,
    secondaryBtnActBgd: secondaryBtnBgd,
    secondaryBtnBgdHover: secondaryBtnBgd,

    floatingBtnBgd: "#F7F7F7",
    floatingBtnActBgd: "#F7F7F7",
    floatingBtnBgdHover: "#F7F7F7",
    floatingBtnColor: subtextColor,
    floatingBtnActColor: activeColorLT,

    linkBtnActColor: textColorLT,

    rangeBrushBgd: "#D3D8E0",
    histogramFillInRange: activeColorLT,
    histogramFillOutRange: "#A0A7B4"
};

export const themeBS = {
    ...theme,
    activeColor: "#E2E2E2",
    dropdownListBgd: "#FFFFFF",
    dropdownListBorderTop: "none",
    dropdownListHighlightBg: "#F6F6F6",
    inputBgd: "#E2E2E2",
    inputBgdActive: "#E2E2E2",
    inputBgdHover: "inherit",
    inputBorderActiveColor: "#000000",
    inputColor: "#000000",
    panelActiveBg: "#E2E2E2",
    panelBackground: "#FFFFFF",
    panelBackgroundHover: "#EEEEEE",
    panelBorderColor: "#E2E2E2",
    primaryBtnBgd: "#E2E2E2",
    primaryBtnBgdHover: "#333333",
    primaryBtnColor: "#000000",
    secondaryBtnActBgd: "#EEEEEE",
    secondaryBtnActColor: "#000000",
    secondaryBtnBgd: "#E2E2E2",
    secondaryBtnBgdHover: "#CBCBCB",

    sideBarCloseBtnBgd: "#E2E2E2",
    sideBarCloseBtnColor: "#000000",
    sideBarCloseBtnBgdHover: "#FFFFFF",

    floatingBtnBgd: "#FFFFFF",
    floatingBtnActBgd: "#EEEEEE",
    floatingBtnBgdHover: "#EEEEEE",
    floatingBtnColor: "#757575",
    floatingBtnActColor: "#000000",

    secondaryBtnColor: "#000000",
    secondaryInputBgd: "#F6F6F6",
    secondaryInputBgdActive: "#F6F6F6",
    secondaryInputBgdHover: "#F6F6F6",
    secondaryInputBorderActiveColor: "#000000",
    secondaryInputBorderColor: "none",
    secondaryInputColor: "#545454",
    sidePanelBg: "#F6F6F6",
    sidePanelHeaderBg: "#FFFFFF",
    subtextColor: "#AFAFAF",
    subtextColorActive: "#000000",
    textColor: "#000000",
    textColorHl: "#545454",
    mapPanelBackgroundColor: "#F6F6F6",
    mapPanelHeaderBackgroundColor: "#FFFFFF",
    titleTextColor: "#000000",
    switchBtnBgdActive: "#000000",
    switchBtnBgd: "#FFFFFF",
    switchTrackBgdActive: "#E2E2E2",
    secondarySwitchTrackBgd: "#E2E2E2",
    switchTrackBgd: "#E2E2E2",
    secondarySwitchBtnBgd: "#FFFFFF",
    histogramFillInRange: "#000000",
    histogramFillOutRange: "#E2E2E2",
    rangeBrushBgd: "#E2E2E2",
    sliderBarBgd: "#E2E2E2",
    sliderHandleColor: "#FFFFFF",
    sliderBarColor: "#000000"
};
