import styled from "styled-components";

export default styled.div`
    height: 700px;
    width: 254px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
