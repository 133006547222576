import React, { Component } from "react";
import styled from "styled-components";

import { SelectTextBold, CenterFlexbox, BottomWidgetInner } from "../common/styled-components";
import { Clock } from "../common/icons";
import SpeedControl from "../common/animation-control/speed-control";
import TimeRangeFilter from "./time-range-filter";
import FloatingTimeDisplay from "../common/animation-control/floating-time-display";

const TOP_SECTION_HEIGHT = "36px";

const CloseButton = styled.div`
    color: #FFFFFF;

    :hover {
        cursor: pointer;
        color: #D3D3D3
    }
`;

const TimeBottomWidgetInner = styled(BottomWidgetInner)`
    padding: 6px 32px 16px 32px;
`;
const TopSectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: ${(props) => props.theme.labelColor};
    height: ${TOP_SECTION_HEIGHT};

    .bottom-widget__y-axis {
        flex-grow: 1;
        margin-left: 20px;
    }

    .bottom-widget__field-select {
        width: 160px;
        display: inline-block;

        .item-selector__dropdown {
            background: transparent;
            padding: 4px 10px 4px 4px;
            border-color: transparent;

            :active,
            :focus,
            &.focus,
            &.active {
                background: transparent;
                border-color: transparent;
            }
        }

        .item-selector__dropdown:hover {
            background: transparent;
            border-color: transparent;

            .item-selector__dropdown__value {
                color: ${(props) => (props.hoverColor ? props.theme[props.hoverColor] : props.theme.textColorHl)};
            }
        }
    }

    .animation-control__speed-control {
        margin-right: -12px;

        .animation-control__speed-slider {
            right: calc(0% - 48px);
        }
    }
`;

const StyledTitle = styled(CenterFlexbox)`
    flex-grow: 0;
    color: ${(props) => props.theme.orion.scheme.primaryText};
    margin-right: -4px;

    .bottom-widget__icon {
        margin-right: 6px;
    }
    .bottom-widget__icon.speed {
        margin-right: 0;
    }
`;

class TimeWidget extends Component {
    state = {
        showSpeedControl: false
    };

    _updateAnimationSpeed = (speed) => this.props.updateAnimationSpeed(speed);

    _toggleSpeedControl = () => this.setState({ showSpeedControl: !this.state.showSpeedControl });

    _setFilterPlotYAxis = (value) => this.props.setFilterPlot(this.props.index, { yAxis: value });

    _toggleAnimation = () => {};

    render() {
        const { filter, setFilter, showTimeDisplay, closeTimeComponent } = this.props;
        const { showSpeedControl } = this.state;

        return (
            <TimeBottomWidgetInner className="bottom-widget--inner">
                <TopSectionWrapper>
                    <StyledTitle className="bottom-widget__field">
                        <CenterFlexbox className="bottom-widget__icon">
                            <Clock height="15px" />
                        </CenterFlexbox>
                        <SelectTextBold>{filter.name}</SelectTextBold>
                    </StyledTitle>

                    <StyledTitle className="bottom-widget__speed">
                        <SpeedControl
                            onClick={this._toggleSpeedControl}
                            showSpeedControl={showSpeedControl}
                            updateAnimationSpeed={this._updateAnimationSpeed}
                            speed={filter.speed}
                        />
                        <CloseButton onClick={closeTimeComponent}>
                            Close
                        </CloseButton>
                    </StyledTitle>
                </TopSectionWrapper>
                <TimeRangeFilter
                    filter={filter}
                    setFilter={setFilter}
                    toggleAnimation={this._toggleAnimation}
                    hideTimeTitle={showTimeDisplay}
                    isAnimatable
                />
                {showTimeDisplay ? <FloatingTimeDisplay currentTime={filter.value} /> : null}
            </TimeBottomWidgetInner>
        );
    }
}

export default TimeWidget;
