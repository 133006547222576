import React, { useState } from "react";
import moment from "moment";
import "./message.css";
// import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// import { IconButton } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Message(props: any) {
  const {
    data,
    isMine,
    startsSequence,
    endsSequence,
    showTimestamp,
    isSource,
    isFullTranscriptSource,
    ftEnd
  } = props;
  let elementID: string;
  elementID = isSource === true ? "source" : "not-source";
  if (isFullTranscriptSource) elementID = "source-FT";
  if (ftEnd) elementID = "ftEnd";
  let mediaMessage = "";
  let textMessage = "";
  let captionedAWSLink = "";
  if (data.message !== null && data.message.includes("|boundaryBetweenTextAndMedia|")) {
    const tempData = data.message.split("|boundaryBetweenTextAndMedia|");
    textMessage = tempData[0];
    mediaMessage = tempData[1];
    captionedAWSLink = "https://s3-us-east-2.amazonaws.com/telegram-bot-media/" + mediaMessage;
  }
  let awsLink =
    "https://s3-us-east-2.amazonaws.com/telegram-bot-media/" +
    data.message;

  const friendlyTimestamp = moment.unix(data.timestamp).format("llll");
  const smallTimestamp = moment.unix(data.timestamp).format("LT");
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };
  const getMedia = () => {
    if (data.type === "photo") {
      return (
        <>
        <div className="bubble-container" id={elementID}>
          <img
            src={awsLink}
            alt={""}
            className="other-media"
            title={friendlyTimestamp}
            onDoubleClick={() => {
              setOpen(true);
            }}
          ></img>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <img
                src={awsLink}
                alt={""}
                className="zoom-in-image"
                title={friendlyTimestamp}
                onDoubleClick={() => {
                  setOpen(true);
                }}
              ></img>
            </DialogContent>
          </Dialog>
          </div>
        </>
      );
    } else if (data.type === "video") {
      return (
        <div className="bubble-container" id={elementID}>
        <video
          src={awsLink}
          className="other-media"
          title={friendlyTimestamp}
          controls
        ></video>
        </div>
      );
    } else if (data.type === "captioned photo") {
      return (
        <>
          <div className="bubble-container" id={elementID}>
          <img
            src={captionedAWSLink}
            alt={""}
            className="other-media"
            title={friendlyTimestamp}
            onDoubleClick={() => {
              setOpen(true);
            }}
          ></img>
          </div>
          <div className="bubble-container">
          <div className="bubble" title={friendlyTimestamp}>{textMessage}</div>
          </div>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <img
                src={awsLink}
                alt={""}
                className="zoom-in-image"
                title={friendlyTimestamp}
                onDoubleClick={() => {
                  setOpen(true);
                }}
              ></img>
            </DialogContent>
          </Dialog>
        </>
      )
    } else if (data.type === "captioned video") {
      return (
        <>
        <div className="bubble-container" id={elementID}>
        <video
          src={captionedAWSLink}
          className="other-media"
          title={friendlyTimestamp}
          controls
        ></video>
        </div>
        <div className="bubble-container">
        <div className="bubble" title={friendlyTimestamp}>{textMessage}</div>
        </div>
        </>
      );
    }
  };

  if (startsSequence && !endsSequence) {
    return (
      <div
        className={[
          "message",
          `${isMine ? "mine" : ""}`,
          `${startsSequence ? "start" : ""}`,
          `${endsSequence ? "end" : ""}`,
          `${(isSource || isFullTranscriptSource) ? "sourceIndicator" : ""}`,
        ].join(" ")}
        id={elementID}
      >
        {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}
        <div className="name">{data.author}</div>
        {getMedia()}
      </div>
    );
  } else if (startsSequence && endsSequence) {
    return (
      <div
        className={[
          "message",
          `${isMine ? "mine" : ""}`,
          `${startsSequence ? "start" : ""}`,
          `${endsSequence ? "end" : ""}`,
          `${(isSource || isFullTranscriptSource) ? "sourceIndicator" : ""}`,
        ].join(" ")}
        id={elementID}
      >
        {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}
        <div className="name">{data.author}</div>
        {getMedia()}
        <div className="smallTimestamp">Sent {smallTimestamp}</div>
      </div>
    );
  } else if (endsSequence && !startsSequence) {
    return (
      <div
        className={[
          "message",
          `${isMine ? "mine" : ""}`,
          `${startsSequence ? "start" : ""}`,
          `${endsSequence ? "end" : ""}`,
          `${(isSource || isFullTranscriptSource) ? "sourceIndicator" : ""}`,
        ].join(" ")}
        id={elementID}
      >
        {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

        {getMedia()}
        <div className="smallTimestamp">Sent {smallTimestamp}</div>
      </div>
    );
  } else {
    return (
      <div
        className={[
          "message",
          `${isMine ? "mine" : ""}`,
          `${startsSequence ? "start" : ""}`,
          `${endsSequence ? "end" : ""}`,
          `${(isSource || isFullTranscriptSource) ? "sourceIndicator" : ""}`,
        ].join(" ")}
        id={elementID}
      >
        {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

        {getMedia()}
      </div>
    );
  }
}