import { default as React,
  useState, useEffect } from "react";
import styled from "styled-components";
import { Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button, } from "@material-ui/core";

const Container = styled.div`
max-height: 100px;
overflow: hidden;
position: relative;
`;

const SeeMore = styled.a`
position: absolute;
right: 0;
bottom: 0;
color: gray;
text-decoration: none;
padding-left: 2em;
background-image: linear-gradient(to right, #fff0 0, white 2em);

&:hover {
 text-decoration: underline;
}
`;

const FullDescription = styled(DialogContentText)`
white-space: pre-line;
color: white;
`;
interface FormattedAsset {
  index: number,
  assetName: string,
  assetType: string,
  client: string,
  team: string,
  description: string,
  operationInterval: string,
  concerns: string,
  eventTypes: string,
  monetaryValue: number,
  needsReview: string,
}

export default ({props} : { props:FormattedAsset }) => {
const [ expanded, setExpanded ] = useState<boolean>(false);
const [ eventTypeText, setEventTypeText ] = useState<string>(props.eventTypes);

useEffect(() => {
  setEventTypeText(props.eventTypes)
},[props])


const expand = (event: React.SyntheticEvent) => {
 event.preventDefault();
 setExpanded(true);
};

const onClose = () => setExpanded(false);

const expansion: React.ReactNode = (
 <>
   <SeeMore onClick={expand}>View</SeeMore>
   <Dialog open={expanded}
           onClose={onClose}>
     <DialogTitle>
       Relevant Event Types
     </DialogTitle>
     <DialogContent>
         <FullDescription>
           {eventTypeText}
         </FullDescription>
     </DialogContent>
     <DialogActions>
       <Button onClick={onClose} color="primary" autoFocus>Close</Button>
     </DialogActions>
   </Dialog>
 </>
);

return (
 <Container>
   {eventTypeText}
   {expansion}
 </Container>
);
};
