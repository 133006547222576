import { CompositeLayer } from "@deck.gl/core";
import { IconLayer } from "@deck.gl/layers";
import { getMarkerImage, getPublicMarkerImage } from "../utils";

export default class MultipleIconsLayer extends CompositeLayer {
    shouldUpdateState({ changeFlags }) {
        return changeFlags.somethingChanged;
    }

    updateState() {
        this.setState({ stateUpdated: true });
    }

    renderLayers() {
        const { data = [] } = this.props;

        var layers = [];

        const titles = Array.from(new Set(data.map((x) => x.properties.markerTitle)));

        for (const title of titles) {
            layers.push(
                new IconLayer(
                    this.getSubLayerProps({
                        id: `${title}-iconlayer`,
                        key: title.includes("-public") ? title.substr(0, title.length - 7) : title,
                        data,
                        getPosition: (d) => d.geometry.coordinates,
                        iconAtlas: title.includes("-public")
                            ? getPublicMarkerImage(title ? title.substr(0, title.length - 7).toLowerCase() : null)
                            : getMarkerImage(title ? title.toLowerCase() : null),
                        iconMapping: {
                            [title]: {
                                x: 0,
                                y: 0,
                                width: 60,
                                height: 71,
                                anchorY: 71,
                                mask: false
                            }
                        },
                        getIcon: (d) => {
                            return d.properties.markerTitle;
                        },
                        sizeUnits: "meters",
                        sizeScale: 2,
                        sizeMinPixels: 44
                    })
                )
            );
        }

        return layers;
    }
}
