import { default as React, useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, TextField, Accordion, Typography, AccordionDetails } from "@material-ui/core";
import { ReactImageGalleryProps } from "react-image-gallery";
import * as MapboxGl from "mapbox-gl";
import { default as ReactMapboxGl, Marker, Feature, Layer } from "react-mapbox-gl";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import MessageComponent from "./MessageComponent/messageComponent";

import { editHistory, PendingAampReport, PendingAampReportUpdate } from "../types/aamp";
import { AdminUserProfile } from "../types/admin";
import { getAllAdminUsers, getPendingReportEdits, getUserProfileByID } from "../types/api/admin";
import ImageGallery from "./imageGallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { AccordionSummary } from "@material-ui/core";
import moment from "moment";
import { center } from "@turf/turf";

import { Point, LineString, Polygon } from 'geojson';

export const MapboxAPIKey =
  "pk.eyJ1IjoidmVyc3RhYW4iLCJhIjoiY2t6cHY0NTR6MzFlNzJ2cXJtaWpqanI3aCJ9.IuFYo-LUE9C0nFQmT64mDA";

const Map = ReactMapboxGl({
  accessToken: MapboxAPIKey,
});

const geocoder = new MapboxGeocoder({
  accessToken: MapboxAPIKey,
  marker: false,
});

/* eslint-disable jsx-a11y/img-redundant-alt */

export default ({
  row,
  report,
  updateReport,
}: {
    row: number;
    report: PendingAampReport;
    updateReport: (row: number, update: Omit<PendingAampReportUpdate, "id" | "changes">) => Promise<void>;
}) => {
  let imageGallery: React.ReactNode = "No images";
    if (report.media && report.media.length > 0) {
        const imageGalleryProps: ReactImageGalleryProps = {
            showThumbnails: false,
            showNav: true,
            showFullscreenButton: false,
            showPlayButton: false,
            showIndex: true,
            items: report.media!.map((dataUri: string) => ({
                original: dataUri,
            })),
        };
        imageGallery = <ImageGallery {...imageGalleryProps} />;
    }
  if (report.point === null) {
    report.point = {
      type: "Point",
      coordinates: [0, 0],
    };
  }


  function getCoords(point: any): [number, number] {
    // if feature
    if (point.geometry) {
      if (point.geometry.type === 'Point') {
        if (point.geometry.coordinates[0] && point.geometry.coordinates[1]){
          return [point.geometry.coordinates[0], point.geometry.coordinates[1]]
        }
        else {
          return [0,0]
        }
      }
    }
    // if point
    if (point.type === 'Point') {
      if (point.coordinates[0] && point.coordinates[1]) return [point.coordinates[0], point.coordinates[1]]
      else {
        return [0,0]
      }
    }
    // else (covers polygons that are not features)
    else {

      if (point.coordinates[0].length < 5) return point.coordinates[0][0]

      return [0,0]
    }

  }
  


  /*
  const originalCoords: [number, number] = [
    report.point!.coordinates[0],
    report.point!.coordinates[1],
  ];
  */


  const originalCoords: [number, number] = getCoords(report.point!)!

  const [coords, setCoords] = useState<[number, number]>(originalCoords);

  const resetCoords = () => {
    setCoords(originalCoords);
  };
  const saveCoords = (): Promise<void> => 
    updateReport(row, {
      point: {
        type: "Point",
        coordinates: coords,
      },
    });

  const [editHistoryState, setEditHistoryState] = useState<string[]>([]);
  const [toggleHistory, setToggleHistory] = useState<boolean>(false);

  // console.log(center(report.point!))
  // console.log(center(report.point!).geometry.coordinates)

  const fetchAndRenderEditHistory = async () => {
    const obj: editHistory[] = await getPendingReportEdits(report.id);
    const edits = [];

    for (let i = 0; i < obj.length; i++){

        let user;
        let action = " changed ";
        if (obj[i].admin_user_id !== null && obj[i].admin_user_id !== undefined){
            let adminUsers: AdminUserProfile[] = await getAllAdminUsers();
            for (let j = 0; j < adminUsers.length; j++){
                if (adminUsers[j].id === obj[i].admin_user_id){
                    if (adminUsers[j].privileges === 1){
                        user = adminUsers[j].first_name + " " + adminUsers[j].last_name + " (admin) ";
                    } else {
                        user = adminUsers[j].first_name + " " + adminUsers[j].last_name + " (manager) ";
                    }
                }
            }
        } else if (obj[i].user_id !== null && obj[i].user_id !== undefined){
            let userProfile = await getUserProfileByID(obj[i].user_id!);
            user = userProfile.first_name + " " + userProfile.last_name;
        } else {
            user = "Reporter";
            action = " set ";
        }

        const timestampPrettified = moment(obj[i].timestamp).format("YYYY-MM-DD hh:mm A");

        if(obj[i].payload.hasOwnProperty("aamp_report_type")){
            edits.push(timestampPrettified + ": " + user + action + "\"type\" field to " + obj[i].payload.aamp_report_type);
        } if(obj[i].payload.hasOwnProperty("aggressor")){
            edits.push(timestampPrettified + ": " + user + action + "\"aggressor\" field to " + obj[i].payload.aggressor);
        } if(obj[i].payload.hasOwnProperty("victim")){
            edits.push(timestampPrettified + ": " + user + action + "\"victim\" field to " + obj[i].payload.victim);
        } if(obj[i].payload.hasOwnProperty("address")){
            edits.push(timestampPrettified + ": " + user + action + "\"address\" field to " + obj[i].payload.address);
        } if(obj[i].payload.hasOwnProperty("date_time")){
            const dateTimePrettified = moment(obj[i].payload.date_time).format("MM/DD/YYYY hh:mm A");
            edits.push(timestampPrettified + ": " + user + action + "\"date/ time\" field to " + dateTimePrettified);
        } if(obj[i].payload.hasOwnProperty("description")){
            edits.push(timestampPrettified + ": " + user + action +  "\"description\" field to " + obj[i].payload.description);
        } if(obj[i].payload.hasOwnProperty("point")){
            edits.push(timestampPrettified + ": " + user + " changed report point")
        } if(obj[i].payload.hasOwnProperty("super_user_review")){
            if(obj[i].payload.super_user_review === true){
                edits.push(timestampPrettified + ": " + user + "approved this report");
            }else{
                edits.push(timestampPrettified + ": " + user + "rejected this report");
            }
        } if(obj[i].payload.hasOwnProperty("manager_review")){
            if(obj[i].payload.manager_review === true){
                edits.push(timestampPrettified + ": " + user + "approved this report");
            }else{
                edits.push(timestampPrettified + ": " + user + "rejected this report");
            }
        } if(obj[i].payload.hasOwnProperty("archived")){
            if(obj[i].payload.archived === true){
                edits.push(timestampPrettified + ": " + user + "archived this report");
            }else{
                edits.push(timestampPrettified + ": " + user + "unarchived this report");
            }
        }
    }
    setEditHistoryState(edits);
    setToggleHistory(true);
  }

  // const coordsChanged: boolean = originalCoords.some((coord, i) => coord !== coords[i]);
  const bottomBar = (
    <div
      style={{
        display: "flex",
        flex: 0,
        justifyContent: "baseline",
        flexDirection: "row",
        padding: "1rem",
        alignItems: "flex-end",
      }}
    >
      <TextField
        label="Latitude"
        type="number"
        value={coords[1]}
        onChange={(event) => {
          setCoords([coords[0], Number(event.target.value)]);
        }}
        style={{ flex: 1 }}
      />
      {", "}
      <TextField
        label="Longitude"
        type="number"
        value={coords[0]}
        onChange={(event) => {
          setCoords([Number(event.target.value), coords[1]]);
        }}
        style={{
          flex: 1,
          margin: "0 8pt",
        }}
      />
      <Button
        onClick={resetCoords}
        style={{ flex: "0 0 auto" }}
        color="secondary"
      >
        Reset
      </Button>
      <Button
        onClick={saveCoords}
        style={{ flex: "0 0 auto", marginLeft: "1rem" }}
        variant="contained"
        color="primary"
      >
        Save
      </Button>
      <Button
        variant="outlined"
        onClick={fetchAndRenderEditHistory}
        style={{ flex: "0 0 auto", marginLeft: "4rem" }}
        color="primary"
      >
          Edit History
      </Button>
    </div>
  );

  const mapDragged = (map: MapboxGl.Map) => {
    const { lng, lat } = map.getCenter();
    setCoords([lng, lat]);
  };
  const noLocation = () => {
    return coords[0] === 0 && coords[1] === 0;
  }
  const displayImageGalleryOrTextUI = () => {
    if(report.bot_generated) return (<MessageComponent data={report}></MessageComponent>);
    else if(report.media && report.media.length > 0) return imageGallery; 
    else return;
  }

  //let polyCenter: number[] = [0,0]
  //if (report.point?.type !== "Point") {
    //polyCenter = report.point?.getCenter()
  //}

  if (!noLocation()) {
    return (
      <div style={{ display: "flex" }}>
        {displayImageGalleryOrTextUI()}
        <div
          style={{
            height: "600px",
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          {originalCoords[0] != 0 || originalCoords[1] != 0 &&
          <Map
            // eslint-disable-next-line
            style="mapbox://styles/mapbox/satellite-streets-v9"
            containerStyle={{ flex: "1 1 0" }}
            center={coords}
            onRender={mapDragged}
            onStyleLoad={(map: MapboxGl.Map) => {
              map.resize();
              map.addControl(geocoder);
            }}
            //zoom={[13]}
          >
              <img
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                height: "40px",
                width: "auto",
                transform: "translate(-50%, -100%)",
                zIndex: 1000,
              }}
              src="/location-marker-orange.png"
              alt="Location"
            />
            <Marker coordinates={originalCoords} anchor="bottom">
              <img
                style={{
                  height: "40px",
                  width: "auto",
                }}
                src="/location-marker-blue.png"
                alt="Original Location"
              />
            </Marker>
            
            {report.photo_metadata_point && (
              <Marker
                coordinates={report.photo_metadata_point.coordinates}
                anchor="bottom"
              >
                <img
                  style={{
                    height: "40px",
                    width: "auto",
                  }}
                  src="/location-marker-blue.png"
                  alt="Location from photo metadata"
                />
              </Marker>
            )}
          </Map>
  }
          {originalCoords[0] == 0 && originalCoords[1] == 0 &&
          <Map
            // eslint-disable-next-line
            style="mapbox://styles/mapbox/satellite-streets-v9"
            containerStyle={{ flex: "1 1 0" }}
            center={center(report.point!).geometry.coordinates as [number, number]}
            //onRender={mapDragged}
            zoom={[16]}
            onStyleLoad={(map: MapboxGl.Map) => {
              map.resize();
              map.addControl(geocoder);
            }}
          >

          <Layer
          type="fill"
          paint={{
            "fill-color": "red",
            "fill-opacity": 0.6,
          }}
          >
          <Feature
            coordinates={report.point!.coordinates}
          />
          </Layer>
            
          </Map>
  }
          {bottomBar}
        </div>
      <Dialog open={toggleHistory}
                onClose={(e) => setToggleHistory(false)}>
              <DialogTitle>
                  Edit History
              </DialogTitle>
              <DialogContent>
                  {editHistoryState.map((element) => {
                      if (element.length >= 80) {
                          return (
                          <Accordion key= {editHistoryState.indexOf(element).toString()}>
                              <AccordionSummary>
                                  <Typography>{element.substring(0, 80) + "..."}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                              <Typography>
                                  {element}
                              </Typography>
                              </AccordionDetails>
                          </Accordion>
                          )} else {
                          return (
                          <Accordion key= {(editHistoryState.indexOf(element) * Date.now()).toString()}>
                              <AccordionSummary>
                                  <Typography>{element}</Typography>
                              </AccordionSummary>
                          </Accordion>
                          )}
                  })}
              </DialogContent>
          </Dialog>
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex" }}>
        {displayImageGalleryOrTextUI()}
        <div
          style={{
            height: "600px",
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <Map
            // eslint-disable-next-line
            style="mapbox://styles/mapbox/satellite-streets-v9"
            containerStyle={{ flex: "1 1 0" }}
            fitBounds={[[-89.9,89.9],[89.9,-89.9]]}
            fitBoundsOptions={{linear:true}}
            onRender={mapDragged}
            onStyleLoad={(map: MapboxGl.Map) => {
              map.resize();
              map.addControl(geocoder);
            }}
          >
            <img
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                height: "40px",
                width: "auto",
                transform: "translate(-50%, -100%)",
                zIndex: 1000,
              }}
              src="/location-marker-orange.png"
              alt="Location"
            />
            {report.photo_metadata_point && (
              <Marker
                coordinates={report.photo_metadata_point.coordinates}
                anchor="bottom"
              >
                <img
                  style={{
                    height: "40px",
                    width: "auto",
                  }}
                  src="/location-marker-blue.png"
                  alt="Location from photo metadata"
                />
              </Marker>
            )}
          </Map>
          {bottomBar}
        </div>
            <Dialog open={toggleHistory}
                  onClose={(e) => setToggleHistory(false)}>
                <DialogTitle>
                    Edit History
                </DialogTitle>
                <DialogContent>
                    {editHistoryState.map((element) => {
                        if (element.length >= 80) {
                            return (
                            <Accordion key= {editHistoryState.indexOf(element).toString()}>
                                <AccordionSummary>
                                    <Typography>{element.substring(0, 80) + "..."}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    {element}
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                            )} else {
                            return (
                            <Accordion key= {(editHistoryState.indexOf(element) * Date.now()).toString()}>
                                <AccordionSummary>
                                    <Typography>{element}</Typography>
                                </AccordionSummary>
                            </Accordion>
                            )}
                    })}
                </DialogContent>
            </Dialog>
        </div>
    );
  } 
};
