import React from "react";
import moment from "moment";
import "./message.css";

export default function Message(props: any) {
  const {
    data,
    isMine,
    startsSequence,
    endsSequence,
    showTimestamp,
    isSource,
    isFullTranscriptSource,
    ftEnd
  } = props;
  let elementID: string;
  elementID = isSource === true ? "source" : "not-source";
  if (isFullTranscriptSource) elementID = "source-FT";
  if (ftEnd) elementID = "ftEnd";


  const friendlyTimestamp = moment.unix(data.timestamp).format("llll");
  const smallTimestamp = moment.unix(data.timestamp).format("LT");
  if (startsSequence && !endsSequence) {
    return (
      <div
        className={[
          "message",
          `${isMine ? "mine" : ""}`,
          `${startsSequence ? "start" : ""}`,
          `${endsSequence ? "end" : ""}`,
          `${(isSource || isFullTranscriptSource) ? "sourceIndicator" : ""}`,
        ].join(" ")}
        id={elementID}
      >
        {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}
        <div className="name">{data.author}</div>
        <div className="bubble-container">
          <div className="bubble" title={friendlyTimestamp}>
            {data.message}
          </div>
        </div>
      </div>
    );
  } else if (startsSequence && endsSequence) {
    return (
      <div
        className={[
          "message",
          `${isMine ? "mine" : ""}`,
          `${startsSequence ? "start" : ""}`,
          `${endsSequence ? "end" : ""}`,
          `${(isSource || isFullTranscriptSource) ? "sourceIndicator" : ""}`,
        ].join(" ")}
        id={elementID}
      >
        {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}
        <div className="name">{data.author}</div>
        <div className="bubble-container">
          <div className="bubble" title={friendlyTimestamp}>
            {data.message}
          </div>
        </div>
        <div className="smallTimestamp">Sent {smallTimestamp}</div>
      </div>
    );
  } else if (endsSequence && !startsSequence) {
    return (
      <div
        className={[
          "message",
          `${isMine ? "mine" : ""}`,
          `${startsSequence ? "start" : ""}`,
          `${endsSequence ? "end" : ""}`,
          `${(isSource || isFullTranscriptSource) ? "sourceIndicator" : ""}`,
        ].join(" ")}
        id={elementID}
      >
        {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

        <div className="bubble-container">
          <div className="bubble" title={friendlyTimestamp}>
            {data.message}
          </div>
        </div>
        <div className="smallTimestamp">Sent {smallTimestamp}</div>
      </div>
    );
  } else {
    return (
      <div
        className={[
          "message",
          `${isMine ? "mine" : ""}`,
          `${startsSequence ? "start" : ""}`,
          `${endsSequence ? "end" : ""}`,
          `${(isSource || isFullTranscriptSource) ? "sourceIndicator" : ""}`,
        ].join(" ")}
        id={elementID}
      >
        {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

        <div className="bubble-container">
          <div className="bubble" title={friendlyTimestamp}>
            {data.message}
          </div>
        </div>
      </div>
    );
  }
}
