import React from "react";
import TimeRangeSlider from "../common/time-range-slider";

/*
 * TimeRangeFilter -> TimeRangeSlider -> RangeSlider
 */

const TimeRangeFilter = ({ filter, setFilter, isAnimatable, toggleAnimation, hideTimeTitle }) => (
    <TimeRangeSlider
        id={filter.id}
        domain={filter.domain}
        value={filter.value}
        plotType={filter.plotType}
        lineChart={filter.lineChart}
        step={filter.step}
        speed={filter.speed}
        histogram={filter.enlarged ? filter.enlargedHistogram : filter.histogram}
        onChange={setFilter}
        toggleAnimation={toggleAnimation}
        isAnimatable={isAnimatable}
        isEnlarged={filter.enlarged}
        hideTimeTitle={hideTimeTitle}
    />
);

export default TimeRangeFilter;
