import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Input,
  Checkbox,
  ListItemText,
  CircularProgress,
  FormLabel,
  Snackbar,
  SnackbarContent,
  IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import {
  getAssetTypes,
  addAsset,
  getAllTeams,
  getAllContainers
} from "../types/api/admin";
import { Form, FormContainer, Section, TextInput } from "./styled";
import { useThrow } from "../catch";
import { DisplayClient, ClientDisplayData, Team } from "../types/client";
import { getClientDisplayData } from "../types/api/admin";
import {AdminNewAsset, AllAssetConcerns, AssetConcern, AssetType} from "../types/case";
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';
import DrawControl from 'react-mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { MapboxAPIKey } from "../aamp/detailPanel";
import { LineString, MultiPolygon, Point, Polygon } from "geojson";
import { getContainerViewportFromId } from "../utils";
import { Container, ViewportBase } from "../types/geo";
import { AllReportTypes } from "../types/reports";

const Map = ReactMapboxGl({
  accessToken: MapboxAPIKey
});

class Value {
  name: string;
  keywords: string[];
  constructor(name: string, keywords: string[]) {
    this.name = name;
    this.keywords = keywords;
  }
}
class Field {
  name: string;
  values: Value[];
  constructor(name: string, values: Value[]) {
    this.name = name;
    this.values = values;
  }
}

export const CreateAsset = () => {
  const error = useThrow();

  const [loading, updateLoading] = useState<boolean>(true);
  const [clientData, updateClientData] = useState<DisplayClient[]>([]);
  const [id, updateID] = useState<string>("");
  const [name, updateName] = useState<string>("");
  const [containerDetails, updateContainerDetails] = useState<Container[]>([]);
  const [assetType, updateAssetType] = useState<string>("");
  const [team, updateTeam] = useState<string>("");
  const [description, updateDescription] = useState<string>("");
  const [concerns, updateConcerns] = useState<AssetConcern[]>([]);
  const [eventTypes, updateEventTypes] = useState<string[]>([]);
  const [monetaryValue, updateMonetaryValue] = useState<string>("");
  const [erpLink, updateERPLink] = useState<string>("");
  const [allAssetTypes, updateAllAssetTypes] = useState<AssetType[]>([]);
  const [allTeams, updateAllTeams] = useState<Team[]>([]);
  const [dynamicFields, updateDynamicFields] = useState<Field[]>([]);
  const [containerVal, updateContainerVal] = useState<string>("");
  const [clientVal, updateClientVal] = useState<string>("");
  const [assetTypeVal, updateAssetTypeVal] = useState<string>("");
  const [teamVal, updateTeamVal] = useState<string>("");
  const [viewportCoordinates, updateViewportCoordinates] = useState<[number, number]>([
    5.553763442738967, 
    9.086147534137456
  ]);
  const [viewportZoom, updateViewportZoom] = useState<[number]>([5.9214246566937865]);
  const [geometry, updateGeometry] = useState<Point | LineString | Polygon | MultiPolygon>({
    "type": "Point",
    "coordinates": [0, 0]
  });
  const [queryResults, updateQueryResults] = useState<any>([]);
  const [markerCoordinates, updateMarkerCoordinates] = useState<[number, number]>([0, 0])
  const [markerStyle, updateMarkerStyle] = useState<any>({});
  const [geocodeVal, updateGeocodeVal] = useState<string>("");

  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorString, setErrorString] = useState("");
  const [openSuccessSnackbar, setOpenSucessSnackbar] = useState(false);
  const [enableAdd, updateEnableAdd] = useState(false);

  let drawControlRef = useRef<DrawControl>(null);

  useEffect(() => {
    (async () => {
      try {
        const response: ClientDisplayData = await getClientDisplayData();
        updateClientData([...response.clientData]);
        updateLoading(false);
      } catch (err) {
        error(err);
        updateLoading(false);
      }
    })();
  }, [error]);

  useEffect(() => {
    (async () => {
      try {
        const response: AssetType[] = await getAssetTypes();
        updateAllAssetTypes(response);
        updateLoading(false);
      } catch (err) {
        error(err);
        updateLoading(false);
      }
    })();
  }, [error]);

  useEffect(() => {
    (async () => {
      try {
        const response: Team[] = await getAllTeams();
        updateAllTeams(response);
        updateLoading(false);
      } catch (err) {
        error(err);
        updateLoading(false);
      }
    })();
  }, [error]);

  useEffect(() => {
    (async () => {
      try {
        const response: Container[] = await getAllContainers();
        const responseDetailsArr: Container[] = [];
 
        response.forEach((fetchedContainer) => {
          responseDetailsArr.push(fetchedContainer);
        });
        updateContainerDetails(responseDetailsArr);
      } catch (err) {
        error(err);
      }
    })();
  }, [error]);

  if (loading) {
    // TODO: improve loading style
    return <CircularProgress style={{position: "absolute", margin: "auto", top: "0", left: "0", right: "0", bottom: "0"}}/>;
  }

  const displaySuccessSnackbar = () => {
    setOpenSucessSnackbar(true);
  };
  const handleCloseSuccessSnackbar = () => {
    setOpenSucessSnackbar(false);
  };

  const displayErrorSnackbar = () => {
    setOpenErrorSnackbar(true);
  };
  const handleCloseErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  };

  const handleAddField = (e: any) => {
    e.preventDefault();
    const inputState = new Field("", []);
    updateDynamicFields((prev) => [...prev, inputState]);
  };

  const onChangeField = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    event.persist();
    updateDynamicFields((prev) => {
      const change = prev.map((item, i) => {
        if (i !== index) {
          return item;
        }
        item.name = event.target.value;
        return item;
      });
      return change;
    });
  };

  const handleRemoveField = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    fieldIndex: number
  ) => {
    e.preventDefault();
    updateDynamicFields((prev) =>
      prev.filter((item) => item !== prev[fieldIndex])
    );
  };

  const handleAddValue = (field: Field, e: any) => {
    e.preventDefault();
    const inputState = new Value("", []);
    let tempValues = field.values;
    field.values = [...tempValues, inputState];
    updateDynamicFields((prev) => [...prev]);
  };

  const onChangeValue = (
    field: Field,
    value: Value,
    valueIndex: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    event.persist();
    let tempValues = field.values;

    field.values = tempValues.map((itemValue, i) => {
      if (i !== valueIndex) {
        return itemValue;
      }
      value.name = event.target.value;
      return value;
    });
    updateDynamicFields((prev) => [...prev]);
  };

  const handleRemoveValue = (
    field: Field,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    valueIndex: number
  ) => {
    e.preventDefault();
    let tempValues = field.values;
    let change = tempValues.filter(
      (itemValue) => itemValue !== tempValues[valueIndex]
    );
    field.values = change;
    updateDynamicFields((prev) => [...prev]);
  };

  const onDrawCreate = () => {
    // @ts-ignore
    const featureObject = drawControlRef.current?.draw?.getAll();
    updateGeometry(featureObject.features[0].geometry);

    if (featureObject.features[0].geometry.type === "Point"){
      updateViewportCoordinates(featureObject.features[0].geometry.coordinates);
    } else if (featureObject.features[0].geometry.type === "LineString"){
      updateViewportCoordinates(featureObject.features[0].geometry.coordinates[0]);
    } else if (featureObject.features[0].geometry.type === "Polygon"){
      updateViewportCoordinates(featureObject.features[0].geometry.coordinates[0][0]);
    }
  }

  const onDrawUpdate = () => {
    // @ts-ignore
    const featureObject = drawControlRef.current?.draw?.getAll();
    updateGeometry(featureObject.features[0].geometry);
    if (featureObject.features[0].geometry.type === "Point"){
      updateViewportCoordinates(featureObject.features[0].geometry.coordinates);
    } else if (featureObject.features[0].geometry.type === "LineString"){
      updateViewportCoordinates(featureObject.features[0].geometry.coordinates[0]);
    } else if (featureObject.features[0].geometry.type === "Polygon"){
      updateViewportCoordinates(featureObject.features[0].geometry.coordinates[0][0]);
    }
  }

  const addPoint = () => {
    // @ts-ignore
    drawControlRef.current?.draw?.add({
      "type": "Point",
      "coordinates": markerCoordinates
    });
    updateGeometry({
      "type": "Point",
      "coordinates": markerCoordinates
    })
  }

  const geocode = (geocodingQuery: string) => {
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${geocodingQuery}.json?access_token=${MapboxAPIKey}`
    )
      .then((resp) => resp)
      .then((resp) => resp.json())
      .then((response) => {
        if (response.message === "Not Found"){
          updateQueryResults([]);
        } else {
          updateQueryResults(response.features);
        }
      });
  }

  const resetForm = () => {
    updateLoading(false);
    updateID("");
    updateName("");
    updateAssetType("");
    updateTeam("");
    updateDescription("");
    updateConcerns([]);
    updateEventTypes([]);
    updateMonetaryValue("");
    updateERPLink("");
    updateContainerVal("");
    updateClientVal("");
    updateAssetTypeVal("");
    updateTeamVal("");
    updateGeocodeVal("");
    // @ts-ignore
    drawControlRef.current?.draw?.deleteAll();
    updateGeometry({
      "type": "Point",
      "coordinates": [0, 0]
    });
    updateDynamicFields([
      new Field("Manager", [new Value("John Doe", [])]),
      new Field("Inventory", [new Value("1000", [])]),
    ]);
    updateMarkerStyle({});
    updateMarkerCoordinates([0, 0]);
    updateQueryResults([]);
  };

  const submit = () => {
    updateLoading(true);

    const DFArr = JSON.parse(JSON.stringify(dynamicFields));
    const finalDF = JSON.parse(JSON.stringify({...DFArr}));

    if (name && assetType && id && description && geometry !== {"type": "Point",
    "coordinates": [0, 0]} && eventTypes && monetaryValue) {
      const addAssetObject : AdminNewAsset = {
        name: name,
        asset_type: assetType,
        client_id: parseInt(id),
        team_id: parseInt(team),
        description: description,
        geography: geometry,
        concerns: concerns,
        relevant_event_types: eventTypes,
        value: parseFloat(monetaryValue),
        details: finalDF,
        erp_link: erpLink,
        imaginary: false,
        arcturus_gen: true
      };

      addAsset(addAssetObject).then(() => {
          resetForm();
          updateLoading(false);
          displaySuccessSnackbar();
        })
        .catch((error) => {
          updateLoading(false);
          setErrorString(error.message);
          displayErrorSnackbar();
        });
    } else {
      error({ message: "Please fill all required fields." });
      updateLoading(false);
    }
  };

  return (
    <FormContainer>
      <Form noValidate autoComplete="off">
        <div style={{display: "flex", flexDirection: "row"}}>
          <Section>
            <TextInput
              id="name"
              label="Name (required)"
              variant="outlined"
              value={name}
              onChange={(e) => updateName(e.target.value)}
            />
            <Autocomplete
              inputValue={clientVal}
              disableClearable
              id="client-name-input"
              options={clientData}
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label="Client Name (required)"
                  variant="outlined"
                />
              )}
              onChange={(event, value) => {
                updateID(value?.id.toString() as string);
                updateClientVal(value?.name);
              }}
            />
            <Autocomplete
              inputValue={assetTypeVal}
              disableClearable
              id="asset-type-input"
              options={allAssetTypes}
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label="Asset Type (required)"
                  variant="outlined"
                />
              )}
              onChange={(event, value) => {
                updateAssetType(value?.name as string);
                updateDescription(value?.description as string);
                updateConcerns(value?.relevant_concerns as AssetConcern[]);
                updateEventTypes(value?.relevant_event_types);
                updateAssetTypeVal(value?.name);
              }}
            />
            <Autocomplete
              inputValue={containerVal}
              disableClearable
              id="container-input"
              options={containerDetails}
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label="Container"
                  variant="outlined"
                />
              )}
              onChange={(event, value) => {
                const viewportObj : ViewportBase = getContainerViewportFromId(value?.id, containerDetails);
                updateViewportCoordinates([viewportObj.longitude, viewportObj.latitude]);
                updateViewportZoom([viewportObj.zoom]);
                updateContainerVal(value.name);
              }}
            />
            <Autocomplete
              inputValue={teamVal}
              disableClearable
              id="team-name-input"
              options={allTeams}
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label="Team Name (required)"
                  variant="outlined"
                />
              )}
              onChange={(event, value) => {
                updateTeam(value?.id.toString() as string);
                updateTeamVal(value.name);
              }}
            />
            <TextInput
              id="description"
              label="Description (required)"
              variant="outlined"
              value={description}
              onChange={(e) => updateDescription(e.target.value)}
            />
            <FormControl variant="outlined" style={{maxWidth: 400}}>
              <InputLabel id="concerns-label">Concerns</InputLabel>
              <Select
                labelId="concerns-label"
                id="concerns"
                multiple
                value={concerns}
                onChange={(event) =>
                  updateConcerns(event.target.value as AssetConcern[])
                }
                input={<Input />}
                renderValue={(selected) => (selected as string[]).join(", ")}
              >
                {AllAssetConcerns.map((name) => 
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={concerns.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{marginTop: 15, marginBottom: 15, maxWidth: 400}}>
              <InputLabel id="events-label">Event Types</InputLabel>
              <Select
                labelId="events-label"
                id="events"
                multiple
                value={eventTypes}
                onChange={(event) =>
                  updateEventTypes(event.target.value as string[])
                }
                input={<Input />}
                renderValue={(selected) => (selected as string[]).join(", ")}
              >
                {AllReportTypes.map((name) => 
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={eventTypes.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <TextInput
              id="value"
              label="Monetary Value ($) (required)"
              variant="outlined"
              value={monetaryValue}
              onChange={(e) => updateMonetaryValue(e.target.value as string)}
            />
            <FormControl component="fieldset" style={{ margin: "20px 0 10px" }}>
              <FormLabel component="legend" style={{ margin: "10px 0" }}>
                Edit Extra Data
              </FormLabel>

              {dynamicFields.map((currentField, fieldIndex) => (
                <div className="row mt-3" key={`item-${fieldIndex}`}>
                  <div className="col">
                    <TextInput
                      id="name"
                      label="Field Name (eg. victim, agressor, etc)"
                      variant="outlined"
                      value={currentField.name}
                      onChange={(e) => onChangeField(fieldIndex, e)}
                    />
                    <Button
                      style={{
                        top: "23px",
                        left: "5px",
                        maxWidth: "30px",
                        minWidth: "30px",
                        maxHeight: "30px",
                        minHeight: "30px",
                      }}
                      variant="text"
                      color="primary"
                      onClick={(e) => handleRemoveField(e, fieldIndex)}
                    >
                      X
                    </Button>
                  </div>
                  <div
                    className="col"
                    style={{ position: "relative", left: "100px" }}
                  >
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                      Values
                    </FormLabel>
                  </div>

                  <div className="">
                    {currentField.values.map((currentValue, valueIndex) => (
                      <div className="" key={`value-${valueIndex}`}>
                        <div className="row mt-3">
                          <div className="col">
                            <TextInput
                              style={{ maxWidth: "300px", left: "100px" }}
                              id="name"
                              label="Value (eg. Civilian, Company, etc)"
                              variant="outlined"
                              value={currentValue.name}
                              onChange={(e) =>
                                onChangeValue(
                                  currentField,
                                  currentValue,
                                  valueIndex,
                                  e
                                )
                              }
                            />
                            <Button
                              style={{
                                top: "23px",
                                left: "105px",
                                maxWidth: "30px",
                                minWidth: "30px",
                                maxHeight: "30px",
                                minHeight: "30px",
                              }}
                              variant="text"
                              color="primary"
                              onClick={(e) =>
                                handleRemoveValue(currentField, e, valueIndex)
                              }
                            >
                              X
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ margin: "10px 0", left: "100px", minWidth: "300px" }}
                    onClick={(e) => handleAddValue(currentField, e)}
                  >
                    Add Value
                  </Button>
                </div>
              ))}

              <Button
                variant="contained"
                color="primary"
                style={{ margin: "10px 0", maxWidth: "400px" }}
                onClick={handleAddField}
              >
                Add Field
              </Button>
            </FormControl>
            <TextInput
              id="erp-link"
              label="ERP Link"
              variant="outlined"
              value={erpLink}
              onChange={(e) => updateERPLink(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={submit}>
              Create Asset
            </Button>
          </Section>
          <div style={{display: "flex", flexDirection: "column", marginLeft: 30}}>
            <div style={{display: "flex", flexDirection: "row", position: "sticky", top: 10}}>
            <Autocomplete
              inputValue={geocodeVal}
              disableClearable
              id="client-name-input"
              options={queryResults}
              // @ts-ignore
              getOptionLabel={(option) => option.place_name}
              // @ts-ignore
              getOptionSelected={(option, value) => option.place_name === value.place_name}
              style={{ width: 200, position: "sticky", top: 10 }}
              renderInput={(params) => (
                <TextInput
                  {...params}
                  id="geocoderSearch"
                  label="Search Location on Map"
                  variant="outlined"
                  style={{width: 300, position: "sticky", top: 10}}
                  onChange={(e) => {
                    updateGeocodeVal(e.target.value)
                    geocode(e.target.value);
                  }}
                />
              )}
              onChange={(event, value) => {
                // @ts-ignore
                updateGeocodeVal(value.place_name);
                // @ts-ignore
                updateViewportCoordinates(value.center as [number, number]);
                // @ts-ignore
                updateMarkerCoordinates(value.center as [number, number]);
                updateEnableAdd(true);
                updateMarkerStyle({
                  width: 10,
                  height: 10, 
                  borderRadius: 5,
                  backgroundColor: "red"
                });
                // @ts-ignore
                if (value.place_type[0] === "address"){
                  updateViewportZoom([13]);
                } else {
                  updateViewportZoom([7]);
                }
              }}
            />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={addPoint} 
              disabled={!enableAdd}
              style={{
                marginLeft: 120,
                position: "sticky",
                width: 100,
                height: 55,
                marginTop: 10
              }}
            >
              Add Point
            </Button>
            </div>
            <Map
              // eslint-disable-next-line react/style-prop-object
              style="mapbox://styles/nbowden/ckrl2x11p0w1t17qvh33d6u3y"
              containerStyle={{
                display: "flex",
                width: "65vh",
                height: "80vh",
                position: "sticky",
                right: 10,
                top: 80,
                bottom: 10,
                borderRadius: 10
              }}
              center={viewportCoordinates}
              zoom={viewportZoom}
            >
              <Marker
                coordinates= {markerCoordinates}
                style={markerStyle}
                anchor="bottom"
              />
              {<DrawControl 
                ref={drawControlRef}
                onDrawCreate={onDrawCreate}
                onDrawUpdate={onDrawUpdate}
                controls={{
                  "point": true,
                  "line_string": true, 
                  "polygon": true, 
                  "trash": true, 
                  "combine_features": false,
                  "uncombine_features": false
                }}
              />}
            </Map>
          </div>  
        </div>
      </Form>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSuccessSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSuccessSnackbar}
        >
          <SnackbarContent
            style={{
              backgroundColor: "#20d420",
              color: "white",
            }}
            message="Form Submitted Successfully!"
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSuccessSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
      </div>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openErrorSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseErrorSnackbar}
        >
          <SnackbarContent
            style={{
              backgroundColor: "red",
              color: "white",
            }}
            message={errorString}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseErrorSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
      </div>
    </FormContainer>
  );
};
