import { PromiseOperation } from "..";
import { ConnectionError, ErrorResponse } from "../../types/api";
import { publicInfoActions } from ".";
import { cuserActions } from "../cuser";
import { PublicReport } from "../../types/reports";
import {adminGetReportsInContainer} from "../../types/api/admin";
// import * as public from "../../api/public";

export const fetchPublicReportsFromContainer: PromiseOperation<void> = (container_id?: number) => async (
    dispatch,
    getState
) => {
    dispatch(publicInfoActions.setLoading({ publicReports: true }));
    try {
        const {
            public_reports: reports,
            container_id: new_container_id
        }: {
            container_id: number;
            public_reports: PublicReport[];
        } = await adminGetReportsInContainer(container_id);

        dispatch(publicInfoActions.loadReports(reports));
        // FIXME ? This is important for first sign in (no container set), will be redundantly called on reloads
        dispatch(cuserActions.updateCurrentContainer(new_container_id));
        if (new_container_id) {
            console.log("Current Container ID updated: " + new_container_id);
        } else {
            console.warn("Failed to update Current Container ID: " + new_container_id);
        }
        dispatch(publicInfoActions.setLoading({ publicReports: false }));
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response when fetching reports from container: " + error.message);
        } else if (error instanceof ConnectionError) {
            console.log("Connection error when fetching reports from container: " + error.message);
        } else {
            console.error("Unknown error occurred in fetchPublicReportsFromContainer: ", error);
        }
        dispatch(publicInfoActions.setLoading({ publicReports: false }));
    }
    return;
};
