import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import styled from "styled-components";
import classnames from "classnames";

import { ButtonGroup, Button } from "../styled-components";
import { Play, Reset, Pause } from "../icons";

const StyledAnimationControls = styled.div`
    display: flex;
    margin-right: 12px;

    &.disabled {
        opacity: 0.4;
        pointer-events: none;
    }
`;

const IconButton = styled(Button)`
    padding: 6px 4px;
    svg {
        margin: 0 6px;
    }
`;

function nop() {}
const DEFAULT_BUTTON_HEIGHT = "18px";

const AnimationPlaybacks = ({
    isAnimatable,
    isAnimating,
    buttonStyle,
    pauseAnimation = nop,
    resetAnimation = nop,
    startAnimation = nop,
    buttonHeight = DEFAULT_BUTTON_HEIGHT
}) => {
    const themeContext = useContext(ThemeContext);

    const btnStyle = buttonStyle ? { [buttonStyle]: true } : {};
    return (
        <StyledAnimationControls
            className={classnames("time-range-slider__control", {
                disabled: !isAnimatable
            })}
        >
            <ButtonGroup>
                <IconButton className="playback-control-button" {...btnStyle} onClick={resetAnimation}>
                    <Reset height={buttonHeight} style={{ fill: themeContext.orion.scheme.innerIcon }} />
                </IconButton>
                <IconButton
                    {...btnStyle}
                    className={classnames("playback-control-button", { active: isAnimating })}
                    onClick={isAnimating ? pauseAnimation : startAnimation}
                >
                    {isAnimating ? (
                        <Pause height={buttonHeight} style={{ fill: themeContext.orion.scheme.innerIcon }} />
                    ) : (
                        <Play height={buttonHeight} style={{ fill: themeContext.orion.scheme.innerIcon }} />
                    )}
                </IconButton>
            </ButtonGroup>
        </StyledAnimationControls>
    );
};

export default AnimationPlaybacks;
