import React, { useEffect, useState } from "react";
import Message from "./Message/message";
import moment from "moment";

import "./messageComponent.css";
import { TelegramMessageDetails } from "../../types/aamp";
import {
  getFullTranscriptMessages,
  getReportChatMessages,
} from "../../types/api/admin";
import { IconButton } from "@material-ui/core";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import OtherMedia from "./Message/otherMedia";
import ReplayIcon from '@material-ui/icons/Replay';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface CustomMessage {
  id: number;
  author: string;
  message: string;
  timestamp: number;
  type: string;
}

export default function MessageComponent({ data }: { data: any }) {
  const dataSecondName = data.last_name ? data.last_name : data.username;
  const reporter = "" + data.first_name + " " + dataSecondName;
  const sourceID: number = data.source_message;
  const [messages, setMessages] = useState<CustomMessage[]>([]);
  const reportID: number = data.id;
  const [refresh, updateRefresh] = useState<boolean>(false);
  const [chatName, setChatName] = useState<string>("");
  const [noMessages, setNoMessages] = useState<boolean>(false);

  const getTelegramMessages = async () => {
    try {
      const telegramMessages: TelegramMessageDetails[] =
        await getReportChatMessages(reportID, 5, 10);
      setChatName(telegramMessages[0].chat.title);
      let tempMessages: CustomMessage[] = [];
      telegramMessages.forEach((message) => {
        let currentMessage: CustomMessage;
        const messageSecondName = message.from.last_name ? message.from.last_name : message.from.username;
        if (message.text !== null && message.media === null) {
          currentMessage = {
            id: message.message_id,
            author: "" + message.from.first_name + " " + messageSecondName,
            message: message.text,
            timestamp: message.date,
            type: message.type,
          };
        } else if (message.media !== undefined && message.text === null) {
          currentMessage = {
            id: message.message_id,
            author: "" + message.from.first_name + " " + messageSecondName,
            message: message.media,
            timestamp: message.date,
            type: message.type,
          };
        } else if (message.media !== undefined && message.text !== null) {
          currentMessage = {
            id: message.message_id,
            author: "" + message.from.first_name + " " + messageSecondName,
            message: message.text + "|boundaryBetweenTextAndMedia|" + message.media,
            timestamp: message.date,
            type: "captioned " + message.type,
          };
        } else {
          currentMessage = {
            id: message.message_id,
            author: "" + message.from.first_name + " " + messageSecondName,
            message: "",
            timestamp: message.date,
            type: message.type,
          };
        }
        tempMessages = [...tempMessages, currentMessage];
      });
      tempMessages.sort((a, b) => {
        return a.id - b.id;
      });
      return tempMessages;
    } catch (err) {
      console.log("Could not get telegram messages");
    }
  };
  
  useEffect(() => {
    getMessages();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, refresh]);

  const getMessages = () => {
    let formerSource = document.getElementById("source");
    let formerFullTranscriptSource = document.getElementById("source-FT");
    if (formerSource !== null && formerFullTranscriptSource !== null) {
      formerSource.id = "";
      formerFullTranscriptSource.id = "";
    }
    getTelegramMessages().then((result) => {
      if (result !== undefined) {
        setMessages(result);
        getFullTranscript();
      } else {
        setNoMessages(true);
      }
    });
  };

  const renderMessages = () => {
    let i = 0;
    let messageCount = messages.length;
    let tempMessages = [];

    while (i < messageCount) {
      let previous = messages[i - 1];
      let current = messages[i];
      let next = messages[i + 1];
      let isMine = current.author === reporter;
      let currentMoment = moment.unix(current.timestamp);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;
      let isSource = false;
      let isFullTranscriptSource = false;

      if (previous) {
        let previousMoment = moment.unix(previous.timestamp);
        let previousDuration = moment.duration(
          currentMoment.diff(previousMoment)
        );
        prevBySameAuthor = previous.author === current.author;

        if (prevBySameAuthor && previousDuration.as("hours") < 1) {
          startsSequence = false;
        }

        if (previousDuration.as("hours") < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment.unix(next.timestamp);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.author === current.author;

        if (nextBySameAuthor && nextDuration.as("hours") < 1) {
          endsSequence = false;
        }
      }
      if (current.id === (sourceID-1)+1) isSource = true; 
      if (current.type === "text") {
        tempMessages.push(
          <Message
            key={i}
            isMine={isMine}
            startsSequence={startsSequence}
            endsSequence={endsSequence}
            showTimestamp={showTimestamp}
            data={current}
            isSource={isSource}
            isFullTranscriptSource={isFullTranscriptSource}
          />
        );
      } else {
        tempMessages.push(
          <OtherMedia
            key={i}
            isMine={isMine}
            startsSequence={startsSequence}
            endsSequence={endsSequence}
            showTimestamp={showTimestamp}
            data={current}
            isSource={isSource}
            isFullTranscriptSource={isFullTranscriptSource}
          />
        );
      }

      // Proceed to the next message.
      i += 1;
    }
    scrollToSource();
    return tempMessages;
  };

  // infinite scrolling transcript
  const [open, setOpen] = useState(false);
  const [fullTranscript, setFullTranscript] = useState<CustomMessage[]>([]);

  const getFullTranscriptTelegrams = async () => {
    try {
      const telegramMessages: TelegramMessageDetails[] =
        await getFullTranscriptMessages(reportID);
      setChatName(telegramMessages[0].chat.title);
      let tempMessages: CustomMessage[] = [];
      telegramMessages.forEach((message) => {
        let currentMessage: CustomMessage;
        const messageSecondName = message.from.last_name ? message.from.last_name : message.from.username;
        if (message.text !== null && message.media === null) {
          currentMessage = {
            id: message.message_id,
            author: "" + message.from.first_name + " " + messageSecondName,
            message: message.text,
            timestamp: message.date,
            type: message.type,
          };
        } else if (message.media !== undefined && message.text === null) {
          currentMessage = {
            id: message.message_id,
            author: "" + message.from.first_name + " " + messageSecondName,
            message: message.media,
            timestamp: message.date,
            type: message.type,
          };
        } else if (message.media !== undefined && message.text !== null) {
          currentMessage = {
            id: message.message_id,
            author: "" + message.from.first_name + " " + messageSecondName,
            message: message.text + "|boundaryBetweenTextAndMedia|" + message.media,
            timestamp: message.date,
            type: "captioned " + message.type,
          };
        } else {
          currentMessage = {
            id: message.message_id,
            author: "" + message.from.first_name + " " + messageSecondName,
            message: "",
            timestamp: message.date,
            type: message.type,
          };
        }
        tempMessages = [...tempMessages, currentMessage];
      });
      tempMessages.sort((a, b) => {
        return a.id - b.id;
      });
      return tempMessages;
    } catch (err) {
      console.log("Could not get telegram messages");
    }
  };
  const getFullTranscript = () => {
    getFullTranscriptTelegrams().then((result) => {
      if (result !== undefined) setFullTranscript(result);
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderFullTranscript = () => {
    let i = 0;
    let messageCount = fullTranscript.length;
    let tempMessages = [];

    while (i < messageCount) {
      let previous = fullTranscript[i - 1];
      let current = fullTranscript[i];
      let next = fullTranscript[i + 1];
      let isMine = current.author === "";
      let currentMoment = moment.unix(current.timestamp);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;
      let isSource = false;
      let isFullTranscriptSource = false;

      if (previous) {
        let previousMoment = moment.unix(previous.timestamp);
        let previousDuration = moment.duration(
          currentMoment.diff(previousMoment)
        );
        prevBySameAuthor = previous.author === current.author;

        if (prevBySameAuthor && previousDuration.as("hours") < 1) {
          startsSequence = false;
        }

        if (previousDuration.as("hours") < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment.unix(next.timestamp);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.author === current.author;

        if (nextBySameAuthor && nextDuration.as("hours") < 1) {
          endsSequence = false;
        }
      }
      
      if (current.id === (sourceID-1)+1) isFullTranscriptSource = true;
      // target message is always 6th message of messages array
      // so we want to set that same message as the source in the full transcript as well

      if (current.type === "text") {
        tempMessages.push(
          <Message
            key={i}
            isMine={isMine}
            startsSequence={startsSequence}
            endsSequence={endsSequence}
            showTimestamp={showTimestamp}
            data={current}
            isSource={isSource}
            isFullTranscriptSource={isFullTranscriptSource}
          />
        );
      } else {
        tempMessages.push(
          <OtherMedia
            key={i}
            isMine={isMine}
            startsSequence={startsSequence}
            endsSequence={endsSequence}
            showTimestamp={showTimestamp}
            data={current}
            isSource={isSource}
            isFullTranscriptSource={isFullTranscriptSource}
          />
        );
      }

      // Proceed to the next message.
      i += 1;
    }
    scrollToFullTranscriptSource();
    return tempMessages;
  };

  const scrollToSource = () => {
    let target = document.getElementById("source");
    if (target !== null && target.parentElement !== null) {
      target.parentElement.scrollTop = target.offsetTop;
    }
  };
  const scrollToFullTranscriptSource = () => {
    let target = document.getElementById("source-FT");
    if (target !== null && target.parentElement !== null) {
      target.parentElement.scrollTop = target.offsetTop;
    }
  };

  const refreshTextUI = () => {
    getMessages();
    updateRefresh(!refresh);
  }

  if (!noMessages) {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="messageHeader">
              <div className="transcript-button">
                <IconButton size="small" onClick={handleClickOpen}>
                  <FullscreenIcon color="secondary" />
                </IconButton>
                <IconButton size="small" className="refresh-button" onClick={refreshTextUI}>
                      <ReplayIcon color="secondary" />
                    </IconButton>
              </div>
              <div className="container">
                <div className="chatTitle">{chatName}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="messenger">
              <div className="scrollable-content">{renderMessages()}</div>
            </div>
          </div>
        </div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <div className="container">
              <div className="row">
                <div className="messageHeader">
                  <div className="transcript-button">
                    <IconButton size="small" onClick={handleClose}>
                      <FullscreenExitIcon color="secondary" />
                    </IconButton>
                    <IconButton size="small" className="refresh-button" onClick={refreshTextUI}>
                      <ReplayIcon color="secondary" />
                    </IconButton>
                  </div>
                  <div className="container">
                    <div className="chatTitle">{chatName}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="messenger">
                  <div className="scrollable-content">
                    {renderFullTranscript()}
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  } else {
    return (
      <div className="container">
        <div className="row">
          <div className="messageHeader">
            <div className="container">
              <div className="chatTitle-null">This report has no messages.</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="messenger">
            <div className="scrollable-content"></div>
          </div>
        </div>
      </div>
    );
  }
}
