import { PolygonLayer, GeoJsonLayer, IconLayer, PathLayer, TextLayer } from "@deck.gl/layers";
import chroma from "chroma-js";
import moment from "moment";
import store from "../../state/store";
import { visualizationsActions } from "../../state/visualizations";
import { EditableGeoJsonLayer } from "@nebula.gl/layers";
import { DrawLineStringMode, ViewMode } from "@nebula.gl/edit-modes";
import { getMatch } from "./utils";
import { filterGridByRiskIndex, RISK_INDEX_COLORS } from "../../visualizations/utils";
import { ScenegraphLayer } from "@deck.gl/mesh-layers";
import { GLTFLoader } from "@loaders.gl/gltf";
import { registerLoaders } from "@loaders.gl/core";
import { getScenegraph, getScenegraphSize, getAssetIcon, getScenarioIcon } from "./menu-layers";
import * as d3 from "d3-geo";

var smooth = require("chaikin-smooth");

export default function renderLayers({
     data: { gridData, zoneData, safeRouteData, originData, destinationData },
     // data: { gridData, safeRouteData, originData, destinationData },
    filter: { day, timeWindow, selectedRoute, riskIndexFilter },
    visible,
    onClick,
    source,
    allDevices,
    showText,
    showAssets,
    assetData,
    showAssetMarkers,
    riskData,
    showRisks,
    selectedAsset,
    selectedRisk,
    selectedScenario,
    addRisk,
    changeGeo
}) {
    const scale = chroma.scale(RISK_INDEX_COLORS);
    // var zoneScale = chroma.scale(["#5A1846", "#900C3F", "#C70039"].reverse());

    let clientDevicesText = [];
    registerLoaders(GLTFLoader);

    const pointsEqual = (point1,point2) => {
        return (Math.abs(point1.coordinates[0] - point2.coordinates[0]) < 0.0005 &&
            Math.abs(point1.coordinates[1] - point2.coordinates[1]) < 0.0005)
    }
    for(let device of allDevices) {
            let sameLocation = clientDevicesText.findIndex((d) => pointsEqual(d.point, device.point));
            if(sameLocation !== -1) {
                clientDevicesText[sameLocation] = 
                    {...clientDevicesText[sameLocation],
                        user_name: clientDevicesText[sameLocation].user_name + "\n" + device.user_name};
            } else clientDevicesText.push(device);
    }
    
    let layers = [];

    // for (let x = 0; x < 7; x++) {
    //     layers.push(
    //         new PolygonLayer({
    //             id: `hotspot-zone-${x}`,
    //             data: zoneData.filter((d) => moment(d.properties.ra_date).weekday() === x),
    //             getPolygon: (d) => d.geometry.coordinates,
    //             opacity: 0.1,
    //             visible: visible && x === day,
    //             pickable: true,
    //             stroked: true,
    //             extruded: false,
    //             filled: true,
    //             getFillColor: (d) => zoneScale(d.properties.risk_score_pct).rgb(),
    //             //onClick, (commenting this out blocks hotspot tooltips, which is the desired behavior)
    //             lineWidthUnits: "pixels",
    //             getLineColor: [255, 0, 0],
    //             getLineWidth: 1
    //         })
    //     );
    // }
    // // @ MIKELEV crime grid render layer, note that gridData is equal to "response.data.features"
    // for (let x = 0; x < 7; x++) {
    //     layers.push(
    //         new PolygonLayer({
    //             id: `hotspot-grid-${x}`,
    //             data: gridData.filter((d) => moment(d.properties.ra_date).weekday() === x),
    //             getPolygon: (d) => d.geometry.coordinates,
    //             opacity: 0.3,
    //             visible: x === day,
    //             pickable: true,
    //             stroked: true,
    //             extruded: false,
    //             filled: true,
    //             getFillColor: (d) => scale(d.properties.risk_score_pct).rgb(),
    //             onClick,
    //             lineWidthUnits: "pixels",
    //             getLineColor: [255, 0, 0],
    //             lineWidthScale: 12,
    //             getLineWidth: (d) => d.properties.risk_score_pct - 0.75
    //         })
    //     );
    // }

    // let count = 0;
    // layers.push(
    //     new PolygonLayer({
    //         id: `hotspot-grid-${1}`,
    //         data: gridData.filter((d) => {
    //             // count++;
    //             // const id = Number(d.properties.id);
    //             // return id >= 379346 && id <= 379346 + 100
    //             if (moment(d.properties.date_time).local().day() === 2 && moment(d.properties.date_time).local().hour() === 4) {
    //                 count++;
    //                 return true;
    //             }
    //             return false;
    //         }),
    //         getPolygon: (d) => d.geometry.coordinates,
    //         opacity: 0.1,
    //         visible: true,
    //         pickable: true,
    //         stroked: true,
    //         extruded: false,
    //         filled: true,
    //         getFillColor: (d) => scale(Number(d.properties.risk_score)).rgb(),
    //         onClick,
    //         lineWidthUnits: "pixels",
    //         getLineColor: [255, 0, 0],
    //         lineWidthScale: 12,
    //         getLineWidth: (d) => Number(d.properties.risk_score) - 0.75
    //     })
    // );
    // console.log("POLYGON COUNT: " + count);

    if (gridData.length !== 0) {
        layers.push(
            new PolygonLayer({
                id: `hotspot-grid-${1}`,
                data: filterGridByRiskIndex(gridData[day][timeWindow], riskIndexFilter),
                getPolygon: (d) => d.geometry.coordinates,
                opacity: 0.1,
                visible: true,
                pickable: true,
                stroked: true,
                extruded: false,
                filled: true,
                getFillColor: (d) => scale(Number(d.properties.risk_score * 1.6667)).rgb(),
                onClick,
                lineWidthUnits: "pixels",
                getLineColor: [255, 0, 0],
                lineWidthScale: 12,
                getLineWidth: (d) => d.properties.risk_index_tmp === 5 ? 0.25 : d.properties.risk_index_tmp === 4 ? 0.1 : 0
            })
        );
    }


    safeRouteData.forEach((route, i) => {
        const { features } = route.hotspot_segments;
        const id = i + 1;
        const smoothedRoute = {
            ...route,
            geometry: {
                ...route.geometry,
                coordinates: smooth(route.geometry.coordinates)
            }
        };

        layers.push(
            new GeoJsonLayer({
                id: `safeRoute-base-${id}`,
                data: {
                    type: "FeatureCollection",
                    features: [smoothedRoute]
                },
                opacity: id === selectedRoute ? 1 : 0.2,
                pickable: true,
                stroked: true,
                extruded: false,
                filled: true,
                getLineColor: [110, 158, 239],
                getLineWidth: 7,
                lineWidthUnits: "pixels",
                onClick: (d) => store.dispatch(visualizationsActions.updateSelectedRoute(id))
            })
        );

        for (let x = 0; x < 7; x++) {
            layers.push(
                new GeoJsonLayer({
                    id: `safeRoute-${id}-day-${x}`,
                    data: {
                        type: "FeatureCollection",
                        features: features.filter((d) => moment(d.properties.ra_date).weekday() === x)
                    },
                    visible: x === day,
                    opacity: id === selectedRoute ? 1 : 0.2,
                    pickable: true,
                    stroked: true,
                    extruded: false,
                    filled: false,
                    getLineColor: (d) => scale(d.properties.risk_score_pct).rgb(),
                    getLineWidth: 7,
                    lineWidthUnits: "pixels",
                    onClick: (d) => store.dispatch(visualizationsActions.updateSelectedRoute(id))
                })
            );
        }
    });

    for (let endpoint of [originData, destinationData]) {
        if (endpoint.coordinates.length) {
            layers.push(
                new IconLayer({
                    id: endpoint.id,
                    data: [endpoint],
                    getPosition: (d) => d.coordinates,
                    pickable: true,
                    iconAtlas:
                        endpoint.id === "origin"
                            ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-theft-marker.png"
                            : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-assault-marker.png",
                    iconMapping: {
                        endpoint: {
                            x: 0,
                            y: 0,
                            width: 60,
                            height: 71,
                            anchorY: 71,
                            mask: false
                        }
                    },
                    getIcon: (d) => "endpoint",
                    sizeUnits: "meters",
                    sizeMinPixels: 65,
                    onClick: (e) => {
                        store.dispatch(
                            visualizationsActions.updateCoordinates({
                                endpoint: e.object.id,
                                coordinates: [],
                                clicked: true
                            })
                        );
                        if (source.current) {
                            source.current.cancel("Canceled");
                        }
                        store.dispatch(visualizationsActions.updateSafeRouteData([]));
                    }
                })
            );
        }
    }

    const { fuzzyRouteInput } = store.getState().visualizations;

    const fuzzyRouteInputGeojson = {
        type: "FeatureCollection",
        features: fuzzyRouteInput
    };

    const fuzzyRouteOutput = store.getState().visualizations.fuzzyRouteOutput;

    layers.push(
        new EditableGeoJsonLayer({
            id: "fuzzyRouteInput",
            data: fuzzyRouteInputGeojson,
            visible: fuzzyRouteOutput.length === 0,
            mode:
                fuzzyRouteInput.length === 0 && store.getState().visualizations.fuzzyRouteEnabled
                    ? DrawLineStringMode
                    : ViewMode,
            selectedFeatureIndexes: [0],
            onEdit: ({ editType, updatedData }) => {
                store.dispatch(visualizationsActions.updateFuzzyRoute(updatedData));
                if (editType === "addFeature") {
                    store.dispatch(visualizationsActions.toggleFuzzyRouteEnabled());
                    var coords = updatedData.features[0].geometry.coordinates;
                    var newCoords = coords.join(";");
                    getMatch(newCoords);
                }
            }
        })
    );

    layers.push(
        new PathLayer({
            id: `fuzzyRouteOutput`,
            data: [fuzzyRouteOutput],
            pickable: true,
            stroked: true,
            extruded: false,
            filled: false,
            getPath: (d) => d,
            getColor: [255, 0, 0],
            getWidth: 7,
            widthUnits: "pixels"
        })
    );

    layers.push(new IconLayer({
        id: `-userlayer`,
        data: allDevices,
        getPosition: (d) => d.point.coordinates,
        pickable: true,
        iconAtlas: true 
            ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/user-location-green.png" 
            : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/user-location-red.png",
        iconMapping: {
            endpoint: {
                x: 0,
                y: 0,
                width: 150,
                height: 150,
                anchorY: 150,
                mask: false
            }
        },
        mask: true,
        getIcon: () => "endpoint",
        sizeUnits: "meters",
        sizeMinPixels: 44,
        sizeScale: 1,
        visible: showAssets
    }));
    layers.push(new TextLayer({
        data: clientDevicesText,
        id: `-userTextlayer`,
        getPosition: (d) => d.point.coordinates,
        getText: (d) => {
            return d.user_name
        },
        pickable: true,
        getPixelOffset: (d) => [0,Math.sqrt(d.user_name.length)*6],
        getSize: 20,
        clusterRadius: 13,
        visible: showText && showAssets,
        getColor: [255,255,255,255]
    }));

    for(const risk of riskData) {
        const geography = JSON.parse(JSON.stringify(risk.geography));
        const selected = risk.id === selectedRisk?.id;
        if(geography){
            if(geography.type === "Point") {
                layers.push(new IconLayer({
                    id: `${risk.id}-risk-icon-layer`,
                    data: [risk],
                    getPosition: (d) => JSON.parse(JSON.stringify(d.geography)).coordinates,
                    pickable: true,
                    iconAtlas: "https://orion-assets-s3.s3.us-east-2.amazonaws.com/asset_icons/risk.png",
                    iconMapping: {
                        endpoint: {
                            x: 0,
                            y: 0,
                            width: 150,
                            height: 150,
                            anchorY: 150,
                            mask: false
                        }
                    },
                    onClick,
                    mask: true,
                    getIcon: () => "endpoint",
                    sizeUnits: "meters",
                    sizeMinPixels: selected ? 60 : 44,
                    sizeScale: 1,
                    visible: showRisks
                }));
            } else if(geography.type === "Polygon") {
                layers.push(new PolygonLayer({
                    id: `${risk.id}-polygon-risk-layer`,
                    data: [risk],
                    pickable: true,
                    stroked: true,
                    filled: true,
                    wireframe: true,
                    onClick,
                    lineWidthMinPixels: 1,
                    getPolygon: d => JSON.parse(JSON.stringify(d.geography)).coordinates,
                    getElevation: d => 30,
                    getFillColor: d => [150, 0, 0, 100],
                    getLineColor: selected ? [255, 255, 255] : [80, 80, 80],
                    getLineWidth: 1,
                    visible: showRisks
                }));
            } else if (geography.type === "LineString") {
                //let coordinates = geography.coordinates[Math.floor(geography.coordinates.length/2)];
                layers.push(new PathLayer({
                    id: `${risk.id}-asset-path-layer`,
                    data: [risk],
                    pickable: true,
                    widthScale: selected ? 30 : 20,
                    onClick,
                    widthMinPixels: selected ? 5 : 2,
                    getPath: d => geography.coordinates,
                    getColor: [150,0,0],
                    getWidth: d => 5,
                    visible: showAssets
                }));
            }
        }
    }


    for(const asset of assetData) {
        const geography = JSON.parse(JSON.stringify(asset.geography));
        const selected = asset.id === selectedAsset?.id || asset.id === selectedScenario?.id;
        if(geography.type === "Point") {
            layers.push(new ScenegraphLayer({
                id: `${asset.id}-scenegraph-asset-layer`,
                data: [asset],
                _animations: {
                    '*': {speed: 5}
                },
                _lighting: 'pbr',
                getOrientation: d => [0, 0, 90],
                getPosition: d => JSON.parse(JSON.stringify(asset.geography)).coordinates,
                loaders: [GLTFLoader],
                scenegraph: getScenegraph(asset.asset_type),
                sizeScale: selected ? 1.5 * getScenegraphSize(asset.asset_type) : getScenegraphSize(asset.asset_type),
                pickable: true,
                onClick,
                visible: showAssets
            }));
            layers.push(new TextLayer({
                data: clientDevicesText,
                id: `${asset.id}-text-asset-layer`,
                getPosition: (d) => asset.geography.coordinates,
                getText: (d) => asset.name,
                pickable: true,
                getPixelOffset: [0,20],
                getPolygonOffset: (layerIndex) => [0, -layerIndex * 1000],
                getSize: 20,
                clusterRadius: 13,
                visible: showText && showAssets && (addRisk !== undefined || (changeGeo && selectedRisk !== undefined)),
                getColor: [255,255,255,255]
            }));
            layers.push(new IconLayer({
                id: `${asset.id}-asset-icon-layer`,
                data: [asset],
                getPosition: (d) => JSON.parse(JSON.stringify(d.geography)).coordinates,
                pickable: true,
                iconAtlas: asset.imaginary ? getScenarioIcon(asset.asset_type) : getAssetIcon(asset.asset_type),
                iconMapping: {
                    endpoint: {
                        x: 0,
                        y: 0,
                        width: 80,
                        height: 80,
                        anchorY: 80,
                        mask: false
                    }
                },
                onClick,
                mask: true,
                getIcon: () => "endpoint",
                sizeUnits: "meters",
                sizeMinPixels: selected ? 60 : 44,
                sizeScale: selected ? 2 : 1,
                visible: showAssets && showAssetMarkers
            }));
        } else if(geography.type === "Polygon") {
            // let geography = JSON.parse(JSON.stringify(asset.geography));
            // let points = 0;
            // let lat = 0;
            // let long = 0;
            // for(let coordinate of geography.coordinates[0]) {
            //     lat += coordinate[0];
            //     long += coordinate[1];
            //     points++;
            // }
            // lat /= points;
            // long /= points;
            //let coordinates = [lat, long];
            layers.push(new PolygonLayer({
                id: `${asset.id}-polygon-asset-layer`,
                data: [asset],
                pickable: true,
                stroked: true,
                filled: true,
                wireframe: true,
                onClick,
                lineWidthMinPixels: 1,
                getPolygon: d => JSON.parse(JSON.stringify(d.geography)).coordinates,
                getElevation: d => 30,
                getFillColor: d => asset.imaginary ? "#65F6F6"  : [80, 0, 100, 100],
                getLineColor: selected ? [255, 255, 255] : [80, 80, 80],
                getLineWidth: 1,
                visible: showAssets
            }));
            layers.push(new TextLayer({
                data: clientDevicesText,
                id: `${asset.id}-text-asset-layer`,
                getPosition: (d) => d3.geoCentroid(asset.geography),
                getText: (d) => asset.name,
                pickable: true,
                getPolygonOffset: (layerIndex) => [0, -layerIndex * 1000],
                getSize: 20,
                clusterRadius: 13,
                visible: showText && showAssets && (addRisk !== undefined || (changeGeo && selectedRisk !== undefined)),
                getColor: [255,255,255,255]
            }));
            // unsure if we really want this
            // layers.push(new IconLayer({
            //     id: `${asset.id}-asset-icon-layer`,
            //     data: [asset],
            //     getPosition: (d) => coordinates,
            //     pickable: true,
            //     iconAtlas: getAssetIcon(asset.asset_type),
            //     iconMapping: {
            //         endpoint: {
            //             x: 0,
            //             y: 0,
            //             width: 80,
            //             height: 80,
            //             anchorY: 80,
            //             mask: false
            //         }
            //     },
            //     mask: true,
            //     getIcon: () => "endpoint",
            //     sizeUnits: "meters",
            //     sizeMinPixels: 44,
            //     sizeScale: 1,
            //     visible: showAssets && !showText
            // }));
        } else if (geography.type === "LineString") {
            let geography = JSON.parse(JSON.stringify(asset.geography));
            //let coordinates = geography.coordinates[Math.floor(geography.coordinates.length/2)];
            layers.push(new PathLayer({
                id: `${asset.id}-asset-path-layer`,
                data: [asset],
                pickable: true,
                widthScale: selected ? 30 : 20,
                onClick,
                widthMinPixels: selected ? 5 : 2,
                getPath: d => geography.coordinates,
                getColor: asset.imaginary ? "#65F6F6": [0, 0, 150],
                getWidth: d => 5,
                visible: showAssets
            }));
            layers.push(new TextLayer({
                data: clientDevicesText,
                id: `${asset.id}-text-asset-layer`,
                getPosition: (d) => asset.geography.coordinates[Math.floor(asset.geography.coordinates.length/2)],
                getText: (d) => asset.name,
                pickable: true,
                getPolygonOffset: (layerIndex) => [0, -layerIndex * 1000],
                getSize: 20,
                clusterRadius: 13,
                visible: showText && showAssets && (addRisk !== undefined || (changeGeo && selectedRisk !== undefined)),
                getColor: [255,255,255,255]
            }));
            // unsure if we really want this
            // layers.push(new IconLayer({
            //     id: `${asset.id}-asset-icon-layer`,
            //     data: [asset],
            //     getPosition: (d) => coordinates,
            //     pickable: true,
            //     iconAtlas: getAssetIcon(asset.asset_type),
            //     iconMapping: {
            //         endpoint: {
            //             x: 0,
            //             y: 0,
            //             width: 80,
            //             height: 80,
            //             anchorY: 80,
            //             mask: false
            //         }
            //     },
            //     mask: true,
            //     getIcon: () => "endpoint",
            //     sizeUnits: "meters",
            //     sizeMinPixels: 44,
            //     sizeScale: 1,
            //     visible: showAssets && !showText
            // }));
        }
    };

    return layers;
}
