import React, { useEffect, useState } from "react";
import { Button, FormControl, InputLabel, Select, Input, MenuItem, ListItemText, Checkbox, FormLabel, TextField, Box, IconButton } from "@material-ui/core";
import { DataGrid, ColDef, ValueGetterParams, CellParams } from "@material-ui/data-grid";
import { Form, FormContainer, Section, ToggleBarContainer,  } from "./styled";
import { getAllDefaultRegions, getSummaryData } from "../types/api/admin";
import { getContainers, getAllContainers } from "../types/api/admin";
import { useThrow } from "../catch";
import { ClientDisplayData, DisplayClient, UserFeatureSelection } from "../types/client";
import { Autocomplete, Alert } from "@material-ui/lab";
import { render } from "@testing-library/react";
import {makeStyles} from '@material-ui/styles';
import Link from "@material-ui/core/Link";
import { Container } from "../types/geo"
import { ConsoleView } from "react-device-detect";
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  LineSeries,
} from '@devexpress/dx-react-chart-material-ui';
import { getMonitoringData } from "../types/api/admin";



const styles = makeStyles((theme) => ({
    root: {
      borderLeft: 0,
      borderRight: 0,
      borderBottom: 0,
      color: 'black',
      backgroundColor: 'grey'
    },
  }));




export const DisplayUserMonitoring = () => {

    const classes = styles()

    const [tablerows, settablerows] = useState<any[]>([]);
    const [totalusers, settotalusers] = useState<number>(0);
    const [onusers, setonusers] = useState<number>(0);
    const [timeframe, setTimeframe] = useState<number>(7);
    const [signuprows, setsignuprows] = useState<any[]>([]);
    const [loading, setLoading] = useState<string>("Loading...");
    const [loading2, setLoading2] = useState<string>("Loading...");
    const [uniquedevrows, setUniquedevrows] = useState<any[]>([]);
    const [uniqtokens, setuniqtokens] = useState<number>(0);


    const newdate = new Date();
    const finaldate = newdate.toISOString().split('T')[0];

    useEffect(() => {

        const fetchdata = async () => {

            console.log("fetching monitoring data")

            const monitordata = await getMonitoringData(62);
            const signupdata = await getMonitoringData(-1);
            const uniquedevdata = await getMonitoringData(-2);
            const uniquedev_fixeddates: any[] = [];
            uniquedevdata.historical.map((devrow: any) => {
              uniquedev_fixeddates.push({date: new Date(devrow.date), uniquetokens: devrow.uniquetokens})
            })
            const uniquedev_fixeddates2 = uniquedev_fixeddates.sort(
              (objA, objB) => objA.date.getTime() - objB.date.getTime(),
            );
            uniquedev_fixeddates2.map((row) => row.strdate = row.date.toLocaleDateString())
            console.log(uniquedev_fixeddates2)
            setuniqtokens(uniquedevdata.tokencount.rowCount as number)
            setUniquedevrows(await tickspacing(uniquedev_fixeddates, timeframe));
            settablerows(monitordata);
            const surows = await tickspacing(signupdata.slice(-timeframe), timeframe);
            surows.map((row) => row.realcumsum = parseInt(row.cumsum));
            setsignuprows(surows);
            setLoading("");
            let totalusercount = 0;
            let totalonusercount = 0;

            for (const point of monitordata) {

                totalusercount += parseInt(point.total_users)
                totalonusercount += parseInt(point.pn_on)

            }

            settotalusers(totalusercount)
            setonusers(totalonusercount)

        }

        fetchdata();
        setLoading2("");

    }, [timeframe]);


    async function tickspacing(inputdata: any[], timeframe: number) { 
      let spacingint = timeframe / 10
      if (timeframe < 15) spacingint = 1
      let emptystr = ''
  
      inputdata.forEach((row, index) => {
      if (index % spacingint != 0) row.signedup_date = emptystr += ' '
      return row
      })
  
      return inputdata
    }


    const columns: ColDef[] = [
        { field: 'name', headerName: 'Team Name', width: 250 },
        { field: 'total_users', headerName: 'Total Users', width: 150 },
        { field: 'pn_on', headerName: 'Users with PN On', width: 150 },
        { field: 'pn_off', headerName: 'Users with PN OFF', width: 150 },
        { field: 'uniq_count', headerName: 'Users that have a unique device', width: 250 },
      ];


    return (
        <div>
            <div style={{ height: '40px'}} />
            <div>
            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px'}} >
                  {loading2}
              </div>
            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px'}} >
                  LIVE Unique Device Token Count - {uniqtokens}
              </div>
            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px'}} >
                  Amount of Distinct Device Tokens, Over Time (Updates Every 30 Min)
              </div>
              <Chart data={uniquedevrows}>
              <ArgumentAxis />
              <ValueAxis />
              <LineSeries valueField="uniquetokens" argumentField="strdate" />
            </Chart>
            </div>
            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px'}} >
                Total Users: {totalusers}, Users with Push Notifications on: {onusers}
            </div>
            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px'}} >
                {((onusers/totalusers)*100).toFixed(2)}% of the way there!
            </div>
            <div style={{ height: '40px'}} />
            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 700, width: '100%'}} >
            <DataGrid rows={tablerows} columns={columns} pageSize={12} rowsPerPageOptions={[5]} className={classes.root} />
            </div>
            <div style={{height:'40px'}} />
            <Button size="small" onClick={() => setTimeframe(7)} >
              7D
            </Button>
            <Button size="small" onClick={() => setTimeframe(30)} >
              30D
            </Button>
            <Button size="small" onClick={() => setTimeframe(90)} >
              90D
          </Button>
          <div style={{ height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            Rate of Change In User Growth Rate, Past {timeframe} Days
          </div>
          <div style={{ height: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
            {loading}
          </div>
          <Chart data={signuprows}>
            <ArgumentAxis />
            <ValueAxis />
            <LineSeries valueField="pct_change2" argumentField="signedup_date" />
          </Chart>
          <div style={{ height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            User Sign Ups (Cumulative), Past {timeframe} Days
          </div>
          <div style={{ height: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
            {loading}
          </div>
          <Chart data={signuprows}>
            <ArgumentAxis />
            <ValueAxis />
            <LineSeries valueField="realcumsum" argumentField="signedup_date" />
          </Chart>
      </div>
    )
}
