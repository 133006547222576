import React from "react";
import styled from "styled-components";
import AnimationSpeedSlider from "./animation-speed-slider";
import { Button, CenterFlexbox } from "../styled-components";
import { Rocket } from "../icons";

const StyledSpeedToggle = styled.div`
    display: flex;
    flex-grow: 0;
    color: ${(props) => props.theme.orion.scheme.primaryText};
    position: relative;
`;

const StyledSpeedText = styled.div`
    display: inline-block;
    width: 24px;
    text-align: left;
`;

const SpeedControl = ({ onClick, updateAnimationSpeed, speed, showSpeedControl, buttonHeight = "18px" }) => (
    <StyledSpeedToggle className="animation-control__speed-control">
        <Button link width="80px" onClick={onClick}>
            <CenterFlexbox className="bottom-widget__icon speed">
                <Rocket height={buttonHeight} />
            </CenterFlexbox>
            <StyledSpeedText style={{ visibility: !showSpeedControl ? "visible" : "hidden" }}>{speed}x</StyledSpeedText>
        </Button>
        {showSpeedControl ? (
            <AnimationSpeedSlider onHide={onClick} updateAnimationSpeed={updateAnimationSpeed} speed={speed} />
        ) : null}
    </StyledSpeedToggle>
);

export default SpeedControl;
