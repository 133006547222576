import styled from "styled-components";
import { GeolocateControl } from "react-map-gl";

// components
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";

export const boxShadow = "rgba(0, 0, 0, 0.1)";
const size = {
    mobile: "576px",
    tablet: "992px"
};
export const device = {
    mobile: `(max-width: ${size.mobile})`,
    tablet: `(max-width: ${size.tablet})`
};

export const FullScreenMapContainer = styled.div`
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
`;

export const SearchbarContainer = styled.div`
    position: absolute;
    top: 22px;
    right: 90px;
    z-index: 10;
    box-shadow: 0px 4px 6px ${boxShadow};
    border-radius: 10px;

    div.mapboxgl-ctrl-geocoder {
        font-family: IBM Plex Sans;
        width: 285px;
        height: 35px;
        border-radius: 10px;
    }

    @media ${device.mobile} {
        box-shadow: none;
        width: calc(100vw - 90px);
        border-radius: 10px;

        div.mapboxgl-ctrl-geocoder {
            width: calc(100vw - 90px);
            left: 70px;
            height: 48px;
            border-radius: 10px;
        }
    }
`;

interface CurrentLocationButtonProps {
    role: number;
}

export const CurrentLocationButton = styled(GeolocateControl)`
    position: fixed;
    right: 40px;
    bottom: ${(props: CurrentLocationButtonProps) => (props.role === -1 ? "115px" : "190px")};
    box-shadow: none;
    z-index: 10;
    border-radius: 10%;
    .mapboxgl-ctrl-group:not(:empty) {
        box-shadow: none;
        border: none;
        outline: none;
    }

    @media ${device.mobile} {
        bottom: 105px;
    }
`;

interface EntryProps {
    color: string;
}

export const EntryName = styled.div`
    width: 90px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    color: ${(props: EntryProps) => props.color};
`;

export const EntryCases = styled.div`
    width: 90px;
    text-align: center;
    color: ${(props: EntryProps) => props.color};
    @media ${device.mobile} {
        margin-top: 20px;
    }
`;

interface AssetModalContainerProps {
    background: string;
    secondary?: boolean;
    tertiary?: boolean;
}

export const AssetModalContainer = styled.div`
    z-index: 25;
    max-height: 600px;
    max-width: 400px;
    min-width: 400px;
    position: fixed;
    display: flex;
    top: 20px;
    left: ${(props : AssetModalContainerProps) => props.tertiary ? "1130px" : props.secondary ? "700px" : "270px"};
    border-radius: 10px;
    padding: 10px;
    flex-direction: column;
    background: ${(props : AssetModalContainerProps) => props.background};
    overflow-y: scroll;
    overscroll-behavior: contain;

`;

interface FieldGroupProps {
    background: string
}

export const FieldGroup = styled.div`
    background: ${(props : FieldGroupProps)  => props.background};
    padding: 10px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
`;

export const ExitButton = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    backdrop-filter: blur(4px);
    z-index: 100000;
    border-radius: 5px;

    :hover {
        cursor: pointer;
    }
`;

export const EditButton = styled.div`
    position: absolute;
    right: 33px;
    padding-right: 2px;
    top: 10px;
    backdrop-filter: blur(4px);
    z-index: 100000;
    border-radius: 5px;


    :hover {
        cursor: pointer;
    }
`;

interface TooltipTextProps {
    color: string;
}

export const ModalTitle = styled.div`
    font-weight: 600;
    font-size: 18px;
    margin-top: 0px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 50px;
    text-transform: uppercase;
    color: ${(props: TooltipTextProps) => props.color};
    margin-right: 15px;
`;

export const ModalText = styled.div`
    font-weight: 600;
    font-size: 18px;
    color: ${(props: TooltipTextProps) => props.color};
    margin-right: 15px;
`;

export const ModalAddress = styled.div`
    font-size: 16px;
    color: ${(props: TooltipTextProps) => props.color};
    margin-bottom: 10px;
`;

export const ModalPostedby = styled.div`
    font-size: 16px;
    color: ${(props: TooltipTextProps) => props.color};
`;

export const HoverDiv = styled.div`
    :hover {
        cursor: pointer;
    }

`;