import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  IconButton,
  SnackbarContent,
  CircularProgress,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Form, FormContainer, Section, TextInput } from "./styled";
import { useThrow } from "../catch";
import { createForm, getAdminProfile, getCountedForms, modifyForm } from "../types/api/admin";
import { NewForm, AampReportFieldsSpecification, CountedForm } from "../types/aamp";
import { reportType, AllReportTypes } from "../types/reports";
import { AdminUserProfile } from "../types/admin";
import { Container } from "../types/geo";
import { getAllAdminUsers, getAllContainers } from "../types/api/admin";

class Value {
  name: string;
  keywords: string[];
  constructor(name: string, keywords: string[]) {
    this.name = name;
    this.keywords = keywords;
  }
}
class Field {
  name: string;
  values: Value[];
  constructor(name: string, values: Value[]) {
    this.name = name;
    this.values = values;
  }
}

export const CreateForm = () => {
  const error = useThrow();

  const [profile, setProfile] = useState<AdminUserProfile | undefined>(
    undefined
  );
  useEffect(() => {
    (async () => {
      const tempProfile = await getAdminProfile();
      setProfile(tempProfile);
      updatePrimaryManager(adminData.find((admin) => admin.id === tempProfile.id) ?? null);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const victimInitialValues: Value[] = [
    new Value("Local Civilian", []),
    new Value("International/Visiting/Foreign Civilian", []),
    new Value("Company", []),
    new Value("Government", []),
    new Value("Gang/Militia", []),
    new Value("Educational Facilities", []),
    new Value("Critical Infrastructure", []),
  ];
  const aggressorInitialValues: Value[] = [
    new Value("Local Civilian", []),
    new Value("International/Visiting/Foreign Civilian", []),
    new Value("Company", []),
    new Value("Government", []),
    new Value("Gang/Militia", []),
    new Value("Protestor", []),
  ];
  const [formName, updateFormName] = useState<string>("");
  const [containerID, updateContainerID] = useState<number | null>(null);
  const [immediatePublic, updateImmediatePublic] = useState<string>("");
  const [acceptsFuture, updateAcceptsFuture] = useState<string>("");
  const [adminData, updateAdminData] = useState<AdminUserProfile[]>([]);
  const [primaryManager, updatePrimaryManager] = useState<AdminUserProfile | null>(null);
  const [containers, updateContainers] = useState<Container[]>([]);
  const [loading, updateLoading] = useState<boolean>(true);
  const [resetValue, updateResetValue] = useState<string>("");
  const [ containersList, setContainersList ] = useState<string[]>([]);
  const [ container, setContainer ] = useState<string>("");
  const [ form_name, updateForm_name] = useState<string | undefined>();
  const [ formID, updateFormID ] = useState<number | undefined>();
  const [ formsByContainer, setFormsByContainer ] = useState<{[container_name: string]: CountedForm[]}>({});
  const [ containerObject, setContainerObject ] = useState<Container | null>(null);

  
    useEffect(() => {
      const fetchFormData = async () => {
          try {
              const fetchedForms = await getCountedForms();
              setFormsByContainer(fetchedForms);
              const forms: Record<number, CountedForm> = {};
              const containersString: Record<number, string> = {};
              for (const [ container_name, containerForms ] of Object.entries(fetchedForms)) {
                  for (const form of containerForms) {
                      forms[form.id] = form;
                      containersString[form.id] = container_name;
                      const newContainers = containersList;
                      if(!newContainers.includes(container_name)) newContainers.push(container_name);
                      setContainersList(newContainers);
                  }
              }
              const localContainer = localStorage.getItem("aampContainer");
              if(localContainer && containersList.includes(localContainer)) {
                  setContainer(localContainer);
              }
          } catch (err) {
              error(err);
          }
      };
      fetchFormData();
  }, [error, containersList, resetValue, containers]);

  const [dynamicFields, updateDynamicFields] = useState<Field[]>([
    new Field("victim", victimInitialValues),
    new Field("aggressor", aggressorInitialValues),
  ]);
  const [aampReportValues, updateAampReportValues] = useState<Value[]>([
    new Value("", []),
  ]);

  useEffect(() => {
    (async () => {
      try {
        const response: AdminUserProfile[] = await getAllAdminUsers();
        updateAdminData(response);
        updateLoading(false);
      } catch {
        setErrorString("Error: Admin Users Could Not Be Found");
        displayErrorSnackbar();
        updateLoading(false);
      }
    })();
  }, [error]);

  useEffect(() => {
    (async () => {
      try {
        const response: Container[] = await getAllContainers();
        updateContainers(response);
      } catch {
        setErrorString("Error: Containers Could Not Be Found");
        displayErrorSnackbar();
      }
    })();
  }, [error]);

  const [openSuccessSnackbar, setOpenSucessSnackbar] = useState(false);
  const displaySuccessSnackbar = () => {
    setOpenSucessSnackbar(true);
  };
  const handleCloseSuccessSnackbar = () => {
    setOpenSucessSnackbar(false);
  };
  const [openWarningSnackbar, setOpenWarningSnackbar] = useState(false);
  const displayWarningSnackbar = () => {
    setOpenWarningSnackbar(true);
  };
  const handleCloseWarningSnackbar = () => {
    setOpenWarningSnackbar(false);
  };
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const displayErrorSnackbar = () => {
    setOpenErrorSnackbar(true);
  };
  const handleCloseErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  };
  const [errorString, setErrorString] = useState("");

  const handleAddField = (e: any) => {
    e.preventDefault();
    const inputState = new Field("", []);
    updateDynamicFields((prev) => [...prev, inputState]);
  };

  const onChangeField = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    event.persist();
    updateDynamicFields((prev) => {
      const change = prev.map((item, i) => {
        if (i !== index) {
          return item;
        }
        item.name = event.target.value;
        return item;
      });
      return change;
    });
  };

  const handleRemoveField = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    fieldIndex: number
  ) => {
    e.preventDefault();
    updateDynamicFields((prev) =>
      prev.filter((item) => item !== prev[fieldIndex])
    );
  };

  const handleAddValue = (field: Field, e: any) => {
    e.preventDefault();
    const inputState = new Value("", []);
    let tempValues = field.values;
    field.values = [...tempValues, inputState];
    updateDynamicFields((prev) => [...prev]);
  };

  const onChangeValue = (
    field: Field,
    value: Value,
    valueIndex: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    event.persist();
    let tempValues = field.values;

    field.values = tempValues.map((itemValue, i) => {
      if (i !== valueIndex) {
        return itemValue;
      }
      value.name = event.target.value;
      return value;
    });
    updateDynamicFields((prev) => [...prev]);
  };

  const handleRemoveValue = (
    field: Field,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    valueIndex: number
  ) => {
    e.preventDefault();
    let tempValues = field.values;
    let change = tempValues.filter(
      (itemValue) => itemValue !== tempValues[valueIndex]
    );
    field.values = change;
    updateDynamicFields((prev) => [...prev]);
  };

  const handleAddAampValue = (e: any) => {
    e.preventDefault();
    const inputState = new Value("", []);
    updateAampReportValues((prev) => [...prev, inputState]);
  };

  const onChangeAampValue = (
    value: Value,
    value2: reportType,
    valueIndex: number,
    event: React.ChangeEvent<{}>
  ) => {
    event.preventDefault();
    event.persist();
    updateAampReportValues((prev) => {
      const change = prev.map((currentValue, i) => {
        if (i !== valueIndex) {
          return currentValue;
        }
        value.name = value2;
        return value;
      });
      return change;
    });
  };

  const handleRemoveAampValue = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    valueIndex: number
  ) => {
    e.preventDefault();
    updateAampReportValues((prev) =>
      prev.filter((item) => item !== prev[valueIndex])
    );
  };

  const handleAddAampKeyword = (value: Value, e: any) => {
    e.preventDefault();
    const inputState = "";
    let tempKeywords = value.keywords;
    value.keywords = [...tempKeywords, inputState];
    updateAampReportValues((prev) => [...prev]);
  };

  const onChangeAampKeyword = (
    value: Value,
    keyword: string,
    keywordIndex: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    event.persist();
    let tempKeywords = value.keywords;

    value.keywords = tempKeywords.map((currentKeyword, i) => {
      if (i !== keywordIndex) {
        return currentKeyword;
      }
      keyword = event.target.value;
      return keyword;
    });
    updateAampReportValues((prev) => [...prev]);
  };

  const handleRemoveAampKeyword = (
    value: Value,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    keywordIndex: number
  ) => {
    e.preventDefault();
    let tempKeywords = value.keywords;
    let change = tempKeywords.filter(
      (currentKeyword) => currentKeyword !== tempKeywords[keywordIndex]
    );
    value.keywords = change;
    updateAampReportValues((prev) => [...prev]);
  };

  if (loading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          margin: "auto",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
        }}
      />
    );
  }

  const resetForm = () => {
    updateFormName("");
    updateContainerID(null);
    setContainerObject(null);
    updatePrimaryManager(null);
    updateDynamicFields([
      new Field("victim", victimInitialValues),
      new Field("aggressor", aggressorInitialValues),
    ]);
    updateAampReportValues([new Value("", [""])]);
    updateImmediatePublic("");
    updateAcceptsFuture("");
    updateResetValue("");
  };

  const submit = () => {
    let stop = 0;
    aampReportValues.forEach((value) => {
      if (value.name === "") {
        displayWarningSnackbar();
        stop = 1;
      }
    });
    if (stop === 1) return;
    if (aampReportValues.length === 0) {
      displayWarningSnackbar();
      return;
    }
    if (
      containerID &&
      primaryManager &&
      dynamicFields &&
      immediatePublic &&
      acceptsFuture &&
      formName
    ) {
      let values = {};
      aampReportValues.forEach((value) => {
        values = {
          ...values,
          [value.name]: value.keywords,
        };
      });

      let finalFieldsObject = {
        aamp_report_type: {
          values,
        },
      };
      dynamicFields.forEach((field) => {
        let nested: string[] = [];
        field.values.forEach((value) => {
          nested = [...nested, value.name];
        });
        finalFieldsObject = {
          ...finalFieldsObject,
          [field.name]: {
            values: nested,
          },
        };
      });

      let aampReportFieldSpecification: AampReportFieldsSpecification =
        JSON.parse(JSON.stringify(finalFieldsObject));

      const createFormObject: NewForm = {
        name: formName,
        container_id: containerID,
        primary_manager: primaryManager.id,
        fields: aampReportFieldSpecification,
        immediate_public: immediatePublic === "true" ? true : false,
        accepts_future_reports: acceptsFuture === "true" ? true : false,
      };
      if(form_name && formID) {
        modifyForm({...createFormObject, id: formID})
          .then(() => {
            displaySuccessSnackbar();
            updateResetValue(" ");
          })
          .catch(() => {
            setErrorString("Error: form not modified");
            displayErrorSnackbar();
          })

      } else {
        createForm(createFormObject)
          .then(() => {
            displaySuccessSnackbar();
            updateResetValue(" ");
            resetForm();
          })
          .catch(() => {
            setErrorString("Error: createForm object not created");
            displayErrorSnackbar();
          });
        }
    } else {
      displayWarningSnackbar();
    }
  };

  const updateForm = (newForm : CountedForm) => {
    updateForm_name(newForm.name !== null && newForm.name !== undefined ? newForm.name : "Form " + newForm.id);
    updateFormID(newForm.id);
    updateImmediatePublic(newForm.immediate_public ? "true" : "false");
    updatePrimaryManager(adminData.find((admin) => admin.id === newForm.primary_manager) ?? null);
    updateContainerID(newForm.container_id);
    setContainerObject(containers.find((containe) => newForm.container_id === containe.id) ?? null);
    let fields : Field[] = [];
    for(let i = 1; i < Object.keys(newForm.fields).length; i++) {
      let values: Value[] = [];
      for(let j = 0; j < Object.values(newForm.fields)[i].values.length; j++) {
        values.push({
          name: Object.values(Object.values(newForm.fields)[i].values)[j],
          keywords: Object.values(Object.values(newForm.fields)[i].values)[j]
        })
      }
      fields.push({
        name: Object.keys(newForm.fields)[i],
        values 
      })
    }
    updateDynamicFields(fields);
    let aampReportValues : Value[] = [];
    for(let k = 0; k < Object.keys(newForm.fields.aamp_report_type.values).length; k++) {
      aampReportValues.push({
        name: Object.keys(newForm.fields.aamp_report_type.values)[k],
        keywords: Object.values(newForm.fields.aamp_report_type.values)[k]
      })
    }
    updateAampReportValues(aampReportValues);
    updateFormName(newForm.name ?? "");
  }

  const ContainerSelection = () : React.ReactElement => {
    const currentContainerForms = (Object.entries(formsByContainer).find(([containerName]) => containerName === container));
    return (
      <>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            marginTop: "20px"
          }}
        >
          <div style={{ width: 400, flexDirection: "column" }}>
            <div style={{ marginLeft: 50 }}>
              Select a Container
              <Select
                value={container}
                style={{ marginLeft: 20, width: 150 }}
                onChange={(event) => {
                  setContainer(event.target.value as string);
                  localStorage.setItem(
                    "currentContainer",
                    event.target.value as string
                  );
                }}
              >
                {containersList.map((value: string) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {container !== undefined &&
              currentContainerForms &&
              currentContainerForms[1].map((value: CountedForm) => (
                <MenuItem
                  key={value.id}
                  value={value.id}
                  id={"" + value.id}
                  onClick={() => {
                    updateForm(value);
                  }}
                  style={{ marginLeft: 50}}
                >
                  <div style={{ flexDirection: "column", marginRight: 10 }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {value.name !== null && value.name !== undefined ? "Form " + value.name : "Form " + value.id}
                      {value.immediate_public &&
                      <Tooltip 
                          title="Reports submitted to this form will be displayed publicly as unverified even if they have not yet been approved."
                          enterDelay={0}
                      >
                          <div style={{
                              color: "orange",
                              marginLeft: 10
                          }}>
                              {"(Immediate Public)"}
                          </div>
                      </Tooltip>}
                    </div>
                    {value.chats.length > 0 &&
                      value.chats.map((chatName: string) => (
                        <div key={chatName} style={{ marginLeft: 20 }}>{chatName}</div>
                      ))}
                      
                  </div>
                </MenuItem>
              ))}
          </div>
        </div>
      </>
    );};

  return (
    <FormContainer>
      <ContainerSelection />
      <Form noValidate autoComplete="off">
        {form_name && (
          <div style={{fontSize: "30px"}}>
            Editing {form_name}
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "10px 0", left: "100px", maxWidth: "300px" }}
              onClick={(e) => {
                updateForm_name(undefined)
                updateFormID(undefined);
                resetForm();
              }}
            >
              Create New Form
            </Button>
          </div>
        )}
        {!form_name &&
          <div style={{fontSize: "30px"}}>
            Create a new form
          </div>
        }
        <Section>
          <FormControl component="fieldset" style={{ margin: "20px 0 10px" }}>
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              Form Name
            </FormLabel>
            <TextInput
              id="name"
              required
              key={resetValue}
              label="Form Name"
              variant="outlined"
              value={formName}
              onChange={(e) => updateFormName(e.target.value)}
            />
          </FormControl>
          <FormControl component="fieldset" style={{ margin: "20px 0 10px" }}>
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              Select Container
            </FormLabel>
            <Autocomplete
              disableClearable
              id="container-input"
              key={resetValue}
              options={containers}
              value={containerObject as Container}
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextInput
                  {...params}
                  required
                  label="Container Name"
                  variant="outlined"
                  value=""
                />
              )}
              onChange={(event, value) => {
                updateContainerID(value.id);
                setContainerObject(value);
              }}
            />
          </FormControl>
          <FormLabel component="legend" style={{ margin: "10px 0" }}>
            Primary Manager
          </FormLabel>
          <Autocomplete
            disableClearable
            id="client-name-input"
            key={resetValue}
            options={adminData}
            value={primaryManager as AdminUserProfile}
            disabled={profile?.privileges === 0}
            getOptionLabel={(option) =>
              "" + option.first_name + " (" + option.email + ")"
            }
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextInput
                {...params}
                required
                label={profile?.privileges === 0 && !formID
                  ? "" + profile.first_name + " " + profile.last_name + " (you)" 
                  : "Manager Name"}
                variant="outlined"
              />
            )}
            onChange={(event, value) => {
              updatePrimaryManager(value);
            }}
          />

          <FormControl component="fieldset" style={{ margin: "20px 0 10px" }}>
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              Edit AAMP Report Types (At Least 1 Required)
            </FormLabel>

            {aampReportValues.map((currentValue, valueIndex) => (
              <div className="" key={`item-${valueIndex}`}>
                {" "}
                <div className="row mt-3">
                  <div className="col">
                    <Autocomplete
                      disableClearable
                      id={"aampReportVals" + currentValue.name}
                      key={resetValue}
                      options={["", ...AllReportTypes]}
                      onChange={(e, value) =>
                        onChangeAampValue(
                          currentValue,
                          value as reportType,
                          valueIndex,
                          e
                        )
                      }
                      value={currentValue.name}
                      renderInput={(params) => (
                        <>
                          {" "}
                          <TextInput
                            {...params}
                            id={"subaampreportvals" + currentValue.name}
                            required
                            label="Report Type"
                            variant="outlined"
                            style={{ maxWidth: "300px", left: "100px" }}
                          />
                          <Button
                            style={{
                              top: "23px",
                              left: "105px",
                              maxWidth: "30px",
                              minWidth: "30px",
                              maxHeight: "30px",
                              minHeight: "30px",
                            }}
                            variant="text"
                            color="primary"
                            onClick={(e) =>
                              handleRemoveAampValue(e, valueIndex)
                            }
                          >
                            X
                          </Button>
                        </>
                      )}
                    />
                  </div>

                  <div className="">
                    {currentValue.keywords.map(
                      (currentKeyword, keywordIndex) => (
                        <div className="" key={`value-${keywordIndex}`}>
                          <div className="row mt-3">
                            <div className="col">
                              <TextInput
                                style={{ maxWidth: "200px", left: "200px" }}
                                id={"keyword" + currentKeyword}
                                label="(eg. Shooting, Theft, etc)"
                                variant="standard"
                                value={currentKeyword}
                                onChange={(e) =>
                                  onChangeAampKeyword(
                                    currentValue,
                                    currentKeyword,
                                    keywordIndex,
                                    e
                                  )
                                }
                              />
                              <Button
                                style={{
                                  top: "23px",
                                  left: "205px",
                                  maxWidth: "30px",
                                  minWidth: "30px",
                                  maxHeight: "30px",
                                  minHeight: "30px",
                                }}
                                variant="text"
                                color="primary"
                                onClick={(e) =>
                                  handleRemoveAampKeyword(
                                    currentValue,
                                    e,
                                    keywordIndex
                                  )
                                }
                              >
                                X
                              </Button>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ margin: "10px 0", left: "240px" }}
                    onClick={(e) => handleAddAampKeyword(currentValue, e)}
                  >
                    Add Keyword
                  </Button>
                </div>
              </div>
            ))}
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "10px 0", left: "100px", maxWidth: "300px" }}
              onClick={(e) => handleAddAampValue(e)}
            >
              Add Report Type
            </Button>
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              Edit Extra Fields
            </FormLabel>

            {dynamicFields.map((currentField, fieldIndex) => (
              <div className="row mt-3" key={`item-${fieldIndex}`}>
                <div className="col">
                  <TextInput
                    id={"dynamicField" + currentField.name}
                    label="Field Name (eg. victim, agressor, etc)"
                    variant="outlined"
                    value={currentField.name}
                    onChange={(e) => onChangeField(fieldIndex, e)}
                  />
                  <Button
                    style={{
                      top: "23px",
                      left: "5px",
                      maxWidth: "30px",
                      minWidth: "30px",
                      maxHeight: "30px",
                      minHeight: "30px",
                    }}
                    variant="text"
                    color="primary"
                    onClick={(e) => handleRemoveField(e, fieldIndex)}
                  >
                    X
                  </Button>
                </div>
                <div
                  className="col"
                  style={{ position: "relative", left: "100px" }}
                >
                  <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Values
                  </FormLabel>
                </div>

                <div className="">
                  {currentField.values.map((currentValue, valueIndex) => (
                    <div className="" key={`value-${valueIndex}`}>
                      <div className="row mt-3">
                        <div className="col">
                          <TextInput
                            style={{ maxWidth: "300px", left: "100px" }}
                            id={"currentField" + currentField.name + Math.random()}
                            label="Value (eg. Civilian, Company, etc)"
                            variant="outlined"
                            value={currentValue.name}
                            onChange={(e) =>
                              onChangeValue(
                                currentField,
                                currentValue,
                                valueIndex,
                                e
                              )
                            }
                          />
                          <Button
                            style={{
                              top: "23px",
                              left: "105px",
                              maxWidth: "30px",
                              minWidth: "30px",
                              maxHeight: "30px",
                              minHeight: "30px",
                            }}
                            variant="text"
                            color="primary"
                            onClick={(e) =>
                              handleRemoveValue(currentField, e, valueIndex)
                            }
                          >
                            X
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "10px 0", left: "100px", minWidth: "300px" }}
                  onClick={(e) => handleAddValue(currentField, e)}
                >
                  Add Value
                </Button>
              </div>
            ))}

            <Button
              variant="contained"
              color="primary"
              style={{ margin: "10px 0", maxWidth: "400px" }}
              onClick={handleAddField}
            >
              Add Field
            </Button>
          </FormControl>

          <FormControl component="fieldset" style={{ margin: "20px 0 10px" }}>
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              Make Public Immediately? *
            </FormLabel>
            <RadioGroup
              name="make-public"
              value={immediatePublic}
              onChange={(e) => updateImmediatePublic(e.target.value)}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset" style={{ margin: "20px 0 10px" }}>
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              Allow the form to accept future reports? *
            </FormLabel>
            <RadioGroup
              name="accepts-future-reports"
              value={acceptsFuture}
              onChange={(e) => updateAcceptsFuture(e.target.value)}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Section>
        <Button variant="contained" color="primary" onClick={submit}>
          {form_name ? "Submit Changes" : "Create Form"}
        </Button>
      </Form>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSuccessSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSuccessSnackbar}
        >
          <SnackbarContent
            style={{
              backgroundColor: "#20d420",
              color: "white",
            }}
            message="Form Submitted Successfully!"
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSuccessSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
      </div>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openWarningSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseWarningSnackbar}
        >
          <SnackbarContent
            style={{
              backgroundColor: "orange",
              color: "white",
            }}
            message="Please Fill In All Required Fields"
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseWarningSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
      </div>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openErrorSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseErrorSnackbar}
        >
          <SnackbarContent
            style={{
              backgroundColor: "red",
              color: "white",
            }}
            message={errorString}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseErrorSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
      </div>
    </FormContainer>
  );
};
