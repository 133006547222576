import moment from "moment";
import { DefaultRegion } from "../types/geo";

export const getDateIndex = (day: number) => {
    const getRelativeWeekday = (day: number) => {
        const today = moment().weekday();
        if (day < today) {
            return day + 7;
        }
        return day;
    };

    return moment().day(getRelativeWeekday(day)).format("YYYY-MM-DD");
};

/**
 * Calculate timeWindow (referring to timeWindowLabels defined below) of which there are six 4-hour periods per day
 * @param hourOfDay: value 0-23 returned from moment.hour()
 */
export const getTimeWindow = (hourOfDay: number): number => {
    if (hourOfDay < 0 || hourOfDay > 23) {
        console.warn("Invalid hourOfDay: " + hourOfDay);
        return 0;
    }
    const timeWindow = Math.trunc(hourOfDay / 4);
    if (timeWindow >= 0 && timeWindow <= 5) {
        return timeWindow;
    } else {
        console.warn("Invalid time window calculation: " + timeWindow);
        return 0;
    }
};

export const getCurrentTimeWindow = (): number => {
    const currentHour = moment().hour();
    if (currentHour) {
        return getTimeWindow(currentHour);
    } else {
        return 0;
    }
};

export const filterGridByRiskIndex = (grid: any[], filter: number[]): any[] => {
    if (filter[0] === 0 && filter[1] === 5) {
        return grid;
    } else if (filter[0] === filter[1]) {
        return grid.filter((d) => {
            return d.properties.risk_index_tmp === filter[0];
        });
    } else {
        return grid.filter((d) => {
            return d.properties.risk_index_tmp >= filter[0] && d.properties.risk_index_tmp <= filter[1];
        });
    }
};

export const getGridCenter = (grid: number[][][]): number[] => {
    const squareCoords = grid[0];

    let x1 = squareCoords[0][0];
    let x2 = squareCoords[0][0];
    let y1 = squareCoords[0][1];
    let y2 = squareCoords[0][1];

    squareCoords.forEach((latLng) => {
        if (latLng[0] < x1) {
            x1 = latLng[0];
        }
        if (latLng[0] > x2) {
            x2 = latLng[0];
        }
        if (latLng[1] < y1) {
            y1 = latLng[1];
        }
        if (latLng[1] > y2) {
            y2 = latLng[1];
        }
    });

    return [x1 + (x2 - x1) / 2, y1 + (y2 - y1) / 2];
};

const defaultRegionBearings = {
    Johannesburg: -0.55,
    "Cape Town": 1.6
};

export const getDefaultRegionBearing = (selectedRegion: number, regions: DefaultRegion[]): number => {
    const region = regions.filter((reg) => selectedRegion === reg.id)[0];
    if (region) {
        // @ts-ignore
        return defaultRegionBearings[region.name] ?? 0;
    }

    return 0;
};

export const forecastingAvailableContainers = ["South Africa"];

export const RISK_INDEX_COLORS = ["#FFC300", "#F1920E", "#E3611C", "#C70039", "#900C3F", "#5A1846"];
export const TIMEWINDOW_LABELS = [
    "Late Night",
    "Early Morning",
    "Mid morning",
    "Afternoon",
    "Early Evening",
    "Evening"
];
