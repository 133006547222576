const clamp = (component: number): number => {
    if (component > 255) {
        return 255;
    } else if (component < 0) {
        return 0;
    }
    return component;
}

export default (color: string, amount: number): string => {
    let usePound: boolean = false;

    if (color[0] === "#") {
        color = color.slice(1);
        usePound = true;
    }

    const num = parseInt(color, 16);

    const r = clamp((num >> 16) + amount);
    const b = clamp(((num >> 8) & 0x00ff) + amount);
    const g = clamp((num & 0x000ff) + amount);

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}
