import React, { useState } from "react";
import {
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Radio,
    RadioGroup,
    Snackbar,
    SnackbarContent,
    IconButton
} from "@material-ui/core";
import { createClient } from "../types/api/admin";
import CloseIcon from "@material-ui/icons/Close";
import { CheckInput, Form, FormContainer, Section, TextInput } from "./styled";

export const CreateClient = () => {

  const [name, updateName] = useState<string>("");
  const [primaryEmail, updatePrimaryEmail] = useState<string>("");
  const [licenses, updateLicenses] = useState<string>("");
  const [containers, updateContainers] = useState<string[]>([]);
  const [firstName, updateFirstName] = useState<string>("");
  const [lastName, updateLastName] = useState<string>("");
  const [password, updatePassword] = useState<string>("");
  const [inviteUser, updateInviteUser] = useState<string>("now");
  const [openSuccessSnackbar, setOpenSucessSnackbar] = useState(false);
  const displaySuccessSnackbar = () => {
    setOpenSucessSnackbar(true);
  };
  const handleCloseSuccessSnackbar = () => {
    setOpenSucessSnackbar(false);
  };
  const [openWarningSnackbar, setOpenWarningSnackbar] = useState(false);
  const displayWarningSnackbar = () => {
    setOpenWarningSnackbar(true);
  };
  const handleCloseWarningSnackbar = () => {
    setOpenWarningSnackbar(false);
  };
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const displayErrorSnackbar = () => {
    setOpenErrorSnackbar(true);
  };
  const handleCloseErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  };
  const [errorString, setErrorString] = useState("");

  const resetForm = () => {
    updateName("");
    updatePrimaryEmail("");
    updateLicenses("");
    updateContainers([]);
    updateFirstName("");
    updateLastName("");
    updatePassword("");
    updateInviteUser("now");
  };

  const changeContainersArray = (newContainer: string) => {
    if (containers.includes(newContainer)) {
      const index = containers.indexOf(newContainer);
      const containersCopy = [...containers];
      containersCopy.splice(index, 1);
      updateContainers(containersCopy);
    } else {
      const containersCopy = [...containers];
      containersCopy.push(newContainer);
      updateContainers(containersCopy);
    }
  };

    const submit = () => {
        if (name && primaryEmail && licenses && containers && firstName && lastName) {
            const createClientObject = {
                client: {
                    name: name,
                    primary_email: primaryEmail,
                    licenses: Number(licenses),
                    containers: containers,
                },
                user: {
                    firstname: firstName,
                    lastname: lastName,
                    password: inviteUser === "now" ? password : undefined,
                },
                invite: inviteUser === "now" ? false : true,
            };

      createClient(createClientObject)
        .then(() => {
          displaySuccessSnackbar();
          resetForm();
        })
        .catch((errorResponse) => {
          setErrorString(errorResponse.message || "Unknown error. Let the dev team know about this :)");
          displayErrorSnackbar();
        });
    } else {
      displayWarningSnackbar();
    }
  };

  return (
    <FormContainer>
      <Form noValidate autoComplete="off">
        <Section>
          <TextInput
            id="name"
            label="Name (required)"
            variant="outlined"
            value={name}
            onChange={(e) => updateName(e.target.value)}
          />
          <TextInput
            id="licenses"
            label="Licenses (required)"
            variant="outlined"
            type="number"
            value={licenses}
            onChange={(e) => updateLicenses(e.target.value)}
          />
          <FormControl component="fieldset" style={{ margin: "20px 0 10px" }}>
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              Containers (required)
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <CheckInput
                    name="south-africa"
                    value={containers.includes("South Africa")}
                    onChange={(e) => changeContainersArray("South Africa")}
                  />
                }
                label="South Africa"
              />
              <FormControlLabel
                control={
                  <CheckInput
                    name="kenya"
                    value={containers.includes("Kenya")}
                    onChange={(e) => changeContainersArray("Kenya")}
                  />
                }
                label="Kenya"
              />
              <FormControlLabel
                control={
                  <CheckInput
                    name="nigeria"
                    value={containers.includes("Nigeria")}
                    onChange={(e) => changeContainersArray("Nigeria")}
                  />
                }
                label="Nigeria"
              />
            </FormGroup>
          </FormControl>
        </Section>
        <Section>
          <FormControl component="fieldset" style={{ margin: "20px 0 10px" }}>
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              Primary Admin Creation
            </FormLabel>
            <RadioGroup
              name="invite-user"
              value={inviteUser}
              onChange={(e) => updateInviteUser(e.target.value)}
            >
              <FormControlLabel
                value="now"
                control={<Radio />}
                label="Create User Now"
              />
              <FormControlLabel
                value="invite"
                control={<Radio />}
                label="Invite User"
              />
            </RadioGroup>
          </FormControl>
          <TextInput
            id="primary-email"
            label="Primary Email (required)"
            variant="outlined"
            value={primaryEmail}
            onChange={(e) => updatePrimaryEmail(e.target.value)}
          />
          <TextInput
            id="first-name"
            label="First Name (required)"
            variant="outlined"
            value={firstName}
            onChange={(e) => updateFirstName(e.target.value)}
          />
          <TextInput
            id="last-name"
            label="Last Name (required)"
            variant="outlined"
            value={lastName}
            onChange={(e) => updateLastName(e.target.value)}
          />
          {inviteUser === "now" && (
            <TextInput
              id="password"
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => updatePassword(e.target.value)}
            />
          )}
        </Section>
        <Button variant="contained" color="primary" onClick={submit}>
          Create Client
        </Button>
      </Form>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSuccessSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSuccessSnackbar}
        >
          <SnackbarContent
            style={{
              backgroundColor: "#20d420",
              color: "white",
            }}
            message="Form Submitted Successfully!"
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSuccessSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
      </div>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openWarningSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseWarningSnackbar}
        >
          <SnackbarContent
            style={{
              backgroundColor: "orange",
              color: "white",
            }}
            message="Please Fill In All Required Fields"
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseWarningSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
      </div>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openErrorSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseErrorSnackbar}
        >
          <SnackbarContent
            style={{
              backgroundColor: "red",
              color: "white",
            }}
            message={errorString}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseErrorSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
      </div>
    </FormContainer>
  );
};
