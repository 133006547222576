import { default as React,
         useState } from "react";
import { FormControl,
         Select,
         Input,
         MenuItem,
         Checkbox,
         ListItemText
       } from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

export default ({
    options,
    defaultValue,
    onChange
}: {
    options: string[],
    defaultValue: string[],
    onChange: (value: string[]) => void
}) => {
    const [ value, setValue ] = useState<string[]>(defaultValue);

    const children: React.ReactNode = options.map((option: string) => (
        <MenuItem key={option} value={option}>
          <Checkbox checked={value.includes(option)} />
          <ListItemText primary={option} />
        </MenuItem>
    ));

    const onSelectionChange = (event: React.ChangeEvent<{ value: any }>) => setValue(event.target.value as string[]);

    const renderValue = (): React.ReactNode => {
        if (defaultValue.length <= 0) {
            return "";
        } else if (defaultValue.length === 1) {
            return defaultValue[0];
        } else {
            return `${defaultValue[0]} +${defaultValue.length - 1}`;
        }
    };

    return (
        <FormControl style={{ width: '100%' }}>
	  <Select
            multiple
            value={value}
            input={<Input />}
            onClose={() => onChange(value)}
            onChange={onSelectionChange}
            renderValue={renderValue}
            MenuProps={MenuProps}
            style={{ marginTop: 0 }} >
            {children}
          </Select>
        </FormControl>
    );
};
