import { produce } from "immer";
import { payloadAction, actionFactory, ActionUnion, simpleAction } from "reductser";
import {
    Container,
    ContainerResponseItem,
    DefaultRegion,
} from "../../types/geo";
import { AdminUserProfile } from "../../types/admin";

const actionMap = {
    successfulSignIn: payloadAction<string>(),
    signOut: simpleAction(),
    successfulGetContainers: payloadAction<ContainerResponseItem>(),
    updateCurrentContainer: payloadAction<number>(),
    setProfile: payloadAction<AdminUserProfile | null>()
};

export const cuserActions = actionFactory(actionMap, "CUSER");

export type CuserAction = ActionUnion<typeof cuserActions>;

export interface CuserState {
    token: null | string;
    containers: Container[];
    defaultRegions: DefaultRegion[];
    current_container: number;
    profile: AdminUserProfile | null;
}

export const getInitialState = (): CuserState => ({
    token: localStorage.getItem("verstaanToken"),
    containers: [],
    current_container: Number.parseInt(localStorage.getItem("containerID") ?? "1"),
    defaultRegions: [],
    profile: null
});

const CuserReducer = (state = getInitialState(), action: CuserAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "CUSER") {
            switch (action.type) {
                case "successfulSignIn":
                    draftState.token = action.payload;
                    break;
                case "setProfile":
                    draftState.profile = action.payload;
                    break;
                case "signOut":
                    draftState.token = null;
                    break;
                case "successfulGetContainers":
                    draftState.containers = action.payload.containers;
                    draftState.defaultRegions = action.payload.default_regions;
                    break;
                case "updateCurrentContainer":
                    draftState.current_container = action.payload;
                    break;
                default:
                    return;
            }
        }
    });

export default CuserReducer;
