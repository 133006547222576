import { default as React,
    useState, useEffect } from "react";
import styled from "styled-components";
import { Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button, 
    Icon,
    TextField} from "@material-ui/core";
import { Risk } from "../../types/case";
import { updateAssetRisk } from "../../types/api/admin";

const Container = styled.div`
max-height: 100px;
overflow: hidden;
position: relative;
`;

const SeeMore = styled.a`
position: absolute;
right: 0;
bottom: 0;
color: gray;
text-decoration: none;
padding-left: 2em;
background-image: linear-gradient(to right, #fff0 0, white 2em);

&:hover {
   text-decoration: underline;
}
`;

const FullDescription = styled(DialogContentText)`
white-space: pre-line;
color: white;
`;
export interface FormattedRisk {
    id: number,
    name: string,
    assets: string,
    eventTypes: string,
    riskInterval: string,
    createdAt: Date,
    severity: number,
    chance: number,
    concerns: string,
    consequences: string,
    containers: string,
    regions: string,
    suggestions: string,
    comments: string
    needsReview: string,
}

export default ({props, riskData, setRiskData} : { props:FormattedRisk, riskData:Risk[], setRiskData: (risks : Risk[]) => void }) => {
const [ expanded, setExpanded ] = useState<boolean>(false);
const [ editing, setEditing ] = useState<boolean>(false);
const [ suggestionsText, setSuggestionsText ] = useState<string>(props.suggestions);

useEffect(() => {
  setSuggestionsText(props.suggestions)
},[props])

const expand = (event: React.SyntheticEvent) => {
   event.preventDefault();
   setExpanded(true);
};

const toggleEditing = () => {
 setEditing(!editing);
}

const onClose = () => setExpanded(false);

const update = async () => {
    if (suggestionsText !== null) {
        try {
            const oldRisk = riskData.find(risk => risk.id === props.id);
            if (oldRisk !== undefined) {
                let newRisk = {
                    asset_ids: oldRisk.asset_ids,
                    name: oldRisk.name,
                    id: oldRisk.id,
                    relevant_event_types: oldRisk.relevant_event_types,
                    risk_interval: oldRisk.risk_interval,
                    created_at: oldRisk.created_at,
                    severity: oldRisk.severity,
                    chance: oldRisk.chance,
                    updates: oldRisk.updates,
                    geography: oldRisk.geography,
                    concerns: oldRisk.concerns,
                    consequences: oldRisk.consequences,
                    is_obsolete: oldRisk.is_obsolete,
                    container_ids: oldRisk.container_ids,
                    default_region_ids: oldRisk.default_region_ids,
                    suggestions: suggestionsText,
                    comments: oldRisk.comments,
                }
                await updateAssetRisk(newRisk.id as number, newRisk, ["suggestopms"]);
                riskData[riskData.indexOf(oldRisk as Risk)] = newRisk;
                setRiskData(riskData);
            }
        } catch (err) {
            throw(err);
        }
    }
    
    setEditing(false);
}

const expansion: React.ReactNode = (
   <>
     <SeeMore onClick={expand}>View/Edit</SeeMore>
     <Dialog open={expanded}
             onClose={onClose}>
       <DialogTitle>
         Suggestions
         <Icon onClick={toggleEditing} style={{marginLeft: 20, paddingTop: 5, height: 25, cursor: "pointer"}}>edit</Icon>
       </DialogTitle>
       <DialogContent>
         {editing &&
           <TextField 
             style={{width: 500}} 
             multiline={true} 
             defaultValue={suggestionsText}
             onChange={(event) => setSuggestionsText(event.target.value)}
           />
         }
         {!editing &&
           <FullDescription>
             {suggestionsText}
           </FullDescription>
         }
       </DialogContent>
       <DialogActions>
         {editing &&
           <Button onClick={update} color="primary" autoFocus>Update</Button>
         }
         <Button onClick={onClose} color="primary" autoFocus>Close</Button>
       </DialogActions>
     </Dialog>
   </>
);

return (
   <Container>
     {suggestionsText}
     {expansion}
   </Container>
);
};
