import styled from "styled-components";
import { Popup } from "react-map-gl";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";

interface TooltipProps {
    background: string;
    color: string;
    sidepadding: string;
}

export const Tooltip = styled(Popup)`
    z-index: 5;
    color: ${(props: TooltipProps) => props.color};

    .mapboxgl-popup-content {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: ${(props: TooltipProps) => props.sidepadding};
        padding-right: ${(props: TooltipProps) => props.sidepadding};
        border-radius: 8px;
        transition: 0.8s;
        background: ${(props: TooltipProps) => props.background};
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    }

    .mapboxgl-popup-tip {
        border-top-color: ${(props: TooltipProps) => props.background};
        transition: 0.8s;
    }
`;

interface TooltipTextProps {
    color: string;
}

export const TooltipTitle = styled.div`
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    color: ${(props: TooltipTextProps) => props.color};
    margin-right: 15px;
`;

export const TooltipText = styled.div`
    font-weight: 600;
    font-size: 18px;
    color: ${(props: TooltipTextProps) => props.color};
    margin-right: 15px;
`;

export const TooltipAddress = styled.div`
    font-size: 16px;
    color: ${(props: TooltipTextProps) => props.color};
    margin-bottom: 10px;
    text-decoration: underline;
`;

export const TooltipPostedby = styled.div`
    font-size: 16px;
    color: ${(props: TooltipTextProps) => props.color};
`;

export const FullReportLink = styled.div`
    cursor: pointer;
    font-style: italic;
    margin-top: 5px;
    font-size: 16px;
    color: ${(props: TooltipTextProps) => props.color};
`;

export const ClusterTooltipContainer = styled.div`
    padding: 0 -15px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${(props: TooltipTextProps) => props.color};
`;

export const IndividualTooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 300px;
`;

export const GridTooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 300px;
`;
