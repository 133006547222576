/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Dialog,
    DialogContent,
    FormControl,
    IconButton,
    Input,
    makeStyles,
    MenuItem,
    Modal,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
    Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import LocationIcon from "@material-ui/icons/RoomOutlined";
import PictureIcon from "@material-ui/icons/PhotoCameraOutlined";
import { request } from "../types/api";
import * as MapboxGl from "mapbox-gl";
import jwtDecode from "jwt-decode";
import { AllReportTypes } from "../types/reports";
import { Form, PendingAampReport, Review } from "../types/aamp";
import { default as ReactMapboxGl, Marker } from "react-mapbox-gl";
import { useThrow } from "../catch";
import CategoryDropdown from "../aamp/categoryDropdown";
import { useSelectFromRedux } from "./../utils/_hooks";

var moment = require("moment");
require("mapbox-gl/dist/mapbox-gl.css");

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        margin: 20,
    },
    dialogText: {
        minWidth: 350,
        whiteSpace: "pre-line",
    },
    tableCell: {
        cursor: "pointer",
    },
    input: {
        width: 150,
    },
}));

const Map = ReactMapboxGl({
    accessToken: "pk.eyJ1IjoiYXRlbmt1bWFyIiwiYSI6ImNrYjlrcno3NTBmM3kzMm8wbWh4cjl3cWcifQ.vAoeZsaclJ5JD9Hr49jTDA",
});

interface RowData extends PendingAampReport {
    full_name: string;
    aamp_report_type: string;
    victim: string;
    aggressor: string;
    container_name: string;
    row: number;
}

type ExtendedReview = Review | "archived";

const toRowData = (report: Omit<RowData, "full_name" | "aamp_report_type" | "victim" | "aggressor">): RowData =>
    Object.assign(report, {
        full_name: `${report.first_name} ${report.last_name}`,
        aamp_report_type: report.fields.aamp_report_type,
        victim: report.fields.victim,
        aggressor: report.fields.aggressor,
    });

export const NewAamp = () => {
    const error = useThrow();
    const classes = useStyles();

    const token = useSelectFromRedux((state) => state.cuser.token);
    const { privileges } = jwtDecode(token as string);
    const [reviewFilter, setReviewFilter] = useState<ExtendedReview[]>([null]);
    const [forms, setForms] = useState<Record<number, Form>>({});
    const [data, setData] = useState<RowData[] | undefined>(undefined);
    const [filteredData, setFilteredData] = useState<RowData[]>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<string | any>("");
    const [modalContentType, setModalContentType] = useState<string>("string");
    const [editId, setEditId] = useState<number | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedForms = await request<{
                    [container_name: string]: Form[];
                }>(true, {
                    method: "GET",
                    url: "/aamp/getForms"
                });

                const forms: Record<number, Form> = {};
                const containers: Record<number, string> = {};
                for (const [container_name, containerForms] of Object.entries(fetchedForms)) {
                    for (const form of containerForms) {
                        forms[form.id] = form;
                        containers[form.id] = container_name;
                    }
                }

                setForms(forms);

                const data: RowData[] = (
                    await request<PendingAampReport[]>(true, {
                        method: "GET",
                        url: "/aamp/getPendingReports"
                    })
                ).map((report: PendingAampReport, row: number) =>
                    toRowData(
                        Object.assign(report, {
                            container_name: containers[report.form_id],
                            row,
                        })
                    )
                );
                setData(data);
            } catch (err) {
                error(err);
            }
        };
        fetchData();
    }, [token, error]);

    useEffect(() => {
        const filtered = data?.filter(({ super_user_review, manager_review, archived }: RowData) =>
            reviewFilter.includes(
                archived ? "archived" : super_user_review === null ? manager_review : super_user_review
            )
        );
        setFilteredData(filtered ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const toggleModal = (operation: string, type: string, value: any) => {
        if (operation === "open") {
            setModalOpen(true);
            setModalContent(value);
            setModalContentType(type);
        } else if (operation === "close") {
            setModalOpen(false);
            setModalContent("");
            setModalContentType("string");
        }
    };

    console.log(modalContent);

    return (
        <>
            <Paper>
                <Toolbar>
                    <Typography variant="h6">Reports</Typography>
                </Toolbar>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" />
                            <TableCell align="left">Reporter</TableCell>
                            <TableCell align="left">Time</TableCell>
                            <TableCell align="left">Reported At</TableCell>
                            <TableCell align="left">Location</TableCell>
                            <TableCell align="left">Type</TableCell>
                            <TableCell align="left">Victim</TableCell>
                            <TableCell align="left">Aggressor</TableCell>
                            <TableCell align="left">Description</TableCell>
                            <TableCell align="left">Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((data) => (
                            <TableRow key={data.id}>
                                <TableCell>
                                    {/* {data.isEditMode ? (
                                    <>
                                        <IconButton
                                            aria-label="done"
                                            //   onClick={() => onToggleEditMode(row.id)}
                                        >
                                            <DoneIcon />
                                        </IconButton>
                                        <IconButton
                                            aria-label="revert"
                                            //   onClick={() => onRevert(row.id)}
                                        >
                                            <RevertIcon />
                                        </IconButton>
                                    </>
                                ) : ( */}
                                    <Tooltip title="Edit">
                                        <IconButton
                                            aria-label="edit"
                                            onClick={() => {
                                                if (editId === null) {
                                                    setEditId(data.id);
                                                } else {
                                                    setEditId(null);
                                                }
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    {/* )} */}
                                </TableCell>
                                <CustomTableCell
                                    editable={data.id === editId}
                                    cellValue={data.full_name}
                                    toggleModal={toggleModal}
                                    type="fullName"
                                />
                                <CustomTableCell
                                    editable={data.id === editId}
                                    cellValue={data.date_time}
                                    toggleModal={toggleModal}
                                    type="dateTime"
                                />
                                <CustomTableCell
                                    editable={data.id === editId}
                                    cellValue={data.created_at}
                                    toggleModal={toggleModal}
                                    type="createdAt"
                                />
                                <CustomTableCell
                                    editable={data.id === editId}
                                    cellValue={data.address}
                                    toggleModal={toggleModal}
                                    type="address"
                                />
                                <CustomTableCell
                                    editable={data.id === editId}
                                    cellValue={data.aamp_report_type}
                                    toggleModal={toggleModal}
                                    type="reportType"
                                />
                                <CustomTableCell
                                    editable={data.id === editId}
                                    cellValue={data.victim}
                                    toggleModal={toggleModal}
                                    type="victim"
                                />
                                <CustomTableCell
                                    editable={data.id === editId}
                                    cellValue={data.aggressor}
                                    toggleModal={toggleModal}
                                    type="aggressor"
                                />
                                <CustomTableCell
                                    editable={data.id === editId}
                                    cellValue={data.description}
                                    toggleModal={toggleModal}
                                    type="description"
                                />
                                <TableCell>
                                    <Tooltip title="Open Map">
                                        <IconButton
                                            aria-label="location"
                                            onClick={() => toggleModal("open", "location", data.point!.coordinates)}
                                        >
                                            <LocationIcon />
                                        </IconButton>
                                    </Tooltip>
                                    {data.media!.length > 0 && (
                                        <Tooltip title="Open Image">
                                            <IconButton
                                                aria-label="media"
                                                onClick={() => toggleModal("open", "image", data.media![0])}
                                            >
                                                <PictureIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Dialog open={modalOpen && modalContent.length > 0} onClose={() => toggleModal("close", "", "")}>
                    <DialogContent className={classes.dialogContent}>
                        {modalContentType === "string" && (
                            <Typography className={classes.dialogText}>{modalContent}</Typography>
                        )}
                        {modalContentType === "image" && <img src={modalContent} alt="event" height="600px" />}
                        {modalContentType === "location" && (
                            <Map
                                // eslint-disable-next-line
                                style="mapbox://styles/mapbox/streets-v11"
                                containerStyle={{ height: "500px", width: "500px" }}
                                center={modalContent}
                                // onDrag={mapDragged}
                                onStyleLoad={(map: MapboxGl.Map) => map.resize()}
                            >
                                <img
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        height: "40px",
                                        width: "auto",
                                        transform: "translate(-50%, -100%)",
                                        zIndex: 1000,
                                    }}
                                    src="/location-marker-orange.png"
                                    alt="Location"
                                />
                            </Map>
                        )}
                    </DialogContent>
                </Dialog>
            </Paper>
        </>
    );
};

const CustomTableCell = ({ editable, cellValue, toggleModal, type }: any) => {
    const classes = useStyles();

    const getValue = () => {
        if (type === "description") {
            return cellValue.substring(0, 50);
        } else if (type === "dateTime" || type === "createdAt") {
            return moment(cellValue).format("MM/DD/YYYY hh:mm A");
        } else {
            return cellValue;
        }
    };

    const getInput = () => {
        if (type === "reportType") {
            return (
                <FormControl>
                    <Select
                        value={cellValue}
                        //   onChange={handleChange}
                    >
                        {AllReportTypes.map((type) => (
                            <MenuItem value={type}>{type}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        } else if (type === "address" || type === "description") {
            return (
                <Input
                    value={cellValue}
                    name={type}
                    onChange={(e) => console.log(e, type)}
                    className={classes.input}
                    multiline={true}
                />
            );
        } else if (type === "dateTime" || type === "createdAt") {
            return moment(cellValue).format("MM/DD/YYYY hh:mm A");
        } else {
            return cellValue;
        }
    };

    return (
        <TableCell
            align="left"
            onClick={() => {
                if (!editable && type === "description") toggleModal("open", "string", cellValue);
            }}
        >
            {editable ? getInput() : getValue()}
        </TableCell>
    );
};
