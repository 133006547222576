import React, { useEffect, useState } from "react";
import { Button, FormControl, InputLabel, Select, Input, MenuItem, ListItemText, Checkbox, FormLabel, TextField, Box, IconButton } from "@material-ui/core";
import { DataGrid, ColDef, ValueGetterParams, CellParams } from "@material-ui/data-grid";
import { Form, FormContainer, Section, ToggleBarContainer,  } from "./styled";
import { fetchActiveTripData, fetchUniversalGeometry, getActiveTripData, getAllDefaultRegions, getSummaryData, getTravelUsers, submitOutputChanges } from "../types/api/admin";
import {makeStyles} from '@material-ui/styles';
import { DestinationOutputs, TravelDestination, TravelTrip, TravelUser, TravelPriorityFeedItem, AnalystNote, TravelUniversalGeometry, AnalystGeometry, TravelIndex } from "../types/travelApp";
import ReactMapboxGl from "react-mapbox-gl";
import * as MapboxGl from "mapbox-gl";
import { FeatureCollection } from "geojson";
import { Layer, Feature, Source, Marker } from 'react-mapbox-gl';
import { DeckGL } from "@deck.gl/react";
import { StaticMap } from "react-map-gl";
import ViewStateProps from "@deck.gl/core/lib/deck";
import { GeoJsonLayer, LineLayer, IconLayer } from "@deck.gl/layers";
import DrawControl from 'react-mapbox-gl-draw';

import { TextInput, CheckInput, RoundButton, SearchButtonContainer } from "./styled";
import {
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { now } from "moment";
import { TravelAccommodation } from "../types/travelApp";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ListItem from '@material-ui/core/ListItem';
import { S3Key } from "../utils";
import { useRef } from "react";



const styles = makeStyles((theme) => ({
  root: {
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    color: 'black',
    backgroundColor: 'grey'
  },
}));



export const DisplayTravelActiveTrips = () => {

  const styles = makeStyles((theme) => ({
      root: {
        borderLeft: 0,
        borderRight: 0,
        borderBottom: 0,
        color: 'black',
        backgroundColor: 'grey'
      },
    }));
  const classes = styles()


  const [activeTrips, setActiveTrips] = useState<TravelTrip[]>([]);
  const [paymentFailedTrips, setPaymentFailedTrips] = useState<TravelTrip[]>([]);
  const [loadingActiveTrips, setLoadingActiveTrips] = useState<string>("Loading trips...");
  const [travelUsers, setTravelUsers] = useState<TravelUser[]>([])
  const [stage, setStage] = useState<string>("select trip")
  const [activeOrPayfail, setActiveOrPayfail] = useState<string>("active")

  const [univGeom, setUnivGeom] = useState<TravelUniversalGeometry[]>([])
  const [addedGeom, setAddedGeom] = useState<any[]>([])
  const [analystGeom, setAnalystGeom] = useState<AnalystGeometry[]>([])
  
  const [selectedTrip, setSelectedTrip] = useState<TravelTrip>()
  const [selectedUserProfile, setSelectedUserProfile] = useState<TravelUser>()
  const [selectedDestinations, setSelectedDestinations] = useState<TravelDestination[]>([])
  const [selectedOutputs, setSelectedOutputs] = useState<DestinationOutputs[]>([])

  const [selectedDestination, setSelectedDestination] = useState<TravelDestination>()
  const [selectedDestOutputs, setSelectedDestOutputs] = useState<DestinationOutputs>()

  const [mapCenter, setMapCenter] = useState<[number, number]>([50,50]);
  const [menuOpen, setMenuOpen] = useState<number>(0);
  const [addFormTitle, setAddFormTitle] = useState<string>("");
  const [addFormType, setAddFormType] = useState<string>("");
  const [newsSchema, enableNewsSchema] = useState<number>(0);
  const [openGeomInfo, setOpenGeomInfo] = useState<number>(0);
  const [openAddedGeomInfo, setOpenAddedGeomInfo] = useState<number>(0);
  const [feedItems, setFeedItems] = useState<TravelPriorityFeedItem[]>([]);
  const [includeReason, setIncludeReason] = useState<string>("");
  const [geomInfo, setGeomInfo] = useState<string[]>([]);
  const [selectedFeat, setSelectedFeat] = useState<Feature>();
  const [showUniv, setShowUniv] = useState<number>(0);
  const [noteText, setNoteText] = useState<string>("");
  const [noteTitle, setNoteTitle] = useState<string>("")
  const [analystNotes, setAnalystNotes] = useState<AnalystNote[]>([]);
  const [userAccommodations, setUserAccommodations] = useState<TravelAccommodation[]>([]);
  const [destIndices, setDestIndices] = useState<TravelIndex[]>([]);

  const [submitClicked, setSubmitClicked] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<string>("")


  const MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoidmVyc3RhYW4iLCJhIjoiY2s0YW12d3lsMDVjczNtcW5ramN0YzZ5byJ9.LaDF5JL90rFiTxgMerv_Yg"

  const ICON_MAPPING = {
    marker: {x: 0, y: 0, width: 128, height: 128, mask: true}
  };

  const Map = ReactMapboxGl({
    accessToken: MAPBOX_ACCESS_TOKEN
  });

  const viewportState = useRef<ViewStateProps>({
    // @ts-ignore
    width: 998,
    height: 600,
    latitude: 32.591153863146296,
    longitude: 9.533802457923096,
    zoom: 0.908387181524258,
    bearing: 0,
    pitch: 0,
    altitude: 1.5,
    maxZoom: 20,
    minZoom: 0,
    maxPitch: 60,
    minPitch: 0
  });

  let drawControlRef = useRef<DrawControl>(null);

  const formDesc = useRef("");
  const formCat = useRef("");

  const formReview = useRef("");

  const formNewsSource = useRef("");
  const formNewsLink = useRef("");

  const formTags = useRef("");
  const formTimeBins = useRef(["morning", "afternoon", "evening"]);


  useEffect(() => {

      const callData = async () => {

        const res = await getActiveTripData()

        setActiveTrips(res.activeTrips)
        setPaymentFailedTrips(res.paymentFailedTrips)

        const users = await getTravelUsers()
        setTravelUsers(users)

        const universalgeometry = await fetchUniversalGeometry()
        setUnivGeom(universalgeometry)

        setLoadingActiveTrips("Done")
          
      }

      callData();

  }, []);


  function getUserNames(params: ValueGetterParams) {
    const rowdata = params.data

    const userIds: number[] = rowdata.attending_users
    
    const attendingUsers: TravelUser[] = travelUsers.filter((user: TravelUser) => userIds.includes(user.id))

    if (attendingUsers.length > 0) {
      
      const emails: string[] = attendingUsers.map((user) => user.email)

      const emailstr: string = emails.join(", ")

      return emailstr

    } else {
      return ""
    }
  }

  async function selectTrip(cellValues: any) {

    const data = cellValues.data

    if (data.attending_users.length > 1) {

      // nothing here yet, this is where you would select the proper user once we implement groups

    } else {

      setLoadingActiveTrips("Pulling selected trip...")

      setSelectedTrip(data)

      const userId: number = data.attending_users[0]

      const activetripdata = await fetchActiveTripData(userId, data.id)

      console.log(activetripdata)

      setSelectedUserProfile(activetripdata.profile)
      setSelectedDestinations(activetripdata.destinations)
      setSelectedOutputs(activetripdata.outputs)

      if (activetripdata.destinations.length > 0 && activetripdata.outputs.length > 0) {

        // COMMENTED OUT until streamlining singular destination active trip adjustments is ironed out
      
        // if (data.singular_dest) { // if single destination trip

        //   console.log("singular dest")

        //   console.log(activetripdata)

        //   await selectDestination(activetripdata.destinations[0])

        //   setStage("edit dest")
        //   setLoadingActiveTrips("Done")

        // } else { // if multi dest trip

          // console.log("multi dest")

        setStage("pick dest")
        setLoadingActiveTrips("Done")

        //}
      } else {

        setLoadingActiveTrips("ERROR - Can't seem to find the necessary destinations or outputs associated with this trip...")

      }

    }
  }

  async function selectDestination(dest: any) {

    setLoadingActiveTrips("Selecting destination and compiling...")

    setSelectedDestination(dest)

    const outputs = selectedOutputs.filter((outputs) => outputs.id == dest.id)[0]

    setSelectedDestOutputs(outputs)

    setAnalystNotes(outputs.analyst_notes)
    setDestIndices(outputs.indices)

    setMapCenter(dest.point_location.coordinates)
    setFeedItems(outputs.secondary.combined_feed)
    setUserAccommodations(dest.accommodations)
    
    const analystgeom: AnalystGeometry[] = outputs.secondary.analyst_geometry
    setAnalystGeom(analystgeom)
    const polys: Feature[] = analystgeom.map((geom) => geom.geometry as Feature) || []
    setAddedGeom(polys)

    // const locations: Feature[] = analy
    //     .filter(
    //         (geom) =>
    //             geom.type === "safety_point" ||
    //             geom.type === "violent_point" ||
    //             geom.type === "nonviolent_point" ||
    //             geom.type === "geonews_point" ||
    //             geom.type === "analystnote_point"
    //     )
    //     .map((geom) => geom.geometry);

    setStage("edit dest")
    setLoadingActiveTrips("Done")

  }


  async function resetChanges() {

    setMapCenter(selectedDestination!.point_location.coordinates as [number, number])
    setFeedItems(selectedDestOutputs!.secondary.combined_feed)
    
    const analystgeom: AnalystGeometry[] = selectedDestOutputs!.secondary.analyst_geometry
    setAnalystGeom(analystgeom)
    const polys: Feature[] = analystgeom.map((geom) => geom.geometry as Feature) || []
    setAddedGeom(polys)

  }

  async function handleGeomClick(id: string, type: string, category: string, desc: string, tags: string, time_bins: string, feat: Feature) {

    setSelectedFeat(feat)
    setGeomInfo([id, type, category, desc, tags, time_bins])
    setOpenGeomInfo(1)

  }

  async function handleAddedGeomClick(id: string, type: string, category: string, desc: string, tags: string, time_bins: string, feat: Feature) {

    setSelectedFeat(feat)
    setGeomInfo([id, type, category, desc, tags, time_bins])
    setOpenAddedGeomInfo(1)

  }

  const layers: any[] = [
    new IconLayer({
      id: 'icon-layer',
      data: userAccommodations,
      pickable: true,
      // iconAtlas and iconMapping are required
      // getIcon: return a string
      iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
      iconMapping: ICON_MAPPING,
      getIcon: d => 'marker',
      sizeScale: 15,
      getPosition: d => d.point.coordinates as [number, number],
      getSize: 5,
      getColor: [255,255,255]
    }),
    new GeoJsonLayer({
    id: `unigeom`,
    //@ts-ignore
    data: (showUniv == 1) ? univGeom : [],
    getLineWidth: 5,
    getFillColor: (geom: any) => {
      if (geom.properties.type === "safety_polygon" || geom.properties.type === "safety_point") return [0,255,0]
      else if (geom.properties.type === "violent_polygon" || geom.properties.type === "violent_point" ) return [255,0,0]
      else if (geom.properties.type === "nonviolent_polygon" || geom.properties.type === "nonviolent_point" ) return [0,0,255]
      else if (geom.properties.type === "geonews_point" ) return [50,50,100]
      else return [50,50,50]
    },
    opacity: .02,
    pickable: true,
    onClick: (info, event) => {
      // @ts-ignore
      const feat = info.object
      // @ts-ignore
      const props = info.object.properties
      handleGeomClick(props.id.toString(), props.type, props.category, props.description, props.tags, props.time_bins, feat as Feature)
    }
    }),
    new GeoJsonLayer({
      id: `geom`,
      //@ts-ignore
      data: addedGeom,
      getLineWidth: 5,
      getFillColor: (geom: any) => {
        if (geom.properties.type === "safety_polygon" || geom.properties.type === "safety_point") return [0,255,0]
        else if (geom.properties.type === "violent_polygon" || geom.properties.type === "violent_point" ) return [255,0,0]
        else if (geom.properties.type === "nonviolent_polygon" || geom.properties.type === "nonviolent_point" ) return [0,0,255]
        else if (geom.properties.type === "geonews_point" ) return [50,50,100]
        else return [50,50,50]
      },
      opacity: .1,
      pickable: true,
      onClick: (info, event) => {
        // @ts-ignore
        const feat = info.object
        console.log(feat)
        // @ts-ignore
        const props = info.object.properties
        console.log(props)
        handleAddedGeomClick(props.id.toString(), props.type, props.category, props.description, props.tags, props.time_bins, feat as Feature)

      }
    }),
    new IconLayer({
      id: 'points',
      data: addedGeom.filter((geom) => (geom.properties.type === "safety_point" || geom.properties.type === "violent_point" || geom.properties.type === "nonviolent_point" || geom.properties.type === "geonews_point" || geom.properties.type === "analystnote_point")),
      iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
      iconMapping: ICON_MAPPING,
      getIcon: d => 'marker',
      sizeScale: 15,
      // @ts-ignore
      getPosition: d => d.geometry.coordinates,
      getSize: 5,
      opacity: .5,
      onClick: (info, event) => {
        // @ts-ignore
        const feat = info.object
        // @ts-ignore
        const props = info.object.properties
        handleGeomClick(props.id.toString(), props.type, props.category, props.description, props.tags, props.time_bins, feat as Feature)

      },
      
      getColor: (geom: any) => {
          if (geom.properties.type === "safety_polygon" || geom.properties.type === "safety_point") return [0,255,0]
          else if (geom.properties.type === "violent_polygon" || geom.properties.type === "violent_point" ) return [255,0,0]
          else if (geom.properties.type === "nonviolent_polygon" || geom.properties.type === "nonviolent_point" ) return [0,0,255]
          else if (geom.properties.type === "geonews_point" ) return [50,50,100]
          else return [50,50,50]
      }
      })
  ]


  async function handleMenu(mode: string) {

    switch (mode) {
        case "safety_polygon":
            enableNewsSchema(0)
            setOpenGeomInfo(0)
            setAddFormTitle("Add a Safety Polygon")
            setAddFormType(mode)
            setMenuOpen(2)
            break;

        case "violent_polygon":
            enableNewsSchema(0)
            setOpenGeomInfo(0)
            setAddFormTitle("Add a Violent Polygon")
            setAddFormType(mode)
            setMenuOpen(2)
            break;

        case "nonviolent_polygon":
            enableNewsSchema(0)
            setOpenGeomInfo(0)
            setAddFormTitle("Add a Non-Violent Polygon")
            setAddFormType(mode)
            setMenuOpen(2)
            break;
        case "safety_point":
            enableNewsSchema(0)
            setOpenGeomInfo(0)
            setAddFormTitle("Add a Safety Point")
            setAddFormType(mode)
            setMenuOpen(1)
            break;
        case "violent_point":
            enableNewsSchema(0)
            setOpenGeomInfo(0)
            setAddFormTitle("Add a Violent Point")
            setAddFormType(mode)
            setMenuOpen(1)
            break;
        case "nonviolent_point":
            enableNewsSchema(0)
            setOpenGeomInfo(0)
            setAddFormTitle("Add a Non-Violent Point")
            setAddFormType(mode)
            setMenuOpen(1)
            break;
        case "analystnote_point":
            enableNewsSchema(0)
            setOpenGeomInfo(0)
            setAddFormTitle("Add an Analyst Note Point")
            setAddFormType(mode)
            setMenuOpen(1)
            break;
        case "geonews_point":
            setOpenGeomInfo(0)
            setAddFormTitle("Add a Geolocated News Point")
            setAddFormType(mode)
            enableNewsSchema(1)
            setMenuOpen(1)
            break;
      }
  }

  async function closeInfoText() {
    //setOpenReportInfo(0)
    setIncludeReason("")
    setOpenAddedGeomInfo(0)
    setOpenGeomInfo(0)
  }

  async function closeMenu() {
    // @ts-ignore
    formTimeBins.current = ["morning", "afternoon", "evening"]
    setAddFormTitle("")
    setAddFormType("")
    enableNewsSchema(0)
    setMenuOpen(0)
  }

  const onDragEnd = ({ destination, source }: any) => {
      
    // dropped outside the list
    if (!destination) return;

    const newItems = arrayMove(feedItems, source.index, destination.index);

    console.log(newItems)

    setFeedItems(newItems);
  };

  function arrayMove(arr: any[], old_index: number, new_index: number) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
    };

    async function deleteFeedItem(feedItem: TravelPriorityFeedItem) {

      if (feedItem.type === "index") setDestIndices(destIndices.filter((index) => index.fullname !== feedItem.header))

      if (feedItem.type === "analyst_note") setAnalystNotes(analystNotes.filter((note) => note.text !== feedItem.text))

      const newItems: TravelPriorityFeedItem[] = feedItems.filter((item) => item != feedItem)
      setFeedItems(newItems)
    }

    useEffect(() => {

      let newFeedItems: TravelPriorityFeedItem[] = [];

      let count = 0

      analystNotes.forEach((note) => {

        newFeedItems.push({
          id: count,
          header: note.title,
          text: note.text,
          type: "analyst_note",
          links: note.links
        })

        count += 1

      })

      destIndices?.forEach((index) => {
        
        newFeedItems.push({
          id: count,
          header: index.fullname,
          text: index.score,
          type: "index",
          severity: index.severity,
          links: index.links,
          description: index.description
        })

        count += 1

      })

      setFeedItems(newFeedItems)

    }, [analystNotes, destIndices]);


    async function handleAddNewAsset() {

      // @ts-ignore
      const drawing: FeatureCollection = drawControlRef?.current?.draw?.getAll()

      drawing.features.forEach((feat) => {

          const newfeat = {
              ...feat,
              properties: {
                  id: 'N/A',
                  type: addFormType,
                  // @ts-ignore
                  category: formCat.current.value,
                  // @ts-ignore
                  description: formDesc.current.value,
                  // @ts-ignore
                  local_review: formReview.current.value,
                  // @ts-ignore
                  news_source: formNewsSource.current.value,
                  // @ts-ignore
                  news_link: formNewsLink.current.value,
                  created_at: now(),
                  // @ts-ignore
                  time_bins: formTimeBins.current,
                  // @ts-ignore
                  tags: (formTags.current.value).split(",")
              }
          }

          setAddedGeom([...addedGeom, newfeat])

          const newAnalystGeom: AnalystGeometry = {
            // @ts-ignore
            type: addFormType,
            // @ts-ignore
            category: formCat.current.value,
            // @ts-ignore
            local_review: formReview.current.value,
            geometry: newfeat,
            // @ts-ignore
            description: formDesc.current.value,
            // @ts-ignore
            tags: (formTags.current.value).split(","),
            // @ts-ignore
            time_bins: formTimeBins.current
          }

          setAnalystGeom([...analystGeom, newAnalystGeom])

      })

      setAddFormTitle("")
      setAddFormType("")
      enableNewsSchema(0)
      setMenuOpen(0)

    }

    async function handleAddUnivAsset(feat: any) {

      const newAnalystGeom: AnalystGeometry = {
        type: feat.properties.type,
        category: feat.properties.category,
        geometry: feat,
        description: feat.properties.description,
        tags: feat.properties.tags,
        time_bins: feat.properties.time_bins,
        reason_for_including: includeReason
      }

      setAddedGeom([...addedGeom, feat])
      setAnalystGeom([...analystGeom, newAnalystGeom])

      closeInfoText()

    }

    async function removeAssetFromTrip(object: Feature | FeatureCollection) {

      setAddedGeom(addedGeom.filter((geom) => geom !== object))
      setAnalystGeom(analystGeom.filter((geom) => geom.geometry !== object))

      closeInfoText()

    }


    async function submitChanges() {

      setSubmitClicked(true)
      setSubmitLoading("Pushing changes to the user...")

      if (selectedDestination && selectedUserProfile && selectedDestOutputs && selectedTrip) {

        const currentStateOutputs: DestinationOutputs = {

          id: selectedDestination!.id,
          user_id: selectedUserProfile!.id,
          trip_id: selectedTrip!.id,
          kde_violent: selectedDestOutputs!.kde_violent,
          kde_nonviolent: selectedDestOutputs!.kde_nonviolent,
          rt_prop: selectedDestOutputs!.rt_prop,
          indices: destIndices,
          analyst_notes: analystNotes,
          secondary: {
            analyst_geometry: analystGeom,
            combined_feed: feedItems,
            // @ts-ignore
            city_insights: selectedDestOutputs!.secondary.city_insights,
            // @ts-ignore
            city_vendors: selectedDestOutputs!.secondary.city_vendors
          }
          
        }

        const res = await submitOutputChanges(currentStateOutputs)

        setSubmitLoading(res)

        if (res === "Success!") window.location.reload()

      }
    }



  const activetripcolumns: ColDef[] = [
    { field: 'name', headerName: 'Trip Name', width: 300 },
    { field: 'trip_start', headerName: 'Start Date', width: 105 },
    { field: 'trip_end', headerName: 'End Date', width: 105 },
    { field: 'Attending Users', width: 200, valueGetter: getUserNames },

    //{ field: 'Trip Owner', headerName: 'Trip Owner', width: 100,  valueGetter: getTripOwnerName},
    { field: 'Edit Trip', renderCell: (cellValues) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => selectTrip(cellValues)}
          >
            SELECT
          </Button>
        );
      } },
  ];

  const payfailcolumns: ColDef[] = [
    { field: 'name', headerName: 'Trip Name', width: 300 },
    { field: 'trip_start', headerName: 'Start Date', width: 105 },
    { field: 'trip_end', headerName: 'End Date', width: 105 },
    { field: 'Attending Users', width: 200, valueGetter: getUserNames },
  ];

  const destcolumns: ColDef[] = [
    { field: 'name', headerName: 'Destination Name', width: 300 },
    { field: 'dest_start', headerName: 'Start Date', width: 105 },
    { field: 'dest_end', headerName: 'End Date', width: 105 },
    { field: 'country_abbr', headerName: 'Country', width: 100 },

    //{ field: 'Trip Owner', headerName: 'Trip Owner', width: 100,  valueGetter: getTripOwnerName},
    { field: 'Select Destination', renderCell: (cellValues) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => selectDestination(cellValues.data)}
          >
            SELECT
          </Button>
        );
      } },
  ];
    

  return (

    <div>
        <ToggleBarContainer>
            <Button size="large" href="/travelPendingTrips">
                Pending Trips
            </Button>
            <Button size="large" href="/travelApp" >
                Home
            </Button>
            <Button size="large" href="/travelAreas" >
                Countries & Cities
            </Button>
            <Button size="large" color="primary" >
                Active Trips
            </Button>
        </ToggleBarContainer>
        {stage !== "select trip" && <ToggleBarContainer>
          <Button size="large" variant="outlined" onClick={() => window.location.reload()}>
            RETURN TO TRIP SELECTION
          </Button>
          {stage === "edit dest" && typeof selectedDestination !== "undefined" && typeof selectedDestOutputs !== "undefined" && <Button size="large" variant="outlined" onClick={async() => await resetChanges()} >
            Reset Changes
          </Button>}
          </ToggleBarContainer>}
        <div style={{height: 50}}/>
        {loadingActiveTrips !== "Done" && <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px', height: '50px'}} >
            {loadingActiveTrips}
        </div>}
        {stage ==="select trip" && loadingActiveTrips === "Done" && <div>
        <ToggleBarContainer>
          <Button size="large" variant={(activeOrPayfail === "active") ? "contained" : "outlined"} onClick={() => setActiveOrPayfail("active")} >
            Active Trips
          </Button>
          <Button size="large" variant={(activeOrPayfail === "payfail") ? "contained" : "outlined"} onClick={() => setActiveOrPayfail("payfail")} >
            Payment Failed
          </Button>
        </ToggleBarContainer>
        </div>}
        {stage ==="select trip" && loadingActiveTrips === "Done" && activeOrPayfail === "active" && <div style={{height: 300}}>
        <DataGrid rows={activeTrips} columns={activetripcolumns} pageSize={8} rowsPerPageOptions={[5]} className={classes.root} />
        </div>}
        {stage ==="select trip" && loadingActiveTrips === "Done" && activeOrPayfail === "payfail" && <div style={{height: 300}}>
        <DataGrid rows={paymentFailedTrips} columns={payfailcolumns} pageSize={8} rowsPerPageOptions={[5]} className={classes.root} />
        </div>}
        {stage === "pick dest" && loadingActiveTrips === "Done" && <div style={{height: 300}}>
        <DataGrid rows={selectedDestinations} columns={destcolumns} pageSize={8} rowsPerPageOptions={[5]} className={classes.root} />
        </div>}

        {stage === "edit dest" && loadingActiveTrips === "Done" && <div style={{ height: 500, width: '100%', position: 'relative' }}>

        <div style={{ height: '100%', width: '100%', position: 'relative' }} >
          <DeckGL
            height="100%"
            width={(menuOpen > 0) ? '65%' : '100%'}
            controller
            layers={(menuOpen > 0) ? [] : layers}
            initialViewState={{
              longitude: mapCenter[0],
              latitude: mapCenter[1],
              zoom: 10,
              pitch: 0,
              bearing: 0
            }}
            // @ts-ignore
            viewState={viewportState.current.value}
            onViewStateChange={(viewState) => {
              viewportState.current = viewState.viewState;
          }}
          >

          { menuOpen > 0 && <Map
              // eslint-disable-next-line react/style-prop-object
              style="mapbox://styles/mapbox/satellite-streets-v9"
              containerStyle={{
                  display: "flex",
                  width: '100%',
                  height: "100%",
                  position: "sticky",
                  right: 10,
                  top: 80,
                  bottom: 10,
                  borderRadius: 10
              }}
              // @ts-ignore
              center={[viewportState.current.longitude as number, viewportState.current.latitude as number]}
              //onStyleLoad={onStyleLoad}
              // @ts-ignore
              zoom={[viewportState.current.zoom]}
              >
                
            {<DrawControl 
                ref={drawControlRef}
                userProperties={true}
                //onDrawCreate={onDrawCreate}
                //onDrawUpdate={onDrawUpdate}
                controls={{
                "point": (menuOpen == 1) ? true : false,
                "line_string": false, 
                "polygon": (menuOpen == 2) ? true : false,
                "trash": (menuOpen > 0) ? true : false,
                "combine_features": false,
                "uncombine_features": false
                }}
            />}

          </Map>}

          { menuOpen == 0 && 
          <StaticMap width="100%" height="90%" mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle={"mapbox://styles/mapbox/satellite-streets-v9"} />
          }

          </DeckGL>
          {menuOpen > 0 && <div style={{float: "right"}}>
              <div style={{ alignItems: 'left', display: 'flex', justifyContent: 'left', fontSize: '20px', height: '50px'}} >
              {addFormTitle}
              </div>

              <FormControl>
              <FormLabel component="legend" style={{ margin: "10px 0" }}>
                Description
                </FormLabel>
                <TextInput
                    id="geomdesc"
                    variant="outlined"
                    inputRef={formDesc}
                    //value={addFormDesc}
                    //onChange={(e) => setAddFormDesc(e.target.value)}
                />
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                {(newsSchema == 0) ? "Category (ex. Hotel, Restaurant)" : "News Headline" }
                </FormLabel>
                <TextInput
                    id="geomcateg"
                    variant="outlined"
                    inputRef={formCat}
                    //value={geomcateg}
                    //onChange={(e) => updateMessageText(e.target.value)}
                  />
                  <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Add a Recent Review (optional)
                    </FormLabel>
                    <TextInput
                        id="geomreview"
                        variant="outlined"
                        inputRef={formReview}
                        //value={addFormDesc}
                        //onChange={(e) => setAddFormDesc(e.target.value)}
                    />
                  {/* <FormLabel component="legend" style={{ margin: "10px 0" }}>
                  Time Bins
                  </FormLabel>
                  <TextInput
                      id="geomdesc"
                      variant="outlined"
                      inputRef={formDesc}
                      //value={addFormDesc}
                      //onChange={(e) => setAddFormDesc(e.target.value)}
                /> */}
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                {"Tags (Separate with a comma)"}
                </FormLabel>
                <TextInput
                    id="geomdesc"
                    variant="outlined"
                    inputRef={formTags}
                    //value={addFormDesc}
                    //onChange={(e) => setAddFormDesc(e.target.value)}
                />
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                  Time Bins
                  </FormLabel>
                <FormGroup>
                  <FormControlLabel
                      control={
                      <CheckInput
                          name="morning"
                          defaultChecked={true}
                          onChange={(e) => {
                              // @ts-ignore
                              if ((formTimeBins.current).includes("morning") == false) {
                                  // @ts-ignore
                                  formTimeBins.current = [...formTimeBins.current, "morning"]
                              } else {
                                  const newarr = (formTimeBins.current).filter((bin) => bin !== "morning")
                                  formTimeBins.current = newarr
                              }
                          }}
                      />
                      }
                      label="Morning"
                  />
                  <FormControlLabel
                      control={
                      <CheckInput
                          name="afternoon"
                          defaultChecked={true}
                          onChange={(e) => {
                              // @ts-ignore
                              if ((formTimeBins.current).includes("afternoon") == false) {
                                  // @ts-ignore
                                  formTimeBins.current = [...formTimeBins.current, "afternoon"]
                              } else {
                                  const newarr = (formTimeBins.current).filter((bin) => bin !== "afternoon")
                                  formTimeBins.current = newarr
                              }
                          }}
                      />
                      }
                      label="Afternoon"
                  />
                  <FormControlLabel
                      control={
                      <CheckInput
                          name="evening"
                          defaultChecked={true}
                          onChange={(e) => {
                              // @ts-ignore
                              if ((formTimeBins.current).includes("evening") == false) {
                                  // @ts-ignore
                                  formTimeBins.current = [...formTimeBins.current, "evening"]
                              } else {
                                  const newarr = (formTimeBins.current).filter((bin) => bin !== "evening")
                                  formTimeBins.current = newarr
                              }
                          }}
                      />
                      }
                      label="Evening"
                  />
                  </FormGroup>
                { newsSchema == 1 && <div>
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                News Source
                </FormLabel>
                <TextInput
                    id="geomsource"
                    variant="outlined"
                    inputRef={formNewsSource}
                    //value={geomcateg}
                    //onChange={(e) => updateMessageText(e.target.value)}
                  />
                  <FormLabel component="legend" style={{ margin: "10px 0" }}>
                  News Link
                  </FormLabel>
                  <TextInput
                      id="geomnewslink"
                      variant="outlined"
                      inputRef={formNewsLink}
                      //value={geomcateg}
                      //onChange={(e) => updateMessageText(e.target.value)}
                      />
                  </div>}
                  <Button
                  onClick={() => {handleAddNewAsset()}}
                  >
                  Add Asset to Destination
              </Button>
              <Button>

              </Button>
              <Button onClick={async() => {await closeMenu()}} >
                  Close
              </Button>
              </FormControl>
              
              
          </div>}
          </div>
          {openGeomInfo == 1 && 
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', whiteSpace: 'pre-line', color: "yellow", height: '170px'}} >
          <ToggleBarContainer>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', whiteSpace: 'pre-line', color: "yellow"}} >
          Clicked Polygon Info {'\n'} ID: {geomInfo[0]} {'\n'} {geomInfo[1]} {'\n'} Category: {geomInfo[2]} {'\n'} Description: {geomInfo[3]} {'\n'} Tags: {geomInfo[4]} {'\n'} Time Bins: {geomInfo[5]}
          </div>
          <Button onClick={() => {closeInfoText()}}>
              Close
          </Button>
          <Button onClick={() => { if (typeof selectedFeat !== "undefined") handleAddUnivAsset(selectedFeat)}} >
              Add Asset to Trip
          </Button>
          </ToggleBarContainer>
          </div>
          }
          {openGeomInfo == 1 && <div>
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              Tell the user why you chose to include this in their trip! (Optional)
            </FormLabel>
            <TextInput
            id="includereason"
            variant="outlined"
            //inputRef={formNewsLink}
            value={includeReason}
            onChange={(e) => setIncludeReason(e.target.value)}
            />
          </div>}
          {openAddedGeomInfo == 1 && 
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', whiteSpace: 'pre-line', color: "cyan", height: '170px'}} >
          <ToggleBarContainer>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', whiteSpace: 'pre-line', color: "cyan"}} >
          Clicked Polygon Info {'\n'} ID: {geomInfo[0]} {'\n'} {geomInfo[1]} {'\n'} Category: {geomInfo[2]} {'\n'} Description: {geomInfo[3]} {'\n'} Tags: {geomInfo[4]} {'\n'} Time Bins: {geomInfo[5]}
          </div>
          <Button onClick={() => {closeInfoText()}}>
              Close
          </Button>
          <Button onClick={() => { if (typeof selectedFeat !== "undefined") removeAssetFromTrip(selectedFeat)}} >
              Remove Asset from Trip
          </Button>
          </ToggleBarContainer>
          </div>
          }
          {menuOpen == 0 && <ToggleBarContainer>

            <Button onClick={() => setShowUniv(1)} color={((showUniv==1) ? 'primary' : 'default')} >
              Show preset world assets
            </Button>
            <Button onClick={() => setShowUniv(0)} color={((showUniv==0) ? 'primary' : 'default')} >
              hide world assets
            </Button>
            
          </ToggleBarContainer>}
          {menuOpen == 0 && <ToggleBarContainer>
            <Button onClick={async() => await handleMenu("safety_polygon")} >
                Safety Polygon
            </Button>
            <Button onClick={async() => await handleMenu("violent_polygon")} >
                Violent Risk Polygon
            </Button>
            <Button onClick={async() => await handleMenu("nonviolent_polygon")} >
                Non-Violent Risk Polygon
            </Button>
        </ToggleBarContainer>}
        {menuOpen == 0 && <ToggleBarContainer>
            <Button onClick={async() => await handleMenu("safety_point")} >
                Safety Point
            </Button>
            <Button onClick={async() => await handleMenu("violent_point")} >
                Violent Risk Point
            </Button>
            <Button onClick={async() => await handleMenu("nonviolent_point")} >
                Non-Violent Risk Point
            </Button>
            <Button onClick={async() => await handleMenu("analystnote_point")} >
                Analyst Note Point
            </Button>
            <Button onClick={async() => await handleMenu("geonews_point")} >
                Geolocated News
            </Button>
        </ToggleBarContainer>}

        <div style={{height: 100}}/>

        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', height: '70px'}} >
        <TextField size="medium" label="Analyst Note Title" margin="normal" onChange={(e) => setNoteTitle(e.target.value)} />
        <TextField size="medium" label="Analyst Note" margin="normal" onChange={(e) => setNoteText(e.target.value)} />
        <Button onClick={() => setAnalystNotes([...analystNotes, {title: noteTitle, text: noteText, links: []}])} >
          Add Note
        </Button>
        </div>

        <div style={{height: 100}}/>

        <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {feedItems.map((item: TravelPriorityFeedItem, index: any) => (
                <Draggable draggableId={item.id.toString()} index={index} key={item.id.toString()}  >
                  {(provided, snapshot) => (
                    <ListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      //className={snapshot.isDragging ? classes.draggingListItem : ''}
                    >
                      <ListItemText primary={item.header} secondary={item.text} />
                      <Button onClick={() => {
                        //setSelectedLinkHost(item)
                        //setOpenLink(1)
                        }} >
                        Link
                      </Button>
                      <Button>

                      </Button>
                      <Button onClick={async() => {await deleteFeedItem(item)}} >
                        Delete
                      </Button>
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
            
          )}
        </Droppable>
      </DragDropContext>

      <div style={{height: 150}}/>

      {submitLoading === "" && <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center',color: "red"}}>
        <Button
        variant="outlined"
        color="inherit"
        onClick={async() => await submitChanges()}
        >
        SEND CHANGES TO USER
        </Button>
      </div>}

        </div>}

    </div>

  )

}