import React from "react";
import { TooltipTitle, TooltipAddress, TooltipPostedby, IndividualTooltipContainer } from "./styled";
import { useSelectFromRedux } from "../../utils/_hooks";
import { S3Key } from "../../utils";

const checkmarkGreenIcon = S3Key + "check_mark.png";

export default function IndividualTooltip() {
    const [{ selectedMarker }] = useSelectFromRedux((state) => [state.map]);
    const colors = useSelectFromRedux((state) => state.color);

    return (
        <IndividualTooltipContainer>
            <TooltipTitle color={colors.scheme.primaryText}>
                {selectedMarker.properties.title}
                {selectedMarker.properties.verified > 0 && (
                    <img
                        src={checkmarkGreenIcon}
                        alt="Verified"
                        style={{
                            height: "19px",
                            marginTop: "-4px",
                            marginLeft: "4px"
                        }}
                    />
                )}
                {selectedMarker.properties.verified === 0 && (
                    <img
                        src={"https://orion-assets-s3.s3.us-east-2.amazonaws.com/open-circle-amber.png"}
                        alt="Unverified"
                        style={{
                            height: "19px",
                            marginTop: "-4px",
                            marginLeft: "4px"
                        }}
                    />
                )}
            </TooltipTitle>
            <TooltipAddress color={colors.scheme.secondaryHeaderText}>
                {selectedMarker.properties.location}
            </TooltipAddress>
            <TooltipPostedby color={colors.scheme.secondaryHeaderText}>
                Posted by {selectedMarker.properties.postedBy}
                <br />
                {selectedMarker.properties.time}
            </TooltipPostedby>
        </IndividualTooltipContainer>
    );
}
