import { Button, Icon, InputAdornment, Paper, SvgIcon, Tab, Tabs, TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import error from "../error";
import { mapActions } from "../state/map";
import { addAsset, getAllClients, getAllTeams, getAssetRisks, getAssets, getAssetTypes, updateAsset } from "../types/api/admin";
import { Client, Team } from "../types/client";
import { useSelectFromRedux } from "../utils/_hooks";
import { FormattedAsset } from "./map";
import { AssetModalContainer, ExitButton, ModalTitle, ModalAddress, ModalText, EditButton, HoverDiv, FieldGroup } from "./styled";
import { Autocomplete } from "@material-ui/lab";
import { TextInput } from "../admin/styled";
import { AllAssetConcerns, Asset, Risk } from "../types/case";
import { AllReportTypes } from "../types/reports";
import { VerticallyCenteredRow } from "../_shared";
import * as _ from "lodash";
import { LineString, Polygon, Point } from "geojson";

export default ({props, getAssetData} : {props: any, getAssetData: () => Promise<void>}) => {
    const [
        { changeGeo, geography, drawMode, selectedScenario, selectedAsset },
        colors
    ] = useSelectFromRedux((state) => [
        state.map,
        state.color
    ]);

    const dispatch = useDispatch();

    const zoomTo  = (geography: LineString | Polygon | Point) => {
        if(geography.type === "Point") {
            let coordinates:[number, number] = [geography.coordinates[0], geography.coordinates[1]];
            dispatch(mapActions.flyToPoint([...coordinates,14]));
        } else if (geography.type === "Polygon") {
            let points = 0;
            let lat = 0;
            let long = 0;
            for(let coordinate of geography.coordinates[0]) {
                lat += coordinate[0];
                long += coordinate[1];
                points++;
            }
            lat /= points;
            long /= points;
            dispatch(mapActions.flyToPoint([lat,long, 12]))
        } else if (geography.type === "LineString") {
            dispatch(mapActions.flyToPoint([...geography.coordinates[Math.floor(geography.coordinates.length/2)], 12]));
        }
    }

    const [clients, setClients] = useState<Client[]>();
    const [teams, setTeams] = useState<Team[]>();
    const [editing, setEditing] = useState<boolean>(selectedScenario?.id === undefined);
    const [formattedAsset, setFormattedAsset] = useState<FormattedAsset>();
    const [assetTypes, setScenarioTypes] = useState<string[]>([]);
    const [risks, setRisks] = useState<Risk[]>();
    const [corrAsset, setCorrAsset] =  useState<Asset>();
    const [asset, setAsset] =  useState<Asset>();
    const [changed, setChanged] = useState<boolean>(false);

    useEffect(() => {
        if(formattedAsset && asset && selectedScenario) {
            const updatedAsset : Asset = {
                id: asset?.id,  
                name: formattedAsset?.assetName,
                asset_type: formattedAsset?.assetType,
                client_id: asset?.client_id,
                team_id: asset?.team_id,
                description: formattedAsset?.description,
                geography: asset?.geography,
                operation_interval: formattedAsset?.operationInterval,
                concerns: asset?.concerns,
                relevant_event_types: asset?.relevant_event_types,
                value: formattedAsset?.monetaryValue,
                details: asset?.details,
                erp_link: asset?.erp_link,
                imaginary: false,
                arcturus_gen: true,
                container_ids: asset?.container_ids,
                default_region_ids: asset?.default_region_ids,
                optimal: asset?.optimal,
                created_at: asset?.created_at
            }
            const change = 
                updatedAsset.id === selectedScenario?.id &&
                updatedAsset.name === selectedScenario?.name &&
                updatedAsset.asset_type === selectedScenario?.asset_type &&
                updatedAsset.client_id === selectedScenario?.client_id &&
                _.isEqual(updatedAsset.concerns, selectedScenario?.concerns) && 
                updatedAsset.description === selectedScenario?.description &&
                _.isEqual(updatedAsset.relevant_event_types, selectedScenario?.relevant_event_types) &&
                updatedAsset.operation_interval === selectedScenario?.operation_interval &&
                _.isEqual(updatedAsset.geography, selectedScenario?.geography) &&
                updatedAsset.value === selectedScenario?.value &&
                updatedAsset.optimal === selectedScenario.optimal;

            setChanged(!change);
    
        }
    }, [asset, formattedAsset, selectedScenario]);


    useEffect(() => {
        setAsset(_.cloneDeep(selectedScenario));
    }, [selectedScenario]);

    useEffect(() => {
        try {
            const getAllAssetTypes = async () => {
                const allAssetTypes = await getAssetTypes();
                const assetTypesStrings:string[] = [];
                allAssetTypes.forEach(assetType => assetTypesStrings.push(assetType.name))
                setScenarioTypes(assetTypesStrings);
            };
            getAllAssetTypes();
        } catch (err) {
            error(err);
            throw err;
        }
    },[]);

    useEffect(() => {
        const getClients = async () => {
            try {
                const fetchedClients = await getAllClients();
                setClients(fetchedClients);
            } catch (err) {
                error(err);
            }
        };
        const getTeams = async () => {
            try {
                const fetchedTeams = await getAllTeams();
                setTeams(fetchedTeams);
            } catch (err) {
                error(err);
            }
        };
        getClients();
        getTeams();
    }, []);

    const getData = useCallback(() => {
        if(clients && teams && selectedScenario) {
            let currentClient = clients.find(client => client.id === selectedScenario?.client_id);
            let currentTeam = teams.find(team => team.id === selectedScenario?.team_id);
            let currentConcerns = "";
            let currentEventTypes = "";
            if (selectedScenario?.concerns !== null) {
                selectedScenario?.concerns?.forEach((concern: string) => currentConcerns = currentConcerns + concern + ", ");
            }
            if (selectedScenario?.relevant_event_types !== undefined) {
                selectedScenario?.relevant_event_types.forEach((eventType: string) => currentEventTypes = currentEventTypes + eventType + ", ");
            }
            currentConcerns = currentConcerns.substring(0, currentConcerns.length - 2);
            currentEventTypes = currentEventTypes.substring(0, currentEventTypes.length -2);
            let tempFormattedAsset:FormattedAsset = {
                "index": selectedScenario?.id as number,
                "assetName": selectedScenario?.name,
                "assetType": selectedScenario?.asset_type,
                "client": currentClient === undefined ? "None" : currentClient.name,
                "team": currentTeam === undefined ? "None" : currentTeam.name,
                "description": selectedScenario?.description === undefined ? "None" : selectedScenario?.description,
                "operationInterval": selectedScenario?.operation_interval === undefined ? "None" : selectedScenario?.operation_interval,
                "concerns": selectedScenario?.concerns === undefined ? "None" : currentConcerns,
                "eventTypes": selectedScenario?.relevant_event_types === undefined ? "None" : currentEventTypes,
                "monetaryValue": selectedScenario?.value === undefined ? 0 : selectedScenario?.value,
                "needsReview": selectedScenario.needs_review === false ? "Reviewed" : "Needs Review",
            };
            const getRisks = async () => {
                try {
                    if(selectedScenario.id) {
                        const fetchedAssetRisks = await getAssetRisks(selectedScenario.id);
                        fetchedAssetRisks.filter((risk) =>  !risk.is_obsolete);
                        setRisks(fetchedAssetRisks);
                    }
                } catch (err) {
                    error(err);
                }
             };
             const getAsset = async () => {
                 try {
                    if(selectedScenario.scenario_asset_id){
                        let fetchedAssetData:Asset[] = await getAssets();
                        setCorrAsset(fetchedAssetData.find((asset : Asset) => asset.id === selectedScenario.scenario_asset_id));
                    }
                 } catch (err) {
                     error(err);
                 }
             }
             getAsset();
             getRisks();

            setFormattedAsset(tempFormattedAsset);
        }
    }, [clients, selectedScenario, teams]);

    useEffect(() => {
        getData()
    }, [clients, teams, selectedScenario, getData]);

    const updateThisAsset = async () => {
        try {
            if(formattedAsset && asset) {
                const updatedAsset : Asset = {
                    ...asset,
                    name: formattedAsset?.assetName,
                    asset_type: formattedAsset?.assetType,
                    description: formattedAsset?.description,
                    operation_interval: formattedAsset?.operationInterval,
                    value: formattedAsset?.monetaryValue,
                    imaginary: true,
                    arcturus_gen: true,
                    needs_review: false
                }
                if(asset.id !== undefined) {
                    await updateAsset(selectedScenario?.id as number, updatedAsset);
                    dispatch(mapActions.setScenario(updatedAsset));
                    dispatch(mapActions.setGeography(undefined));
                    dispatch(mapActions.setChangeGeo(false));
                }
                else {
                    await addAsset(updatedAsset);
                    dispatch(mapActions.setScenario(undefined));
                    dispatch(mapActions.setGeography(undefined));
                    dispatch(mapActions.setChangeGeo(false));
                }
                getAssetData();
                dispatch(mapActions.setGeography(undefined));
                dispatch(mapActions.setChangeGeo(false));
            }
        } catch (err) {
            error(err);
        }
    }

    if(asset && formattedAsset){ 
        return (
            <AssetModalContainer background={colors.scheme.generalCard} secondary={selectedAsset !== undefined}>
                <div style={{position: "sticky", right: "10px", top:"0px", zIndex: 2000}}>
                    <ExitButton onClick={() => {
                        if(changed) {
                            getData();
                            setAsset(_.cloneDeep(selectedScenario));
                        } else {
                            dispatch(mapActions.setScenario(undefined));
                            dispatch(mapActions.setGeography(undefined));
                            dispatch(mapActions.setChangeGeo(false));
                        }
                    }}>
                        <Icon style={{color: changed ? "red" : undefined}}>close</Icon>
                    </ExitButton>
                    <EditButton onClick={() => {
                        if(editing && changed)  updateThisAsset();
                        setEditing(!editing);
                    }}>
                        <Icon style={{color: changed && editing ? "#00ff00" : undefined}}>{editing && changed ? "check" : "edit"}</Icon>
                    </EditButton>
                </div>
                {editing &&
                <>
                <ModalTitle color={colors.scheme.primaryText}>
                    <TextField
                        multiline={false}
                        style={{width: "300px"}}
                        value={formattedAsset.assetName}
                        onChange={(event) => setFormattedAsset({...formattedAsset, assetName: event.target.value as string})}
                    />
                    </ModalTitle>
                    {asset &&
                    <ModalAddress color={colors.scheme.secondaryHeaderText}>
                        Scenario for {corrAsset?.name}
                    </ModalAddress>
                    }
                    <Button onClick={() => {        
                        setAsset({...asset, optimal: !asset.optimal});
                    }}>{asset.optimal ? "Set not optimal" : "Set optimal"}</Button>
                    <ModalAddress color={colors.scheme.secondaryHeaderText}>
                        <Autocomplete
                            disableClearable
                            id="assetTypes"
                            key={formattedAsset.assetType}
                            options={assetTypes}
                            value={formattedAsset.assetType}
                            renderInput={(params) => (
                            <TextInput
                                {...params}
                                required
                                label="Change Asset Type"
                                variant="outlined"
                                style={{ width: 360 }}
                            />
                            )}
                            onChange={(event, value) => {
                            setFormattedAsset({...formattedAsset, assetType: "" + value});
                            }}
                        />
                    </ModalAddress>
                    <FieldGroup background={colors.scheme.modalBackground}>
                        <>
                            <ModalText color={colors.scheme.secondaryHeaderText}>
                                Concerns
                            </ModalText>
                            <ModalAddress color={colors.scheme.primaryText}>
                                <Autocomplete
                                    disableClearable
                                    id="Concerns"
                                    options={AllAssetConcerns}
                                    renderInput={(params) => (
                                    <TextInput
                                        {...params}
                                        required
                                        label="Add Concern"
                                        variant="outlined"
                                        style={{ width: 360 }}
                                    />
                                    )}
                                    onChange={(event, value) => {
                                        let newConcerns = asset.concerns;
                                        if(newConcerns) {
                                            if(!newConcerns.includes(value)) newConcerns.push(value);
                                            setAsset({...asset, concerns: newConcerns});
                                        }
                                    }}
                                />
                                {asset.concerns?.map((concern) => (
                                    <div key={concern} style={{display: "flex", flexDirection: "row"}}>
                                        {concern}
                                        <HoverDiv onClick={() => {
                                            let newConcerns = asset.concerns;
                                            if(newConcerns) {
                                                let index = newConcerns.findIndex((c) => c === concern);
                                                newConcerns.splice(index,1);
                                                setAsset({...asset, concerns: newConcerns});
                                            }
                                        }}>
                                            <Icon>close</Icon>
                                        </HoverDiv>
                                    </div>
                                ))}
                            </ModalAddress>
                        </>
                        <>
                            <ModalText color={colors.scheme.secondaryHeaderText}>
                                Event Types
                            </ModalText>
                            <ModalAddress color={colors.scheme.primaryText}>
                                <Autocomplete
                                    disableClearable
                                    id="EventTypes"
                                    options={AllReportTypes}
                                    renderInput={(params) => (
                                    <TextInput
                                        {...params}
                                        required
                                        label="Add Relevant Event Type"
                                        variant="outlined"
                                        style={{ width: 360 }}
                                    />
                                    )}
                                    onChange={(event, value) => {
                                        let newEvents = asset.relevant_event_types;
                                        if(newEvents) {
                                            if(!newEvents.includes(value)) newEvents.push(value);
                                            setAsset({...asset, relevant_event_types: newEvents});
                                        }
                                    }}
                                />
                                {asset.relevant_event_types?.map((event) => (
                                    <div key={event} style={{display: "flex", flexDirection: "row"}}>
                                        {event}
                                        <HoverDiv onClick={() => {
                                            let newEvents = asset.relevant_event_types;
                                            if(newEvents) {
                                                let index = newEvents.findIndex((c) => c === event);
                                                newEvents.splice(index,1);
                                                setAsset({...asset, relevant_event_types: newEvents});
                                            }
                                        }}>
                                            <Icon>close</Icon>
                                        </HoverDiv>
                                    </div>
                                ))}
                            </ModalAddress>
                        </>
                        <>
                            <ModalText color={colors.scheme.secondaryHeaderText}>
                                Geography
                            </ModalText>
                            <ModalAddress color={colors.scheme.primaryText}>
                                <Button onClick={() => {
                                    zoomTo(asset.geography as LineString | Polygon | Point);
                                }}>{asset.geography.type}</Button>
                            </ModalAddress>
                            {changeGeo &&
                            <VerticallyCenteredRow style={{ width: "360px"}}>
                                <Paper square>
                                    <Tabs
                                        value={drawMode}
                                        onChange={(event, newValue) => dispatch(mapActions.setDrawMode(newValue))}
                                        style={{ width: "360px" }}
                                    >
                                        <Tab
                                            style={{minWidth: "120px", maxWidth: "120px", marginRight: "0px"}}
                                            value="point"
                                            label="Point"
                                            disableRipple
                                        />
                                        <Tab
                                            style={{minWidth: "120px", maxWidth: "120px", marginRight: "0px"}}
                                            value="polygon"
                                            label="Polygon"
                                        />
                                        <Tab
                                            style={{minWidth: "120px", maxWidth: "120px", marginRight: "0px"}}
                                            value="line"
                                            label="Line"
                                        />
                                    </Tabs>
                                </Paper>
                            </VerticallyCenteredRow>}
                            <Button onClick={() => {
                                    if(changeGeo && geography) {
                                        setAsset({...asset, geography: geography});
                                        dispatch(mapActions.setChangeGeo(false));
                                    } else {
                                        dispatch(mapActions.setChangeGeo(true));
                                        dispatch(mapActions.setDrawMode(
                                            asset.geography.type === "LineString" ? "line" :
                                            asset.geography.type === "Polygon" ?  "polygon" : "point"));
                                    }
                            }}>{changeGeo ? "Submit Geography Change" : "Change Geography"}</Button>
                        </>
                    </FieldGroup>
                    <FieldGroup background={colors.scheme.modalBackground}>
                        <>
                            <ModalText color={colors.scheme.secondaryHeaderText}>
                                Monetary Value
                            </ModalText>
                            <ModalAddress color={colors.scheme.primaryText}>
                                <TextField
                                    multiline={false}
                                    style={{width: "360px"}} 
                                    type="number"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    value={formattedAsset.monetaryValue}
                                    onChange={(event) => setFormattedAsset({...formattedAsset, monetaryValue: parseInt(event.target.value)})}
                                />
                            </ModalAddress>
                        </>
                        <>
                            <ModalText color={colors.scheme.secondaryHeaderText}>
                                Operation Interval
                            </ModalText>
                            <ModalAddress color={colors.scheme.primaryText}>
                                <TextField
                                    multiline={false}
                                    style={{width: "360px"}}
                                    value={formattedAsset.operationInterval}
                                    onChange={(event) => setFormattedAsset({...formattedAsset, operationInterval: event.target.value as string})}
                                />
                            </ModalAddress>
                        </>
                        <>
                            <ModalText color={colors.scheme.secondaryHeaderText}>
                                Client
                            </ModalText>
                            <ModalAddress color={colors.scheme.primaryText}>
                                {formattedAsset.client}
                            </ModalAddress>
                        </>
                        <>
                            <ModalText color={colors.scheme.secondaryHeaderText}>
                                Team
                            </ModalText>
                            <ModalAddress color={colors.scheme.primaryText}>
                                {formattedAsset.team}
                            </ModalAddress>
                        </>
                        <>
                            <ModalText color={colors.scheme.secondaryHeaderText}>
                                Description
                            </ModalText>
                            <ModalAddress color={colors.scheme.primaryText}>
                                <TextField
                                    multiline={true} 
                                    style={{width: "360px"}}
                                    value={formattedAsset.description}
                                    onChange={(event) => setFormattedAsset({...formattedAsset, description: event.target.value as string})}
                                />
                            </ModalAddress>
                        </>
                    </FieldGroup>
                </>}
                {!editing &&
                <>
                    <ModalTitle color={colors.scheme.primaryText} style={{display: "flex", flexDirection: "row"}}>
                        {asset.optimal && 
                            <SvgIcon>
                                <path d="M7 0.5C5.636 0.503071 4.30492 0.919309 3.18217 1.69387C2.05942 2.46843 1.19767 3.56497 0.710468 4.839C0.223267 6.11303 0.133477 7.50477 0.452929 8.83084C0.772381 10.1569 1.48608 11.3551 2.5 12.2675V21.5L7 18.5L11.5 21.5V12.2675C12.5139 11.3551 13.2276 10.1569 13.5471 8.83084C13.8665 7.50477 13.7767 6.11303 13.2895 4.839C12.8023 3.56497 11.9406 2.46843 10.8178 1.69387C9.69508 0.919309 8.36401 0.503071 7 0.5ZM10 18.695L7.8325 17.255L7 16.7L6.1675 17.255L4 18.695V13.2875C4.93037 13.7569 5.95792 14.0014 7 14.0014C8.04208 14.0014 9.06963 13.7569 10 13.2875V18.695ZM10.6675 11C9.80013 11.8468 8.66644 12.3674 7.45889 12.4733C6.25133 12.5793 5.04432 12.2641 4.04273 11.5813C3.04114 10.8985 2.3067 9.89008 1.96408 8.72731C1.62146 7.56455 1.69176 6.31904 2.16306 5.20222C2.63436 4.0854 3.47761 3.16608 4.54967 2.60031C5.62174 2.03454 6.85656 1.85719 8.04452 2.09836C9.23248 2.33954 10.3004 2.98437 11.067 3.9234C11.8335 4.86244 12.2515 6.03781 12.25 7.25C12.2491 7.94893 12.1087 8.64066 11.837 9.2846C11.5652 9.92854 11.1676 10.5117 10.6675 11Z" fill="#D3B159"/>
                            </SvgIcon>
                        }
                        {asset.needs_review &&
                            <Icon style={{color: "red", marginRight: "5px"}}>error</Icon>
                        }
                        {formattedAsset.assetName}
                    </ModalTitle>
                    {asset &&
                    <ModalAddress color={colors.scheme.secondaryHeaderText} style={{marginLeft: "10px"}}>
                        Scenario for {corrAsset?.name} ({formattedAsset.assetType})
                    </ModalAddress>
                    }
                    {risks && risks.length > 0 &&
                    <>
                        <ModalText color={colors.scheme.secondaryHeaderText}>
                            Risks
                        </ModalText>
                        <ModalAddress color={colors.scheme.primaryText}>
                        {risks.map((risk: Risk) => (
                            <HoverDiv 
                                style={{marginLeft: "10px"}} 
                                key={risk.id}
                                onClick={() => dispatch(mapActions.setRisk(risk))}
                            >-{risk.name}</HoverDiv>
                        ))}
                        <Button onClick={() => {
                            dispatch(mapActions.setAddRisk({
                                name: "",
                                asset_ids: asset.id ? [asset.id] :  [],
                                relevant_event_types: [],
                                risk_interval: "",
                                severity: 0,
                                chance: 0,
                                updates: [],
                                concerns: [],
                                consequences: [],
                                is_obsolete: false,
                                suggestions: "",
                                comments: ""
                            }));
                            dispatch(mapActions.setRisk(undefined));
                        }}>Add New Risk</Button>
                        </ModalAddress>
                    </>
                    }
                    <FieldGroup background={colors.scheme.modalBackground}>
                        {formattedAsset.concerns &&
                        <>
                            <ModalText color={colors.scheme.secondaryHeaderText}>
                                Concerns
                            </ModalText>
                            <ModalAddress color={colors.scheme.primaryText}>
                                {formattedAsset.concerns}
                            </ModalAddress>
                        </>
                        }
                        {formattedAsset.eventTypes &&
                        <>
                            <ModalText color={colors.scheme.secondaryHeaderText}>
                                Event Types
                            </ModalText>
                            <ModalAddress color={colors.scheme.primaryText}>
                                {formattedAsset.eventTypes}
                            </ModalAddress>
                        </>
                        }
                        {asset.geography &&
                        <>
                            <ModalText color={colors.scheme.secondaryHeaderText}>
                                    Geography
                            </ModalText>
                            <ModalAddress color={colors.scheme.primaryText}>
                                <Button onClick={() => {
                                    zoomTo(asset.geography as LineString | Polygon | Point);
                                }}>{asset.geography.type}</Button>
                            </ModalAddress>
                        </>}
                    </FieldGroup>
                    <FieldGroup background={colors.scheme.modalBackground}>
                        {formattedAsset.monetaryValue &&
                        <>
                            <ModalText color={colors.scheme.secondaryHeaderText}>
                                Monetary Value
                            </ModalText>
                            <ModalAddress color={colors.scheme.primaryText}>
                                ${formattedAsset.monetaryValue}
                            </ModalAddress>
                        </>
                        }
                        {formattedAsset.operationInterval &&
                        <>
                            <ModalText color={colors.scheme.secondaryHeaderText}>
                                Operation Interval
                            </ModalText>
                            <ModalAddress color={colors.scheme.primaryText}>
                                {formattedAsset.operationInterval}
                            </ModalAddress>
                        </>
                        }
                        {formattedAsset?.client &&
                        <>
                            <ModalText color={colors.scheme.secondaryHeaderText}>
                                Client
                            </ModalText>
                            <ModalAddress color={colors.scheme.primaryText}>
                                {formattedAsset.client}
                            </ModalAddress>
                        </>
                        }
                        {formattedAsset?.team &&
                        <>
                            <ModalText color={colors.scheme.secondaryHeaderText}>
                                Team
                            </ModalText>
                            <ModalAddress color={colors.scheme.primaryText}>
                                {formattedAsset.team}
                            </ModalAddress>
                        </>
                        }
                        {formattedAsset.description &&
                        <>
                            <ModalText color={colors.scheme.secondaryHeaderText}>
                                Description
                            </ModalText>
                            <ModalAddress color={colors.scheme.primaryText}>
                                {formattedAsset.description}
                            </ModalAddress>
                        </>
                        }
                    </FieldGroup>
                </>}
            </AssetModalContainer>
    )} else return (<></>)
}
