import { default as React,
    useState, useEffect } from "react";
import styled from "styled-components";
import { Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button, 
    Icon,
    Snackbar,
    SnackbarContent,
    IconButton} from "@material-ui/core";
import { Asset } from "../types/case";
import { Autocomplete } from "@material-ui/lab";
import { TextInput } from "../admin/styled";
import CloseIcon from "@material-ui/icons/Close";
import { getAssetTypes, updateAsset } from "../types/api/admin";
import { useThrow } from "../catch";

interface FormattedAsset {
    index: number,
    assetName: string,
    assetType: string,
    client: string,
    team: string,
    description: string,
    operationInterval: string,
    concerns: string,
    eventTypes: string,
    monetaryValue: number,
    needsReview: string,
}

const Container = styled.div`
max-height: 100px;
overflow: hidden;
position: relative;
`;

const SeeMore = styled.a`
position: absolute;
right: 0;
bottom: 0;
color: gray;
text-decoration: none;
padding-left: 2em;
background-image: linear-gradient(to right, #fff0 0, white 2em);

&:hover {
   text-decoration: underline;
}
`;

const FullAssetType = styled(DialogContentText)`
white-space: pre-line;
color: white;
`;

export default ({props, assetData, setAssetData} : { props: FormattedAsset, assetData:Asset[], setAssetData: (assets : Asset[]) => void }) => {
const error = useThrow();
let currentAsset = assetData.find((asset) => asset.id === props.index);
const [ expanded, setExpanded ] = useState<boolean>(false);
const [ editing, setEditing ] = useState<boolean>(false);
const [addedAssetType, setAddedAssetType] = useState<string>(currentAsset?.asset_type === undefined ? "" : currentAsset?.asset_type)
const [assetTypes, setAssetTypes] = useState<string[]>([]);

useEffect(() => {
    try {
        const getAllAssetTypes = async () => {
            const allAssetTypes = await getAssetTypes();
            const assetTypesStrings:string[] = [];
            allAssetTypes.forEach(assetType => assetTypesStrings.push(assetType.name))
            setAssetTypes(assetTypesStrings);
        };
        getAllAssetTypes();
    } catch (err) {
        error(err);
        throw err;
    }
},[error])

const expand = (event: React.SyntheticEvent) => {
   event.preventDefault();
   setExpanded(true);
};

const toggleEditing = () => {
 setEditing(!editing);
}

const onClose = () => {
    setExpanded(false);
    setEditing(false);
};

const update = async () => {
    if (addedAssetType !== undefined) {
        if (props.assetType.includes(addedAssetType)) {
            displayWarningSnackbar();
        } else {
            try {
                if(currentAsset) {
                  let newAsset: Asset = {
                      id: currentAsset.id,
                      name: currentAsset.name,
                      asset_type: addedAssetType,
                      client_id: currentAsset.client_id,
                      team_id: currentAsset.team_id,
                      description: currentAsset.description,
                      geography: currentAsset.geography,
                      operation_interval: currentAsset.operation_interval,
                      concerns: currentAsset.concerns,
                      relevant_event_types: currentAsset.relevant_event_types,
                      value: currentAsset.value,
                      details: currentAsset.details,
                      erp_link: currentAsset.erp_link,
                      imaginary: false,
                      arcturus_gen: true,
                      created_at: currentAsset.created_at
                  }
                  await updateAsset(currentAsset.id as number, newAsset);
                  assetData[props.index] = newAsset;
                  setAssetData(assetData);
                } else error({message: "Something went wrong. Please reload and try again."})
            } catch (err) {
                error(err);
                throw err;
            }
        }
    }
}

const [openWarningSnackbar, setOpenWarningSnackbar] = useState(false);
const displayWarningSnackbar = () => {
    setOpenWarningSnackbar(true);
};
const handleCloseWarningSnackbar = () => {
    setOpenWarningSnackbar(false);
};

const expansion: React.ReactNode = (
   <>
     <SeeMore onClick={expand}>Edit</SeeMore>
     <Dialog open={expanded}
             onClose={onClose}>
       <DialogTitle>
         Asset Type
         <Icon onClick={toggleEditing} style={{marginLeft: 20, paddingTop: 5, height: 25, cursor: "pointer"}}>edit</Icon>
       </DialogTitle>
       <DialogContent>
         {editing &&
           <>
           <Autocomplete
             disableClearable
             id="assetTypes"
             key={addedAssetType}
             options={assetTypes}
             value={addedAssetType}
             renderInput={(params) => (
               <TextInput
                 {...params}
                 required
                 label="Change Asset Type"
                 variant="outlined"
                 style={{ width: 240 }}
               />
             )}
             onChange={(event, value) => {
               setAddedAssetType("" + value);
             }}
           />
       </>
         }
         {!editing &&
           <FullAssetType>
             {addedAssetType}
           </FullAssetType>
         }
       </DialogContent>
       <DialogActions>
         {editing &&
           <Button onClick={update} color="primary" autoFocus>Update</Button>
         }
         <Button onClick={onClose} color="primary" autoFocus>Close</Button>
       </DialogActions>
     </Dialog>
   </>
);

return (
   <Container>
     {props.assetType}
     {expansion}
     <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openWarningSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseWarningSnackbar}
        >
          <SnackbarContent
            style={{
              backgroundColor: "orange",
              color: "white",
            }}
            message="Asset Type Already Added, Please Choose A Different One"
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseWarningSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
      </div>
   </Container>
);
};
