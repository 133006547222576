import styled from "styled-components";
import Slider from "@material-ui/core/Slider";
import { activeSubmitButtonBackground } from "../utils";
import { Select } from "antd";

export const FormField = styled.input`
    font-family: IBM Plex Sans;
    width: 254px;
    height: 37px;
    background-color: #8a8c96;
    color: white;
    border: none;
    border-radius: 18px;
    margin: 10px;
    text-align: center;
    font-size: 14px;
    outline: none;

    ::placeholder {
        color: white;
        letter-spacing: 1px;
    }
`;

interface SubmitButtonProps {
    color: string;
    background: string;
    tapColor?: string;
}

export const SubmitButtonAddRep = styled.div`
    display: flex;
    height: 40px;
    width: 80%;
    border-radius: 18px;
    background: ${(props: SubmitButtonProps) => props.background};
    color: ${(props: SubmitButtonProps) => props.color};
    margin: 30px 10% 15px;
    text-transform: uppercase;
    font-weight: 600;
    justify-content: center;
    align-items: center;

    :active {
        background: ${(props: SubmitButtonProps) => (props.tapColor ? props.tapColor : activeSubmitButtonBackground)};
    }

    :hover {
        cursor: pointer;
    }
`;

export const SubmitButton = styled.input`
    font-family: IBM Plex Sans;
    background-color: rgba(237, 159, 78, 0.08);
    width: 254px;
    height: 37px;
    text-transform: uppercase;
    border-radius: 18px;
    border: #cc662f solid 1px;
    color: #cc662f;
    margin: 10px;
    margin-bottom: 0px;
    font-size: 14px;
    letter-spacing: 1px;
    cursor: pointer;
    outline: none;
`;

export const FormContainer = styled.form`
    width: 100%;
    top: 50px;
    margin-top: 17px;
    max-height: 120%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
`;

export const RouteInfoContainer = styled.div`
    display: flex;
    margin-top: 10px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
`;

export const Slide = styled(Slider)`
    margin-top: 0px !important;
    padding: 10px !important;
    .MuiSlider-markLabel {
        top: 20px !important;
    }
`;

interface DropdownProps {
    color: string;
    background: string;
    arrowcolor: string;
}

export const Dropdown = styled(Select)`
    width: 140px;
    color: ${(props: DropdownProps) => props.color};
    font-size: 14px;
    text-align: center;

    .ant-select-selector {
        background: ${(props: DropdownProps) => props.background} !important;
        border: none !important;
        border-radius: 8px !important;
    }

    .ant-select-arrow {
        color: ${(props: DropdownProps) => props.arrowcolor};
        pointer-events: none;
    }
`;
