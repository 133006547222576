import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import { Login } from "../types/auth";
import { useThrow } from "../catch";
import Background from "../background";
import Container from "./container";
import FormField from "./formField";
import SubmitButton from "./submitButton";
import { adminSignIn } from "../types/api/auth";
import { auth, signInFirebase } from "../types/firebase";
import { cuserActions } from "./../state/cuser";
import { useDispatch } from "react-redux";


export default () => {
    const dispatch = useDispatch();
    const error = useThrow();
    const [ token, setToken ] = useState<string>("");
    const [ email, setEmail ] = useState<string>("");
    const [ password, setPassword ] = useState<string>("");

    if (token) {
        return <Redirect to="/" />;
    }
 
    const submit = async () => {
        try {
            const login: Login = await adminSignIn(email, password);
            signInFirebase(auth, login.token)
                .then((userCredential) => {
                    localStorage.setItem("verstaanToken", login.token);
                    dispatch(cuserActions.successfulSignIn(login.token!));
                    setToken(login.token);
                    console.log("User signed in woot");
                })
                .catch((err) => {
                    error(err);
                });

        } catch (err) {
            error(err);
        }
    };

    return (
        <Background>
          <Container>
            <FormField id="email"
                       type="text"
                       name="email"
                       placeholder="EMAIL"
                       className="inputs"
                       value={email}
                       onChange={(event) => setEmail(event.target.value)} />
            <FormField id="password"
                       type="password"
                       name="password"
                       placeholder="PASSWORD"
                       className="inputs"
                       value={password}
                       onChange={(event) => setPassword(event.target.value)} />
            <SubmitButton type="submit" value="Log In" onClick={submit} />
          </Container>
        </Background>
    );
};
