import React, { useState } from "react";
import {
    Button,
    FormControl,
    FormControlLabel,
    Snackbar,
    SnackbarContent,
    IconButton,
    InputLabel,
    Select,
    Input,
    MenuItem,
    Checkbox,
    ListItemText,
    Switch
} from "@material-ui/core";
import { addAssetType } from "../types/api/admin";
import CloseIcon from "@material-ui/icons/Close";
import { Form, FormContainer, Section, TextInput } from "./styled";
import { AllAssetConcerns, AssetConcern, AssetType } from "../types/case";

export const CreateAssetType = () => {

  const eventTypesOptions = [
    "Protest",
    "Gang Activity",
    "Theft",
    "Violent Crime",
    "Murder",
    "Rape",
    "Stabbing",
    "Shooting",
    "Assault"
  ];

  const geographyOptions = [
    "Point",
    "LineString",
    "Polygon",
    "Multi-Polygon"
  ]

  const [name, updateName] = useState<string>("");
  const [description, updateDescription] = useState<string>("");
  const [category, updateCategory] = useState<string>("");
  const [eventTypes, updateEventTypes] = useState<string[]>([]);
  const [concerns, updateConcerns] = useState<string[]>([]);
  const [geographies, updateGeographies] = useState<string[]>([]);
  const [mobile, updateMobile] = useState<boolean>(true);
  const [openSuccessSnackbar, setOpenSucessSnackbar] = useState(false);
  const displaySuccessSnackbar = () => {
    setOpenSucessSnackbar(true);
  };
  const handleCloseSuccessSnackbar = () => {
    setOpenSucessSnackbar(false);
  };
  const [openWarningSnackbar, setOpenWarningSnackbar] = useState(false);
  const displayWarningSnackbar = () => {
    setOpenWarningSnackbar(true);
  };
  const handleCloseWarningSnackbar = () => {
    setOpenWarningSnackbar(false);
  };
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const displayErrorSnackbar = () => {
    setOpenErrorSnackbar(true);
  };
  const handleCloseErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  };
  const [errorString, setErrorString] = useState("");

  const resetForm = () => {
    updateName("");
    updateDescription("");
    updateCategory("");
    updateConcerns([]);
    updateEventTypes([]);
    updateGeographies([]);
    updateMobile(false);
  };

  const submit = () => {
    if (name && description && category && eventTypes && concerns && geographies) {
      const createAssetTypeObject: AssetType = {
        name: name,
        description: description,
        category: category,
        relevant_event_types: eventTypes,
        relevant_concerns: concerns,
        relevant_geographies: geographies,
        mobile: mobile
      };

      console.log(createAssetTypeObject);

      addAssetType(createAssetTypeObject)
        .then(() => {
          displaySuccessSnackbar();
          resetForm();
        })
        .catch(() => {
          setErrorString("Error: Asset Type was not created");
          displayErrorSnackbar();
        });
    } else {
      displayWarningSnackbar();
    }
  };

  return (
    <FormContainer>
      <Form noValidate autoComplete="off">
        <Section>
          <TextInput
            id="name"
            label="Name (required)"
            variant="outlined"
            value={name}
            onChange={(e) => updateName(e.target.value)}
          />
          <TextInput
            id="description"
            label="Description (required)"
            variant="outlined"
            value={description}
            onChange={(e) => updateDescription(e.target.value)}
          />
          <TextInput
            id="category"
            label="Category (required)"
            variant="outlined"
            value={category}
            onChange={(e) => updateCategory(e.target.value)}
          />
          <FormControl variant="outlined">
            <InputLabel id="concerns-label">Concerns</InputLabel>
            <Select
              labelId="concerns-label"
              id="concerns"
              multiple
              value={concerns}
              onChange={(event) =>
                updateConcerns(event.target.value as AssetConcern[])
              }
              input={<Input />}
              renderValue={(selected) => (selected as string[]).join(", ")}
            >
              {AllAssetConcerns.map((name) => 
                <MenuItem key={name} value={name}>
                  <Checkbox checked={concerns.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl variant="outlined" style={{marginTop: 15, marginBottom: 15}}>
            <InputLabel id="events-label">Event Types</InputLabel>
            <Select
              labelId="events-label"
              id="events"
              multiple
              value={eventTypes}
              onChange={(event) =>
                updateEventTypes(event.target.value as string[])
              }
              input={<Input />}
              renderValue={(selected) => (selected as string[]).join(", ")}
            >
              {eventTypesOptions.map((name) => 
                <MenuItem key={name} value={name}>
                  <Checkbox checked={eventTypes.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl variant="outlined" style={{marginTop: 15, marginBottom: 15}}>
            <InputLabel id="geographies-label">Geography Types</InputLabel>
            <Select
              labelId="geographies-label"
              id="geographies"
              multiple
              value={geographies}
              onChange={(event) =>
                updateGeographies(event.target.value as string[])
              }
              input={<Input />}
              renderValue={(selected) => (selected as string[]).join(", ")}
            >
              {geographyOptions.map((name) => 
                <MenuItem key={name} value={name}>
                  <Checkbox checked={geographies.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl component="fieldset" style={{ margin: "20px 0 10px" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={mobile}
                  onChange={(event) => updateMobile(event.target.checked)}
                  name="mobile"
                  color="primary"
                />
              }
              label="Mobile?"
            />
          </FormControl>
        </Section>
        <Button variant="contained" color="primary" onClick={submit}>
          Create Asset Type
        </Button>
      </Form>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSuccessSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSuccessSnackbar}
        >
          <SnackbarContent
            style={{
              backgroundColor: "#20d420",
              color: "white",
            }}
            message="Form Submitted Successfully!"
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSuccessSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
      </div>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openWarningSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseWarningSnackbar}
        >
          <SnackbarContent
            style={{
              backgroundColor: "orange",
              color: "white",
            }}
            message="Please Fill In All Required Fields"
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseWarningSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
      </div>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openErrorSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseErrorSnackbar}
        >
          <SnackbarContent
            style={{
              backgroundColor: "red",
              color: "white",
            }}
            message={errorString}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseErrorSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
      </div>
    </FormContainer>
  );
};
