import React, { Component } from "react";
import styled from "styled-components";
import RangeSlider from "../range-slider/range-slider";
import onClickOutside from "react-onclickoutside";
import { SPEED_CONTROL_RANGE, SPEED_CONTROL_STEP } from "../../../constants/default-settings";

const SliderWrapper = styled.div`
    position: relative;
`;

const SpeedSliderContainer = styled.div`
    position: absolute;
    bottom: 50px;
    right: calc(0% - 32px);
    width: 180px;
    padding: 2px 8px 2px 12px;
    background-color: ${(props) => props.theme.orion.scheme.modalBackground};
    box-shadow: -2px -2px 0 0 rgba(0, 0, 0, 0.1);
    .kg-range-slider__input {
        width: 36px;
    }
`;

class AnimationSpeedSlider extends Component {
    handleClickOutside = (e) => {
        this.props.onHide();
    };

    _onChange = (v) => this.props.updateAnimationSpeed(v[1]);

    render() {
        return (
            <SpeedSliderContainer className="animation-control__speed-slider">
                <SliderWrapper>
                    <RangeSlider
                        range={SPEED_CONTROL_RANGE}
                        step={SPEED_CONTROL_STEP}
                        value0={0}
                        value1={this.props.speed}
                        onChange={this._onChange}
                        isRanged={false}
                        showTooltip
                        showInput
                        inputTheme="secondary"
                        inputSize="tiny"
                    />
                </SliderWrapper>
            </SpeedSliderContainer>
        );
    }
}

export default onClickOutside(AnimationSpeedSlider);
