import React from "react";
import { default as MaterialTable,
         MaterialTableProps,
         Query,
         QueryResult,
         Filter } from "material-table";
import { useThrow } from "../catch";
import { Log } from "../types/admin";
import { queryLogs } from "../types/api/admin";

export default () => {
    const error = useThrow();

    const props: MaterialTableProps<Log> = {
        title: "Logs",
        columns: [
            {
                title: "Timestamp",
                field: "timestamp",
                type: "datetime",
                defaultSort: "desc"
            },
            {
                title: "User ID",
                field: "user_id",
                type: "numeric"
            },
            {
                title: "Client ID",
                field: "client_id",
                type: "numeric"
            },
            {
                title: "Admin User ID",
                field: "admin_user_id",
                type: "numeric"
            },
            {
                title: "Route",
                field: "route",
                type: "string"
            }
        ],
        data: async (query: Query<Log>): Promise<QueryResult<Log>> => {
            try {
                for (const filter of query.filters) {
                    if (filter.column.field === "route") {
                        filter.operator = "like" as Filter<Log>["operator"];
                        filter.value = `%${filter.value}%`;
                    }
                }
                return await queryLogs(query);
            } catch (err) {
                error(err);
                throw err;
            }
        },
        style: {
            borderRadius: 0
        },
        options: {
            filtering: true,
            search: false,
            thirdSortClick: false,
            toolbarButtonAlignment: "left"
        }
    };

    return <MaterialTable {...props} />;
};
