import React from "react";
import ClusterTooltip from "./clusterTooltip";
import IndividualTooltip from "./individualTooltip";
import GridTooltip from "./gridTooltip";
import { Tooltip } from "./styled";
import { useDispatch } from "react-redux";
import { mapActions } from "../../state/map";
import { centerOfMass } from "@turf/turf";

import { useSelectFromRedux } from "../../utils/_hooks";

export default function TooltipComponent() {
    const [{ selectedMarker, showTooltip }] = useSelectFromRedux((state) => [state.map]);
    const colors = useSelectFromRedux((state) => state.color);

    let center = null;
    if (selectedMarker && selectedMarker.properties && selectedMarker.properties.risk_score) {
        center = centerOfMass(selectedMarker);
    }

    const dispatch = useDispatch();
    const closeTooltip = () => {
        return dispatch(mapActions.toggleTooltip());
    };

    if (!selectedMarker || !selectedMarker.geometry?.coordinates) {
        return null;
    }

    const switchTooltip = () => {
        if (selectedMarker.properties.cluster) {
            return <ClusterTooltip />;
        } else if (selectedMarker.properties.risk_score) {
            return <GridTooltip />;
        } else {
            return <IndividualTooltip />;
        }
    };

    return (
        <>
            {showTooltip && selectedMarker && (
                <Tooltip
                    latitude={center ? center.geometry!.coordinates[1] : selectedMarker.geometry.coordinates[1]}
                    longitude={center ? center.geometry!.coordinates[0] : selectedMarker.geometry.coordinates[0]}
                    onClose={closeTooltip}
                    offsetTop={-40}
                    background={colors.scheme.modalBackground}
                    color={colors.scheme.primaryText}
                    sidepadding={selectedMarker.properties.cluster ? "0" : "20px"}
                >
                    {switchTooltip()}
                </Tooltip>
            )}
        </>
    );
}
