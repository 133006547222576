import React from "react";
import { Redirect } from "react-router-dom";
import { useSelectFromRedux } from "./utils/_hooks";

export default ({ children }: { children: React.ReactNode }) => {
    const token = useSelectFromRedux((state) => state.cuser.token);

    if (token) {
        return <>{children}</>;
    } else {
        return <Redirect to="/login" />;
    }
};
