import { produce } from "immer";
import { payloadAction, actionFactory, ActionUnion } from "reductser";

const actionMap = {
    changeColorScheme: payloadAction<"dark" | "light" | "space-grey">()
};

export const colorActions = actionFactory(actionMap, "COLOR");

export type ColorAction = ActionUnion<typeof colorActions>;

const lightModeColorState: ColorState["scheme"] = {
    modalBackground: "#F9F9F9",
    primaryText: "#4C4C4C",
    primaryButton: "#757575",
    primaryButtonAct: "#4C4C4C",
    primaryButtonBkg: "rgb(179, 181, 187)",
    primaryButtonActBkg: "rgb(225, 225, 227)",
    innerIcon: "#FEFEFEcho",
    generalCard: "rgb(225, 225, 227)",
    cardLightCaptionText: "989A9E",
    secondaryHeaderText: "#757575",
    selectorBackground: "rgb(179, 181, 187)",
    selectorLightText: "#FEFEFE",
    selectedOptionBackground: "#FFFFFF",
    selectorDarkText: "#4C4C4C",
    submitButtonBackground: "#0A84FF",
    submitButtonBackgroundDisabled:"rgba(62,88,127,0.56)",
    submitButtonText: "#FFFFFF",
    toggleBackground: "#99F9D7",
    analyticsPieHazard: "#D4A864",
    analyticsPieViolent: "#B46363",
    analyticsPieNonviolent: "#668D9E",
    analyticsPieOther: "#DBDADD",
    analyticsGraphBase: "#324276",
    analyticsPercentageIncrease: "#9F0000",
    analyticsPercentageDecrease: "#084C8B",
    filterHazardTag: "#D4A864",
    filterViolentTag: "#B46363",
    filterNonViolentTag: "#668D9E",
    filterUnselectedTag: "#EEEEEE",
    filterSelectedTag: "#274B6E",
    filterModal: "#FFFFFF",
    filterModalBottomBar: "#F8F8F8",
    modalBottomBar: "#F3F3F3"
};

const darkModeColorState: ColorState["scheme"] = {
    modalBackground: "#13192D",
    primaryText: "#FFFFFF",
    primaryButton: "#ADAFB5",
    primaryButtonAct: "#FFFFFF",
    primaryButtonBkg: "#272D41",
    primaryButtonActBkg: "#4D515F",
    innerIcon: "#FFFFFF",
    generalCard: "#4D515F",
    cardLightCaptionText: "#FFFFFF",
    secondaryHeaderText: "#ADAFB5",
    selectorBackground: "#272D41",
    selectorLightText: "#FEFEFE",
    selectedOptionBackground: "#E8E8EA",
    selectorDarkText: "#606369",
    submitButtonBackground: "#0A84FF",
    submitButtonBackgroundDisabled:"rgba(62,88,127,0.56)",
    submitButtonText: "#FFFFFF",
    toggleBackground: "#2D9077",
    analyticsPieHazard: "#D4A864",
    analyticsPieViolent: "#B46363",
    analyticsPieNonviolent: "#668D9E",
    analyticsPieOther: "#DBDADD",
    analyticsGraphBase: "#E5E5E5",
    analyticsPercentageIncrease: "#FF8F53",
    analyticsPercentageDecrease: "#44F9B8",
    filterHazardTag: "#D4A864",
    filterViolentTag: "#B46363",
    filterUnselectedTag: "#EEEEEE",
    filterSelectedTag: "#3B4F63",
    filterNonViolentTag: "#77A9BF",
    filterModal: "#7A808F",
    filterModalBottomBar: "#9CA1AE",
    modalBottomBar: "#2B2F3E"
};

const spaceGreyModeColorState: ColorState["scheme"] = {
    modalBackground: "#68717D",
    primaryText: "#FFFFFF",
    primaryButton: "#CCCCCC",
    primaryButtonAct: "#FFFFFF",
    primaryButtonBkg: "#4E5660",
    primaryButtonActBkg: "#8a97a8",
    innerIcon: "#FFFFFF",
    generalCard: "#4E5660",
    cardLightCaptionText: "#FFFFFF",
    secondaryHeaderText: "#CCCCCC",
    selectorBackground: "#4E5660",
    selectorLightText: "#FEFEFE",
    selectedOptionBackground: "#E8E8EA",
    selectorDarkText: "#606369",
    submitButtonBackground: "#0A84FF",
    submitButtonBackgroundDisabled: "rgba(62,88,127,0.56)",
    submitButtonText: "#FFFFFF",
    toggleBackground: "#55B99C",
    analyticsPieHazard: "#D4A864",
    analyticsPieViolent: "#B46363",
    analyticsPieNonviolent: "#668D9E",
    analyticsPieOther: "#EEEEEE",
    analyticsGraphBase: "#E5E5E5",
    analyticsPercentageIncrease: "#FF8F53",
    analyticsPercentageDecrease: "#44F9B8",
    filterHazardTag: "#D4A864",
    filterViolentTag: "#B46363",
    filterNonViolentTag: "#668D9E",
    filterUnselectedTag: "#EEEEEE",
    filterSelectedTag: "#274B6E",
    filterModal: "#4E5660", // "#FFFFFF",
    filterModalBottomBar: "#68717D",
    modalBottomBar: "#7A828C"
};

const generalColorState : ColorState["general"] = {
    sosCountdown: "#AA020190",
    sosReportModal: "#B01918",
    confirmationNotification: "#3A6852",
    sosAlertNotification: "#AA0201",
    generalNotification: "#41454E",
    addReportButton: "linear-gradient(#2E3961, #13192D)",
    sosAlertButton: "linear-gradient(#CB0D0C, #A30404)",
    sosSelectorBackground: "#98100F",
    standardGreen: "#43A978",
    amber: "#E99F2D"
};

export interface ColorState {
    mode: string;
    scheme: {
        modalBackground: string;
        primaryText: string;
        primaryButton: string;
        primaryButtonAct: string;
        primaryButtonBkg: string;
        primaryButtonActBkg: string;
        innerIcon: string;
        generalCard: string;
        cardLightCaptionText: string;
        secondaryHeaderText: string;
        selectorBackground: string;
        selectorLightText: string;
        selectedOptionBackground: string;
        selectorDarkText: string;
        submitButtonBackground: string;
        submitButtonBackgroundDisabled: string;
        submitButtonText: string;
        toggleBackground: string;
        analyticsPieHazard: string;
        analyticsPieViolent: string;
        analyticsPieNonviolent: string;
        analyticsPieOther: string;
        analyticsGraphBase: string;
        analyticsPercentageIncrease: string;
        analyticsPercentageDecrease: string;
        filterHazardTag: string;
        filterViolentTag: string;
        filterNonViolentTag: string;
        filterUnselectedTag: string;
        filterSelectedTag: string;
        filterModal: string;
        filterModalBottomBar: string;
        modalBottomBar: string;
    };
    general: {
        sosCountdown: string;
        sosReportModal: string;
        confirmationNotification: string;
        sosAlertNotification: string;
        generalNotification: string;
        sosSelectorBackground: string;
        addReportButton: string;
        sosAlertButton: string;
        standardGreen: string;
        amber: string
    };
}

export const getInitialState = (): ColorState => ({
    mode: "space-grey",
    scheme: spaceGreyModeColorState,
    general: generalColorState
});

const colorReducer = (state = getInitialState(), action: ColorAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "COLOR") {
            switch (action.type) {
                case "changeColorScheme":
                    if (action.payload === "dark") {
                        draftState.mode = action.payload;
                        draftState.scheme = darkModeColorState;
                        localStorage.setItem("theme", "dark");
                    } else if (action.payload === "light") {
                        draftState.mode = action.payload;
                        draftState.scheme = lightModeColorState;
                        localStorage.setItem("theme", "light");
                    } else if (action.payload === "space-grey") {
                        draftState.mode = action.payload;
                        draftState.scheme = spaceGreyModeColorState;
                        localStorage.setItem("theme", "space-grey");
                    }
                    break;
                default:
                    break;
            }
        }
    });

export default colorReducer;
