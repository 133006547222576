import { default as React, useState, useEffect } from "react";
import { default as MaterialTable, MaterialTableProps, MTableEditRow } from "material-table";
import { Icon, IconProps } from "@material-ui/core";

import { createAdminUser, getAllAdminUsers } from "../types/api/admin";
import {AdminUserProfile, AdminPrivileges, NewAdminUser} from "../types/admin";
import { useThrow } from "../catch";

const PasswordEditRow = (props: any) => (
    <MTableEditRow
        {...props}
        columns={[
            ...props.columns,
            {
                title: "New password",
                field: "password",
                type: "string",
                tableData: {
                    columnOrder: 1000,
                    groupOrder: -1,
                },
                cellStyle: {
                    minWidth: "2in",
                },
                editable: "onAdd",
            },
        ]}
    />
);

export default () => {
    const error = useThrow();

    const [data, setData] = useState<AdminUserProfile[] | undefined>(undefined);
    useEffect(() => {
        (async () => {
            try {
                setData(await getAllAdminUsers());
            } catch (err) {
                error(err);
            }
        })();
    }, [error]);

    const props: MaterialTableProps<AdminUserProfile> = {
        title: "Admin Users",
        columns: [
            {
                title: "ID",
                field: "id",
                type: "numeric",
                width: 0,
                editable: "never",
            },
            {
                title: "First Name",
                field: "first_name",
                type: "string",
            },
            {
                title: "Last Name",
                field: "last_name",
            },
            {
                title: "Email",
                field: "email",
            },
            {
                title: "Superuser",
                field: "privileges",
                type: "boolean",
                width: 0,
            },
            {
                title: "Phone Number",
                field: "phone_primary",
                type: "string"
            }
        ],
        data: data ?? [],
        isLoading: !data,
        style: {
            borderRadius: 0,
        },
        options: {
            paging: false,
            thirdSortClick: false,
            toolbarButtonAlignment: "left",
            actionsColumnIndex: -1,
        },
        editable: {
            onRowAdd: async (newRow: AdminUserProfile) => {
                newRow.privileges = newRow.privileges ? AdminPrivileges.SUPERUSER : AdminPrivileges.MANAGER;
                try {
                    newRow.id = await createAdminUser(newRow as NewAdminUser);
                    setData([...(data ?? []), newRow]);
                } catch (err) {
                    error(err);
                }
            },
        },
        icons: {
            Add: React.forwardRef((props: IconProps, ref: any) => (
                <Icon {...props} ref={ref}>
                    person_add
                </Icon>
            )),
        },
        components: {
            EditRow: PasswordEditRow,
        },
        localization: {
            header: {
                actions: "",
            },
        },
    };

    return <MaterialTable {...props} />;
};
