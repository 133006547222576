/** @type {typeof import('./schema').Schema} */
export default class Schema {
    constructor({ version = "v1", key = "", properties = null } = {}) {
        this.version = version;
        this.properties = properties;
        this.key = key;
    }

    loadPropertiesOrApplySchema(node, parents = [], accumulator) {
        return this._getPropertyValueFromSchema("load", node, parents, accumulator);
    }

    savePropertiesOrApplySchema(node, parents = [], accumulator) {
        return this._getPropertyValueFromSchema("save", node, parents, accumulator);
    }

    _getPropertyValueFromSchema(operation, node, parents = [], accumulator) {
        const internal = `_${operation}`;
        return {
            [this.key]: this.properties
                ? Object.keys(this.properties).reduce((accu, key) => {
                      return {
                          ...accu,
                          ...(key in node
                              ? this.properties[key]
                                  ? // if it's another schema
                                    this.properties[key][operation]
                                      ? // call save or load
                                        this.properties[key][internal](node[key], [...parents, node], accu)
                                      : {}
                                  : { [key]: node[key] }
                              : {})
                      };
                  }, {})
                : node
        };
    }

    _isCurrentVersion() {
        return this.version === "v1";
    }

    outdatedVersionError() {
        if (!this._isCurrentVersion()) {
            console.error(`${this.key} ${this.version} is outdated. save should not be called anymore`);
        }
    }

    _save(...args) {
        // make sure nothing is saved to an outdated version
        this.outdatedVersionError();
        return this.save(...args);
    }

    save(node, parents = [], accumulator = {}) {
        return this.savePropertiesOrApplySchema(node, parents, accumulator);
    }

    _load(...args) {
        return this.load(...args);
    }

    load(node, parents = [], accumulator = {}) {
        return this.loadPropertiesOrApplySchema(node, parents, accumulator);
    }
}
