import React, { useEffect, useState } from "react";
import { Button, FormControl, InputLabel, Select, Input, MenuItem, ListItemText, Checkbox, FormLabel, TextField, Box, IconButton } from "@material-ui/core";
import { DataGrid, ColDef, ValueGetterParams, CellParams } from "@material-ui/data-grid";
import { Form, FormContainer, Section, TextInput, ToggleBarContainer,  } from "./styled";
import { getAllDefaultRegions, getSummaryData } from "../types/api/admin";
import { getContainers, getAllContainers } from "../types/api/admin";
import { useThrow } from "../catch";
import { ClientDisplayData, DisplayClient, ScheduledMesssage, UserFeatureSelection, UserProfile } from "../types/client";
import { Autocomplete, Alert } from "@material-ui/lab";
import { render } from "@testing-library/react";
import {makeStyles} from '@material-ui/styles';
import Link from "@material-ui/core/Link";
import { Container, DefaultRegion } from "../types/geo"
import { ConsoleView } from "react-device-detect";
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  LineSeries,
} from '@devexpress/dx-react-chart-material-ui';
import { handleScheduledMessages, getAllAdminUsers } from "../types/api/admin";
import { useSelectFromRedux } from "../utils/_hooks";
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import { AdminUserProfile } from "../types/admin";
import { getDefaultRegions } from "../types/api/public";
import { getAllUsers } from "../types/api/admin";


const styles = makeStyles((theme) => ({
    root: {
      borderLeft: 0,
      borderRight: 0,
      borderBottom: 0,
      color: 'black',
      backgroundColor: 'grey'
    },
  }));




export const DisplayNotifMessaging = () => {

    let options = {
        timeZone: "Africa/Nairobi",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      },
      // @ts-ignore
      formatter = new Intl.DateTimeFormat([], options); // error ignored on options -> types completely match exactly but says they dont, presents no issues

    const localTime = new Date()
    const kenyaTime = formatter.format(new Date());

    const CuserId = useSelectFromRedux((state) => state.cuser.profile?.id);
    //const kenyaTime = moment()

    const classes = styles();

    const [upcomingMessages, setUpcomingMessages] = useState<ScheduledMesssage[]>([]);
    const [allMessages, setAllMessages] = useState<ScheduledMesssage[]>([]);
    const [containerNames, updateContainerNames] = useState<string[]>([]);
    const [containers, updateContainers] = useState<string[]>([]);
    const [regionNames, updateRegionNames] = useState<string[]>([]);
    const [regions, updateRegions] = useState<string[]>([]);
    const [allContainers, setAllContainers] = useState<Container[]>([]);
    const [allRegions, setAllRegions] = useState<DefaultRegion[]>([]);
    const [messageTitle, updateMessageTitle] = useState<string>("");
    const [messageText, updateMessageText] = useState<string>("");

    const [messageTypes, updateMessageTypes] = useState<string>("");
    const [messageLocat, updateMessageLocat] = useState<string>("");
    const [messageTimePds, updateMessageTimePds] = useState<string>("");
    const [messageAdvisory, updateMessageAdvisory] = useState<string>("");
    const [formatToggle, setFormatToggle] = useState<number>(0);

    const [ scheduledTime, setScheduledTime ] = useState<Date>(addHoursToDate(new Date(), 1));
    var [refresh, setRefresh] = useState<string>("");
    const [adminUsers, setAdminUsers] = useState<AdminUserProfile[]>([]);

    useEffect(() => {

        const fetchdata = async () => {

            const messages = await handleScheduledMessages("read", null, null, null, null, null, null, null, false, []);
            setUpcomingMessages(messages);
            setAllMessages(messages);

            const adminusers = await getAllAdminUsers()
            setAdminUsers(adminusers)

            const regions: DefaultRegion[] = await getAllDefaultRegions();
            setAllRegions(regions);

            const response: Container[] = await getAllContainers();
            setAllContainers(response);
            const responseNamesArr: string[] = [];
            const responseDetailsArr: Container[] = [];

            response.forEach((fetchedContainer) => {
                responseDetailsArr.push(fetchedContainer);
                responseNamesArr.push(fetchedContainer.name);
            });
            updateContainerNames(responseNamesArr);
        }

        fetchdata();
        setRefresh("");

    }, [refresh]);


    useEffect(() => {
        const loadAvailableRegions = async () => {
            let availableRegions: string[] = [];

            containers.forEach((containerName) => {
                const id = allContainers.filter((container) => container.name == containerName)[0]?.id;
                const cont_regs = allRegions.filter((region) => region.container_id == id);
                cont_regs.map((reg) => availableRegions.push(reg.name));
            })
            updateRegionNames(availableRegions);
        }

        loadAvailableRegions();
    }, [refresh, containers]);

    async function deleteUpcomingMsg(cellValues: any) {
        const data = cellValues.data;
        await handleScheduledMessages("delete", null, null, null, null, null, null, data.id, null, []);
        setRefresh(refresh += ".")
    }

    function addHoursToDate(date: Date, hours: number): Date {
        return new Date(new Date(date).setHours(date.getHours() + hours));
      }

    async function submitNewMsg(containers: number[], regions: number[], msgtitle: string, msgtxt: string, scheduletime: Date, is_test: Boolean) {
        await handleScheduledMessages("create", scheduletime, containers, regions, msgtitle, msgtxt, (CuserId || null), null, is_test, []);
        setRefresh(refresh += ".")
    }

    async function filterForSentStatus(filter: string) {
        if (filter == "all") {
            setRefresh(refresh += ".")
        } else if (filter == "sent") {
            setUpcomingMessages(allMessages.filter(message => message.has_been_sent == true))
        } else if (filter == "not sent") {
            setUpcomingMessages(allMessages.filter(message => message.has_been_sent == false))
        }
    }

    function getIdsFromStringInput(loc: any[], stringLst: string[]) {
        let applicableIds: number[] = [];
        stringLst.forEach((str: string) => {
            const id = (loc.filter((l: any) => l.name == str))[0]?.id;
            if (typeof id === "number") applicableIds.push(id);
        })
        console.log("applicable id test")
        console.log(applicableIds)
        return applicableIds
    }


    function getAdminUserById(params: ValueGetterParams) {
        const data = params.data
        const user = (adminUsers.filter((user) => user.id == data.sent_by))[0]?.email
        if (typeof user === "string") {
            return user
        } else {
            return "loading..."
        }

    }

    function getContainerNames(params: ValueGetterParams) {
        let contnames: string[] = [];
        const container_ids = params.data.container_ids;

        container_ids.forEach((cont_id: number) => {
            const name = (allContainers.filter((cont) => cont.id == cont_id))[0]?.name;
            if (typeof name === "string") { 
                contnames.push(name);
            } else {
                return ["loading..."]
            }
        })
        return contnames;
    }

    function getRegionNames(params: ValueGetterParams) {
        let regnames: string[] = [];
        const region_ids = params.data.default_region_ids;

        region_ids.forEach((reg_id: number) => {
            const name = (allRegions.filter((reg) => reg.id == reg_id))[0]?.name;
            if (typeof name === "string") { 
                regnames.push(name);
            } else {
                return ["loading..."]
            }
        })
        return regnames;
    }

    const columns: ColDef[] = [
        { field: 'scheduled_for', headerName: 'UTC Time Scheduled to be Sent', width: 220 },
        { field: 'Submitted By', headerName: 'Submitted By', width: 110, valueGetter: getAdminUserById },
        { field: 'message_title', headerName: 'Notification Title', width: 200 },
        { field: 'text', headerName: 'Notification Message', width: 400 },
        { field: 'Containers', headerName: 'Containers', width: 110, valueGetter: getContainerNames },
        { field: 'Regions', headerName: 'Regions', width: 180, valueGetter: getRegionNames },
        { field: 'Delete', renderCell: (cellValues) => {
            return (
              <Button
                variant="contained"
                color="primary"
                onClick={() => deleteUpcomingMsg(cellValues)}
              >
                DELETE
              </Button>
            );
          } },
        { field: 'has_been_sent', headerName: 'Has Been Sent', width: 150 },

      ];


    return (
        <div>
            <div style={{ height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
            Your Local Time: {localTime.toLocaleDateString()} {localTime.toLocaleTimeString()}, Current Time in Kenya: {kenyaTime}
          </div>
            <div style={{ height: 50, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            Upcoming Scheduled Notifications
          </div>
          <ToggleBarContainer>
          <Button size="small" onClick={() => {filterForSentStatus("all")}} >
           ALL
          </Button>
          <Button size="small" onClick={() => {filterForSentStatus("sent")}} >
            SENT
          </Button>
          <Button size="small" onClick={() => {filterForSentStatus("not sent")}} >
            NOT SENT YET
          </Button>
        </ToggleBarContainer>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 700, width: '100%'}} >
            <DataGrid rows={upcomingMessages} columns={columns} pageSize={12} rowsPerPageOptions={[5]} className={classes.root} />
            </div>
            <div style={{ height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            Schedule a New Notification
          </div>
          <ToggleBarContainer>
            Want to schedule a forecast for specific users?
            <Button href={"/customUserNotifMessaging"}>
              CLICK HERE
            </Button>
          </ToggleBarContainer>
          {containerNames.length < 1 && <div  style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 100, width: '100%', fontSize: '30px'}} >
            LOADING...
          </div>}
          <FormContainer>
              <Form>
                  <Section>
                  <FormControl>
                      <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Select Containers
                        </FormLabel>
                      <Select
                        labelId="modify-containers-label"
                        id="modify-containers"
                        multiple
                        value={containers}
                        onChange={(event) =>
                            updateContainers(event.target.value as string[])
                        }
                        input={<Input />}
                        renderValue={(selected) => (selected as string[]).join(", ")}
                        >
                        {containerNames.map((name) => (
                            <MenuItem key={name} value={name}>
                            <Checkbox checked={containers.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                        </Select>
                      </FormControl>
                  </Section>
                  <Section>
                  <FormControl>
                      <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Select Regions
                        </FormLabel>
                      <Select
                        labelId="modify-regions-label"
                        id="modify-regions"
                        multiple
                        value={regions}
                        onChange={(event) =>
                            updateRegions(event.target.value as string[])
                        }
                        input={<Input />}
                        renderValue={(selected) => (selected as string[]).join(", ")}
                        >
                        {regionNames.map((name) => (
                            <MenuItem key={name} value={name}>
                            <Checkbox checked={regions.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                        </Select>
                      </FormControl>
                  </Section>
                  <Section>
                  <ToggleBarContainer>
                      <Button onClick={(e) => setFormatToggle(0)} color={((formatToggle == 0) ? 'primary' : 'default')} >
                        Forecast Bulletin Format
                      </Button>
                      <Button onClick={(e) => setFormatToggle(1)} color={((formatToggle == 1) ? 'primary' : 'default')} >
                        Regular Message
                      </Button>
                    </ToggleBarContainer>
                  <FormControl>
                      <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Enter Notification Title
                        </FormLabel>
                        <TextInput
                            id="notif-title"
                            variant="outlined"
                            value={messageTitle}
                            onChange={(e) => updateMessageTitle(e.target.value)}
                        />
                        {formatToggle == 1 && 
                        <div>
                        <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Enter Notification Message
                        </FormLabel>
                        <TextInput
                            id="notif-message"
                            variant="outlined"
                            value={messageText}
                            onChange={(e) => updateMessageText(e.target.value)}
                        />
                        </div>
                        }
                        {formatToggle == 0 && 
                        <div>
                        <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Enter Forecast Types
                        </FormLabel>
                        <TextInput
                            id="notif-types"
                            variant="outlined"
                            value={messageTypes}
                            onChange={(e) => updateMessageTypes(e.target.value)}
                        />
                        <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Enter Forecast Locations
                        </FormLabel>
                        <TextInput
                            id="notif-locations"
                            variant="outlined"
                            value={messageLocat}
                            onChange={(e) => updateMessageLocat(e.target.value)}
                        />
                        <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Enter Forecast Time Periods
                        </FormLabel>
                        <TextInput
                            id="notif-timepds"
                            variant="outlined"
                            value={messageTimePds}
                            onChange={(e) => updateMessageTimePds(e.target.value)}
                        />
                        <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Enter Forecast Advisory
                        </FormLabel>
                        <TextInput
                            id="notif-advisory"
                            variant="outlined"
                            value={messageAdvisory}
                            onChange={(e) => updateMessageAdvisory(e.target.value)}
                        />
                        </div>
                        }
                      </FormControl>
                  </Section>
                  <Section>
                      <FormControl>
                      <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Set Date and Time for When to Send Notification, In Your Local Time
                        </FormLabel>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                        minDateTime={addHoursToDate(new Date(), 1)}
                        value={scheduledTime}
                        onChange={(date: Date) => setScheduledTime(date)}
                        style={{marginLeft: 30, marginRight: 30}}
                        />
                    </MuiPickersUtilsProvider>
                      </FormControl>
                  </Section>
                  <div style={{ height: 20, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px' }}>
                        To confirm - the currently selected time would send a notification at {formatter.format(scheduledTime)} in local Kenya time
                    </div>
                  <Section>
                      <Button onClick={() => {
                        let finalMessageText: string = "";
                        if (formatToggle == 0) {
                          finalMessageText = `Types: ${messageTypes}\n Locations: ${messageLocat}\n Time Periods: ${messageTimePds}\n Advisory: ${messageAdvisory}`
                        }
                        else {
                          finalMessageText = messageText
                        }
                        submitNewMsg(getIdsFromStringInput(allContainers, containers), getIdsFromStringInput(allRegions, regions), messageTitle, finalMessageText, scheduledTime, false)}
                        }>
                          SUBMIT
                      </Button>
                  </Section>
              </Form>
          </FormContainer>
          <div>
            <div style={{height: 400}} />
              <div>
                  DEV PURPOSES ONLY
              </div>
              Click this to log all of the scheduled tasks in node-schedule that are currently active (check PM2/Jarvis Logs)
              <Button onClick={() => {handleScheduledMessages("log node-schedule", null, null, null, null, null, null, null, null, [])}}>
                  CONSOLE LOG
              </Button>
              <div>
                  Use this if you would like to debug the scheduler or confirm that the scheduled_messages table is correctly representing what's on node-schedule
              </div>
              <div>
                  Additionally, you can schedule a test message to send to certain Arcturus team members!
                  <Button href={"/notifMessagingTEST"} >
                      SCHEDULE TEST MESSAGE
                  </Button>
              </div>
              <div>
                  Note that scheduled test messages do not appear as actual bulletins in the insights menu, but solely send a test notification.
              </div>
          </div>

      </div>
    )
}


export const DisplayNotifMessagingTEST = () => {

    let options = {
        timeZone: "Africa/Nairobi",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      },
      // @ts-ignore
      formatter = new Intl.DateTimeFormat([], options); // error ignored on options -> types completely match exactly but says they dont, presents no issues

    const localTime = new Date()
    const kenyaTime = formatter.format(new Date());

    const CuserId = useSelectFromRedux((state) => state.cuser.profile?.id);
    //const kenyaTime = moment()

    const classes = styles();

    const [upcomingMessages, setUpcomingMessages] = useState<ScheduledMesssage[]>([]);
    const [allMessages, setAllMessages] = useState<ScheduledMesssage[]>([]);
    const [allContainers, setAllContainers] = useState<Container[]>([]);
    const [allRegions, setAllRegions] = useState<DefaultRegion[]>([]);
    const [messageTitle, updateMessageTitle] = useState<string>("");
    const [messageText, updateMessageText] = useState<string>("");
    const [ scheduledTime, setScheduledTime ] = useState<Date>(addHoursToDate(new Date(), 1));
    var [refresh, setRefresh] = useState<string>("");
    const [adminUsers, setAdminUsers] = useState<AdminUserProfile[]>([]);

    useEffect(() => {

        const fetchdata = async () => {

            const messages = await handleScheduledMessages("read", null, null, null, null, null, null, null, true, []);
            setUpcomingMessages(messages);
            setAllMessages(messages);

            const adminusers = await getAllAdminUsers()
            setAdminUsers(adminusers)

            const regions: DefaultRegion[] = await getAllDefaultRegions();
            setAllRegions(regions);

            const response: Container[] = await getAllContainers();
            setAllContainers(response);
            const responseNamesArr: string[] = [];
            const responseDetailsArr: Container[] = [];

            response.forEach((fetchedContainer) => {
                responseDetailsArr.push(fetchedContainer);
                responseNamesArr.push(fetchedContainer.name);
            });
        }

        fetchdata();
        setRefresh("");

    }, [refresh]);

    async function deleteUpcomingMsg(cellValues: any) {
        const data = cellValues.data;
        await handleScheduledMessages("delete", null, null, null, null, null, null, data.id, null, []);
        setRefresh(refresh += ".")
    }

    function addHoursToDate(date: Date, hours: number): Date {
        return new Date(new Date(date).setHours(date.getHours() + hours));
      }

    async function submitNewMsg(containers: number[], regions: number[], msgtitle: string, msgtxt: string, scheduletime: Date, is_test: Boolean) {
        await handleScheduledMessages("create", scheduletime, containers, regions, msgtitle, msgtxt, (CuserId || null), null, is_test, []);
        setRefresh(refresh += ".")
    }

    async function filterForSentStatus(filter: string) {
        if (filter == "all") {
            setRefresh(refresh += ".")
        } else if (filter == "sent") {
            setUpcomingMessages(allMessages.filter(message => message.has_been_sent == true))
        } else if (filter == "not sent") {
            setUpcomingMessages(allMessages.filter(message => message.has_been_sent == false))
        }
    }

    function getAdminUserById(params: ValueGetterParams) {
        const data = params.data
        const user = (adminUsers.filter((user) => user.id == data.sent_by))[0]?.email
        if (typeof user === "string") {
            return user
        } else {
            return "loading..."
        }

    }

    const columns: ColDef[] = [
        { field: 'scheduled_for', headerName: 'UTC Time Scheduled to be Sent', width: 220 },
        { field: 'Submitted By', headerName: 'Submitted By', width: 110, valueGetter: getAdminUserById },
        { field: 'message_title', headerName: 'Notification Title', width: 300 },
        { field: 'text', headerName: 'Notification Message', width: 600 },
        { field: 'Delete', renderCell: (cellValues) => {
            return (
              <Button
                variant="contained"
                color="primary"
                onClick={() => deleteUpcomingMsg(cellValues)}
              >
                DELETE
              </Button>
            );
          } },
        { field: 'has_been_sent', headerName: 'Has Been Sent', width: 150 },
      ];


    return (
        <div>
            <div style={{ height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
            Your Local Time: {localTime.toLocaleDateString()} {localTime.toLocaleTimeString()}, Current Time in Kenya: {kenyaTime}
          </div>
            <div style={{ height: 50, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            Upcoming Scheduled TEST Notifications
          </div>
          <ToggleBarContainer>
          <Button size="small" onClick={() => {filterForSentStatus("all")}} >
           ALL
          </Button>
          <Button size="small" onClick={() => {filterForSentStatus("sent")}} >
            SENT
          </Button>
          <Button size="small" onClick={() => {filterForSentStatus("not sent")}} >
            NOT SENT YET
          </Button>
        </ToggleBarContainer>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 700, width: '100%'}} >
            <DataGrid rows={upcomingMessages} columns={columns} pageSize={12} rowsPerPageOptions={[5]} className={classes.root} />
            </div>
            <div style={{ height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            Schedule a New TEST Notification
          </div>
          <FormContainer>
              <Form>
                  <Section>
                    <ToggleBarContainer>
                      <Button>
                        Forecast Bulletin Format
                      </Button>
                      <Button>
                        Regular Message
                      </Button>
                    </ToggleBarContainer>
                  <FormControl>
                      <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Enter Notification Title
                        </FormLabel>
                        <TextInput
                            id="notif-title"
                            variant="outlined"
                            value={messageTitle}
                            onChange={(e) => updateMessageTitle(e.target.value)}
                        />
                        <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Enter Forecast Message
                        </FormLabel>
                        <TextInput
                            id="notif-message"
                            variant="outlined"
                            value={messageText}
                            onChange={(e) => updateMessageText(e.target.value)}
                        />

                      </FormControl>
                      
                      
                  </Section>
                  <Section>
                      <FormControl>
                      <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Set Date and Time for When to Send Notification, In Your Local Time
                        </FormLabel>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                        minDateTime={addHoursToDate(new Date(), 1)}
                        value={scheduledTime}
                        onChange={(date: Date) => setScheduledTime(date)}
                        style={{marginLeft: 30, marginRight: 30}}
                        />
                    </MuiPickersUtilsProvider>
                      </FormControl>
                  </Section>
                  <div style={{ height: 20, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px' }}>
                        To confirm - the currently selected time would send a notification at {formatter.format(scheduledTime)} in local Kenya time
                    </div>
                  <Section>
                      <Button onClick={() => {submitNewMsg([], [], messageTitle, messageText, scheduledTime, true)}}>
                          SUBMIT
                      </Button>
                  </Section>
              </Form>
          </FormContainer>
          <div>
              <Button href={"/notifMessaging"} >
                  BACK TO NORMAL SCHEDULER
              </Button>
          </div>

      </div>
    )
}


export const DisplayCustomUserNotifMessaging = () => {

  let options = {
      timeZone: "Africa/Nairobi",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    },
    // @ts-ignore
    formatter = new Intl.DateTimeFormat([], options); // error ignored on options -> types completely match exactly but says they dont, presents no issues

  const localTime = new Date()
  const kenyaTime = formatter.format(new Date());

  const CuserId = useSelectFromRedux((state) => state.cuser.profile?.id);
  //const kenyaTime = moment()

  const classes = styles();

  const [upcomingMessages, setUpcomingMessages] = useState<ScheduledMesssage[]>([]);
  const [allMessages, setAllMessages] = useState<ScheduledMesssage[]>([]);
  const [containerNames, updateContainerNames] = useState<string[]>([]);
  const [containers, updateContainers] = useState<string[]>([]);
  const [regionNames, updateRegionNames] = useState<string[]>([]);
  const [regions, updateRegions] = useState<string[]>([]);
  const [allContainers, setAllContainers] = useState<Container[]>([]);
  const [allRegions, setAllRegions] = useState<DefaultRegion[]>([]);
  const [messageTitle, updateMessageTitle] = useState<string>("");
  const [messageText, updateMessageText] = useState<string>("");
  const [ scheduledTime, setScheduledTime ] = useState<Date>(addHoursToDate(new Date(), 1));
  var [refresh, setRefresh] = useState<string>("");
  const [adminUsers, setAdminUsers] = useState<AdminUserProfile[]>([]);
  const [userIds, setUserIds] = useState<number[]>([]);
  const [selectedNames, setSelectedNames] = useState<string>("");
  const [allUsers, setAllUsers] = useState<UserProfile[]>([]);
  const [foundUsers, setFoundUsers] = useState<UserProfile[]>([]);
  const [userSearchTerm, setUserSearchTerm] = useState<string>("");

  const [messageTypes, updateMessageTypes] = useState<string>("");
  const [messageLocat, updateMessageLocat] = useState<string>("");
  const [messageTimePds, updateMessageTimePds] = useState<string>("");
  const [messageAdvisory, updateMessageAdvisory] = useState<string>("");
  const [formatToggle, setFormatToggle] = useState<number>(0);


  useEffect(() => {

      const fetchdata = async () => {

          const messages = await handleScheduledMessages("read", null, null, null, null, null, null, null, false, []);
          setUpcomingMessages(messages);
          setAllMessages(messages);

          const adminusers = await getAllAdminUsers()
          setAdminUsers(adminusers)

          const regions: DefaultRegion[] = await getAllDefaultRegions();
          setAllRegions(regions);

          const users: UserProfile[] = await getAllUsers();
          setAllUsers(users);

          const response: Container[] = await getAllContainers();
          setAllContainers(response);
          const responseNamesArr: string[] = [];
          const responseDetailsArr: Container[] = [];

          response.forEach((fetchedContainer) => {
              responseDetailsArr.push(fetchedContainer);
              responseNamesArr.push(fetchedContainer.name);
          });
          updateContainerNames(responseNamesArr);
      }

      fetchdata();
      setRefresh("");

  }, [refresh]);


  useEffect(() => {
      const loadAvailableRegions = async () => {
          let availableRegions: string[] = [];

          containers.forEach((containerName) => {
              const id = allContainers.filter((container) => container.name == containerName)[0]?.id;
              const cont_regs = allRegions.filter((region) => region.container_id == id);
              cont_regs.map((reg) => availableRegions.push(reg.name));
          })
          updateRegionNames(availableRegions);
      }

      loadAvailableRegions();
  }, [refresh, containers]);

  async function deleteUpcomingMsg(cellValues: any) {
      const data = cellValues.data;
      await handleScheduledMessages("delete", null, null, null, null, null, null, data.id, null, []);
      setRefresh(refresh += ".")
  }

  function addHoursToDate(date: Date, hours: number): Date {
      return new Date(new Date(date).setHours(date.getHours() + hours));
    }

  async function submitNewMsg(containers: number[], regions: number[], msgtitle: string, msgtxt: string, scheduletime: Date, is_test: Boolean, custom_user_ids: number[]) {
      await handleScheduledMessages("create", scheduletime, containers, regions, msgtitle, msgtxt, (CuserId || null), null, is_test, custom_user_ids);
      setRefresh(refresh += ".")
  }

  async function filterForSentStatus(filter: string) {
      if (filter == "all") {
          setRefresh(refresh += ".")
      } else if (filter == "sent") {
          setUpcomingMessages(allMessages.filter(message => message.has_been_sent == true))
      } else if (filter == "not sent") {
          setUpcomingMessages(allMessages.filter(message => message.has_been_sent == false))
      }
  }

  function getIdsFromStringInput(loc: any[], stringLst: string[]) {
      let applicableIds: number[] = [];
      stringLst.forEach((str: string) => {
          const id = (loc.filter((l: any) => l.name == str))[0]?.id;
          if (typeof id === "number") applicableIds.push(id);
      })
      console.log("applicable id test")
      console.log(applicableIds)
      return applicableIds
  }


  function getAdminUserById(params: ValueGetterParams) {
      const data = params.data
      const user = (adminUsers.filter((user) => user.id == data.sent_by))[0]?.email
      if (typeof user === "string") {
          return user
      } else {
          return "loading..."
      }

  }

  function getContainerNames(params: ValueGetterParams) {
      let contnames: string[] = [];
      const container_ids = params.data.container_ids;

      container_ids.forEach((cont_id: number) => {
          const name = (allContainers.filter((cont) => cont.id == cont_id))[0]?.name;
          if (typeof name === "string") { 
              contnames.push(name);
          } else {
              return ["loading..."]
          }
      })
      return contnames;
  }

  function getRegionNames(params: ValueGetterParams) {
      let regnames: string[] = [];
      const region_ids = params.data.default_region_ids;

      region_ids.forEach((reg_id: number) => {
          const name = (allRegions.filter((reg) => reg.id == reg_id))[0]?.name;
          if (typeof name === "string") { 
              regnames.push(name);
          } else {
              return ["loading..."]
          }
      })
      return regnames;
  }

  function searchUsers(search: string) {

    let found: UserProfile[] = [];

    allUsers.forEach((user) => {
      if ((user.email == search) || (user.phone_primary == search)) {
        if ((!foundUsers.includes(user)) && (!found.includes(user))) found.push(user)
      }
    })

    setFoundUsers(found);

  }

  async function selectUser(cellValues: any) {
    const user: UserProfile = cellValues.data;
    if (!userIds.includes(user.id)) {
      let existing: number[] = userIds;
      existing.push(user.id);
      setUserIds(existing);
      setSelectedNames(selectedNames + " " + user.first_name + " " + user.last_name + ",")
    }
  }

  const columns: ColDef[] = [
      { field: 'scheduled_for', headerName: 'UTC Time Scheduled to be Sent', width: 220 },
      { field: 'Submitted By', headerName: 'Submitted By', width: 110, valueGetter: getAdminUserById },
      { field: 'message_title', headerName: 'Notification Title', width: 200 },
      { field: 'text', headerName: 'Notification Message', width: 400 },
      { field: 'Containers', headerName: 'Containers', width: 110, valueGetter: getContainerNames },
      { field: 'Regions', headerName: 'Regions', width: 180, valueGetter: getRegionNames },
      { field: 'Delete', renderCell: (cellValues) => {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() => deleteUpcomingMsg(cellValues)}
            >
              DELETE
            </Button>
          );
        } },
      { field: 'has_been_sent', headerName: 'Has Been Sent', width: 150 },

    ];

    const usercolumns: ColDef[] = [
      { field: 'id', headerName: 'ID', width: 50 },
      { field: 'email', headerName: 'Email', width: 150 },
      { field: 'phone_primary', headerName: 'Phone', width: 150 },
      { field: 'first_name', headerName: 'First Name', width: 150 },
      { field: 'last_name', headerName: 'Last Name', width: 150 },
      { field: 'SELECT', renderCell: (cellValues) => {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() => selectUser(cellValues)}
            >
              SELECT
            </Button>
          );
        } },

    ];


  return (
      <div>
          <div style={{ height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
          Your Local Time: {localTime.toLocaleDateString()} {localTime.toLocaleTimeString()}, Current Time in Kenya: {kenyaTime}
        </div>
          <div style={{ height: 50, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
          Upcoming Scheduled Notifications
        </div>
        <ToggleBarContainer>
        <Button size="small" onClick={() => {filterForSentStatus("all")}} >
         ALL
        </Button>
        <Button size="small" onClick={() => {filterForSentStatus("sent")}} >
          SENT
        </Button>
        <Button size="small" onClick={() => {filterForSentStatus("not sent")}} >
          NOT SENT YET
        </Button>
      </ToggleBarContainer>
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 700, width: '100%'}} >
          <DataGrid rows={upcomingMessages} columns={columns} pageSize={12} rowsPerPageOptions={[5]} className={classes.root} />
          </div>
          <div style={{ height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
          Schedule a New Notification
        </div>
        <ToggleBarContainer>
          <Button href={"/notifMessaging"} >
              BACK TO NORMAL SCHEDULER
          </Button>
        </ToggleBarContainer>
        {containerNames.length < 1 && <div  style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 100, width: '100%', fontSize: '30px'}} >
            LOADING...
          </div>}
        <FormContainer>
            <Form>
                <Section>
                <div style={{ height: 50, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px' }}>
                  1. Search and Select Users for Sending Notifications/Bulletins To
                </div>
                <FormControl>
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                      Enter Email or Phone Number
                      </FormLabel>
                      <ToggleBarContainer>
                      <TextInput
                          id="searchterm"
                          variant="outlined"
                          value={userSearchTerm}
                          onChange={(e) => setUserSearchTerm(e.target.value)}
                      />
                      <Button onClick={() => {searchUsers(userSearchTerm)}} >
                        SEARCH
                      </Button>
                      </ToggleBarContainer>
                      <div style={{ height: 20 }} />
                      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 200, width: '100%'}} >
                      <DataGrid rows={foundUsers} columns={usercolumns} pageSize={12} rowsPerPageOptions={[3]} className={classes.root} />
                      </div>
                      <div style={{ height: 20 }} />
                      <div>
                        Selected Users: {selectedNames}
                      </div>
                      <Button onClick={() => {
                        setUserIds([]);
                        setSelectedNames("")
                      }}>
                        CLEAR SELECTED USERS
                      </Button>
                </FormControl>
                </Section>
                <div style={{ height: 50, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px' }}>
                        2. Enter Notification and Bulletin Information
                      </div>
                <Section>
                  <FormControl>
                      <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Select Containers
                        </FormLabel>
                      <Select
                        labelId="modify-containers-label"
                        id="modify-containers"
                        multiple
                        value={containers}
                        onChange={(event) =>
                            updateContainers(event.target.value as string[])
                        }
                        input={<Input />}
                        renderValue={(selected) => (selected as string[]).join(", ")}
                        >
                        {containerNames.map((name) => (
                            <MenuItem key={name} value={name}>
                            <Checkbox checked={containers.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                        </Select>
                      </FormControl>
                  </Section>
                  <Section>
                  <FormControl>
                      <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Select Regions
                        </FormLabel>
                      <Select
                        labelId="modify-regions-label"
                        id="modify-regions"
                        multiple
                        value={regions}
                        onChange={(event) =>
                            updateRegions(event.target.value as string[])
                        }
                        input={<Input />}
                        renderValue={(selected) => (selected as string[]).join(", ")}
                        >
                        {regionNames.map((name) => (
                            <MenuItem key={name} value={name}>
                            <Checkbox checked={regions.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                        </Select>
                      </FormControl>
                  </Section>
                <Section>
                <ToggleBarContainer>
                      <Button onClick={(e) => setFormatToggle(0)} color={((formatToggle == 0) ? 'primary' : 'default')} >
                        Forecast Bulletin Format
                      </Button>
                      <Button onClick={(e) => setFormatToggle(1)} color={((formatToggle == 1) ? 'primary' : 'default')} >
                        Regular Message
                      </Button>
                    </ToggleBarContainer>
                <FormControl>
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                      Enter Notification Title
                      </FormLabel>
                      <TextInput
                          id="notif-title"
                          variant="outlined"
                          value={messageTitle}
                          onChange={(e) => updateMessageTitle(e.target.value)}
                      />
                      {formatToggle == 1 && 
                        <div>
                        <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Enter Notification Message
                        </FormLabel>
                        <TextInput
                            id="notif-message"
                            variant="outlined"
                            value={messageText}
                            onChange={(e) => updateMessageText(e.target.value)}
                        />
                        </div>
                        }
                        {formatToggle == 0 && 
                        <div>
                        <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Enter Forecast Types
                        </FormLabel>
                        <TextInput
                            id="notif-types"
                            variant="outlined"
                            value={messageTypes}
                            onChange={(e) => updateMessageTypes(e.target.value)}
                        />
                        <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Enter Forecast Locations
                        </FormLabel>
                        <TextInput
                            id="notif-locations"
                            variant="outlined"
                            value={messageLocat}
                            onChange={(e) => updateMessageLocat(e.target.value)}
                        />
                        <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Enter Forecast Time Periods
                        </FormLabel>
                        <TextInput
                            id="notif-timepds"
                            variant="outlined"
                            value={messageTimePds}
                            onChange={(e) => updateMessageTimePds(e.target.value)}
                        />
                        <FormLabel component="legend" style={{ margin: "10px 0" }}>
                        Enter Forecast Advisory
                        </FormLabel>
                        <TextInput
                            id="notif-advisory"
                            variant="outlined"
                            value={messageAdvisory}
                            onChange={(e) => updateMessageAdvisory(e.target.value)}
                        />
                        </div>
                        }
                    </FormControl>
                </Section>
                <Section>
                    <FormControl>
                    <FormLabel component="legend" style={{ margin: "10px 0" }}>
                      Set Date and Time for When to Send Notification, In Your Local Time
                      </FormLabel>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                      minDateTime={addHoursToDate(new Date(), 1)}
                      value={scheduledTime}
                      onChange={(date: Date) => setScheduledTime(date)}
                      style={{marginLeft: 30, marginRight: 30}}
                      />
                  </MuiPickersUtilsProvider>
                    </FormControl>
                </Section>
                <div style={{ height: 20, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px' }}>
                      To confirm - the currently selected time would send a notification at {formatter.format(scheduledTime)} in local Kenya time
                  </div>
                <Section>
                  <Button onClick={() => {
                      let finalMessageText: string = "";
                      if (formatToggle == 0) {
                        finalMessageText = `Types: ${messageTypes}\n Locations: ${messageLocat}\n Time Periods: ${messageTimePds}\n Advisory: ${messageAdvisory}`
                      }
                      else {
                        finalMessageText = messageText
                      }
                      submitNewMsg(getIdsFromStringInput(allContainers, containers), getIdsFromStringInput(allRegions, regions), messageTitle, finalMessageText, scheduledTime, false, userIds)}
                      }>
                        SUBMIT
                    </Button>
                </Section>
            </Form>
        </FormContainer>
        <div>
        <div style={{ height: 800 }} />
            <div>
                DEV PURPOSES ONLY
            </div>
            Click this to log all of the scheduled tasks in node-schedule that are currently active (check PM2/Jarvis Logs)
            <Button onClick={() => {handleScheduledMessages("log node-schedule", null, null, null, null, null, null, null, null, [])}}>
                CONSOLE LOG
            </Button>
        </div>

    </div>
  )
}