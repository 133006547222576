import React, { useEffect, useState } from "react";
import { Button, FormControl, InputLabel, Select, Input, MenuItem, ListItemText, Checkbox, FormLabel, TextField, Box, IconButton } from "@material-ui/core";
import { DataGrid, ColDef, ValueGetterParams, CellParams } from "@material-ui/data-grid";
import { Form, FormContainer, Section, ToggleBarContainer,  } from "./styled";
import { getAllDefaultRegions, getHomepageData, getSummaryData } from "../types/api/admin";
import { getContainers, getAllContainers } from "../types/api/admin";
import { useThrow } from "../catch";
import { ClientDisplayData, DisplayClient, UserFeatureSelection } from "../types/client";
import { Autocomplete, Alert } from "@material-ui/lab";
import {makeStyles} from '@material-ui/styles';
import Link from "@material-ui/core/Link";
import { Container } from "../types/geo"
import { ConsoleView } from "react-device-detect";
import { getPublicReports, getAllPublicReports } from "../types/api/admin";
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  LineSeries,
} from '@devexpress/dx-react-chart-material-ui';
import { stringify } from "querystring";


const styles = makeStyles((theme) => ({
  root: {
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    color: 'black',
    backgroundColor: 'grey'
  },
}));


export const DisplayTravelApp = () => {

  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const [homepageCounts, setHomepageCounts] = useState<any>()

  useEffect(() => {

    const callData = async () => {

      const res = await getHomepageData()

      setHomepageCounts(res.counts)

      console.log(res)

      setDataLoaded(true)

    }

    callData();

  }, []);
    

  return (

    <div>
        <ToggleBarContainer>
            <Button size="large" href="/travelPendingTrips">
                Pending Trips
            </Button>
            <Button size="large" color="primary" >
                Home
            </Button>
            <Button size="large" href="/travelAreas" >
                Countries & Cities
            </Button>
            <Button size="large" href="/travelActiveTrips" >
                Active Trips
            </Button>
        </ToggleBarContainer>
        <div>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '30px', height: '100px'}} >
            Welcome to the Jett Terminal!
          </div>
          <div style={{height: 80}}/>
          {dataLoaded == false && <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px', height: '50px'}} >
          Loading Information ...
          </div>}
          {dataLoaded == true && <div>
          <div style={{height: 50}}/>
          <div style={{ alignItems: 'center', display: 'flex', whiteSpace: 'pre-line', justifyContent: 'center', fontSize: '20px', height: '50px'}} >
          Total Users Signed Up: {homepageCounts.users} {'\n\n'}
          Trips Created: {homepageCounts.totalTrips} in total, {homepageCounts.approvedTrips} approved, {homepageCounts.pendingTrips} still pending {'\n\n'}
          Out of the approved trips: {'\n'}
          - {homepageCounts.pastTrips} {(homepageCounts.pastTrips == 1) ? "trip has" : "trips have"} already taken place and ended {'\n'}
          - {homepageCounts.futureTrips} {(homepageCounts.futureTrips == 1) ? "trip has" : "trips have"} yet to take place and have not begun yet {'\n'}
          - {homepageCounts.currentTrips} {(homepageCounts.currentTrips == 1) ? "trip is" : "trips are"} currently ongoing. {'\n\n'}
          Within the {homepageCounts.totalTrips} {(homepageCounts.totalTrips == 1) ? "trip that has" : "trips that have"} been created, {homepageCounts.destinations} {(homepageCounts.destinations == 1) ? "destination has been" : "destinations have been"} added {'\n\n'}
          Currently, the average number of destinations per trip is {homepageCounts.averageDestsInTrip}
          </div>
          </div>}
        </div>
    </div>

  )

}