import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import TimeWidget from "./filters/time-widget";

const propTypes = {
    filters: PropTypes.arrayOf(PropTypes.object),
    visStateActions: PropTypes.object
};

const BottomWidgetContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    padding-top: ${(props) => props.theme.bottomWidgetPaddingTop}px;
    padding-right: ${(props) => props.theme.bottomWidgetPaddingRight}px;
    padding-bottom: ${(props) => props.theme.bottomWidgetPaddingBottom}px;
    padding-left: ${(props) => props.theme.bottomWidgetPaddingLeft}px;
    width: ${(props) => props.width}px;
    align-self: center;
    bottom: 0px;
    left: 420px;
    z-index: 1;
`;

const BottomWidget = (props) => {
    const { filter, visStateActions, containerW, closeTimeComponent } = props;

    const filterWidth = containerW - 500;
    const minWidth = 300;

    return (
        <BottomWidgetContainer width={Math.max(filterWidth, minWidth)} className="bottom-widget--container">
            <TimeWidget
                filter={filter}
                index={0}
                showTimeDisplay={true}
                setFilterPlot={visStateActions.setFilterPlot}
                setFilter={visStateActions.setFilter}
                updateAnimationSpeed={visStateActions.updateFilterAnimationSpeed}
                closeTimeComponent={closeTimeComponent}
            />
        </BottomWidgetContainer>
    );
};

BottomWidget.propTypes = propTypes;

export default BottomWidget;
