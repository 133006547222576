import { default as React,
         useRef } from "react";
import styled from "styled-components";

import { default as ReactImageGallery,
         ReactImageGalleryProps,
         ReactImageGalleryItem } from "react-image-gallery";

const VIDEO_MIME_TYPES: Record<string, string> = {
    MOV: "mp4",
    MP4: "mp4",
    WEBM: "webm"
};

const AUDIO_MIME_TYPES: Record<string, string> = {
    OPUS: "ogg",
    MP3: "mpeg"
};

function renderItem(this: React.RefObject<ReactImageGallery>, item: ReactImageGalleryItem): React.ReactNode {
    // TODO: render based on Content-Type response header from fetching item.original ?
    const extension = item?.original?.split(".")?.pop()?.toUpperCase();
    if (extension) {
        let mimeType = VIDEO_MIME_TYPES[extension];
        if (mimeType) {
            // Video:
            return (
                <div>
                  <video width="100%" controls>
                    <source src={item.original} type={"video/" + mimeType} />
                    Your browser is unable to play video directly.
                  </video>
                </div>
            );
        }

        mimeType = AUDIO_MIME_TYPES[extension];
        if (mimeType) {
            // Audio:
            return (
                <div>
                  <audio controls>
                    <source src={item.original} type={"audio/" + mimeType} />
                    Your browser is unable to play audio directly.
                  </audio>
                </div>
            );
        }
    }

    return (this.current as any)?.renderItem?.(item);
};

const ImageGallery = ({ className, ...props }: ReactImageGalleryProps & { className?: string }) => {
    const galleryRef = useRef<ReactImageGallery>(null);
    return <ReactImageGallery {...props} ref={galleryRef} renderItem={renderItem.bind(galleryRef)} additionalClass={className} />;
};

export default styled(ImageGallery)`
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: black;
`;
