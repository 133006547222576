import { default as React, useState} from "react";
import styled from "styled-components";
import { Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    MenuItem,
    Select} from "@material-ui/core";
import { flattenValues, RowData } from ".";
import moment from "moment";
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import { Form, PendingAampReportUpdate } from "../types/aamp";

const Container = styled.div`
max-height: 100px;
overflow: hidden;
position: relative;
`;

const FullDescription = styled(DialogContentText)`
white-space: pre-line;
color: white;
`;

export default ({props, setTooltip, form, updateReport} : {
  props : RowData, 
  setTooltip: (tool : RowData | undefined) => void, 
  form : Form | undefined,
  updateReport: (row: number, update: Omit<PendingAampReportUpdate, "id" | "changes">) => Promise<void>
}) => {

const onClose = () => setTooltip(undefined);

if(!form) return null;

const [editing, setEditing] = useState<boolean>(false);
const [ time, setTime ] = useState<Date>(props.date_time as Date);
const [ location, setLocation ] = useState<string | undefined>(props.address);
const [ type, setType ] = useState<string>(props.aamp_report_type);
const [ victim, setVictim ] = useState<string>(props.victim);
const [ aggressor, setAggressor ] = useState<string>(props.aggressor)
const [ description, setDescription ] = useState<string>(props.description);

const toggleEditing = () => {
  setEditing(!editing);
}

const update = () => {
  updateReport(props.row, {
    address: location,
    date_time: time,
    description: description,
    fields: {
        aamp_report_type: type,
        victim: victim,
        aggressor: aggressor
      }
  })
  setEditing(false);
}

const getChildren = (s : "aamp_report_type" | "aggressor" | "victim"): React.ReactNode => {
 return flattenValues(form.fields[s].values).map((value: string) => (
  <MenuItem key={value} value={value}>{value}</MenuItem>
 ))};

return (
   <Container>
     <Dialog open={props !== undefined}
             onClose={onClose}>
      {!editing &&
      <>
        <DialogTitle style={{height: 25, marginBottom: 5}}>
          Reporter
        </DialogTitle>
        <DialogContent>
              {props.first_name + " " + props.last_name ? props.last_name : props.username}
        </DialogContent>
        <DialogTitle style={{height: 25, marginBottom: 5}}>
          Time
        </DialogTitle>
        <DialogContent>
              {moment(new Date(time).toUTCString()).toString()}
        </DialogContent>
        <DialogTitle style={{height: 25, marginBottom: 5}}>
          Reported At
        </DialogTitle>
        <DialogContent>
              {moment(new Date(props.created_at).toUTCString()).toString()}
        </DialogContent>
        <DialogTitle style={{height: 25, marginBottom: 5}}>
          Location
        </DialogTitle>
        <DialogContent>
              {location}
        </DialogContent>
        <DialogTitle style={{height: 25, marginBottom: 5}}>
          Type
        </DialogTitle>
        <DialogContent>
              {type}
        </DialogContent>
        <DialogTitle style={{height: 25, marginBottom: 5}}>
          Victim
        </DialogTitle>
        <DialogContent>
              {victim}
        </DialogContent>
        <DialogTitle style={{height: 25, marginBottom: 5}}>
          Aggressor
        </DialogTitle>
        <DialogContent>
              {aggressor}
        </DialogContent>
        <DialogTitle style={{height: 25, marginBottom: 5}}>
          Description
        </DialogTitle>
        <DialogContent>
          <FullDescription>
              {description}
          </FullDescription>
        </DialogContent>
      </>
      }
      {editing &&
      <>
        <DialogTitle style={{height: 25, marginBottom: 5}}>
          Reporter
        </DialogTitle>
        <DialogContent>
              {props.first_name + " " + props.last_name ? props.last_name : props.username}
        </DialogContent>
        <DialogTitle style={{height: 25, marginBottom: 5}}>
          Time
        </DialogTitle>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              value={time}
              onChange={(date: Date) => setTime(date)}
              style={{marginLeft: 30, marginRight: 30}}
            />
          </MuiPickersUtilsProvider>
        <DialogTitle style={{height: 25, marginBottom: 5}}>
          Reported At
        </DialogTitle>
        <DialogContent>
              {moment(new Date(props.created_at).toUTCString()).toString()}
        </DialogContent>
        <DialogTitle style={{height: 25, marginBottom: 5}}>
          Location
        </DialogTitle>
        <DialogContent>
          <TextField 
            style={{width: 500}} 
            multiline={true} 
            defaultValue={location}
            onChange={(event) => setLocation(event.target.value)}
          />
        </DialogContent>
        <DialogTitle style={{height: 25, marginBottom: 5}}>
          Type
        </DialogTitle>
        <DialogContent>
          <Select value={type} onChange={(event) => setType(event.target.value as string)}>
            {getChildren("aamp_report_type")}
          </Select>
        </DialogContent>
        <DialogTitle style={{height: 25, marginBottom: 5}}>
          Victim
        </DialogTitle>
        <DialogContent>
          <Select value={victim} onChange={(event) => setVictim(event.target.value as string)}>
            {getChildren("victim")}
          </Select>
        </DialogContent>
        <DialogTitle style={{height: 25, marginBottom: 5}}>
          Aggressor
        </DialogTitle>
        <DialogContent>
          <Select value={aggressor} onChange={(event) => setAggressor(event.target.value as string)}>
            {getChildren("aggressor")}
          </Select>
        </DialogContent>
        <DialogTitle style={{height: 25, marginBottom: 5}}>
          Description
        </DialogTitle>
        <DialogContent>
          <TextField 
            style={{width: 500}} 
            multiline={true} 
            defaultValue={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </DialogContent>
      </>
      }
       <DialogActions>
          {editing &&
          <Button onClick={update} color="primary" autoFocus>Update</Button>
          }
          <Button onClick={toggleEditing} color="primary" autoFocus>Edit</Button>
          <Button onClick={onClose} color="primary" autoFocus>Close</Button>
       </DialogActions>
     </Dialog>
   </Container>
);
};
