/* eslint-disable jsx-a11y/anchor-is-valid */
import { default as React, useRef, useState, useEffect, useCallback } from "react";
import { useDispatch} from "react-redux";


import Geocoder from "react-map-gl-geocoder";
import { useThrow } from "../catch";
import DeckGL from "deck.gl";
import { IconLayer, PolygonLayer, PathLayer } from "@deck.gl/layers";
import {isEqual} from "lodash";
import { StaticMap, _MapContext as MapContext } from "react-map-gl";
import { MapboxAPIKey } from "../aamp/detailPanel";
import MyController from "./controller";
import { boxShadow, SearchbarContainer } from "./styled";
import { createMarkersFromReports, Marker } from "./utils";
import { renderAnalyticsLayers, renderMenuLayers, renderVisLayers } from "./renderLayers";
import { useSelectFromRedux, useDispatchPromise } from "../utils/_hooks";
import { Menu } from "antd";
import styled from "styled-components";
import { cuserActions } from "../state/cuser";
import { fetchPublicReportsFromContainer } from "../state/publicInfo/operations";
import { buttonOrange, getContainerViewportFromId } from "../utils";
import { mapActions, Viewport } from "../state/map";
import { filterActions } from "../state/filter";
import { Container, DefaultRegion } from "../types/geo";
import { visualizationsActions } from "../state/visualizations";
import Tooltip from "./tooltip";
import VisApiComponent from "./apiComponents/vis-api-component";
import TimeComponent from "./keplerComponents/components/time-component";
import Filter from "./filter";
import { Asset, Risk } from "../types/case";
import { getAllClients, getAllContainers, getAllDefaultRegions, getAllRisks, getAllTeams, getAssets } from "../types/api/admin";
import { default as MaterialTable, Action, Column } from "material-table";
import { Client, Team } from "../types/client";
import Concern from "./concern";
import RelevantEventTypes from "./relevantEventTypes";
import AssetTypeEdit from "./assetTypeEdit";
import RiskView from "./riskView";
import AddRiskView from "./addRiskView";
import AssetView from "./assetView";
import ScenarioView from "./scenarioView";
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { AddReportButton, PlusImage } from "../_shared";
import { DrawLineStringMode, DrawPointMode, DrawPolygonMode, EditableGeoJsonLayer } from "nebula.gl";
import AssetDescription from "./assetDescription";
import RiskSuggestions from "./riskViews/riskSuggestions";
import RiskComments from "./riskViews/riskComments";
import RiskAssetView from "./riskViews/riskAssetView";
import RiskEventTypesView from "./riskViews/riskEventTypesView";
import RiskConcernsView from "./riskViews/riskConcernsView";
import RiskConsequenceView from "./riskViews/riskConsequenceView";
import { Position } from "geojson";

interface StyledMenuItemProps {
    background: string;
    hoverbackground: string;
    selected: boolean;
}

interface DropdownContentProps {
    background: string;
    color: string;
}

export interface FormattedAsset {
    index: number,
    assetName: string,
    assetType: string,
    client: string,
    team: string,
    description: string,
    operationInterval: string,
    concerns: string,
    eventTypes: string,
    monetaryValue: number,
    needsReview: string,
}

export interface FormattedRisk {
    id: number,
    name: string,
    assets: string,
    eventTypes: string,
    riskInterval: string,
    createdAt: Date,
    severity: number,
    chance: number,
    concerns: string,
    consequences: string,
    containers: string,
    regions: string,
    suggestions: string,
    comments: string,
    needsReview: string,
}


const StyledMenuItem = styled(Menu.Item)`
    background-color: ${(props: StyledMenuItemProps) => (props.selected ? props.hoverbackground : props.background)};
    margin-left: 20px;

    :hover {
        cursor: pointer;
        padding-left: 20px;
        background-color: ${(props: StyledMenuItemProps) => props.hoverbackground};
    }
`;

const CurrentContainerButton = styled.div`
    z-index: 20;
    position: absolute;
    border-radius: 50%;
    box-shadow: 0px 4px 4px ${boxShadow};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${buttonOrange};
    width: 40px;
    height: 40px;
    top: 0px;
    right: 0px;
    color: white;
    font-size: 18px;

    :hover{
        cursor: pointer;
    }
    `;

const DropdownContent = styled(Menu)`
    position: relative;
    transform: none;
    transition: none;
    width: 200px;
    text-weight: bold;
    font-size: 13px;
    z-index: 100;
    padding: 10px;
    margin-top: 50px;
    padding-top: 5px;
    border-radius: 5px;
    background: ${(props: DropdownContentProps) => props.background};
    color: ${(props: DropdownContentProps) => props.color};
`;

const CurrentDisplayContainer = styled.div`
    z-index: 20;
    position: absolute;
    top: 20px;
    right: 20px;
`;

const ScreenWideDiv = styled.div`
    width: calc(100vw - 250px)
`;

const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

export default (props: any) => {
    const error = useThrow();
    const [renderAssetOrRisk, setRenderAssetOrRisk] = useState<boolean>(false); // if true, shows asset table, if false, shows risk table
    const [assetData, setAssetData] = useState<Asset[]>([]);
    const [riskData, setRiskData] = useState<Risk[]>([]);
    const [formattedAssetData, setFormattedAssetData] = useState<FormattedAsset[]>([]);
    const [formattedRiskData, setFormattedRiskData] = useState<FormattedRisk[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const [containers, setContainers] = useState<Container[]>([]);
    const [regions, setRegions] = useState<DefaultRegion[]>([]);
    const [teams, setTeams] = useState<Team[]>([]);
    const [markers, setMarkers] = useState<Marker[]>();
    const dispatch = useDispatch();
    const dispatchPromise = useDispatchPromise();
    const [gridData, setGridData] = useState([]);
    const [zoneData, setZoneData] = useState([]);
    const [ filteredData, setFilteredData ] = useState<Asset[]>([]);
    const [menuOpen, updateMenuOpen] = useState<boolean>(false);
    
    const toggleMenu = () => {
        updateMenuOpen(!menuOpen);
    }

    const onViewportChange = (viewport: Viewport) => {
        dispatch(mapActions.handleViewportChange(viewport));
    };

    const [
        filter, 
        colors, 
        reports, 
        {
            content, 
            showTooltip, 
            selectedMarker, 
            viewport, 
            allDevices, 
            showAssets,
            selectedAsset,
            showRisks,
            selectedRisk,
            addRisk,
            geography,
            changeGeo,
            drawMode,
            selectedScenario,
            filterScenarios
        },
        currentContainer,
        availableContainers,
        timeFilter,
        {
            day,
            timeWindow,
            showZones,
            focusedInput,
            fuzzyRouteEnabled,
            hasStartedForecasting,
            loadingRiskForecastsMessage, 
            riskIndexFilter,
            safeRouteData,
            destination,
            selectedRoute
        }
    ] = useSelectFromRedux((state) => [
        state.filter, 
        state.color, 
        state.publicInfo.reports,
        state.map,
        state.cuser.current_container,
        state.cuser.containers,
        state.timeFilter,
        state.visualizations
    ]);

    useEffect(() => {
        setMarkers(
            createMarkersFromReports(reports, filter)
        );
    }, [filter, reports, setMarkers]);

    const onClick = (selected: any, viewport?: any) => {
        if(!addRisk && !changeGeo) {
            if(selected.object && selected.object.asset_type) {
                if(selected.object.imaginary) {
                    dispatch(mapActions.setScenario(selected.object));
                    dispatch(mapActions.setRisk(undefined));
                    if(selectedAsset && !(selectedAsset.id === selected.object.scenario_asset_id)) {
                        dispatch(mapActions.setAsset(undefined));
                    }
                } else {
                    dispatch(mapActions.setRisk(undefined));
                    dispatch(mapActions.setAsset(selected.object));
                    if(selectedScenario && !(selectedScenario.scenario_asset_id === selected.object.id)) {
                        dispatch(mapActions.setScenario(undefined));
                    }
                }
            } else if (selected.object && selected.object.asset_ids) {
                dispatch(mapActions.setAsset(undefined));
                dispatch(mapActions.setRisk(selected.object));
                dispatch(mapActions.setScenario(undefined));
            } else if (selected.object) {
                if (!showTooltip) {
                    dispatch(mapActions.toggleTooltip());
                } else if (selected.object.properties && selected.object.properties.cluster && isEqual(selected.object, selectedMarker)) {
                    dispatch(mapActions.toggleTooltip());
                    dispatch(
                        mapActions.flyToPoint([
                            selected.object.geometry.coordinates[0],
                            selected.object.geometry.coordinates[1],
                            selected.expansionZoom * 1.2
                        ])
                    );
                }
                return dispatch(mapActions.renderTooltip(selected.object));
            }
        }
    };

    const _renderLayers: any = () => {
        switch (content) {
            case "menu":
                let result : any[] = renderMenuLayers({ data: markers, onClick, filter, colors, allDevices, showText, showAssets, assetData: filteredData, showAssetMarkers, riskData, showRisks, selectedAsset, selectedRisk, selectedScenario, addRisk, changeGeo});
                if(addRisk !== undefined || changeGeo) result.push(layer);
                if(newShapeLayer) result.push(newShapeLayer);
                return result;
            case "analytics":
                let result2 : any[] = renderAnalyticsLayers({ data: markers, onClick, filter, colors, timeFilter, allDevices, showText, showAssets, assetData: filteredData, showAssetMarkers, riskData, showRisks, selectedAsset, selectedRisk, selectedScenario, addRisk, changeGeo});
                if(addRisk !== undefined || changeGeo) result2.push(layer);
                return result2;
            case "visualizations":
                let result3 : any[]  = renderVisLayers({
                    data: {
                        gridData,
                        zoneData,
                        safeRouteData,
                        originData: { id: "origin", coordinates: origin },
                        destinationData: { id: "destination", coordinates: destination }
                    },
                    filter: { day, timeWindow, selectedRoute, riskIndexFilter },
                    visible: showZones,
                    onClick,
                    source: props.source,
                    allDevices,
                    showText,
                    showAssets,
                    assetData: filteredData,
                    showAssetMarkers,
                    riskData, showRisks,
                    selectedAsset,
                    selectedRisk,
                    selectedScenario,
                    addRisk,
                    changeGeo
                });
                if(addRisk !== undefined || changeGeo) result3.push(layer);
                return result3;
            default:
                let result4 : any[] = renderMenuLayers({ data: markers, onClick, filter, colors, allDevices, showText, showAssets, assetData: filteredData, showAssetMarkers, riskData, showRisks, selectedAsset, selectedRisk, selectedScenario, addRisk, changeGeo });
                if(addRisk !== undefined || changeGeo) result4.push(layer);
                return result4;
        }
    };

    useEffect(() => {
        const containerViewportInfo = getContainerViewportFromId(currentContainer, availableContainers);
        dispatch(
            mapActions.handleViewportChange({
                width: "100vw",
                height: "100vh",
                ...containerViewportInfo,
                bearing: 0,
                pitch: 20
            })
        );
    }, [availableContainers, currentContainer, dispatch])

    const setContainer = (container_id: number) => {
        dispatch(cuserActions.updateCurrentContainer(container_id));
        localStorage.setItem("containerID", "" + container_id);
        dispatchPromise(fetchPublicReportsFromContainer(container_id)).then(() => {
            const containerViewportInfo = getContainerViewportFromId(container_id, availableContainers);
            dispatch(
                mapActions.flyToPoint([
                    containerViewportInfo.longitude,
                    containerViewportInfo.latitude,
                    containerViewportInfo.zoom
                ])
            );
        });
        dispatch(
            filterActions.updateFilter({
                ...filter,
                selectedRegion: 0
            })
        );
    };

    const [features] = useState({
        type: "FeatureCollection",
        features: []
    });
    const [selectedFeatureIndexes] = useState([]);

    //@ts-ignore
    const layer = new EditableGeoJsonLayer({
        id: `edit-risk-geojson-layer`,
        //@ts-ignore
        data: features,
        mode: drawMode === 'line' ? DrawLineStringMode :
                    drawMode === 'polygon' ? DrawPolygonMode :  DrawPointMode,
        selectedFeatureIndexes,
        visible: addRisk !== undefined || changeGeo,
        pickable: addRisk !== undefined || changeGeo,
        onEdit: ({ updatedData } : any) => {
            if(updatedData.features[0]) dispatch(mapActions.setGeography(updatedData.features[0].geometry));
        }
    });

    const newShapeLayer = geography?.type === "Point" ? 
        new IconLayer({
            id: `new-risk-icon-layer`,
            data: [geography],
            //@ts-ignore
            getPosition: (x) => geography.coordinates as Position,
            pickable: true,
            iconAtlas: selectedAsset 
                ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/asset_icons/asset_geo.png" 
                :"https://orion-assets-s3.s3.us-east-2.amazonaws.com/asset_icons/risk_green.png",
            iconMapping: {
                endpoint: {
                    x: 0,
                    y: 0,
                    width: 150,
                    height: 150,
                    anchorY: 150,
                    mask: false
                }
            },
            onClick,
            mask: true,
            getIcon: () => "endpoint",
            sizeUnits: "meters",
            sizeMinPixels: 44,
            sizeScale: 1,
            visible: showRisks
        })
        : geography?.type === "Polygon" ?
        //@ts-ignore
        new PolygonLayer({
            id: `polygon-new-risk-layer`,
            data: [geography],
            pickable: true,
            stroked: true,
            filled: true,
            wireframe: true,
            onClick,
            lineWidthMinPixels: 1,
            getPolygon: (d: any) => geography.coordinates,
            getElevation: 30,
            getFillColor: [0, 150, 0, 100],
            getLineColor: [80, 80, 80],
            getLineWidth: 1,
            visible: showRisks
        }) : geography?.type === "LineString" ?
        new PathLayer({
            id: `new-asset-path-layer`,
            data: [geography],
            pickable: true,
            widthScale: 20,
            onClick,
            widthMinPixels: 2,
            //@ts-ignore
            getPath: (d : any) => geography.coordinates,
            //@ts-ignore
            getColor: [0, 150, 0],
            getWidth: (d : any) => 5,
            visible: showRisks
        }) : undefined;

    const [showText, setShowText] = useState<boolean>(false);
    const [showAssetMarkers, setShowAssetMarkers] = useState<boolean>(true);

    const menu = (
        <DropdownContent background={colors.scheme.modalBackground} color={colors.scheme.secondaryHeaderText}>
            Select your region. This will reload the map and reset your data
            {availableContainers
                .slice(0)
                .reverse()
                .map((availableContainer) => (
                    <StyledMenuItem
                        key={availableContainer.id}
                        background={colors.scheme.modalBackground}
                        hoverbackground={colors.scheme.generalCard}
                        selected={availableContainer.id === currentContainer}
                    >
                        <div
                            style={{ color: colors.scheme.primaryText }}
                            onClick={() => setContainer(availableContainer.id)}
                        >
                            {availableContainer.name}
                        </div>
                    </StyledMenuItem>
                ))}
        </DropdownContent>
    );

    const { showRiskForecastingDetails } = filter;

    const getContainerAbbreviation = () => {
        return availableContainers.filter((c: Container) => c.id === currentContainer)[0]?.abbreviation;
    };

    const mapOnClick = (e: any) => {
        if (focusedInput) {
            if (document.getElementById(focusedInput)) document.getElementById(focusedInput)!.blur();
            dispatch(
                visualizationsActions.updateCoordinates({
                    endpoint: focusedInput,
                    coordinates: e.coordinate,
                    clicked: true
                })
            );
        }

        if (showTooltip && e.layer === null) {
            dispatch(mapActions.toggleTooltip());
        }
    };

    const tableRef = useRef();

    
    const mapRef = useRef<any>(null);
    const geocoderContainerRef = useRef<any>(null);

    const Map = (
        <div style={{minHeight: "75vh", width: "100px"}}>
                <CurrentDisplayContainer>
                    <div
                        style={{
                            height: "60px",
                            width: "90px",
                            position: "absolute",
                            top: "0",
                            right: "0"
                        }}
                    >
                        <CurrentContainerButton onClick={toggleMenu}>{getContainerAbbreviation()}</CurrentContainerButton>
                    </div>
                    {menuOpen && menu}
                </CurrentDisplayContainer>
                <DeckGL
                    ContextProvider={MapContext.Provider}
                    layers={_renderLayers()}
                    initialViewState={viewport}
                    // controller contains onMouseDown function
                    controller={MyController as any}
                    onClick={mapOnClick}
                    width="100vw"
                    height="73vh"
                    getCursor={() => (fuzzyRouteEnabled || addRisk || changeGeo ? "crosshair" : "auto")}
                    onViewStateChange={(viewState: any) => {
                        if(viewState.viewState.zoom > 8) {
                            setShowText(true)
                        } else setShowText(false);
                        if(viewState.viewState.zoom > 13) {
                            setShowAssetMarkers(false);
                        } else setShowAssetMarkers(true);
                        if (
                            showRiskForecastingDetails === true &&
                            content === "visualizations" &&
                            hasStartedForecasting &&
                            loadingRiskForecastsMessage === null
                        )
                            dispatch(filterActions.toggleShowRiskForecastingDetails(false));
                    }}
                >
                    <StaticMap
                        reuseMaps
                        width={window.innerWidth}
                        height={window.innerHeight}
                        mapStyle={"mapbox://styles/nbowden/ckrl2x11p0w1t17qvh33d6u3y"}
                        //@ts-ignore
                        ref={mapRef}
                        preventStyleDiffing={true}
                        mapboxApiAccessToken={MapboxAPIKey}
                    />
                    <Geocoder
                        mapRef={mapRef}
                        onViewportChange={onViewportChange}
                        containerRef={geocoderContainerRef}
                        mapboxApiAccessToken={MapboxAPIKey}
                        placeholder="Search"
                        style={{
                            height: "20px",
                            borderRadius: "50px"
                        }}
                    />
                    <AddReportButton
                        onClick={() => {
                            dispatch(mapActions.setScenario(undefined));
                            dispatch(mapActions.setAsset(undefined));
                            dispatch(mapActions.setRisk(undefined));
                            dispatch(mapActions.setAddRisk({
                                name: "",
                                asset_ids: [],
                                relevant_event_types: [],
                                risk_interval: "",
                                severity: 0,
                                chance: 0,
                                updates: [],
                                concerns: [],
                                consequences: [],
                                is_obsolete: false,
                                suggestions: "",
                                comments: ""
                            }))
                        }}
                        background={colors.general.sosAlertButton}
                    >
                        <PlusImage src="https://orion-assets-s3.s3.us-east-2.amazonaws.com/plus-white.png" alt="Plus Icon" />
                    </AddReportButton>
                    <SearchbarContainer ref={geocoderContainerRef} />
                    <MapContext.Consumer>
                        {(outerContext: any) => (
                            <MapContext.Provider
                                value={{
                                    ...outerContext,
                                    map: mapRef.current ? mapRef.current.getMap() : null
                                }}
                            >
                            </MapContext.Provider>
                        )}
                    </MapContext.Consumer>
                    <Tooltip />
                </DeckGL>
                <VisApiComponent setGridData={setGridData} setZoneData={setZoneData} />
                <TimeComponent data={markers?.filter((marker: Marker) => marker.containerId === currentContainer)} />
            </div>
    );

    const [ tableData, setTableData ] = useState<FormattedAsset[]>();

    const getAssetData = useCallback(async () => {
        try {
            let fetchedAssetData:Asset[] = await getAssets();
            fetchedAssetData = fetchedAssetData.sort((a,b) => a.id as number - (b.id as number))
            setAssetData(fetchedAssetData);
            let ids : number[] = [];
            if(tableData) {
                for(let asset of tableData) {
                    ids.push(asset.index);
                }
                setFilteredData(fetchedAssetData.filter((asset : Asset) => ids.includes(asset.id as number) && (!filterScenarios || !asset.imaginary || asset.scenario_asset_id === selectedAsset?.id || asset.id === selectedScenario?.id)));
            } else setFilteredData(fetchedAssetData.filter((asset : Asset) => (!filterScenarios || !asset.imaginary || asset.scenario_asset_id === selectedAsset?.id || asset.id === selectedScenario?.id)));
        } catch (err) {
            error(err);
        }
    }, [error, filterScenarios, selectedAsset, tableData, selectedScenario]);

    const getRiskData = useCallback(async () => {
        try{
            let fetchedRiskData: Risk[] = await getAllRisks();
            fetchedRiskData = fetchedRiskData
                .filter((risk: Risk) => !risk.is_obsolete)
                .sort((a,b) => a.id as number - (b.id as number))
            setRiskData(fetchedRiskData);
        } catch (err) {
            error(err);
        }
    }, [error]);

    useEffect(() => {
        getRiskData();
        getAssetData();
    }, [error, getAssetData, getRiskData]);
    
    useEffect(() => {
        const getClients = async () => {
            try {
                const fetchedClients = await getAllClients();
                setClients(fetchedClients);
            } catch (err) {
                error(err);
            }
        };
        const getTeams = async () => {
            try {
                const fetchedTeams = await getAllTeams();
                setTeams(fetchedTeams);
            } catch (err) {
                error(err);
            }
        };
        const getContainers = async () => {
            try {
                const fetchedContainers = await getAllContainers();
                setContainers(fetchedContainers);
            } catch (err) {
                error(err);
            }
        }
        const getRegions = async () => {
            try {
                const fetchedRegions = await getAllDefaultRegions();
                setRegions(fetchedRegions);
            } catch (err) {
                error(err);
            }
        }
        getClients();
        getTeams();
        getContainers();
        getRegions();
    }, [error]);
    useEffect(() => {
        const formatData = () => {
            if (teams && clients && assetData) {
                let tempFormattedData:FormattedAsset[] = [];
                assetData.forEach((tempAsset:Asset) => {
                    let currentClient = clients.find(client => client.id === tempAsset.client_id);
                    let currentTeam = teams.find(team => team.id === tempAsset.team_id);
                    let currentConcerns = "";
                    let currentEventTypes = "";
                    if (tempAsset.concerns !== null) {
                        tempAsset.concerns?.forEach((concern) => currentConcerns = currentConcerns + concern + ", ");
                    }
                    if (tempAsset.relevant_event_types !== undefined) {
                        tempAsset.relevant_event_types.forEach((eventType) => currentEventTypes = currentEventTypes + eventType + ", ");
                    }
                    currentConcerns = currentConcerns.substring(0, currentConcerns.length - 2);
                    currentEventTypes = currentEventTypes.substring(0, currentEventTypes.length -2);
                    let tempFormattedAsset:FormattedAsset = {
                        "index": tempAsset.id as number,
                        "assetName": tempAsset.name,
                        "assetType": tempAsset.asset_type,
                        "client": currentClient === undefined ? "None" : currentClient.name,
                        "team": currentTeam === undefined ? "None" : currentTeam.name,
                        "description": tempAsset.description === undefined ? "None" : tempAsset.description,
                        "operationInterval": tempAsset.operation_interval === undefined ? "None" : tempAsset.operation_interval,
                        "concerns": tempAsset.concerns === undefined ? "None" : currentConcerns,
                        "eventTypes": tempAsset.relevant_event_types === undefined ? "None" : currentEventTypes,
                        "monetaryValue": tempAsset.value === undefined ? 0 : tempAsset.value,
                        "needsReview": tempAsset.needs_review === true ? "Needs Review" : "Reviewed",
                    }
                    if(!tempAsset.imaginary) tempFormattedData = [...tempFormattedData, tempFormattedAsset];
                })
                tempFormattedData.sort((a,b) => a.index - b.index)
                setFormattedAssetData(tempFormattedData);
                setColumns([
                    { title: 'Asset Name', field: "assetName", type: 'string', },
                    { title: 'Asset Type', field: "assetType", type: 'string', render: (props: FormattedAsset) => <AssetTypeEdit props={props} assetData={assetData} setAssetData={setAndFetchAssetData}/> },
                    { title: 'Client', field: "client", type: 'string', editable: 'never' },
                    { title: 'Team', field: "team", type: 'string', editable: 'never' },
                    { title: 'Description', field: "description", type: 'string', render: (props: FormattedAsset) => <AssetDescription props={props} assetData={assetData} setAssetData={setAndFetchAssetData}/> },
                    { title: 'Operation Interval', field: "operationInterval", type: 'string' },
                    { title: 'Concerns', field: "concerns", type: 'string', render: (props: FormattedAsset) => <Concern props={props}/> },
                    { title: 'Relevant Event Types', field: "eventTypes", type: 'string', render: (props: FormattedAsset) => <RelevantEventTypes props={props}/> },
                    { title: 'Monetary Value ($USD)', field: "monetaryValue", type: 'numeric' },
                    { title: 'Needs Review', field: "needsReview", type: 'string', lookup: { "Needs Review": 'Needs Review', "Reviewed": 'Reviewed' }, defaultGroupOrder: 0}
                  ])
            }
          }
          formatData();
          const formatRisks = () => {
              if(riskData) {
                  let tempFormattedRisks:FormattedRisk[] = [];
                  riskData.forEach((tempRisk:Risk) => {
                    let currentAssets:string = "";
                    let currentContainers:string = "";
                    let currentRegions:string = "";
                    let currentConcerns = "";
                    let currentEventTypes = "";
                    let currentConsequence = "";
                    if (tempRisk.asset_ids !== null) {
                        tempRisk.asset_ids.forEach(assetID => {
                            let thisAsset = assetData.find(asset => asset.id === assetID);
                            currentAssets = currentAssets + thisAsset?.name + ", ";
                        })
                    }
                    if (tempRisk.concerns !== null) {
                        tempRisk.concerns?.forEach((concern) => currentConcerns = currentConcerns + concern + ", ");
                    }
                    if (tempRisk.relevant_event_types !== undefined) {
                        tempRisk.relevant_event_types.forEach((eventType) => currentEventTypes = currentEventTypes + eventType + ", ");
                    }
                    if (tempRisk.consequences !== undefined) {
                        tempRisk.consequences.forEach((consequence) => currentConsequence = currentConsequence + consequence.concern + " (Magnitude: " + consequence.magnitude + ", Timeframe: " + consequence.timeframe + ", Probability: " + consequence.probability + "), ")
                    }
                    currentAssets = currentAssets.substring(0, currentAssets.length -2);
                    currentConcerns = currentConcerns.substring(0, currentConcerns.length - 2);
                    currentEventTypes = currentEventTypes.substring(0, currentEventTypes.length -2);
                    currentConsequence = currentConsequence.substring(0, currentConsequence.length -2);
                    tempRisk.container_ids?.forEach(containerID => {
                        let containerName:string = containers.find(container => container.id === containerID)?.name as string;
                        currentContainers = currentContainers + containerName + ", ";
                    })
                    tempRisk.default_region_ids?.forEach(regionID => {
                        let regionName:string = regions.find(region => region.id === regionID)?.name as string;
                        currentRegions = currentRegions + regionName + ", ";
                    })
                    currentContainers = currentContainers.substring(0, currentContainers.length -2);
                    currentRegions = currentRegions.substring(0, currentRegions.length -2);
                      let tempFormattedRisk:FormattedRisk = {
                        id: tempRisk.id as number,
                        name: tempRisk.name,
                        assets: currentAssets,
                        eventTypes: currentEventTypes,
                        riskInterval: tempRisk.risk_interval,
                        createdAt: tempRisk.created_at as Date,
                        severity: tempRisk.severity,
                        chance: tempRisk.chance,
                        concerns: currentConcerns,
                        consequences: currentConsequence,
                        containers: currentContainers,
                        regions: currentRegions,
                        suggestions: tempRisk.suggestions,
                        comments: tempRisk.comments,
                        needsReview: tempRisk.needs_review === true ? "Needs Review" : "Reviewed",
                      }
                      tempFormattedRisks = [...tempFormattedRisks, tempFormattedRisk];
                  })
                  tempFormattedRisks.sort((a,b) => a.id - b.id)
                  setFormattedRiskData(tempFormattedRisks);
                  setRiskColumns([
                    { title: 'Risk Name', field: "name", type: 'string', },
                    { title: 'Risk Assets', field: "assets", type: 'string', render: (props: FormattedRisk) => <RiskAssetView props={props}/>},
                    { title: 'Relevant Event Types', field: "eventTypes", type: 'string', render: (props: FormattedRisk) => <RiskEventTypesView props={props}/>},
                    { title: 'Risk Interval', field: "riskInterval", type: 'string', },
                    { title: 'Created At', field: "createdAt", type: 'datetime', editable: "never"},
                    { title: 'Severity', field: "severity", type: 'string', },
                    { title: 'Chance', field: "chance", type: 'string', },
                    { title: 'Concerns', field: "concerns", type: 'string', render: (props: FormattedRisk) => <RiskConcernsView props={props}/>},
                    { title: 'Consequences', field: "consequences", type: 'string', render: (props: FormattedRisk) => <RiskConsequenceView props={props}/>},
                    { title: 'Containers', field: "containers", type: 'string', editable: "never"},
                    { title: 'Regions', field: "regions", type: 'string', editable: "never"},
                    { title: 'Suggestions', field: "suggestions", type: 'string', render: (props: FormattedRisk) => <RiskSuggestions props={props} riskData={riskData} setRiskData={setAndFetchRiskData}/>},
                    { title: 'Comments', field: "comments", type: 'string', render: (props: FormattedRisk) => <RiskComments props={props} riskData={riskData} setRiskData={setAndFetchRiskData}/>},
                    { title: 'Needs Review', field: "needsReview", type: 'string', lookup: { "Needs Review": 'Needs Review', "Reviewed": 'Reviewed' }, defaultGroupOrder: 0},
                  ])
              }
          }
          formatRisks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[error, selectedRisk, teams, clients, containers, regions]);

    const setAndFetchAssetData = (assets : Asset[]) => {
        setAssetData(assets);
        getAssetData();
    }

    const setAndFetchRiskData = (risks : Risk[]) => {
        setRiskData(risks);
        getRiskData();
    }

    useEffect(() => {
        if(tableData) {
            let ids : number[] = [];
            for(let asset of tableData) {
                ids.push(asset.index);
            }
            setFilteredData(assetData.filter((asset : Asset) => ids.includes(asset.id as number) && (!filterScenarios || !asset.imaginary || asset.scenario_asset_id === selectedAsset?.id || asset.id === selectedScenario?.id)));
        }
    }, [assetData, filterScenarios, selectedAsset, tableData, selectedScenario]);

    const [columns, setColumns] = useState<Column<FormattedAsset>[]>([]);
    const [riskColumns, setRiskColumns] = useState<Column<FormattedRisk>[]>([]);

    const handleAssetToRiskChange = () => {
        setRenderAssetOrRisk(!renderAssetOrRisk);
    }

    const renderAssetTable = () => {
        if(assetData !== []) {
            return (<div style={{ maxWidth: '100%', alignItems: "center", display: "flex", flexDirection: "column", marginTop: "-20px" }}>
            <MaterialTable
              columns={columns}
              data={formattedAssetData}
              title="Assets"
              tableRef={tableRef}
              onFilterChange={() => setTableData((tableRef as any).current.dataManager.filteredData)}
              detailPanel={(asset) => {
                const thisAsset = assetData[formattedAssetData.indexOf(asset)];
                const scenarios:Asset[] = assetData.filter((currentAsset) => currentAsset.scenario_asset_id as number === thisAsset.id as number);
                let formattedScenarioData:FormattedAsset[] = [];
                scenarios.forEach(scenario => {
                    let currentClient = clients.find(client => client.id === scenario.client_id);
                    let currentTeam = teams.find(team => team.id === scenario.team_id);
                    let currentConcerns = "";
                    let currentEventTypes = "";
                    if (scenario.concerns !== null) {
                        scenario.concerns?.forEach((concern) => currentConcerns = currentConcerns + concern + ", ");
                    }
                    if (scenario.relevant_event_types !== undefined) {
                        scenario.relevant_event_types.forEach((eventType) => currentEventTypes = currentEventTypes + eventType + ", ");
                    }
                    currentConcerns = currentConcerns.substring(0, currentConcerns.length - 2);
                    currentEventTypes = currentEventTypes.substring(0, currentEventTypes.length -2);
                    let tempFormattedScenario:FormattedAsset = {
                        "index": scenario.id as number,
                        "assetName": scenario.name,
                        "assetType": scenario.asset_type,
                        "client": currentClient === undefined ? "None" : currentClient.name,
                        "team": currentTeam === undefined ? "None" : currentTeam.name,
                        "description": scenario.description === undefined ? "None" : scenario.description,
                        "operationInterval": scenario.operation_interval === undefined ? "None" : scenario.operation_interval,
                        "concerns": scenario.concerns === undefined ? "None" : currentConcerns,
                        "eventTypes": scenario.relevant_event_types === undefined ? "None" : currentEventTypes,
                        "monetaryValue": scenario.value === undefined ? 0 : scenario.value,
                        "needsReview": scenario.needs_review === true ? "Needs Review" : "Reviewed",
                    }
                    formattedScenarioData = [...formattedScenarioData, tempFormattedScenario];
                })
                const scenarioColumns:Column<FormattedAsset>[] = [
                    { title: 'Scenario Name', field: "assetName", type: 'string', },
                    { title: 'Scenario Type', field: "assetType", type: 'string', render: (props: FormattedAsset) => <AssetTypeEdit props={props} assetData={assetData} setAssetData={setAndFetchAssetData}/> },
                    { title: 'Client', field: "client", type: 'string', editable: 'never' },
                    { title: 'Team', field: "team", type: 'string', editable: 'never' },
                    { title: 'Description', field: "description", type: 'string', render: (props: FormattedAsset) => <AssetDescription props={props} assetData={assetData} setAssetData={setAndFetchAssetData}/> },
                    { title: 'Operation Interval', field: "operationInterval", type: 'string' },
                    { title: 'Concerns', field: "concerns", type: 'string', render: (props: FormattedAsset) => <Concern props={props}/> },
                    { title: 'Relevant Event Types', field: "eventTypes", type: 'string', render: (props: FormattedAsset) => <RelevantEventTypes props={props}/> },
                    { title: 'Monetary Value ($USD)', field: "monetaryValue", type: 'numeric' },
                  ];
                return(
                    <>
                    <MaterialTable
                    columns={scenarioColumns}
                    data={formattedScenarioData}
                    title="Scenarios"
                    options={{
                        search: false,
                        paging: true,
                        pageSize: 5,
                      }}
                    />
                    </>
                );
              }}
              actions={[(asset: FormattedAsset) : Action<FormattedAsset> => {
                const action: Action<FormattedAsset> = {
                    icon: "map",
                    onClick: (why: any) => {
                        const geography = JSON.parse(JSON.stringify(assetData.find((a) => a.id === asset.index)?.geography));
                        if(geography.type === "Point") {
                            let coordinates:[number, number] = geography.coordinates;

                            dispatch(mapActions.flyToPoint([...coordinates,17]));
                        } else if (geography.type === "Polygon") {
                            let points = 0;
                            let lat = 0;
                            let long = 0;
                            for(let coordinate of geography.coordinates[0]) {
                                lat += coordinate[0];
                                long += coordinate[1];
                                points++;
                            }
                            lat /= points;
                            long /= points;
                            dispatch(mapActions.flyToPoint([lat,long, 8]))
                        } else if (geography.type === "LineString") {
                            dispatch(mapActions.flyToPoint([...geography.coordinates[Math.floor(geography.coordinates.length/2)], 8]));
                        }
                    }
                };
                return action;
            }, (asset: FormattedAsset) : Action<FormattedAsset> => {
                const action: Action<FormattedAsset> = {
                    icon: "edit",
                    onClick: (why: any) => {
                        getAssetData();
                        const index = formattedAssetData.indexOf(asset);
                        const thisAsset = assetData[index];
                        dispatch(mapActions.setAsset(thisAsset));
                    }
                };
                return action;
            }
        ]}
              options={{
                paging: true,
                pageSize: 10,
                actionsColumnIndex: -1,
                exportButton: true,
                filtering: true,
                grouping: true,
              }}
            />
          </div>)
        }
    };
    const renderRiskTable = () => {
        if (riskData !== null) {
            return (<div style={{ maxWidth: '100%', alignItems: "center", display: "flex", flexDirection: "column", marginTop: "-20px" }}>
            <MaterialTable
              columns={riskColumns}
              data={formattedRiskData}
              title="Risks"
              tableRef={tableRef}
              actions={[(risk: FormattedRisk) : Action<FormattedRisk> => {
                const action: Action<FormattedRisk> = {
                    icon: "map",
                    onClick: (why: any) => {
                        const geography = JSON.parse(JSON.stringify(riskData.find((a) => a.id === risk.id)?.geography));
                        if(geography.type === "Point") {
                            let coordinates:[number, number] = geography.coordinates;

                            dispatch(mapActions.flyToPoint([...coordinates,17]));
                        } else if (geography.type === "Polygon") {
                            let points = 0;
                            let lat = 0;
                            let long = 0;
                            for(let coordinate of geography.coordinates[0]) {
                                lat += coordinate[0];
                                long += coordinate[1];
                                points++;
                            }
                            lat /= points;
                            long /= points;
                            dispatch(mapActions.flyToPoint([lat,long, 8]))
                        } else if (geography.type === "LineString") {
                            dispatch(mapActions.flyToPoint([...geography.coordinates[Math.floor(geography.coordinates.length/2)], 8]));
                        }
                    }
                };
                return action;
            }, 
            (risk: FormattedRisk) : Action<FormattedRisk> => {
                const action: Action<FormattedRisk> = {
                    icon: "edit",
                    onClick: (why: any) => {
                        getRiskData();
                        const index = formattedRiskData.indexOf(risk);
                        const thisRisk = riskData[index];
                        dispatch(mapActions.setRisk(thisRisk));
                    }
                };
                return action;
            }]}
              options={{
                paging: true,
                pageSize: 10,
                actionsColumnIndex: -1,
                exportButton: true,
                filtering: true,
                grouping: true,
              }}
            />
          </div>)
        }
    }

    return (
        <div style={{overflowY: "scroll"}}>
            {Map}
            {selectedAsset && <AssetView {...props} getAssetData={getAssetData}/>}
            {selectedRisk && <RiskView {...props} getRiskData={getRiskData}/>}
            {addRisk && <AddRiskView {...props} getRiskData={getRiskData}/>}
            {selectedScenario && <ScenarioView {...props} getAssetData={getAssetData}/>}
            <ScreenWideDiv style={{top:"75%", height: "200px", flex: 1, overflowX: "hidden", paddingLeft: "20px"}}>
                <Filter/>
            </ScreenWideDiv>
            <br></br>
            <div style={{paddingLeft:"25px"}}>
                <FormGroup>
                    <Typography component="div">
                        <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>Risk Table</Grid>
                        <Grid item>
                            <AntSwitch onChange={handleAssetToRiskChange} name="checkedC" />
                        </Grid>
                        <Grid item>Asset Table</Grid>
                        </Grid>
                    </Typography>
                </FormGroup>
            </div>
            <br></br>
            <br></br>
            {renderAssetOrRisk && renderAssetTable()}
            {!renderAssetOrRisk && renderRiskTable()}
        </div>
    );
};
