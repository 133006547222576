import React from "react";
import { ClusterTooltipContainer } from "./styled";
import { useSelectFromRedux } from "../../utils/_hooks";
import PieChart from "../layers/pie-chart-layer/PieChart";

export default function ClusterTooltip() {
    const [{ selectedMarker }] = useSelectFromRedux((state) => [state.map]);
    const colors = useSelectFromRedux((state) => state.color);
    return (
        <ClusterTooltipContainer color={colors.scheme.primaryText}>
            <PieChart markers={selectedMarker.properties.points} />
        </ClusterTooltipContainer>
    );
}
