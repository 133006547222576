import { default as React,
    useState,
    useRef,
    useEffect } from "react";
import { default as MaterialTable,
    MaterialTableProps,
    MTableEditField,
    EditComponentProps } from "material-table";
import { TextField } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { Client,
    UserProfile,
    DisplayClient,
    DisplayTeam,
    DisplayUserProfile,
    ClientDisplayData, 
    UserFeatureSelection} from "../types/client";
import { useThrow } from "../catch";
import adjustColor from "./adjustColor";
// import CategoryDropdown from "./categoryDropdown";
import { getClientDisplayData } from "../types/api/admin";
import moment from "moment";

type RowData = DisplayClient | DisplayTeam | DisplayUserProfile;

const ContainersContext = React.createContext<string[]>([]);
const TeamsContext = React.createContext<Record<number, Record<number | "null", string>>>({});

export default () => {
const error = useThrow();
const theme = useTheme();
// const [{ token }] = useCookies(["token"]);

const [ data, setData ] = useState<RowData[] | undefined>(undefined);
const [ teams, setTeams ] = useState<Record<number, Record<number | "null", string>>>({});
const [ containers, setContainers ] = useState<string[]>([]);
const [ totalUsers, setTotalUsers ] = useState<number>(0);

// UseEffect() hook to query Jarvis API
useEffect(() => {(async () => {
   // send request for client display datas
   try {
       const response: ClientDisplayData = await getClientDisplayData();
       // combine client, team, and user rows into "data" variable
       setData(
         [
           ...response.clientData,
           ...response.teamData,
           ...response.userData
         ]
       );
       // set team & container context
       setTeams(response.teamContext);
       setContainers(response.containerContext);
       
       let activeUsers = 0;
       for (let user = 0; user < response.userData.length; user++){
           if (response.userData[user].activity > 0) {
               activeUsers++;
           }
       }
       setTotalUsers(activeUsers);
   } catch (err) {
       error(err);
   }
})();}, [error]);

    const tableRef = useRef<any>();

    const props: MaterialTableProps<RowData> = {
        title: "Clients",
        tableRef,
        columns: [
            {
                title: "ID",
                field: "id",
                type: "numeric",
                editable: "never"
            },
            {
                title: "Name",
                field: "name",
                render: (rowData: RowData) => {
                    switch (rowData.type) {
                    case "UserProfile":
                        return rowData.first_name + " " + rowData.last_name;
                    case "Team":
                        if (rowData.id === null) {
                            return <span style={{ fontWeight: 300 }}>{rowData.name}</span>;
                        }
                        // fallthrough if not null:
                    default:
                        return rowData.name;
                    }
                },
                customFilterAndSearch: (filter: string, rowData: RowData) =>
                    filter ?
                    ((rowData.type === "UserProfile")
                     ? (rowData.first_name + rowData.last_name)
                     : rowData.name)
                    .replace(/\s+/g, "")
                    .toUpperCase()
                    .includes(filter.replace(/\s+/g, "")
                              .toUpperCase()) : false,
                editComponent: ({ value, onChange, rowData, onRowDataChange, columnDef }: EditComponentProps<RowData>) => {
                    switch (rowData.type) {
                    case "UserProfile":
                        return (
                            <div style={{
                                     display: "flex",
                                     marginBottom: -10,
                                     marginTop: 10
                                 }}>
                              <TextField value={rowData.first_name}
                                         helperText="First&nbsp;name"
                                         style={{ marginRight: "1em" }}
                                         onChange={(event) => onRowDataChange({ ...rowData, first_name: event.target.value })} />
                              <TextField value={rowData.last_name}
                                         helperText="Last&nbsp;name"
                                         onChange={(event) => onRowDataChange({ ...rowData, last_name: event.target.value })} />
                            </div>
                        )
                    default:
                        return (<MTableEditField value={value} columnDef={columnDef} onChange={onChange} />);
                    }
                }
                // Combo of first and last for user
            },
            {
                title: "Email",
                field: "email",
                render: (rowData: RowData) => {
                    switch (rowData.type) {
                    case "UserProfile":
                        return (rowData as UserProfile).email;
                    case "Client":
                        return (rowData as Client).primary_email;
                    default:
                        return null;
                    }
                },
                editComponent: ({ rowData, onRowDataChange, columnDef }: EditComponentProps<RowData>) => {
                    let field: string | undefined = {
                        UserProfile: "email",
                        Client: "primary_email",
                        Team: undefined
                    }[rowData.type];

               if (!field) {
                   return null;
               }

                    const onChange = (newValue: string) => {
                        const newData: any = { ...rowData };
                        newData[field!] = newValue;
                        onRowDataChange(newData);
                    }
                    return (<MTableEditField value={(rowData as any)[field]} columnDef={columnDef} onChange={onChange} />);
                }
            },
            {
                title: "Created",
                field: "created_at",
                type: "datetime",
                defaultSort: "asc",
                editable: "never",
                render: (rowData: RowData) => {
                    switch (rowData.type) {
                        case "UserProfile":
                            return moment((rowData as UserProfile).created_at).format("YYYY-MM-DD");
                        case "Client":
                            return moment((rowData as Client).created_at).format("YYYY-MM-DD");
                        default:
                            return null;
                    }
                }
            },
            {
                title: "Last Active",
                field: "last_active",
                type: "datetime",
                defaultSort: "asc",
                editable: "never",
                render: (rowData: RowData) => {
                    switch (rowData.type) {
                        case "UserProfile":
                            return moment((rowData as DisplayUserProfile).last_active).format("YYYY-MM-DD hh:mm A");
                        default:
                            return null;
                    }
                }
            },
            {
                title: "Activity (" + totalUsers + ")",
                field: "activity",
                type: "numeric",
                editable: "never",
                cellStyle: {
                    minWidth: 150,
                    whiteSpace: "nowrap"
                },
                render: (rowData: RowData) => {
                    switch (rowData.type) {
                    case "Client":
                        return (rowData.activity ?? 0) + "\xa0active\xa0user" + (rowData.activity === 1 ? "" : "s");
                    case "UserProfile":
                        return (rowData.activity ?? 0) + "\xa0logins";
                    default:
                        return null;
                    }
                },
                customSort: (data1: any, data2: any) => (data1?.activity ?? 0) - (data2?.activity ?? 0)
            },
            {
                title: "Licenses:",
                field: "licenses",
                type: "numeric",
                render: (rowData: RowData) => {
                    switch (rowData.type) {
                    case "Client":
                        return rowData.licenses;
                    case "UserProfile":
                        return (rowData.role === 0) ? "Staff" : ((rowData.role === 1) ? "Admin" : null);
                    default:
                        return null;
                    }
                },
                editComponent: ({ rowData, onRowDataChange, columnDef }: EditComponentProps<RowData>) => {
                    let field: string | undefined = {
                        Client: "licenses",
                        UserProfile: "role",
                        Team: undefined
                    }[rowData.type];

               if (!field) {
                   return null;
               }

               const onChange = (newValue: string) => {
                   const newData: any = { ...rowData };
                   newData[field!] = newValue;
                   onRowDataChange(newData);
               }
               return (
                   <div style={rowData.type === "UserProfile" ? {
                            marginBottom: -10,
                            marginTop: 10
                        } : undefined}>
                     <MTableEditField value={(rowData as any)[field]}
                                      columnDef={{
                                          ...columnDef,
                                          editPlaceholder: rowData.type === "UserProfile" ? "Role" : "Licenses"
                                      }}
                                      helperText={rowData.type === "UserProfile" ? "Role" : undefined}
                                      onChange={onChange} />
                   </div>
               );
           }
       },
       {
           title: "Incidents",
           field: "incidents",
           type: "numeric",
           editable: "never"
       },
       {
           title: "Alerts",
           field: "alerts",
           type: "numeric",
           editable: "never"
       },
       {
           title: "Full\xa0Reports",
           field: "full_reports",
           type: "numeric",
           editable: "never"
       },
       {
           title: "Disabled\xa0Features",
           field: "disabled_features",
           editable: "never",
           render: (rowData: RowData) => {
               switch (rowData.type) {
               case "UserProfile":
                   let featureArr = [];
                   
                   var i: keyof UserFeatureSelection;
                   for (i in rowData.features){
                       if (rowData.features![i] === false){
                           featureArr.push(i);
                       }
                   }

                   let featureStr = featureArr.join(", ");
                   return featureStr;
               default:
                   return null;
               }
           }
       }
   ],
   data: data ?? [],
   isLoading: !data,
   style: {
       borderRadius: 0
   },
   options: {
       paging: false,
       thirdSortClick: false,
       draggable: false,
       toolbarButtonAlignment: "left",
       rowStyle: (_data, _index, level: number) => ({
           backgroundColor: adjustColor(theme.palette.background.paper, -10 * level)
       }),
       tableLayout: "auto"
   },
   parentChildData: (rowData: RowData, data: RowData[]) => {
       switch (rowData.type) {
       case "Team":
           return data.find((candidate: RowData) =>
                            candidate.type === "Client"
                            && candidate.id === rowData.client_id);
       case "UserProfile":
           return data.find((candidate: RowData) =>
                            candidate.type === "Team"
                            && candidate.client_id === rowData.client_id
                            && candidate.id === rowData.team_id);
       }
   }
} as MaterialTableProps<RowData>;

return (
   <ContainersContext.Provider value={containers}>
     <TeamsContext.Provider value={teams}>
       <MaterialTable {...props} />
     </TeamsContext.Provider>
   </ContainersContext.Provider>
);
};