import { default as React,
    useState } from "react";
import styled from "styled-components";
import { Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button, 
    Icon,
    TextField} from "@material-ui/core";
import { RowData } from ".";
import { PendingAampReportUpdate } from "../types/aamp";

const Container = styled.div`
max-height: 100px;
overflow: hidden;
position: relative;
`;

const SeeMore = styled.a`
position: absolute;
right: 0;
bottom: 0;
color: gray;
text-decoration: none;
padding-left: 2em;
background-image: linear-gradient(to right, #fff0 0, white 2em);

&:hover {
   text-decoration: underline;
}
`;

const Fullanalyst_notes = styled(DialogContentText)`
white-space: pre-line;
color: white;
`;

export default ({props, updateReport} : { props: RowData, updateReport: (row: number, update: Omit<PendingAampReportUpdate, "id" | "changes">) => Promise<void>}) => {
    const [ expanded, setExpanded ] = useState<boolean>(false);
    const [ editing, setEditing ] = useState<boolean>(false);
    const [ anlaystNotesText, setAnalystNotesText ] = useState<string>(props.analyst_notes || "");

    const expand = (event: React.SyntheticEvent) => {
        event.preventDefault();
        setExpanded(true);
    };

    const toggleEditing = () => {
        setEditing(!editing);
    }

    const onClose = () => setExpanded(false);

    const update = () => {
        updateReport(props.row, {
        address: props.address,
        date_time: props.date_time,
        description: props.description,
        analyst_notes: anlaystNotesText,
        fields: {
            aamp_report_type: props.aamp_report_type,
            victim: props.victim,
            aggressor: props.aggressor
            }
        })
        setEditing(false);
    }

    const expansion: React.ReactNode = (
    <>
        <SeeMore onClick={expand}>View/Edit</SeeMore>
        <Dialog open={expanded}
                onClose={onClose}>
        <DialogTitle>
            Analyst Notes
            <Icon onClick={toggleEditing} style={{marginLeft: 20, paddingTop: 5, height: 25, cursor: "pointer"}}>edit</Icon>
        </DialogTitle>
        <DialogContent>
            {editing &&
            <TextField 
                style={{width: 500}} 
                multiline={true} 
                defaultValue={props.analyst_notes}
                onChange={(event) => setAnalystNotesText(event.target.value)}
            />
            }
            {!editing &&
            <Fullanalyst_notes>
                {props.analyst_notes}
            </Fullanalyst_notes>
            }
        </DialogContent>
        <DialogActions>
            {editing &&
            <Button onClick={update} color="primary" autoFocus>Update</Button>
            }
            <Button onClick={onClose} color="primary" autoFocus>Close</Button>
        </DialogActions>
        </Dialog>
    </>
    );

    return (
    <Container>
        {props.analyst_notes || <i>None entered yet</i>}
        {expansion}
    </Container>
    );
};
