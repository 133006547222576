import React, { useEffect, useState } from "react";
import { Button, FormControl, InputLabel, Select, Input, MenuItem, ListItemText, Checkbox, FormLabel, TextField, Box, IconButton } from "@material-ui/core";
import { DataGrid, ColDef, ValueGetterParams, CellParams } from "@material-ui/data-grid";
import { Form, FormContainer, Section, ToggleBarContainer,  } from "./styled";
import { getAllDefaultRegions, getSummaryData } from "../types/api/admin";
import { getContainers, getAllContainers } from "../types/api/admin";
import { useThrow } from "../catch";
import { ClientDisplayData, DisplayClient, UserFeatureSelection } from "../types/client";
import { Autocomplete, Alert } from "@material-ui/lab";
import { render } from "@testing-library/react";
import {makeStyles} from '@material-ui/styles';
import Link from "@material-ui/core/Link";
import { Container } from "../types/geo"
import { ConsoleView } from "react-device-detect";
import { getPublicReports, getAllPublicReports } from "../types/api/admin";
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  LineSeries,
} from '@devexpress/dx-react-chart-material-ui';
import { stringify } from "querystring";



const styles = makeStyles((theme) => ({
  root: {
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    color: 'black',
    backgroundColor: 'grey'
  },
}));



export const DisplayOutliers = () => {

  const error = useThrow();

  const classes = styles()


  // Initial States
  const [initialrows, setinitialrows] = useState<any[]>([]);
  const [initialcountrynamelist, setinitialcountrynamelist] = useState<string[]>([]);
  const [initialregionnamelist, setinitialregionnamelist] = useState<string[]>([]);

  // Set Table Rows
  const [tablerows, settablerows] = useState<any[]>([]);

  // *** NEW method of getting container and region names
  const [containers, setcontainers] = useState<any[]>([]);
  const [defaultregions, setdefaultregions] = useState<any[]>([]);

  // User Input - Custom Timeframe
  const [customtimeframe, setcustomtimeframe] = useState<number>();

  // Query and Filter Containers
  const [containerNames, updateContainerNames] = useState<string[]>([]);
  const [countrynamelist, setcountrynamelist] = useState<string[]>([]);
  const [filteredNames, setFilteredNames] = useState<string[]>([]);

  // Query and Filter Regions
  const [regionNames, updateRegionNames] = useState<string[]>([]);
  const [regionnamelist, setregionnamelist] = useState<string[]>([]);
  const [filteredRegionNames, setFilteredRegionNames] = useState<string[]>([]);

  // Query Date
  const [querydate, setquerydate] = useState<string>(new Date().toISOString().slice(0, 10));

  // Reset Filter
  const [resetFilterFlag, setResetFilterFlag] = useState<number>(0);

  // Filter TOD
  const timeblocks = ['Morning', 'Afternoon', 'Evening/Night']
  const [filteredTOD, setFilteredTOD] = useState<string[]>([]);

  // Filter Category
  const categories = ['Actor', 'Target', 'Report Type']
  const [filteredCategories, setFilteredCategories] = useState<string[]>([]);

  // Public Reports List, Graph Data, + Outlier Expansion
  const [pubreprows, setpubreprows] = useState<any[]>([])
  const [dailyoccurrencedata, setdailyoccurrencedata] = useState<any[]>([])
  const [selectedoutlierinfo, setselectedoutlierinfo] = useState<string>("NO OUTLIER SELECTED")
  const [selectedoutlierinfo2, setselectedoutlierinfo2] = useState<string>("NO OUTLIER SELECTED")

  // Manual Date Query
  const [datetextfieldinput, setdatetextfieldinput] = useState<string>()

  // Loading State
  const [loadingstate, setloadingstate] = useState<string>()

  // Time Scale Toggle
  const [selectedrowdata, setselectedrowdata] = useState<any[]>([])
  const [selectedtimeframe, setselectedtimeframe] = useState<number>(7)


  useEffect(() => {

    const fetchoutliers = async () => {

      const result = await getSummaryData(querydate, "outliers");
      setinitialrows(result);
      settablerows(result);


      // Query Containers
      const containerresponse: any[] = await getAllContainers();

      ///
      setcontainers(containerresponse)
      ///

      const containerresponseNamesArr: string[] = [];
      const containerresponseDetailsArr: any[] = [];

      containerresponse.forEach((fetchedContainer) => {
        containerresponseDetailsArr.push(fetchedContainer);
        containerresponseNamesArr.push(fetchedContainer.name);
      });

      const relevantContainersArr: any[] = [];
      const relevantContainerNames: string[] = [];
      const query_countrynamelist: string[] = [];

      for (let i = 0; i < result.length; i++){
        for (let j = 0; j < containerresponseDetailsArr.length; j++){
          if (result[i].container_id == JSON.stringify(containerresponseDetailsArr[j].id)){
            query_countrynamelist.push(containerresponseDetailsArr[j].name)
            if (relevantContainersArr.includes({'Name': containerresponseDetailsArr[j].name, 'id': containerresponseDetailsArr[j].id}) === false) relevantContainersArr.push({'Name': containerresponseDetailsArr[j].name, 'id': containerresponseDetailsArr[j].id});
            if (relevantContainerNames.includes(containerresponseDetailsArr[j].name) === false) relevantContainerNames.push(containerresponseDetailsArr[j].name)

          }
        }
      }


      // Query Regions
      const regionresponse: any[] = await getAllDefaultRegions();

      ///
      setdefaultregions(regionresponse)
      ///

      const regionresponseNamesArr: string[] = [];
      const regionresponseDetailsArr: any[] = [];

      regionresponse.forEach((fetchedRegion) => {
        regionresponseDetailsArr.push(fetchedRegion);
        regionresponseNamesArr.push(fetchedRegion.name);
      });

      const relevantRegionsArr: any[] = [];
      const relevantRegionNames: string[] = [];
      const query_regionnamelist: string[] = [];

      for (let i = 0; i < result.length; i++){
        for (let j = 0; j < regionresponseDetailsArr.length; j++){
          if (result[i].default_region_id == JSON.stringify(regionresponseDetailsArr[j].id)){
            query_regionnamelist.push(regionresponseDetailsArr[j].name)
            if (relevantRegionsArr.includes({'Name': regionresponseDetailsArr[j].name, 'id': regionresponseDetailsArr[j].id}) === false) relevantRegionsArr.push({'Name': regionresponseDetailsArr[j].name, 'id': regionresponseDetailsArr[j].id});
            if (relevantRegionNames.includes(regionresponseDetailsArr[j].name) === false) relevantRegionNames.push(regionresponseDetailsArr[j].name)

          }
        }
      }

      setcountrynamelist(query_countrynamelist)
      setinitialcountrynamelist(query_countrynamelist)
      updateContainerNames(relevantContainerNames)
      setFilteredNames(relevantContainerNames)
      setFilteredCategories(categories)
      setregionnamelist(query_regionnamelist)
      setinitialregionnamelist(query_regionnamelist)
      updateRegionNames(relevantRegionNames)
      setFilteredRegionNames(relevantRegionNames)
      setFilteredTOD(timeblocks)

    };
    fetchoutliers();



  }, [querydate, resetFilterFlag]);


  function getContainerName(params: ValueGetterParams) {
    const rowdata = params.data

    const correctcontainer = containers.filter((row) => row.id == rowdata.container_id)

    //console.log(JSON.stringify(containerinfo))

    if (typeof correctcontainer[0] !== 'undefined') return correctcontainer[0].name
    else return ''

  }

  function getRegionName(params: ValueGetterParams) {
    const rowdata = params.data

    const correctregion = defaultregions.filter((row) => row.id == rowdata.default_region_id)

    //console.log(JSON.stringify(containerinfo))

    if (typeof correctregion[0] !== 'undefined') return correctregion[0].name
    else return ''

  }


  function getSeverityCategory(params: ValueGetterParams) {

    const rowdata = params.data
    const sdnum = rowdata.sdnum

    if (sdnum >= 2 && sdnum < 2.5) return "Bad"
    if (sdnum >= 2.5 && sdnum < 3) return "Very Bad"
    if (sdnum >= 3 && sdnum < 4) return "Distressing"
    if (sdnum >= 4 && sdnum < 6) return "Disasterous"
    if (sdnum >= 6) return "Catastrophic"

  }

  const columns: ColDef[] = [
    { field: 'timeframe', headerName: 'Timeframe', width: 150 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'week', headerName: 'Week', width: 120 },
    { field: 'month', headerName: 'Month', width: 90 },
    { field: 'CountryName', width: 150, valueGetter: getContainerName },
    { field: 'RegionName', width: 150, valueGetter: getRegionName },
    { field: 'tod', headerName: 'Time of Day', width: 150 },
    { field: 'category', headerName: 'Category', width: 150 },
    { field: 'category_value', headerName: 'Outlier Value', width: 150 },
    //{ field: 'count', headerName: 'count', width: 150 },
    { field: 'Severity', width: 150, valueGetter: getSeverityCategory },
    { field: 'sdnum', headerName: '# of SD Deviation', width: 150 },
    { field: 'EXPAND', renderCell: (cellValues) => {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => expandoutlier(cellValues)}
        >
          SELECT
        </Button>
      );
    } }

  ];



  function filterrows_bycontainer(event: string[]) {

    const newrows: any[] = []
    const newcountrynamelist: string[] = []
    const newregionnamelist: string[] = []
    const newuniqueregionnamelist: string[] = []

    initialrows.forEach((row, index) => {
      const rowcountryname: string = initialcountrynamelist[index]
      const rowregionname: string = initialregionnamelist[index]
      if (event.includes(rowcountryname) && filteredRegionNames.includes(rowregionname) && filteredTOD.includes(row.tod) && filteredCategories.includes(row.category)) {
        newrows.push(row)
        newcountrynamelist.push(rowcountryname)
        newregionnamelist.push(rowregionname)
        if (newuniqueregionnamelist.includes(rowregionname) === false) newuniqueregionnamelist.push(rowregionname)
    }});

    setcountrynamelist(newcountrynamelist);
    settablerows(newrows);
    setFilteredNames(event as string[]);

    setregionnamelist(newregionnamelist);
    setFilteredRegionNames(newuniqueregionnamelist)

  }

  function filterrows_byregion(event: string[]) {

    const newrows: any[] = []
    const newregionnamelist: string[] = []
    const newcountrynamelist: string[] = []

    initialrows.forEach((row, index) => {
      const rowregionname: string = initialregionnamelist[index]
      const rowcountryname: string = initialcountrynamelist[index]
      if (event.includes(rowregionname) && filteredNames.includes(rowcountryname) && filteredTOD.includes(row.tod) && filteredCategories.includes(row.category)) {
        newrows.push(row)
        newregionnamelist.push(rowregionname)
        newcountrynamelist.push(rowcountryname)

    }});

    setregionnamelist(newregionnamelist);
    settablerows(newrows);
    setFilteredRegionNames(event as string[]);

    setcountrynamelist(newcountrynamelist)

  }


  function filterrows_byTOD(event: string[]) {

    const newrows: any[] = []
    const newregionnamelist: string[] = []
    const newcountrynamelist: string[] = []

    initialrows.forEach((row, index) => {

      const rowTOD: string = row.tod
      const rowCategory: string = row.category
      const rowregionname: string = initialregionnamelist[index]
      const rowcountryname: string = initialcountrynamelist[index]

      if (event.includes(rowTOD) && filteredNames.includes(rowcountryname) && filteredRegionNames.includes(rowregionname) && filteredCategories.includes(rowCategory)) {
        newrows.push(row)
        newregionnamelist.push(rowregionname)
        newcountrynamelist.push(rowcountryname)
      }

    });

    setFilteredTOD(event as string[])
    settablerows(newrows)

    setregionnamelist(newregionnamelist);
    setcountrynamelist(newcountrynamelist)

  }


  function filterrows_byCategories(event: string[]) {

    const newrows: any[] = []
    const newregionnamelist: string[] = []
    const newcountrynamelist: string[] = []

    initialrows.forEach((row, index) => {

      const rowCategory: string = row.category
      const rowTOD: string = row.tod
      const rowregionname: string = initialregionnamelist[index]
      const rowcountryname: string = initialcountrynamelist[index]

      if (event.includes(rowCategory) && filteredTOD.includes(rowTOD) && filteredNames.includes(rowcountryname) && filteredRegionNames.includes(rowregionname)) {
        newrows.push(row)
        newregionnamelist.push(rowregionname)
        newcountrynamelist.push(rowcountryname)
      }

    });

    setFilteredCategories(event as string[])
    settablerows(newrows)

    setregionnamelist(newregionnamelist);
    setcountrynamelist(newcountrynamelist)

  }

  function WithoutTime(dateTime: Date) {
    var date = new Date(dateTime.getTime());
    date.setHours(0, 0, 0, 0);
    return date;

}

  function querydatefunc(direction: String) {

    const olddate = WithoutTime(new Date(querydate))
    if (direction === "back") {
      const newdate = WithoutTime(new Date(olddate.setDate(olddate.getDate())))
      const finaldate = newdate.toISOString().split('T')[0]
      setquerydate(finaldate.toString())
    }
    if (direction === "forward") {
      const newdate = WithoutTime(new Date(olddate.setDate(olddate.getDate()+2)))
      const finaldate = newdate.toISOString().split('T')[0]
      setquerydate(finaldate.toString())
    }

  }


  function resetfilter() {
    setResetFilterFlag(resetFilterFlag+1)
  }


  function getCategoryColumn(outliercol: string) {
    if (outliercol === "Actor") return "actor"
    if (outliercol === "Target") return "target"
    if (outliercol === "Report Type") return "report_type"
  }

  function adjustreportsbyTOD(rows: any[], selectedTOD: string | undefined) {

    function adjustdatetime_timezone(datetime: Date, container_id: number) {

      let new_datetime = new Date();

      if (container_id == 1) new_datetime = new Date(datetime.setHours(datetime.getUTCHours() - 5 + 1)); // -5 is a TEMP FIX for knex reading in UTC time as local time
      if (container_id == 2) new_datetime = new Date(datetime.setHours(datetime.getUTCHours() - 5 + 3));
      if (container_id == 3) new_datetime = new Date(datetime.setHours(datetime.getUTCHours() - 5 + 2));
      else new_datetime = datetime

      return new_datetime
    }

    let filteredrows = []

    if (selectedTOD === "Morning") filteredrows = rows.filter((row: any) => (adjustdatetime_timezone(new Date(row.date_time), row.container_id).getHours() <= 12) && (adjustdatetime_timezone(new Date(row.date_time), row.container_id).getHours() > 4))
    if (selectedTOD === "Afternoon") filteredrows = rows.filter((row: any) => (adjustdatetime_timezone(new Date(row.date_time), row.container_id).getHours() <= 18) && (adjustdatetime_timezone(new Date(row.date_time), row.container_id).getHours() > 12))
    if (selectedTOD === "Evening/Night") filteredrows = rows.filter((row: any) => (adjustdatetime_timezone(new Date(row.date_time), row.container_id).getHours() <= 4) || (adjustdatetime_timezone(new Date(row.date_time), row.container_id).getHours() > 18))
    if (typeof selectedTOD === 'undefined' || selectedTOD===null) filteredrows = rows

    return filteredrows
  }

  async function expandoutlier(cellvals: any) {

    setdailyoccurrencedata([])
    setpubreprows([])

    const celldata = cellvals.data
    setselectedrowdata(celldata)

    const country = getContainerName(cellvals)
    const region = getRegionName(cellvals)
    const category = celldata.category
    const categoryval = celldata.category_value
    const tod = celldata.tod
    const weekday = celldata.weekday

    let outliersentence = `${country} ${category} Outliers for ${categoryval}`
    if (region !== null && region !== '') outliersentence = outliersentence + ` in ${region} `
    if (tod !== null) outliersentence = outliersentence + ` during the ${tod} `
    const outliersentence2 = outliersentence
    if (weekday !== null) outliersentence = outliersentence + ` on ${weekday}s `

    setselectedoutlierinfo(outliersentence)
    setselectedoutlierinfo2(outliersentence2)

    const result = await getPublicReports(celldata.date, celldata.week, celldata.month, celldata.container_id, celldata.default_region_id, getCategoryColumn(celldata.category)!, celldata.category_value)
    setpubreprows(adjustreportsbyTOD(result, tod))

    parsedatafordailyoccurrence(celldata, 7)
  }


  function manualdateinput(datestring: any) {
    if (typeof datestring === 'string') {
      setquerydate(datestring)
    }
  }


  async function parsedatafordailyoccurrence(cellvals: any, timescaleint: number | undefined) {

    if (typeof timescaleint !== 'undefined' && selectedoutlierinfo !== "NO OUTLIER SELECTED"){
    setloadingstate("LOADING....")
    setselectedtimeframe(timescaleint)
    let occurrencedata = []
    const startingdate = WithoutTime(new Date(cellvals.date))
    const adjustedstartingdate = WithoutTime(new Date(startingdate.setDate(startingdate.getDate()+2)))
    for (let t = 0; t < timescaleint; t++){
      const newdate = WithoutTime(new Date(adjustedstartingdate.setDate(adjustedstartingdate.getDate()-1)))
      const finaldate = newdate.toISOString().split('T')[0]
      const reps = await getPublicReports(finaldate, cellvals.week, cellvals.month, cellvals.container_id, cellvals.default_region_id, getCategoryColumn(cellvals.category)!, cellvals.category_value)
      const lengthnum = adjustreportsbyTOD(reps, cellvals.tod).length
      occurrencedata.push({"date": finaldate, "daily_occurrence": lengthnum})
    }

    setloadingstate('')
    setdailyoccurrencedata(tickspacing(occurrencedata.reverse(), timescaleint))

  }
  }


  function tickspacing(inputdata: any[], timeframe: number) { 
    let spacingint = timeframe / 10
    if (timeframe < 15) spacingint = 1
    let emptystr = ''

    inputdata.forEach((row, index) => {
    if (index % spacingint != 0) row.date = emptystr += ' '
    return row
    })

    return inputdata
  }



  const pubrepcolumns: ColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'container_id', headerName: 'Container ID', width: 100 },
    { field: 'default_region_id', headerName: 'Region ID', width: 100 },
    { field: 'date_time', headerName: 'Date and Time', width: 150 },
    { field: 'report_type', headerName: 'Report Type', width: 150 },
    { field: 'actor', headerName: 'Actor', width: 150 },
    { field: 'target', headerName: 'Target', width: 150 },
    { field: 'description', headerName: 'Description', width: 1000 },

  ];




  return (
    <Section>
      <ToggleBarContainer>
          <Button size="large" href={"/news"}>
            NEWS
          </Button>
          <Button size="large" href={"/fusionCenter"}>
            HOME
          </Button>
          <Button color="primary" size="large" href={"/outliers"}>
            OUTLIERS
          </Button>
        </ToggleBarContainer>
        <ToggleBarContainer>
          <Button size="small" onClick={() => querydatefunc("back")} >
           BACK
          </Button>
          <Button color="secondary" size="medium">
          {querydate}
          </Button>
          <Button size="small" onClick={() => querydatefunc("forward")}>
            FORWARD
          </Button>
        </ToggleBarContainer>
        <ToggleBarContainer>
          <TextField size="small" label="Date Search" margin="dense" helperText="Use YYYY-MM-DD Format" onChange={(e) => setdatetextfieldinput(e.target.value)} InputProps={{endAdornment: <Button onClick={(e) => manualdateinput(datetextfieldinput)} > Enter </Button>}} >
          </TextField>
        </ToggleBarContainer>
        <div style={{height: 8}} />
        <ToggleBarContainer>
          {selectedoutlierinfo}
      </ToggleBarContainer>
        <div style={{height: 50}}>
        </div>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 500, width: '100%'}} >
            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
              Outlier Table for {querydate}
            </div>
            <DataGrid rows={tablerows} columns={columns} pageSize={12} rowsPerPageOptions={[5]} className={classes.root} />
          </div>

        <FormContainer>
          <Form>
          <Button onClick={() => resetfilter()}>
            RESET FILTER
            </Button>
            <FormControl variant='outlined' >
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              Filter Container
            </FormLabel>
              <Select
              labelId="filterby_container"
              id="filterby_con"
              multiple
              value={filteredNames}
              onChange={(event) =>
                filterrows_bycontainer(event.target.value as string[])
              }
              input={<Input />}
              renderValue={(selected) => (selected as string[]).join(", ")}
            >
              {containerNames.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={filteredNames.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
            </FormControl>
            <FormControl variant='outlined' >
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              Filter Region
            </FormLabel>
              <Select
              labelId="filterby_region"
              id="filterby_reg"
              multiple
              value={filteredRegionNames}
              onChange={(event) =>
                filterrows_byregion(event.target.value as string[])
              }
              input={<Input />}
              renderValue={(selected) => (selected as string[]).join(", ")}
            >
              {regionNames.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={filteredRegionNames.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
            </FormControl>
            <FormControl variant='outlined' >
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              Filter Time of Day
            </FormLabel>
              <Select
              labelId="filterby_tod"
              id="filterby_tod"
              multiple
              value={filteredTOD}
              onChange={(event) =>
                filterrows_byTOD(event.target.value as string[])
              }
              input={<Input />}
              renderValue={(selected) => (selected as string[]).join(", ")}
            >
              {timeblocks.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={filteredTOD.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
            </FormControl>
            <FormControl variant='outlined' >
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              Filter Outlier Category
            </FormLabel>
              <Select
              labelId="filterby_category"
              id="filterby_category"
              multiple
              value={filteredCategories}
              onChange={(event) =>
                filterrows_byCategories(event.target.value as string[])
              }
              input={<Input />}
              renderValue={(selected) => (selected as string[]).join(", ")}
            >
              {categories.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={filteredCategories.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
            </FormControl>
          </Form>
        </FormContainer>
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '30px', height: 150 }}>
            {selectedoutlierinfo}
          </div>
        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: 350, width: '100%'}} >
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            Relevant Incidents Pertaining to {selectedoutlierinfo2}
          </div>
          <DataGrid rows={pubreprows} columns={pubrepcolumns} pageSize={8} rowsPerPageOptions={[5]} className={classes.root} />
        </div>
        <div style={{ height: 350, width: '100%'}}>
          <div style={{ height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            Daily Amount of {selectedoutlierinfo2}, Previous {selectedtimeframe} Days
          </div>
          <div style={{ height: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
            {loadingstate}
          </div>
        <Chart data={dailyoccurrencedata}>
          <ArgumentAxis />
          <ValueAxis />
          <LineSeries valueField="daily_occurrence" argumentField="date" />
        </Chart>
        <ToggleBarContainer>
          <Button size="small" onClick={() => parsedatafordailyoccurrence(selectedrowdata, 7)} >
            7D
          </Button>
          <Button size="small" onClick={() => parsedatafordailyoccurrence(selectedrowdata, 30)} >
            30D
          </Button>
          <Button size="small" onClick={() => parsedatafordailyoccurrence(selectedrowdata, 90)} >
            90D
          </Button>
        </ToggleBarContainer>
        <ToggleBarContainer>
          <TextField size="small" label="Custom Lookback" margin="dense" helperText="Enter number of days" onChange={(e) => setcustomtimeframe(parseInt(e.target.value))} InputProps={{endAdornment: <Button onClick={(e) => parsedatafordailyoccurrence(selectedrowdata, customtimeframe)} > Enter </Button>}} >
          </TextField>
        </ToggleBarContainer>
        </div>
      </Section>


  )

}