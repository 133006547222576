import styled from "styled-components";

export default styled.input`
    font-family: IBM Plex Sans;
    background-color: rgba(237, 159, 78, 0.08);
    width: 254px;
    height: 37px;
    text-transform: uppercase;
    border-radius: 18px;
    border: #cc662f solid 1px;
    color: #cc662f;
    margin: 10px;
    margin-bottom: 0px;
    font-size: 14px;
    letter-spacing: 1px;
    cursor: pointer;
    outline: none;
`;
