import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Input,
  Checkbox,
  ListItemText,
  CircularProgress,
  Snackbar
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import {
  updateClient,
  modifyClientContainers,
  getAllContainers,
  getClientContainers
} from "../types/api/admin";
import { Form, FormContainer, Section, TextInput } from "./styled";
import { useThrow } from "../catch";
import { DisplayClient, ClientDisplayData } from "../types/client";
import { Container } from "../types/geo"
import { getClientDisplayData } from "../types/api/admin";

export const UpdateClient = () => {
  const error = useThrow();

  const [loading, updateLoading] = useState<boolean>(true);
  const [clientData, updateClientData] = useState<DisplayClient[]>([]);
  const [id, updateID] = useState<string>("");
  const [name, updateName] = useState<string>("");
  const [primaryEmail, updatePrimaryEmail] = useState<string>("");
  const [licenses, updateLicenses] = useState<string>("");
  const [licensesBC, updateLicensesBC] = useState<string>("");
  const [licensesEC, updateLicensesEC] = useState<string>("");
  const [licensesFC, updateLicensesFC] = useState<string>("");
  const [containers, updateContainers] = useState<string[]>([]);
  const [containerNames, updateContainerNames] = useState<string[]>([]);
  const [containerDetails, updateContainerDetails] = useState<Container[]>([]);
  const [message, setMessage] = useState<string>("");
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const displaySuccessSnackbar = () => {
    setOpenSuccessSnackbar(true);
  };

  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const displayErrorSnackbar = () => {
    setOpenErrorSnackbar(true);
  };

  const [openWarningSnackbar, setOpenWarningSnackbar] = useState(false);
  // const displayWarningSnackbar = () => {
  //   setOpenWarningSnackbar(true);
  // };

  useEffect(() => {
    (async () => {
      try {
        const response: ClientDisplayData = await getClientDisplayData();
        updateClientData([...response.clientData]);
        updateLoading(false);
      } catch (err) {
        error(err);
        updateLoading(false);
      }
    })();
  }, [error]);

  useEffect(() => {
    (async () => {
      try {
        const response: Container[] = await getAllContainers();
        const responseNamesArr: string[] = [];
        const responseDetailsArr: Container[] = [];
 
        response.forEach((fetchedContainer) => {
          responseDetailsArr.push(fetchedContainer);
          responseNamesArr.push(fetchedContainer.name);
        });
        updateContainerNames(responseNamesArr);
        updateContainerDetails(responseDetailsArr);
      } catch (err) {
        error(err);
      }
    })();
  }, [error]);

  if (loading) {
    // TODO: improve loading style
    return <CircularProgress style={{position: "absolute", margin: "auto", top: "0", left: "0", right: "0", bottom: "0"}}/>;
  }

  const resetForm = () => {
    updateLoading(false);
    updateID("");
    updateName("");
    updatePrimaryEmail("");
    updateLicenses("");
    updateLicensesBC("");
    updateLicensesEC("");
    updateLicensesFC("");
    updateContainers([]);
  };

  const fetchContainers = async (id: string) => {
    const clientContainersArr = [];
    const response: any = await getClientContainers(parseInt(id));
    for (let i = 0; i < response.length; i++){
      for (let j = 0; j < containerDetails.length; j++){
        if (response[i].container_id === containerDetails[j].id){
          clientContainersArr.push(containerDetails[j].name);
        }
      }
    }
    updateContainers(clientContainersArr);
  }

  const submit = () => {
    updateLoading(true);

    if (id && name && primaryEmail && licenses) {
      const updateClientObject = {
        id: Number(id),
        name: name,
        primary_email: primaryEmail,
        licenses: Number(licenses),
        licenses_BC: Number(licensesBC),
        licenses_EC: Number(licensesEC),
        licenses_FC: Number(licensesFC),
        ignored_report_types: undefined,
      };
      const addContainersObject = {
        client_id: Number(id),
        containers: containers,
      };

      Promise.all([
        updateClient(updateClientObject),
        modifyClientContainers(addContainersObject),
      ])
        .then(() => {
          setMessage("Successfully modifed client")
          displaySuccessSnackbar();
          resetForm();
        })
        .catch((errorMsg) => {
          setMessage(errorMsg.message);
          displayErrorSnackbar();
          updateLoading(false);
        });
    } else {
      error({ message: "Please fill all required fields." });
    }
  };

  return (
    <FormContainer>
      <Form noValidate autoComplete="off">
        <Section>
          <Autocomplete
            disableClearable
            id="client-name-input"
            options={clientData}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextInput
                {...params}
                label="Client Name (required)"
                variant="outlined"
              />
            )}
            onChange={(event, value) => {
              updateID(value?.id.toString() as string);
              updateName(value?.name as string);
              updatePrimaryEmail(value?.primary_email as string);
              if (value.licenses_BC === null) {
                updateLicensesBC("");
              } else {
                updateLicensesBC(value?.licenses_BC?.toString() as string);
              }
              if (value.licenses_BC === null) {
                updateLicensesEC("");
              } else {
                updateLicensesEC(value?.licenses_EC?.toString() as string);
              }
              if (value.licenses_BC === null) {
                updateLicensesFC("");
              } else {
                updateLicensesFC(value?.licenses_FC?.toString() as string);
              }
              updateLicenses(value?.licenses.toString() as string);
              fetchContainers(value?.id.toString() as string);
            }}
          />
          <TextInput
            id="name"
            label="Name (required)"
            variant="outlined"
            value={name}
            onChange={(e) => updateName(e.target.value)}
          />
          <TextInput
            id="email"
            label="Primary Email (required)"
            variant="outlined"
            value={primaryEmail}
            onChange={(e) => updatePrimaryEmail(e.target.value)}
          />
          <TextInput
            id="licenses"
            label="Licenses (required)"
            variant="outlined"
            type="number"
            value={licenses}
            onChange={(e) => updateLicenses(e.target.value)}
          />
          <TextInput
            id="licensesBC"
            label="Business Class Licenses"
            variant="outlined"
            type="number"
            value={licensesBC}
            onChange={(e) => updateLicensesBC(e.target.value)}
          />
          <TextInput
            id="licensesEC"
            label="Economy Class Licenses"
            variant="outlined"
            type="number"
            value={licensesEC}
            onChange={(e) => updateLicensesEC(e.target.value)}
          />
          <TextInput
            id="licensesFC"
            label="First Class Licenses"
            variant="outlined"
            type="number"
            value={licensesFC}
            onChange={(e) => updateLicensesFC(e.target.value)}
          />
          <FormControl variant="outlined">
            <InputLabel id="modify-containers-label">Containers</InputLabel>
            <Select
              labelId="modify-containers-label"
              id="modify-containers"
              multiple
              value={containers}
              onChange={(event) =>
                updateContainers(event.target.value as string[])
              }
              input={<Input />}
              renderValue={(selected) => (selected as string[]).join(", ")}
            >
              {containerNames.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={containers.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Section>
        <Button variant="contained" color="primary" onClick={submit}>
          Update Client
        </Button>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={openSuccessSnackbar}
          onClose={(e) => setOpenSuccessSnackbar(false)}
          autoHideDuration={4000}
          message={message}
        >
          <Alert severity="success"variant="filled">
            {message}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={openErrorSnackbar}
          onClose={(e) => setOpenErrorSnackbar(false)}
          autoHideDuration={4000}
          message={message}
        >
          <Alert severity="error"variant="filled">
            {message}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={openWarningSnackbar}
          onClose={(e) => setOpenWarningSnackbar(false)}
          autoHideDuration={4000}
          message={message}
        >
          <Alert severity="warning"variant="filled">
            {message}
          </Alert>
        </Snackbar>
      </Form>
    </FormContainer>
  );
};
