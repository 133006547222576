import { default as React,
    useState,
    useEffect,
    useRef,
    useContext,
    useMemo,
    useCallback} from 'react';
import { default as MaterialTable,
         MaterialTableProps,
         EditComponentProps,
         Action,
         Filter,
         Column,
         MTableFilterRow,
         MTableBody,
         MTableHeader} from "material-table";
import { DateRangePicker } from "react-date-range";
import { TextField,
    InputAdornment,
    Tooltip,
    Icon,
    Select,
    Popover,
    MenuItem,
    Button,
    IconButton} from "@material-ui/core";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import OtherMedia from "./MessageComponent/Message/otherMedia";
import Message from "./MessageComponent/Message/message";
import moment from "moment";
import "./MessageComponent/messageComponent.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import jwtDecode from "jwt-decode";

import { CountedForm, Form,
    PendingAampReport,
    PendingAampReportUpdate,
    Review,
    TelegramChat,
    TelegramMessageDetails} from "../types/aamp";
import { AdminPrivileges, AdminUserProfile } from "../types/admin";
import { useThrow } from "../catch";
import { getAampReportsByFormId, getAllAdminUsers, getChats, getCountedForms, getFullTranscriptMessagesByChatID, updatePendingReport } from "../types/api/admin";
import DetailPanel, { MapboxAPIKey } from "./detailPanel";
import Description from "./description";
import AnalystNotes from "./analystNotes";
import CategoryDropdown from "./categoryDropdown";
import "./primaryColor.css";
import "./font.css";
import { default as ReactMapboxGl, Marker } from "react-mapbox-gl";
import * as MapboxGl from "mapbox-gl";
import MarkerTooltip from "./markertooltip";
import { Point, LineString, Polygon, Position, GeoJsonObject } from 'geojson';
import { useSelectFromRedux } from "./../utils/_hooks";
import { ConsoleView } from 'react-device-detect';
import { GeometryObject } from 'geojson';
import { center } from '@turf/turf';

export const flattenValues = (values: Record<string, string[]> | string[]): string[] =>
 Array.isArray(values) ? values : ([] as string[]).concat(...Object.values(values));

export interface RowData extends PendingAampReport {
    full_name: string;
    aamp_report_type: string;
    victim: string;
    aggressor: string;
    container_name: string;
    row: number;
    flag?: "duplicate" | "time";
}
  
interface CustomMessage {
    id: number;
    author: string;
    message: string;
    timestamp: number;
    type: string;
}

const toRowData = (report: Omit<RowData, "full_name" | "aamp_report_type" | "victim" | "aggressor">): RowData => Object.assign(report, {
full_name: `${report.first_name} ${report.last_name}`,
aamp_report_type: report.fields.aamp_report_type,
victim: report.fields.victim,
aggressor: report.fields.aggressor,
impact_level: report.impact_level,
});

const SelectField = ({ forms, rowData, value, onChange, columnDef }: EditComponentProps<RowData> & { forms: Record<number, Form> }) => {
    const onSelectionChange = (event: React.ChangeEvent<{ value: any }>) => onChange(event.target.value as string);

    const children: React.ReactNode = flattenValues(forms[rowData.form_id].fields[columnDef.field].values).map((value: string) => (
    <MenuItem key={value} value={value}>{value}</MenuItem>
    ));

    return (
    <Select value={value} onChange={onSelectionChange}>
        {children}
    </Select>
    );
};

const SelectImpactLevel = ({ forms, rowData, value, onChange, columnDef }: EditComponentProps<RowData> & { forms: Record<number, Form> }) => {
    const onSelectionChange = (event: React.ChangeEvent<{ value: any }>) => onChange(event.target.value as string);
    
    const children: React.ReactNode = flattenValues(["Low", "High", "Priveliged Source"]).map((value: string) => (
       <MenuItem key={value} value={value}>{value}</MenuItem>
    ));
    return (
       <Select value={value} onChange={onSelectionChange}>
         {children}
       </Select>
    );
    };

const everyRow = <T,>(func: (rowData: T) => void) =>
 (event: any, rowDatas: T | T[]) => {
     if (Array.isArray(rowDatas)) {
         rowDatas.forEach(func);
     } else {
         func(rowDatas);
     }
 };

interface DateRange {
startDate?: Date;
endDate?: Date;
}

const DateRangeFilter = ({
columnDef,
onFilterChanged
}: {
columnDef: Column<RowData>,
onFilterChanged: (id: string, value?: DateRange) => void
}) => {
const filter: DateRange | undefined = columnDef.defaultFilter;
const [ range, setRange ] = useState<DateRange | undefined>(filter);

const [ anchor, setAnchor ] = useState<Element | null>(null);
const showPopover = (event: React.SyntheticEvent) => setAnchor(event.currentTarget);

const onClose = () => {
   if (range?.endDate) {
       range.endDate = new Date(range.endDate.getTime());
       range.endDate.setHours(23, 59, 59, 999);
   }
   onFilterChanged((columnDef as any).tableData.id, range);
   setAnchor(null);
}

const adornment: React.ReactNode = (
   <InputAdornment position="start">
     <Tooltip title="Filter by range">
       <Icon>date_range</Icon>
     </Tooltip>
</InputAdornment>
);

let inputValue = "";
if (filter?.startDate) {
   inputValue += filter.startDate.toLocaleDateString() + (filter.endDate ? " to" : " onwards");
}
if (filter?.endDate) {
   inputValue += (filter.startDate ? " " : "until ") + filter.endDate.toLocaleDateString();
}

const setSearchHandler = (element: any) => {
   if (element) {
       element.onsearch = (event: Event) => {
           setRange(undefined);
           onFilterChanged((columnDef as any).tableData.id, undefined);
           setAnchor(null);
       };
   }
};
return (
   <>
     <TextField InputProps={{ startAdornment: adornment }}
                type="search"
                style={filter && { minWidth: "31ch" }}
                onClick={showPopover}
                ref={setSearchHandler}
                value={inputValue} />
     <Popover id={anchor ? "popover" : undefined}
              open={Boolean(anchor)}
              anchorEl={anchor}
              onClose={onClose}
              anchorOrigin={{
                  vertical: "top",
                  horizontal: "center"
              }}
              transformOrigin={{
                  vertical: "top",
                  horizontal: "center"
              }}>
       <DateRangePicker ranges={[{
                            ...range,
                            key: "selection"
                        }]}
                        dateDisplayFormat="MMM d"
                        startDatePlaceholder="Beginning of time"
                        endDatePlaceholder="End of time"
                        editableDateInput={true}
                        onChange={(change: any) => setRange(change.selection)} />
     </Popover>
   </>
)
};
const dateRangeFilter = (filter: DateRange, rowData: any, columnDef: Column<RowData>): boolean => {
const date: Date = new Date(rowData[columnDef.field as string]);
if (filter?.startDate && date < filter.startDate) {
   return false;
}
if (filter?.endDate && date > filter.endDate) {
   return false;
}
return true;
};


const fieldFilter = (filter: string[], rowData: any, columnDef: Column<RowData>): boolean =>
 filter.includes(rowData[columnDef.field as string]);

const CountContext = React.createContext<number | undefined>(undefined);
type ExtendedReview = Review | "archived";
const ReviewFilterContext = React.createContext<[ExtendedReview[], (filter: ExtendedReview[]) => void]>([[], () => {}]);

export default () => {
    const error = useThrow();

    // const [ { token } ] = useCookies(["token"]);
    // const token = useSelectFromRedux((state) => state.cuser.token);
    // const { privileges } = jwtDecode(token as string);

    const profile = useSelectFromRedux((state) => state.cuser.profile);

    const [ forms, setForms ] = useState<Record<number, Form>>({});
    const [ reviewFilter, setReviewFilter ] = useState<ExtendedReview[]>([null]);
    const [ data, setData ] = useState<RowData[]  | undefined>(undefined);
    const [ containersList, setContainersList ] = useState<string[]>([]);
    const [ container, setContainer ] = useState<string>("");
    const [ formsByContainer, setFormsByContainer ] = useState<{[container_name: string]: CountedForm[]}>({});
    const [ containerFetchData, setContainerFetchData ] = useState<Record<number, string>>();
    const [ loading, setLoading ] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [fullTranscript, setFullTranscript] = useState<CustomMessage[]>([]);
    const [allChats, setAllChats] = useState<TelegramChat[]>([]);
    const [loadingFullTranscript, setLoadingFullTranscript] = useState<boolean>(false);
    const [currentForm, setCurrentForm] = useState<CountedForm | undefined>();
    const [adminData, updateAdminData] = useState<AdminUserProfile[]>([]);

    const handleClickOpen = (currentChatName:string) => {
        getFullTranscript(currentChatName);
        setOpen(true);
      };

      useEffect(() => {
          if ((profile?.privileges ?? 0) > 0) {
              (async () => {
                  try {
                      const response: AdminUserProfile[] = await getAllAdminUsers();
                      updateAdminData(response);
                  } catch (err) {
                      throw(err);
                  }
              })();
          }
      }, [error, profile]);

    const FieldFilter = ({
    forms,
    columnDef,
    onFilterChanged
    }: {
    forms: Record<number, Form>,
    columnDef: Column<RowData>,
    onFilterChanged: (id: string, value?: string[]) => void
    }) => {
    const options: string[] = useMemo<string[]>((): string[] => {
        const result: Set<string> = new Set<string>();
        if (currentForm !== undefined) {
            for (const option of flattenValues(currentForm.fields[columnDef.field as string].values)) {
                result.add(option);
            }
        }
        return Array.from(result);
    }, [columnDef.field]);
    return (
        <CategoryDropdown options={options}
                            defaultValue={columnDef.defaultFilter ?? []}
                            onChange={(value: string[]) => onFilterChanged((columnDef as any).tableData.id, value.length > 0 ? value : undefined)} />
                            
    );
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    function getCoords(point: Point | LineString | Polygon): [number, number] {
        if (point.type === 'Point') {
          if (point.coordinates[0] && point.coordinates[1]) {
            return [point.coordinates[0], point.coordinates[1]]

          } else {
              return [0,0]

          }
        }
        else {
            return center(point).geometry.coordinates as [number, number]
        }
    }


    const getFullTranscriptTelegrams = async (currentChatName:string) => {
        try {
          const currentChat= allChats.find(chat => chat.title === currentChatName);
          let telegramMessages:TelegramMessageDetails[] = [];
          if (currentChat !== undefined) {
            telegramMessages = await getFullTranscriptMessagesByChatID(currentChat.id);
          }
          let tempMessages: CustomMessage[] = [];
          telegramMessages.forEach((message) => {
            let currentMessage: CustomMessage;
            const secondName = message.from.last_name ? message.from.last_name : message.from.username;
            if (message.text !== null && message.media === null) {
                currentMessage = {
                  id: message.message_id,
                  author: "" + message.from.first_name + " " + secondName,
                  message: message.text,
                  timestamp: message.date,
                  type: message.type,
                };
              } else if (message.media !== undefined && message.text === null) {
                currentMessage = {
                  id: message.message_id,
                  author: "" + message.from.first_name + " " + secondName,
                  message: message.media,
                  timestamp: message.date,
                  type: message.type,
                };
              } else if (message.media !== undefined && message.text !== null) {
                currentMessage = {
                  id: message.message_id,
                  author: "" + message.from.first_name + " " + secondName,
                  message: message.text + "|boundaryBetweenTextAndMedia|" + message.media,
                  timestamp: message.date,
                  type: "captioned " + message.type,
                };
              } else {
                currentMessage = {
                  id: message.message_id,
                  author: "" + message.from.first_name + " " + secondName,
                  message: "",
                  timestamp: message.date,
                  type: message.type,
                };
              }
            tempMessages = [...tempMessages, currentMessage];
          });
          tempMessages.sort((a, b) => {
            return a.id - b.id;
          });
          return tempMessages;
        } catch (err) {
          console.log("Could not get telegram messages");
        }
      };
    const getFullTranscript = async (currentChatName:string) => {
        setLoadingFullTranscript(true);
        await getFullTranscriptTelegrams(currentChatName).then((result) => {
          if (result !== undefined) setFullTranscript(result);
          setLoadingFullTranscript(false);
        });
    };


    const displayFullTranscript = (chatName:string) => {
        return (<Dialog
            open={open}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            >
            <DialogContent>
                <div className="container">
                <div className="row">
                    <div className="messageHeader">
                    <div className="transcript-button">
                        <IconButton size="small" onClick={handleClose}>
                        <FullscreenExitIcon color="secondary" />
                        </IconButton>
                    </div>
                    <div className="container">
                        <div className="chatTitle">{chatName}</div>
                    </div>
                    </div>
                </div>
                {loadingFullTranscript &&
                    <div className="row">
                        <div className="messengerLoader">
                            <div className="loaderAnimation">
                        Loading Messages...
                        </div>
                        </div>
                    </div>
                }
                {!loadingFullTranscript &&
                <div className="row">
                    <div className="messenger">
                        <div className="scrollable-content">
                            {renderFullTranscript()}
                        </div>
                    </div>
                </div>
                }
                </div>
            </DialogContent>
        </Dialog>)
    }

    const AampFilterRow = (props: any): React.ReactElement => {
        const [ reviewFilter, setReviewFilter ] = useContext(ReviewFilterContext);
        return <MTableFilterRow {...{
            ...props,
            hasActions: false,
            hasDetailPanel: true,
            columns: [
                ...props.columns,
                {
                    // Review filter (replaces actions column):
                    tableData: {
                        id: "key-review-filter-column",
                        columnOrder: 1000,
                        groupOrder: -1
                    },
                    field: true,
                    filterComponent: () => {
                        const toReview: Record<string, ExtendedReview> = {
                            Pending: null,
                            Approved: true,
                            Rejected: false,
                            Archived: "archived"
                        };
                        const props = {
                            options: Object.keys(toReview),
                            defaultValue: reviewFilter.map((review: ExtendedReview): string => {
                                for (const [ key, value ] of Object.entries(toReview)) {
                                    if (review === value) {
                                        return key;
                                    }
                                }
                                return "";
                            }),
                            onChange: (value: string[]) =>  {
                                setReportCenter(undefined);
                                setReviewFilter(value.map((selection: string): ExtendedReview => toReview[selection]))
    
                            }
                        };
                        return <CategoryDropdown {...props} />;
                    }
                }
            ]
        }} />;
    };

    const fetchFormData = useCallback(async () => {
        try {
            const fetchedForms = await getCountedForms();
            setFormsByContainer(fetchedForms);
            const forms: Record<number, CountedForm> = {};
            const containers: Record<number, string> = {};
            for (const [ container_name, containerForms ] of Object.entries(fetchedForms)) {
                for (const form of containerForms) {
                    forms[form.id] = form;
                    containers[form.id] = container_name;
                    const newContainers = containersList;
                    if(!newContainers.includes(container_name)) newContainers.push(container_name);
                    setContainersList(newContainers);
                }
            }
            const localContainer = localStorage.getItem("aampContainer");
            if(localContainer && containersList.includes(localContainer)) {
                setContainer(localContainer);
            }
            setContainerFetchData(containers)
            setForms(forms);
            const fetchedChats = await getChats();
            setAllChats(fetchedChats);
        } catch (err) {
            console.log("ERROR HERE")
            error(err);
        }
    }, [containersList, error]);

    useEffect(() => {
        fetchFormData();
    }, [profile, error, containersList, currentForm, fetchFormData]);

    const fetchData = async (form : number) => {
        if(form && containerFetchData) {
            setLoading(true);
            setReportCenter(undefined);
            const data: RowData[] = (await getAampReportsByFormId(form))
                .map((report: PendingAampReport, row: number) => toRowData(Object.assign(report, {
                    container_name: containerFetchData[report.form_id],
                    row
                })));
            setData(data);
            setLoading(false);
        }
    }

    

    const Map = ReactMapboxGl({
        accessToken: MapboxAPIKey,
    });

    const updateReport = async (row: number, update: Omit<PendingAampReportUpdate, "id" | "changes">): Promise<void> => {
        try {
            let newData: RowData[] = [...data!];
            const oldRow: RowData = data![row];
            newData[row] = toRowData(Object.assign({}, oldRow, update));
            const realUpdate: PendingAampReportUpdate = update as PendingAampReportUpdate;
            realUpdate.changes = [];
            realUpdate.id = oldRow.id;

            if (oldRow.super_user_review !== newData[row].super_user_review){
                realUpdate.changes?.push("super_user_review");
            } else if (oldRow.manager_review !== newData[row].manager_review){
                realUpdate.changes?.push("manager_review");
            } else if (oldRow.archived !== newData[row].archived){
                realUpdate.changes?.push("archived")
            } else if (oldRow.point !== newData[row].point){
                realUpdate.changes?.push("point");
            } else {
                if (oldRow.fields.aamp_report_type !== realUpdate.fields?.aamp_report_type){
                    realUpdate.changes?.push("aamp_report_type");
                } if (oldRow.fields.aggressor !== realUpdate.fields?.aggressor){
                    realUpdate.changes?.push("aggressor");
                } if (oldRow.fields.victim !== realUpdate.fields?.victim){
                    realUpdate.changes?.push("victim");
                } if (oldRow.address !== realUpdate.address){
                    realUpdate.changes?.push("address");
                } if (oldRow.date_time !== realUpdate.date_time){
                    realUpdate.changes?.push("date_time");
                } if (oldRow.description !== realUpdate.description){
                    realUpdate.changes?.push("description");
                } if (oldRow.analyst_notes !== realUpdate.analyst_notes){
                    realUpdate.changes?.push("analyst_notes");
                } if (oldRow.impact_level !== realUpdate.impact_level){
                    realUpdate.changes?.push("impact_level");
                }
            }

            await updatePendingReport(realUpdate);
            setData(newData);
            fetchFormData();
        } catch (err) {
            error(err);
        }
    };

    const reviewReport = (row: number, review: Review) =>
          updateReport(row, (profile?.privileges ?? 0) >= AdminPrivileges.SUPERUSER ? { super_user_review: review } : { manager_review : review });

    const [ filters, setFilters ] = useState<Record<string, any>>({});

    const tableRef = useRef();
    const [ count, setCount ] = useState<number | undefined>();
    const recount = () => setCount((tableRef as any).current.dataManager.searchedData.length);
    useEffect(recount, [reviewFilter, data]);
    const [ filteredData, setFilteredData ] = useState<RowData[] | undefined>(data);
    const [ mapFilteredData, setMapFilteredData ] = useState<RowData[] | undefined>(data);

    const pointsEqual = (point1 : Point, point2 : Point) : boolean => {
        return (point1.coordinates[0] === point2.coordinates[0] &&
            point1.coordinates[1] === point2.coordinates[1])
    }

    const [ selectedReport, setSelectedReport ] = useState<number>();

    const [futureToggle, setFutureToggle] = useState<Boolean>(false);
    const [selectedFutureToggle, setSelectedFutureToggle] = useState<String>("");

    const [shapes, setShapes] = useState<any[]>([]);

    useEffect(() => {
        let newData = data?.filter(({ super_user_review, manager_review, archived }: RowData) =>
        reviewFilter.includes(archived ? "archived"
                              : super_user_review === null ? manager_review
                              : super_user_review)) ?? [];
        newData.forEach((rowData : RowData) => {
            rowData.flag = undefined;
        })
        for(let i = 0; i < newData.length; i++) {
            if(newData[i].date_time && (new Date(newData[i].created_at).getTime() - new Date(newData[i].date_time ?? 0).getTime() > 2*7*24*60*60*1000)) {
                newData[i].flag = "time";
            }
            for(let j = i + 1; j < newData.length; j++) {
                if(newData[i].point && newData[j].point) {
                    //@ts-ignore ts was saying the points could be undefined, which is clearly not true b/c ^
                    if(pointsEqual(newData[i].point,newData[j].point) && Object.is(newData[i].aamp_report_type,newData[j].aamp_report_type)) {
                        newData[i].flag = "duplicate";
                        newData[j].flag = "duplicate";
                    }
                }
            }
        }

        setMapFilteredData(newData.filter((row : RowData) => row.point));
        setFilteredData(newData.filter((row:RowData) => row.is_future == futureToggle));

        const shapes = newData.filter((row: RowData) => row.point?.type !== 'Point');
        console.log(newData)
        console.log(shapes)
        setShapes(shapes!);

    }, [data, reviewFilter, futureToggle])

    const [ reportCenter, setReportCenter ] = useState<[number, number] | undefined>();
    const [ tooltip, setTooltip ] = useState<RowData | undefined>();

    
    const getMapBounds = (rowData: RowData[]) : [[number, number], [number, number]] => {
        if(reportCenter) {
            return [[reportCenter[0] - 0.10, reportCenter[1] - 0.10], [reportCenter[0] + 0.10, reportCenter[1] + 0.10]];
        }
        let maxLong : number;
        let minLong : number;
        let maxLat : number;
        let minLat : number;
        rowData = rowData.filter(currentRowData => currentRowData.point !== null);
        if(rowData.length > 0) {
            const base_coords = getCoords(rowData[0].point!);
            maxLong = base_coords[0]
            maxLat = base_coords[1]
            minLong = base_coords[0]
            minLat = base_coords[1]
            rowData.forEach((report : RowData) =>  {
                const row_coords = getCoords(report.point!);
                if(maxLong < report.point!.coordinates[0]) maxLong = row_coords[0];
                if(maxLat < report.point!.coordinates[1]) maxLat = row_coords[1];
                if(minLong > report.point!.coordinates[0]) minLong = row_coords[0];
                if(minLat > report.point!.coordinates[1]) minLat = row_coords[1];
            })
            return [[minLong-1, minLat-1],  [maxLong+1, maxLat+2]];
        }
        return [[0,0],[0,0]];
    }
    

    const renderFullTranscript = () => {
        let i = 0;
        let messageCount = fullTranscript.length;
        let tempMessages = [];

        while (i < messageCount) {
            let previous = fullTranscript[i - 1];
            let current = fullTranscript[i];
            let next = fullTranscript[i + 1];
            let isMine = current.author === "";
            let currentMoment = moment.unix(current.timestamp);
            let prevBySameAuthor = false;
            let nextBySameAuthor = false;
            let startsSequence = true;
            let endsSequence = true;
            let showTimestamp = true;
            let isSource = false;
            let isFullTranscriptSource = false;
            let ftEnd = false;
            if (i === messageCount - 1) ftEnd = true;

            if (previous) {
            let previousMoment = moment.unix(previous.timestamp);
            let previousDuration = moment.duration(
                currentMoment.diff(previousMoment)
            );
            prevBySameAuthor = previous.author === current.author;

            if (prevBySameAuthor && previousDuration.as("hours") < 1) {
                startsSequence = false;
            }

            if (previousDuration.as("hours") < 1) {
                showTimestamp = false;
            }
            }

            if (next) {
                let nextMoment = moment.unix(next.timestamp);
                let nextDuration = moment.duration(nextMoment.diff(currentMoment));
                nextBySameAuthor = next.author === current.author;

                if (nextBySameAuthor && nextDuration.as("hours") < 1) {
                    endsSequence = false;
                }
            }

            if (current.type === "text") {
            tempMessages.push(
                <Message
                key={i}
                isMine={isMine}
                startsSequence={startsSequence}
                endsSequence={endsSequence}
                showTimestamp={showTimestamp}
                data={current}
                isSource={isSource}
                isFullTranscriptSource={isFullTranscriptSource}
                ftEnd={ftEnd}
                />
            );
            } else {
            tempMessages.push(
                <OtherMedia
                key={i}
                isMine={isMine}
                startsSequence={startsSequence}
                endsSequence={endsSequence}
                showTimestamp={showTimestamp}
                data={current}
                isSource={isSource}
                isFullTranscriptSource={isFullTranscriptSource}
                ftEnd={ftEnd}
                />
            );
            }
            // Proceed to the next message.
            i += 1;
        }
        tempMessages = tempMessages.reverse();
        return tempMessages;
    };

    const ContainerSelection = (props: any) : React.ReactElement => {
        const currentContainerForms = (Object.entries(formsByContainer).find(([containerName]) => containerName === container));
        return(
            <>
            <div style={{
                display: "flex",
                flex: 1,
                flexDirection: "row"
            }}>
                <div style={{width: 400, flexDirection: "column"}}>
                    <div style={{marginLeft: 50}}>
                    Select a Container
                        <Select
                            value={container}
                            style={{marginLeft: 20, width: 150}}
                            onChange={(event) => {
                                setContainer(event.target.value as string);
                                setCurrentForm(undefined);
                                localStorage.setItem("aampContainer", event.target.value as string)
                            }}
                        >
                            {containersList.map((value: string) => (
                                <MenuItem key={value} value={value}>{value}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    {container !== undefined && currentContainerForms &&
                        currentContainerForms[1].map((value: CountedForm) => (
                            <MenuItem
                                key={value.id}
                                value={value.id}
                                onClick={() => {
                                    fetchData(value.id);
                                    setCurrentForm(value);
                                }}
                                style={{marginLeft: 50}}
                            >
                                <div style={{flexDirection: "column", marginRight: 10}}>
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        {value.name !== null && value.name !== undefined ? "Form " + value.name : "Form " + value.id}
                                        {value.count > 0 &&
                                        <div style={{
                                            color: "#90EE90",
                                            marginLeft: 10
                                        }}>
                                            {"(" + value.count + " pending)"}
                                        </div>}
                                        {value.immediate_public &&
                                        <Tooltip
                                            title="Reports submitted to this form will be displayed publicly as unverified even if they have not yet been approved."
                                            enterDelay={0}
                                        >
                                            <div style={{
                                                color: "orange",
                                                marginLeft: 10
                                            }}>
                                                {"(Immediate Public)"}
                                            </div>
                                        </Tooltip>}
                                    </div>
                                    {value.chats.length > 0 && value.chats.map((chatName: string) => (
                                        <div key={chatName} style={{marginLeft: 20}}>
                                            {chatName}
                                            <IconButton size="small" onClick={() => {
                                                handleClickOpen(chatName)
                                            }
                                            }>
                                                <FullscreenIcon color="secondary" />
                                            </IconButton>
                                            {displayFullTranscript(chatName)}
                                        </div>
                                    ))}
                                </div>
                            </MenuItem>
                        ))}
                </div>
                {currentForm &&
                <ReportsMap/>}
            </div>
            {!data &&
                <div style={{
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    flex: 1,
                    padding: "50px",
                    alignItems: "center",
                    justifyContent: "center"

                }}>
                    Select a Container and Form to View Data
                </div>
            }

        <div style={{marginLeft:"60px"}}>{renderFormInfo()}</div>
            </>
        )};


    const ReportsMap = (props: any) => {
        return (
            <>
                {mapFilteredData && mapFilteredData.length > 0 &&
                    <div style={{marginLeft: 10,minHeight: 600, display: "flex", flex: 1}}>
                        <Map
                            // eslint-disable-next-line
                            style="mapbox://styles/mapbox/satellite-streets-v9"
                            containerStyle={{ flex: "1 1 0", height: "100", width: "500", borderRadius: 5 }}
                            fitBounds={getMapBounds(mapFilteredData.slice(0,100))}
                            onStyleLoad={(map: MapboxGl.Map) => {
                                map.resize()
                                
                            }}
                            onSourceDataLoading={(map: MapboxGl.Map) => {
                                
                                map.on('load', function () {
                                    const linecol = ['red','blue','orange','green','purple','yellow']

                                        //console.log("HERE AAT THE SHAPES")
                                        //console.log(shapes)
                                    
                                        shapes.forEach((shape, index) => {
                                            var pickedcolor = linecol[Math.floor(Math.random()*linecol.length)]
                                            map.addSource(`shape_${index}`, {
                                            'type': 'geojson',
                                            'data': {
                                                'type' : 'Feature',
                                                'geometry' : shape.point!,
                                                'properties': null
                                            }
                                        });
                                            map.addLayer({
                                                'id': `shape_layer_${index}`,
                                                'type': 'line',
                                                'source': `shape_${index}`,
                                                'layout': {},
                                                'paint': {
                                                'line-color': pickedcolor,
                                                'line-width': 3,
                                                }
                                            })

                                            delete shapes[index]


                        
                                        })
                                    //}
                                });
                                
                            }}
                
                        >
                            {mapFilteredData.filter(currentData => currentData.point !== null && currentData.point!.type === 'Point').slice(0,50).map((report: RowData) =>
                                <Marker
                                    key={report.id}
                                    coordinates={getCoords(report.point!)}
                                    //coordinates={[report.point!.coordinates[0], report.point!.coordinates[1]]}
                                    anchor="bottom"
                                    style={{zIndex: selectedReport === report.id ? 100 : 1}}
                                >
                                    <img
                                        style={{
                                            height: selectedReport === report.id ? "40px" : "30px",
                                            width: "auto",
                                        }}
                                        src={getMarkerImage(report.aamp_report_type.toLowerCase())}
                                        alt={"Report"}
                                        key={report.id}
                                        onClick={(e) => {
                                            setTooltip(report)
                                        }}
                                    />
                                </Marker>
                            )}
                            
                        </Map>
                    </div>
                    }
                </>
        )
    }

    const filterFuture = (toggle: (0|1)) => {
        if (currentForm?.accepts_future_reports == true){
            if (toggle == 0){
                setSelectedFutureToggle("Displaying Present and Past AAMP Reports...")
                setFutureToggle(false)
                }
            if (toggle == 1){
                setSelectedFutureToggle("Displaying FUTURE AAMP Reports...")
                setFutureToggle(true)
                }
        } else {
            setSelectedFutureToggle("Future Reports NOT Enabled for this Form...")
        }


    }

    const blank = (props: any) : React.ReactElement => {
        return(
            <>
            </>
        )
    }

    const renderFormInfo = () => {
        if ((profile?.privileges ?? 0) > 0) {
            let formInfoString = "";
            let managerInfoString = "";
            let managerEmail = "";
            if (currentForm !== undefined && adminData !== undefined) {
                let primaryManager = adminData.find(admin => admin.id === currentForm.primary_manager)
                formInfoString = "Selected Form: Form " + currentForm.id;
                managerInfoString = "Primary Manager: " + primaryManager?.first_name + " " + primaryManager?.last_name;
                managerEmail = "Email: " + primaryManager?.email;
            }
            return (
                <>
                    <br></br>
                    <div>{formInfoString}</div>
                    <div>{managerInfoString}</div>
                    <div>{managerEmail}</div>
                    <div>
                    <Button onClick={() => filterFuture(0)} size='large' > NORMAL REPORTS </Button>
                    <Button onClick={() => filterFuture(1)} size='large' > FUTURE REPORTS </Button>
                    <div>
                    {selectedFutureToggle}
                    </div>
                    </div>
                </>
            )
        } else {
            return null;
        }

    }

    const props: MaterialTableProps<RowData> = {
        title: "Reports",
        columns: ([
            {
                title: "Reporter",
                field: "full_name",
                type: "string",
                editable: "never",
                render: (rowData : RowData) => (
                    <>
                        {rowData.flag === "duplicate" &&
                            <div style={{color: "red", marginRight: 5}}>(Possible Duplicate)</div>
                        }
                        {rowData.flag === "time" && 
                            <div style={{color: "red", marginRight: 5}}>(Possible Old Report)</div>
                        }
                        {rowData.full_name}
                    </>
                ),
                customFilterAndSearch: (filter: string, rowData: RowData) =>
                    rowData.full_name.replace(/\s+/g, "").toUpperCase().includes(filter.replace(/\s+/g, "").toUpperCase())
            },
            {
                title: "Time",
                field: "date_time",
                type: "datetime",
                filterComponent: DateRangeFilter,
                customFilterAndSearch: dateRangeFilter
            },
            {
                title: "End Time",
                field: "end_date",
                type: "datetime",
                filterComponent: DateRangeFilter,
                customFilterAndSearch: dateRangeFilter
            },
            {
                title: "Reported at",
                field: "created_at",
                type: "datetime",
                defaultSort: "desc",
                editable: "never",
                filterComponent: DateRangeFilter,
                customFilterAndSearch: dateRangeFilter
            },
            {
                title: "Location",
                field: "address"
            },
            {
                title: "Type",
                type: "string",
                field: "aamp_report_type",
                editComponent: (props: EditComponentProps<RowData>) => <SelectField {...props} forms={forms} />,
                render: (rowData: RowData) => rowData.fields.aamp_report_type,
                filterComponent: (props: {
                    columnDef: Column<RowData>,
                    onFilterChanged: (id: string, value?: string[]) => void
                }) => <FieldFilter {...props} forms={forms} />,
                customFilterAndSearch: fieldFilter
            },
            {
                title: "Victim",
                type: "string",
                field: "victim",
                editComponent: (props: EditComponentProps<RowData>) => <SelectField {...props} forms={forms} />,
                render: (rowData: RowData) => rowData.fields.victim,
                filterComponent: (props: {
                    columnDef: Column<RowData>,
                    onFilterChanged: (id: string, value?: string[]) => void
                }) => <FieldFilter {...props} forms={forms} />,
                customFilterAndSearch: fieldFilter
            },
            {
                title: "Aggressor",
                type: "string",
                field: "aggressor",
                editComponent: (props: EditComponentProps<RowData>) => <SelectField {...props} forms={forms} />,
                render: (rowData: RowData) => rowData.fields.aggressor,
                filterComponent: (props: {
                    columnDef: Column<RowData>,
                    onFilterChanged: (id: string, value?: string[]) => void
                }) => <FieldFilter {...props} forms={forms} />,
                customFilterAndSearch: fieldFilter
            },
            {
                title: "Description",
                field: "description",
                type: "string",
                editable: "never",
                grouping: false,
                render: (props: RowData) => <Description props={props} updateReport={updateReport}/>
            },
            {
                title: "Impact Level",
                field: "impact_level",
                editComponent: (props: EditComponentProps<RowData>) => <SelectImpactLevel {...props} forms={forms} />,
                type: "string",
                render: (rowData: RowData) => rowData.impact_level,

            },
            {
                title: "Analyst Notes",
                field: "analyst_notes",
                type: "string",
                editable: "never",
                grouping: false,
                render: (props: RowData) => <AnalystNotes props={props} updateReport={updateReport}/>,         
            },
            {
                title: "Public Report",
                type: "numeric",
                field: "public_report_id",
                emptyValue: "Not public",
                editable: "never",
                render: (rowData: RowData) => `#${rowData.public_report_id}`,
                hidden: (profile?.privileges ?? 0) >= AdminPrivileges.SUPERUSER
            }
        ] as Column<RowData>[]).map((column: Column<RowData>): Column<RowData> => {
            column.defaultFilter = filters[column.field!];
            return column;
        }),
        editable: {
            onRowUpdate: (rowData: RowData): Promise<void> => {
                return updateReport(rowData.row, {
                    address: rowData.address,
                    date_time: rowData.date_time,
                    description: rowData.description,
                    impact_level: rowData.impact_level,
                    fields: {
                        aamp_report_type: rowData.aamp_report_type,
                        victim: rowData.victim,
                        aggressor: rowData.aggressor
                    }
                }).then(() => {
                    if(rowData.date_time && (new Date(rowData.created_at).getTime() - new Date(rowData.date_time ?? 0).getTime() > 2*7*24*60*60*1000)) {
                        rowData.flag = "time";
                    } else { 
                        rowData.flag = undefined;
                    }
                });
            }
        },
        detailPanel: [
            {
                tooltip: "More",
                render: (rowData: RowData) => <DetailPanel row={rowData.row} report={rowData} updateReport={updateReport} />
            }
        ],
        actions: [
            (report: RowData) => {
                const action: Action<RowData> = {
                    icon: "done_all",
                    onClick: everyRow((report: RowData) => reviewReport(report.row, true))
                };
                if (report.point === null) {
                    action.tooltip = "Please Add a Location First";
                    action.iconProps = {
                        color: "disabled"
                    };
                 } else if (report.super_user_review === null && report.manager_review === null) {
                    action.tooltip = "Approve";
                    action.iconProps = {
                        color: "primary"
                    };
                } else if (report.super_user_review === true || report.manager_review === true) {
                    action.tooltip = "Already approved by "
                        + ((report.super_user_review)
                           ? "admin"
                           : ((profile?.privileges ?? 0) >= AdminPrivileges.SUPERUSER)
                           ? "a manager"
                           : "me");
                    action.iconProps = {
                        color: "action"
                    };
                    action.icon = "done_outline";
                    action.disabled = true;
                } else {
                    action.iconProps = {
                        color: "disabled"
                    };
                    action.icon = "done_outline";
                    action.disabled = true;
                }
                return action;
            },
            (report: RowData) => {
                const action: Action<RowData> = {
                    icon: "delete",
                    onClick: everyRow((report: RowData) => reviewReport(report.row, false))
                };
                if (report.super_user_review === null && report.manager_review === null) {
                    action.tooltip = "Reject";
                    action.iconProps = {
                        color: "error"
                    };
                } else if (report.super_user_review === true || report.manager_review === true) {
                    action.iconProps = {
                        color: "disabled"
                    };
                    action.icon = "delete_outline";
                    action.disabled = true;
                } else {
                    action.tooltip = "Already rejected by "
                        + ((report.super_user_review === false)
                           ? "admin"
                           : ((profile?.privileges ?? 0) >= AdminPrivileges.SUPERUSER)
                           ? "a manager"
                           : "me");
                    action.iconProps = {
                        color: "action"
                    };
                    action.icon = "delete_outline";
                    action.disabled = true;
                }
                return action;
            },
            (report: RowData): Action<RowData> =>
                report.archived ? {
                    icon: "unarchive",
                    tooltip: "Unarchive",
                    onClick: everyRow((report: RowData) => updateReport(report.row, { archived: false }))
                } : {
                    icon: "archive",
                    tooltip: "Archive",
                    onClick: everyRow((report: RowData) => updateReport(report.row, { archived: true }))
                },
            (report: RowData) : Action<RowData> => {
                const action: Action<RowData> = {
                    icon: "map",
                    onClick: (why: any) => {
                        setSelectedReport(report.id);
                        setReportCenter(getCoords(report.point!))
                        //setReportCenter([report.point!.coordinates[0], report.point!.coordinates[1]]);
                    }
                };
                return action;
            }
        ],
        options: {
            paging: true,
            pageSizeOptions: [5,10,25,50,75,100],
            pageSize: 10,
            actionsColumnIndex: -1,
            grouping: true,
            filtering: true,
            exportButton: (profile?.privileges ?? 0) >= AdminPrivileges.SUPERUSER,
            exportAllData: true
        },
        style: {
            border: "none",
            boxShadow: "none"
        },
        data: filteredData ?? [],
        localization: {
            body: {
                emptyDataSourceMessage: (data === undefined) ? "Loading reports..." : "No reports to show"
            },
            grouping: {
                groupedBy: "Segmented by: "
            }
        },
        components: {
            FilterRow: AampFilterRow,
            Groupbar: ContainerSelection,
            Body: data ? MTableBody : blank,
            Header: data ?  MTableHeader : blank,
            // this gets rid of the pagination at the bottom, but causes a bunch of warnings due to the library.
            // while the warnings don't cause any issues (I looked that up), they are kind of annoying...
            // Pagination: data ? MTablePagination : blank
        },
        tableRef,
        onFilterChange: (filters: Filter<RowData>[]) => {
            recount();
            const newFilters: Record<string, any> = {};
            for (const filter of filters) {
                newFilters[filter.column.field!] = filter.value;
            }
            setFilters(newFilters);
        },
        onSearchChange: recount,
        isLoading: loading
    };

    return (
        <CountContext.Provider value={count}>
          <ReviewFilterContext.Provider value={[reviewFilter, setReviewFilter]}>
              {tooltip && <MarkerTooltip props={tooltip} setTooltip={setTooltip} form={currentForm} updateReport={updateReport}/>}
            <MaterialTable {...props} />
          </ReviewFilterContext.Provider>
        </CountContext.Provider>
    );
};


export const getMarkerImage = (eventType: string) => {
switch (eventType) {
   case "theft":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-theft-marker.png";
   case "assault":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-assault-marker.png";
   case "shooting":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-shooting-marker.png";
   case "protest":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-protest-marker.png";
   case "auto theft":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-auto-theft-marker.png";
   case "explosion (accidental)":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-explosion-marker.png";
   case "explosive weapon":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-explosive-weapon-marker.png";
   case "harassment":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-harassment-marker.png";
   case "abduction":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-kidnap-marker.png";
   case "dangerous terrain":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-dangerous-terrain-marker.png";
   case "emergency response":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-emergency_response-marker.png";
   case "gang activity":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-gang-activity-marker.png";
   case "roadblock":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-roadblock-marker.png";
   case "violent crime":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-violent-crime.png";
   case "congested area":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-congested-area-marker.png";
   case "coordinated attack":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-coordinated-attack-marker.png";
   case "danger":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-danger-marker.png";
   case "fire":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-fire-marker.png";
   case "police":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-police-marker.png";
   case "rocket attack":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-rocket-attack-marker.png";
   case "suspicious activity":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-suspicious-person-marker-marker.png";
   case "suspicious object":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-sus-object-marker.png";
   case "suspicious vehicle":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-sus-vehicle-marker.png";
   case "auto accident":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-traffic-accident-marker.png";
   case "stabbing":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-violent-crime-marker.png";
   case "homicide":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-murder.png";
   case "murder":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-murder.png";
   case "hijacking":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-hijacking-marker.png";
   case "smash and grab":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-smash-and-grab.png";
   case "home invasion":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-home-invasion.png";
   case "corruption":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-corruption-marker.png";
   case "drugs":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-drug-deal.png";
   case "other":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-other-marker.png";
   case "sos":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-SOS-inactive.png";
   case "active sos":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-SOS.png";
   case "checkpoint":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-checkpoint-marker.png";
   case "robbery":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-robbery-marker.png";
   case "arms & ammunition":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-arms-ammunition-marker.png";
   case "terrorism":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-terrorism-marker.png";
   case "rioting":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-rioting-marker.png";
   case "police response":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-police-response-marker.png";
   case "fraud":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-fraud-marker.png";
   case "vandalism":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-police-response-marker.png";
   case "disorderly conduct":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-disorderly_conduct-marker.png";
   case "property damage":
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-property_damage-marker.png";
   default:
       console.log("unhandled marker image", eventType);
       return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-other-marker.png";
}
};
