import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText
} from "@material-ui/core";
import { Form, FormContainer, Section, TextInput } from "./styled";
import { createClientUser, getClientDisplayData } from "../types/api/admin";
import { useThrow } from "../catch";
import { ClientDisplayData, DisplayClient, UserFeatureSelection } from "../types/client";
import { Autocomplete, Alert } from "@material-ui/lab";

export const CreateUser = () => {
  const error = useThrow();

  const featureOptions = [
    "SOS Button",
    "Add Report Button", 
    "Filtering", 
    "Team Reports Menu", 
    "Teams Menu",
    "Analytics Menu",
    "Advanced Analytics Menu",
    "Forecasting Menu",
    "Public Insights Menu",
    "Newsfeed Menu"
  ];

  const [clientData, updateClientData] = useState<DisplayClient[]>([]);
  const [email, updateEmail] = useState<string>("");
  const [firstName, updateFirstName] = useState<string>("");
  const [lastName, updateLastName] = useState<string>("");
  const [clientId, updateClientId] = useState<string>("");
  const [password, updatePassword] = useState<string>("");
  const [admin, updateAdmin] = useState<string>("staff");
  const [inviteUser, updateInviteUser] = useState<string>("now");
  const [filterTimeRange, updateFilterTimeRange] = useState<string>("28 Day")
  const [userFeatures, updateUserFeatures] = useState<string[]>([
    "SOS Button",
    "Add Report Button", 
    "Filtering", 
    "Team Reports Menu", 
    "Teams Menu",
    "Analytics Menu",
    "Advanced Analytics Menu",
    "Forecasting Menu",
    "Public Insights Menu",
    "Newsfeed Menu"
  ]);
  const [message, setMessage] = useState<string>("");

  const [openSuccessSnackbar, setOpenSucessSnackbar] = useState(false);
  const displaySuccessSnackbar = () => {
    setOpenSucessSnackbar(true);
  };
  // const handleCloseSuccessSnackbar = () => {
  //   setOpenSucessSnackbar(false);
  // };
  const [openWarningSnackbar, setOpenWarningSnackbar] = useState(false);
  const displayWarningSnackbar = () => {
    setOpenWarningSnackbar(true);
  };
  // const handleCloseWarningSnackbar = () => {
  //   setOpenWarningSnackbar(false);
  // };
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const displayErrorSnackbar = () => {
    setOpenErrorSnackbar(true);
  };
  // const handleCloseErrorSnackbar = () => {
  //   setOpenErrorSnackbar(false);
  // };
  // const [errorString, setErrorString] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const response: ClientDisplayData = await getClientDisplayData();
        updateClientData([...response.clientData]);
      } catch (err) {
        error(err);
      }
    })();
  }, [error]);

  const resetForm = () => {
    updateEmail("");
    updateFirstName("");
    updateLastName("");
    updatePassword("");
    updateAdmin("staff");
    updateInviteUser("now");
  };

  const submit = () => {
    if (email && firstName && lastName && clientId) {
      const featuresObject: UserFeatureSelection = {
        sosButton: true,
        addReportButton: true,
        filtering: true,
        filterTimeRangeDefault: "28 Day",
        teamReportsMenu: true,
        teamsMenu: true,
        analyticsMenu: true,
        analyticsAdvancedMenu: true,
        forecastingMenu: true,
        publicInsightsMenu: true,
        newsfeedMenu: true
      }

      if (!userFeatures.includes("SOS Button")){
        featuresObject.sosButton = false;
      } if (!userFeatures.includes("Add Report Button")){
        featuresObject.addReportButton = false;
      } if (!userFeatures.includes("Team Reports Menu")){
        featuresObject.teamReportsMenu = false;
      } if (!userFeatures.includes("Teams Menu")){
        featuresObject.teamsMenu = false;
      } if (!userFeatures.includes("Analytics Menu")){
        featuresObject.analyticsMenu = false;
      } if (!userFeatures.includes("Advanced Analytics Menu")){
        featuresObject.analyticsAdvancedMenu = false;
      } if (userFeatures.includes("Forecasting Menu")){
        featuresObject.forecastingMenu = true;
      } if (!userFeatures.includes("Public Insights Menu")){
        featuresObject.publicInsightsMenu = false;
      } if (!userFeatures.includes("Newsfeed Menu")){
        featuresObject.newsfeedMenu = false;
      } if (!userFeatures.includes("Filtering")){
        featuresObject.filtering = false;
        featuresObject.filterTimeRangeDefault = null;
      } else if (filterTimeRange === "24 Hour") {
        featuresObject.filterTimeRangeDefault = "24 Hour";
      } else if (filterTimeRange === "7 Day") {
        featuresObject.filterTimeRangeDefault = "7 Day";
      }
      
      const createClientUserObject = {
        user: {
          email: email,
          firstname: firstName,
          lastname: lastName,
          client_id: Number(clientId),
          password: inviteUser === "now" ? password : null,
          role: admin === "admin" ? Number(1) : Number(0),
          features: featuresObject
        },
        invite: inviteUser === "now" ? false : true,
      };
      
      createClientUser(createClientUserObject)
        .then(() => {
            setMessage("Successfully created user");
            displaySuccessSnackbar();
            resetForm();
        })
        .catch((errorMsg) => {
          setMessage(errorMsg.message || "Unknown error. Please let the dev team know about this :)");
          displayErrorSnackbar();
        });
    } else {
        setMessage("Please fill all required fields");
        displayWarningSnackbar();
    }
  };

  return (
    <FormContainer>
      <Form noValidate autoComplete="off">
        <Section>
          <TextInput
            id="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => updateEmail(e.target.value)}
          />
          <TextInput
            id="first-name"
            label="First Name"
            variant="outlined"
            value={firstName}
            onChange={(e) => updateFirstName(e.target.value)}
          />
          <TextInput
            id="last-name"
            label="Last Name"
            variant="outlined"
            value={lastName}
            onChange={(e) => updateLastName(e.target.value)}
          />
          <Autocomplete
            disableClearable
            id="client-name-input"
            options={clientData}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextInput
                {...params}
                label="Client Name (required)"
                variant="outlined"
              />
            )}
            onChange={(event, value) => {
              updateClientId(value?.id.toString() as string);}}
          />
          <FormControl component="fieldset" style={{ margin: "20px 0 10px" }}>
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              User Creation Type
            </FormLabel>
            <RadioGroup
              name="invite-user"
              value={inviteUser}
              onChange={(e) => updateInviteUser(e.target.value)}
            >
              <FormControlLabel
                value="now"
                control={<Radio />}
                label="Create User Now"
              />
              <FormControlLabel
                value="invite"
                control={<Radio />}
                label="Invite User"
              />
            </RadioGroup>
          </FormControl>
          {inviteUser === "now" && (
            <TextInput
              id="password"
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => updatePassword(e.target.value)}
            />
          )}
          <FormControl component="fieldset" style={{ margin: "20px 0 10px" }}>
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              User Role
            </FormLabel>
            <RadioGroup
              name="user-role"
              value={admin}
              onChange={(e) => updateAdmin(e.target.value)}
            >
              <FormControlLabel
                value="staff"
                control={<Radio />}
                label="Staff"
              />
              <FormControlLabel
                value="admin"
                control={<Radio />}
                label="Admin"
              />
            </RadioGroup>
            <FormControl component="fieldset" style={{maxWidth: 400, margin: "20px 0 10px"}}>
              <InputLabel id="demo-mutiple-checkbox-label">User Features</InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={userFeatures}
                onChange={(event) => updateUserFeatures(event.target.value as string[])}
                input={<Input />}
                //renderValue={(selected) => (selected as string[]).join(', ')}
                renderValue={(selected) => userFeatures.join(', ')}
              >
                {featureOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Checkbox checked={userFeatures.indexOf(option) > -1} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {userFeatures.includes("Filtering") && (
              <FormControl component="fieldset" style={{ margin: "20px 0 10px" }}>
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                  Default Filtering Time Range
                </FormLabel>
                <RadioGroup
                  name="filter-time-range"
                  value={filterTimeRange}
                  onChange={(e) => updateFilterTimeRange(e.target.value)}
                >
                  <FormControlLabel
                    value="24 Hour"
                    control={<Radio />}
                    label="24 Hours"
                  />
                  <FormControlLabel
                    value="7 Day"
                    control={<Radio />}
                    label="7 Days"
                  />
                  <FormControlLabel
                    value="28 Day"
                    control={<Radio />}
                    label="28 Days"
                  />
                </RadioGroup>
              </FormControl>
            )}
          </FormControl>
        </Section>
        <Button variant="contained" color="primary" onClick={submit}>
          Create User
        </Button>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={openSuccessSnackbar}
          onClose={(e) => setOpenSucessSnackbar(false)}
          autoHideDuration={4000}
          message={message}
        >
          <Alert severity="success"variant="filled">
            {message}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={openErrorSnackbar}
          onClose={(e) => setOpenErrorSnackbar(false)}
          autoHideDuration={4000}
          message={message}
        >
          <Alert severity="error"variant="filled">
            {message}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={openWarningSnackbar}
          onClose={(e) => setOpenWarningSnackbar(false)}
          autoHideDuration={4000}
          message={message}
        >
          <Alert severity="warning"variant="filled">
            {message}
          </Alert>
        </Snackbar>
      </Form>
    </FormContainer>
  );
};
