import { default as React,
    useState } from "react";
import styled from "styled-components";
import { Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button, 
    Icon,
    TextField} from "@material-ui/core";
import { Asset } from "../types/case";
import { updateAsset } from "../types/api/admin";

const Container = styled.div`
max-height: 100px;
overflow: hidden;
position: relative;
`;

const SeeMore = styled.a`
position: absolute;
right: 0;
bottom: 0;
color: gray;
text-decoration: none;
padding-left: 2em;
background-image: linear-gradient(to right, #fff0 0, white 2em);

&:hover {
   text-decoration: underline;
}
`;

const FullDescription = styled(DialogContentText)`
white-space: pre-line;
color: white;
`;
interface FormattedAsset {
    index: number,
    assetName: string,
    assetType: string,
    client: string,
    team: string,
    description: string,
    operationInterval: string,
    concerns: string,
    eventTypes: string,
    monetaryValue: number,
    needsReview: string,
}

export default ({props, assetData, setAssetData} : { props:FormattedAsset, assetData:Asset[], setAssetData: (risks : Asset[]) => void }) => {
const [ expanded, setExpanded ] = useState<boolean>(false);
const [ editing, setEditing ] = useState<boolean>(false);
const [ description, setDescription ] = useState<string>(props.description);

const expand = (event: React.SyntheticEvent) => {
   event.preventDefault();
   setExpanded(true);
};

const toggleEditing = () => {
 setEditing(!editing);
}

const onClose = () => setExpanded(false);

const update = async () => {
    if (description !== null) {
        try {
            const oldAsset = assetData.find(asset => asset.id === props.index);
            if (oldAsset !== undefined) {
                let newAsset = {
                    ...oldAsset, description: description
                }
                await updateAsset(newAsset.id as number, newAsset);
                assetData[assetData.indexOf(oldAsset as Asset)] = newAsset;
                setAssetData(assetData);
            }
        } catch (err) {
            throw(err);
        }
    }
    
    setEditing(false);
}

const expansion: React.ReactNode = (
   <>
     <SeeMore onClick={expand}>View/Edit</SeeMore>
     <Dialog open={expanded}
             onClose={onClose}>
       <DialogTitle>
         Description
         <Icon onClick={toggleEditing} style={{marginLeft: 20, paddingTop: 5, height: 25, cursor: "pointer"}}>edit</Icon>
       </DialogTitle>
       <DialogContent>
         {editing &&
           <TextField 
             style={{width: 500}} 
             multiline={true} 
             defaultValue={description}
             onChange={(event) => setDescription(event.target.value)}
           />
         }
         {!editing &&
           <FullDescription>
             {description}
           </FullDescription>
         }
       </DialogContent>
       <DialogActions>
         {editing &&
           <Button onClick={update} color="primary" autoFocus>Update</Button>
         }
         <Button onClick={onClose} color="primary" autoFocus>Close</Button>
       </DialogActions>
     </Dialog>
   </>
);

return (
   <Container>
     {description}
     {expansion}
   </Container>
);
};
