import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { scaleUtc } from "d3-scale";
import { select } from "d3-selection";
import { axisBottom } from "d3-axis";
import { createSelector } from "reselect";
import styled from "styled-components";

const MIN_TICK_WIDTH = 50;

const TimeSliderContainer = styled.svg`
    pointer-events: none;
    position: absolute;

    .axis text {
        font-size: 9px;
        fill: ${(props) => props.theme.orion.scheme.primaryText};
    }

    .axis line,
    .axis path {
        fill: none;
        stroke: ${(props) => props.theme.sliderBarBgd};
        shape-rendering: crispEdges;
        stroke-width: 2;
    }

    .axis .domain {
        display: none;
    }

    .value {
        fill: ${(props) => props.theme.orion.scheme.primaryText};
        font-size: 10px;

        &.start {
            text-anchor: start;
        }

        &.end {
            text-anchor: end;
        }
    }
`;

const height = 30;

class TimeSliderMarker extends Component {
    static propTypes = {
        domain: PropTypes.arrayOf(PropTypes.any).isRequired,
        width: PropTypes.number.isRequired
    };

    componentDidMount() {
        this._updateAxis(this.scaleSelector(this.props));
    }

    componentDidUpdate(prevProps) {
        if (this.scaleSelector(this.props) !== this.scaleSelector(prevProps)) {
            this._updateAxis(this.scaleSelector(this.props));
        }
    }

    xAxis = createRef();

    domainSelector = (props) => props.domain;
    widthSelector = (props) => props.width;
    scaleSelector = createSelector(this.domainSelector, this.widthSelector, (domain, width) =>
        Array.isArray(domain) ? scaleUtc().domain(domain).range([0, width]) : null
    );

    _updateAxis(scale) {
        if (!scale) {
            return;
        }

        // TODO: pass in ticks if interval is defined
        const ticks = Math.floor(this.props.width / MIN_TICK_WIDTH);

        const xAxis = axisBottom(scale).ticks(ticks).tickSize(8).tickPadding(6);

        select(this.xAxis.current).call(xAxis);
    }

    render() {
        return (
            <TimeSliderContainer className="time-slider-marker" width={this.props.width} height={height}>
                <g className="x axis" ref={this.xAxis} transform="translate(0, 0)" />
            </TimeSliderContainer>
        );
    }
}

export default TimeSliderMarker;
