import { CompositeLayer } from "@deck.gl/core";
import { ScatterplotLayer } from "@deck.gl/layers";
import MultipleIconsLayer from "./multiple-icons-layer";
import { getMarkerColor } from "../utils";

export default class ZoomIconLayer extends CompositeLayer {
    shouldUpdateState({ changeFlags }) {
        return changeFlags.somethingChanged;
    }

    updateState() {
        this.setState({ stateUpdated: true });
    }

    renderLayers() {
        const {
            data,
            getPosition,
            visible,
            filled,
            getRadius,
            getLineColor,
            colors,
            transitionZoom,
            transitionRadius,
            screenMaxRadius
        } = this.props;

        const { zoom } = this.context.viewport;

        const showDots = transitionZoom
            ? zoom < transitionZoom
            : transitionRadius
            ? screenMaxRadius > transitionRadius
            : true;

        const layers = [
            new ScatterplotLayer(
                this.getSubLayerProps({
                    id: `scatterplot`,
                    data,
                    getPosition,
                    getLineColor,
                    radiusMinPixels: 5,
                    radiusMaxPixels: 5,
                    filled,
                    visible: visible && showDots,
                    getRadius,
                    getFillColor: (d) => {
                        return getMarkerColor(d.properties.category, colors);
                    }
                })
            ),
            new MultipleIconsLayer(
                this.getSubLayerProps({
                    id: "multiple-icons",
                    data,
                    visible: visible && !showDots
                })
            )
        ];
        return layers;
    }
}
