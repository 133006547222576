import { CompositeLayer } from "@deck.gl/core";
import { ScatterplotLayer } from "@deck.gl/layers";
import Supercluster from "supercluster";
import geoViewport from "@mapbox/geo-viewport";
import ZoomIconLayer from "./zoom-icon-layer";
import { eventToCategory, hexToRgb, getMode } from "../utils";

export default class ClusterLayer extends CompositeLayer {
    shouldUpdateState({ changeFlags }) {
        return changeFlags.somethingChanged;
    }

    updateState({ props, oldProps, changeFlags }) {
        this.setState({ stateUpdated: true });
        const rebuildIndex = changeFlags.dataChanged || props.clusterRadius !== oldProps.clusterRadius;

        if (rebuildIndex) {
            const index = new Supercluster({
                maxZoom: props.maxZoom,
                radius: props.clusterRadius,
                reduce: (accumulated, props) => {
                    accumulated.points = [...accumulated.points, ...props.points];
                },
                map: (props) => ({ points: [props.data] })
            });
            index.load(
                props.data.map((d) => ({
                    type: "Point",
                    geometry: { coordinates: props.getPosition(d) },
                    properties: {
                        ...d,
                        data: d,
                        points: [d],
                        point_count: 1,
                        point_count_abbreviated: "1"
                    }
                }))
            );
            this.setState({ index });
        }
        const zoom = Math.round(this.context.viewport.zoom);
        const { longitude, latitude, width, height } = this.context.viewport;
        const bbox = geoViewport.bounds([longitude, latitude], zoom, [width, height]);
        if (rebuildIndex || bbox !== this.state.bbox) {
            this.setState({
                data: this.state.index.getClusters(bbox, zoom),
                bbox
            });
        }
    }

    getPickingInfo({ info, sourceLayer }) {
        if (info.object && info.object.properties && info.object.properties.cluster) {
            const expansionZoom = Math.min(
                this.state.index.getClusterExpansionZoom(info.object.properties.cluster_id),
                15
            );
            return { ...info, sourceLayer, expansionZoom };
        }
        return { ...info, sourceLayer };
    }

    onClick(object) {
        this.props.onClick(object, this.context.viewport);
    }

    renderLayers() {
        const { data } = this.state;
        const { clusterRadius, colors, maxZoom, minClusterSize = 2, transitionRadius, visible } = this.props;
        const { zoom, metersPerPixel } = this.context.viewport;
        const radiusScale = metersPerPixel * (Math.pow(Math.max(zoom, 2), 1.5) / 5);
        const getMarkerColor = (category) => {
            switch (category) {
                case "Violent":
                    return hexToRgb(colors.scheme.analyticsPieViolent);
                case "Non-Violent":
                    return hexToRgb(colors.scheme.analyticsPieNonviolent);
                case "Hazard":
                    return hexToRgb(colors.scheme.analyticsPieHazard);
                case "Other":
                    return hexToRgb(colors.scheme.analyticsPieOther);
                default:
                    return hexToRgb(colors.scheme.analyticsPieOther);
            }
        };

        const clusterData = data.filter((x) => x.properties.cluster && x.properties.point_count >= minClusterSize);
        const getUnclusteredData = () => {
            const points = data.filter((x) => !x.properties.cluster);
            const clusters = data.filter((x) => x.properties.cluster && x.properties.point_count < minClusterSize);
            const newPoints = [];
            for (var cluster of clusters) {
                for (var point of cluster.properties.points) {
                    const pointGeoJSON = {
                        type: "Point",
                        geometry: {
                            coordinates: [point.longitude, point.latitude]
                        },
                        properties: point
                    };
                    newPoints.push(pointGeoJSON);
                }
            }
            const allPoints = points.concat(newPoints);
            return allPoints;
        };

        const screenMaxRadius = Math.max(
            0,
            ...clusterData.map((cluster) => radiusScale * Math.sqrt(cluster.properties.point_count))
        );

        return [
            new ScatterplotLayer(
                this.getSubLayerProps({
                    id: `cluster`,
                    data: clusterData,
                    opacity: 0.5,
                    clusterRadius,
                    maxZoom,
                    getPosition: (d) => d.geometry.coordinates,
                    onClick: this.onClick,
                    getRadius: (d) =>
                        Math.max(
                            radiusScale * Math.sqrt(d.properties.cluster ? d.properties.point_count : 1),
                            metersPerPixel * 7
                        ),
                    getFillColor: (d) => {
                        const mode = getMode(d.properties.points.map((x) => eventToCategory(x.markerTitle.includes("-public") ? x.markerTitle.substr(0,x.markerTitle.length-7) : x.markerTitle)));
                        return getMarkerColor(mode);
                    }
                })
            ),
            new ZoomIconLayer(
                this.getSubLayerProps({
                    id: `zoomIcon`,
                    data: getUnclusteredData(),
                    visible,
                    getPosition: (d) => d.geometry.coordinates,
                    onClick: this.onClick,
                    filled: true,
                    getRadius: 1,
                    colors,
                    getLineColor: (d) => [0, 0, 0],
                    getLineWidth: (d) => 2,
                    screenMaxRadius,
                    transitionRadius
                })
            )
        ];
    }
}
