import React from "react";
import { FilterContainer, VerticallyCenteredRow } from "../_shared";
import { useDispatch } from "react-redux";
import { useSelectFromRedux } from "../utils/_hooks";
import { visualizationsActions } from "../state/visualizations";

const scale = [
    {
        color: "#5A1846",
        label: "6. Most Dangerous"
    },
    {
        color: "#900C3F",
        label: "5. Dangerous"
    },
    {
        color: "#C70039",
        label: "4. Highly Cautionary"
    },
    {
        color: "#E3611C",
        label: "3. Cautionary"
    },
    {
        color: "#F1920E",
        label: "2. Safe"
    },
    {
        color: "#FFC300",
        label: "1. Safest"
    }
];

export default function Info(props: any) {
    const colors = useSelectFromRedux((state) => state.color);
    const dispatch = useDispatch();

    const { infoOpen } = props;
    return (
        <>
            {infoOpen && (
                <FilterContainer background={colors.scheme.filterModal}>
                    <img
                        src={
                            colors.mode === "light"
                                ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/close-dark.png"
                                : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/close-white.png"
                        }
                        alt="close"
                        style={{
                            position: "absolute",
                            right: "10px",
                            marginTop: "-10px",
                            height: "20px",
                            cursor: "pointer"
                        }}
                        onClick={() => dispatch(visualizationsActions.toggleRiskIndexInfoOpen(false))}
                    />
                    <span style={{ color: colors.scheme.primaryText, fontWeight: "bold" }}>Risk Index</span>
                    <div style={{ fontSize: "14px", color: colors.scheme.primaryText }}>
                        Orion calculates a Risk Index per MGRS grid space based on violent crime patterns, riots,
                        and other disruptive illegal activity. Risk is always calculated relative to the current region.
                    </div>
                    <div style={{ marginLeft: "25%", marginTop: "8px", marginBottom: "20px" }}>
                        {scale.map(({ color, label }, index) => (
                            <VerticallyCenteredRow key={index}>
                                <div
                                    style={{
                                        height: "10px",
                                        width: "10px",
                                        background: color,
                                        marginRight: "10px"
                                    }}
                                />
                                <div style={{ fontSize: "14px", color: colors.scheme.primaryText }}>{label}</div>
                            </VerticallyCenteredRow>
                        ))}
                    </div>
                    <span style={{ color: colors.scheme.primaryText, fontWeight: "bold" }}>Safety Scores</span>
                    <div style={{ fontSize: "14px", color: colors.scheme.primaryText }}>
                        Orion’s Safety Score indicates the relative safety of a given space in terms of percentage.
                        A higher safety score indicates greater safety and ease of access.
                        Safety Scores are absolute, and are not adjusted relative to the current forecasting region (unlike the Risk Index.)
                    </div>
                    {/*<span style={{ color: colors.scheme.primaryText, fontWeight: "bold" }}>Confidence Scores</span>*/}
                    {/*<div style={{ fontSize: "14px", color: colors.scheme.primaryText }}>*/}
                    {/*    {"Orion’s CONFIDENCE MEASURE indicates how well (statistically significant) " +*/}
                    {/*    "the model consistently predicts risk. Four categories depict the elevated confidence: " +*/}
                    {/*    "Highly Confident (=p-value of <0.001), Confident (=p-value of <0.01), " +*/}
                    {/*    "Marginally Confident (=p-value of <0.05)" +*/}
                    {/*    "Not Confident."}*/}
                    {/*</div>*/}
                </FilterContainer>
            )}
        </>
    );
}
