import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  InputLabel,
  Select,
  Input,
  Menu,
  MenuItem,
  Checkbox,
  Toolbar,
  TextField,
  ListItemText,
  SortDirection,
  ButtonBase
} from "@material-ui/core";
import { Form, FormContainer, Section, TextInput, ToggleBarContainer } from "./styled";
import { createClientUser, getClientDisplayData } from "../types/api/admin";
import { useThrow } from "../catch";
import { ClientDisplayData, DisplayClient, UserFeatureSelection } from "../types/client";
import { Autocomplete, Alert } from "@material-ui/lab";
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  LineSeries,
  PieSeries,
  BarSeries,
  Legend,
  Tooltip,
  Title
} from '@devexpress/dx-react-chart-material-ui';
import { getAllPublicReports } from "../types/api/admin";
import { EventTracker } from '@devexpress/dx-react-chart';
import { Row } from "antd";

const Violent = [
  "Gang Activity",
  "Assault",
  "Shooting",
  "Violent Crime",
  "Homicide",
  "Hijacking",
  "Stabbing",
  "Abduction",
  "Rocket Attack",
  "Coordinated Attack",
  "Explosive Weapon",
  "Robbery",
  "Arms & Ammunition",
  "Terrorism",
  "Rioting"
]

const NonViolent = [
  "Protest",
  "Emergency Response",
  "Theft",
  "Smash and Grab",
  "Home Invasion",
  "Corruption",
  "Drugs",
  "Harassment",
  "Police Response",
  "Fraud",
  "Auto Theft",
  "Vandalism",
  "Disorderly Conduct",
  "Property Damage"
]

const Hazard = [
  "Dangerous Terrain",
  "Roadblock",
  "Fire",
  "Police",
  "Checkpoint",
  "Explosion (Accidental)",
  "Auto Accident"
]

const Other = [
  "Other",
  "Suspicious Activity",
  "Suspicious Vehicle",
  "Suspicious Object"
]


export const DisplayFusionCenter = () => {

  console.log("Booting up Fusion Center")

  var tzoffset = (new Date()).getTimezoneOffset() * 60000; // gets YYYY-MM-DD format in LOCAL time (so that you don't skip ahead a day between time zones)

  const currentday = (new Date(Date.now() - tzoffset)).toISOString().slice(0, 10)
  const rawcurrentday = new Date()
  const currentday_spelledout = new Date().toLocaleDateString(navigator.language, {year: 'numeric', month: 'long', day: 'numeric' });

  const [todayrows, settodayrows] = useState<any[]>([]);
  const [filteredcontainers, setfilteredcontainers] = useState<number[]>([1,2,3]);
  const [filteredcontainerselection, setfilteredcontainerselection] = useState<string>("ALL");

  // User Input - Custom Timeframe
  const [customtimeframe, setcustomtimeframe] = useState<number>();

  // Loading State
  const [loadingstate, setloadingstate] = useState<string>()
  const [pieloadingstate, setpieloadingstate] = useState<string>('')
  const [rtloadingstate, setrtloadingstate] = useState<string>('')
  const [rtbarloadingstate, setrtbarloadingstate] = useState<string>('')

  // Time Scale Toggle
  const [selectedtimeframe_dailyoccurr, setselectedtimeframe_dailyoccurr] = useState<number>(7)
  const [selectedtimeframe_pie, setselectedtimeframe_pie] = useState<any>("Today")
  const [selectedtimeframe_rtline, setselectedtimeframe_rtline] = useState<any>("Today")
  const [selectedtimeframe_rtbar, setselectedtimeframe_rtbar] = useState<any>("Today")

  // Daily Public Report Occurrence Plot
  const [dailyoccurrencedata, setdailyoccurrencedata] = useState<any[]>([])

  // Category Pie Chart
  const [pierows, setpierows] = useState<any[]>([])

  // Report Type Composition Plots
  const [rtdata, setrtdata] = useState<any[]>([])
  const [mostcommonrt, setmostcommonrt] = useState<string[]>([])
  const [rtbardata, setrtbardata] = useState<any[]>([])

  const [forcerendervisualsFLAG, setforcerendervisualsFLAG] = useState<string>("")


  // Rounding numbers to three decimals
  const round = (val: number) => {
    return Number(val.toFixed(3));
  };


  useEffect(() => {

    async function getTodaysStats() {
      const todaysreports = await getAllPublicReports(JSON.stringify(currentday))
      settodayrows(filterpagebycontainer(todaysreports))

    }
    getTodaysStats()
    parsedatafordailyoccurrence(7)
    parsedataforRTlineplot(7)
    parsedataforpie(1)
    parsedataforRTbar(2)



  }, [filteredcontainerselection]);


  useEffect(() => {

    parsedataforRTlineplot(7)
    parsedataforpie(1)
    parsedataforRTbar(2)

  }, [forcerendervisualsFLAG])




  function WithoutTime(dateTime: Date) {
    var date = new Date(dateTime.getTime());
    date.setHours(0, 0, 0, 0);
    return date;
}



  async function parsedatafordailyoccurrence(timescaleint: number | undefined) {
    if (typeof timescaleint !== 'undefined'){

    setloadingstate("LOADING....")
    setselectedtimeframe_dailyoccurr(timescaleint)


    let occurrencedata = []
    const adjustedstartingdate = WithoutTime(new Date(rawcurrentday.setDate(rawcurrentday.getDate()+1)))
    for (let t = 0; t < timescaleint; t++){
      const newdate = WithoutTime(new Date(adjustedstartingdate.setDate(adjustedstartingdate.getDate()-1)))
      const finaldate = newdate.toISOString().split('T')[0]
      const rawreps = await getAllPublicReports(finaldate)
      const reps = filterpagebycontainer(rawreps)
      const lengthnum = reps.length

      const violentnum = reps.filter((repsrow: any) => Violent.includes(repsrow.report_type)).length
      const nonviolentnum = reps.filter((repsrow: any) => NonViolent.includes(repsrow.report_type)).length
      const hazardnum = reps.filter((repsrow: any) => Hazard.includes(repsrow.report_type)).length

      occurrencedata.push({"date": finaldate, "daily_occurrence": lengthnum, "violent_occurrence": violentnum, "nonviolent_occurrence": nonviolentnum, "hazard_occurrence": hazardnum})
    }

    setloadingstate('')
    setdailyoccurrencedata(tickspacing(occurrencedata.reverse(), timescaleint))

  }
  }

  async function parsedataforpie(timescaleint: number | undefined) {
    if (typeof timescaleint !== 'undefined') {

    setpieloadingstate("LOADING...")

    if (timescaleint == 1) setselectedtimeframe_pie("Today")
    if (timescaleint > 1) setselectedtimeframe_pie(`Previous ${timescaleint} Days`)

    let piereadyrows: any = []
    const adjustedstartingdate = WithoutTime(new Date())
    for (let t = 0; t < timescaleint; t++){
      const newdate = WithoutTime(new Date(adjustedstartingdate.setDate(adjustedstartingdate.getDate()-1)))
      const finaldate = newdate.toISOString().split('T')[0]
      const newrows = await getAllPublicReports(finaldate)

      newrows.forEach((row: any) => {
        piereadyrows.push(row)
      });
    }

    setpieloadingstate('')
    setpierows(filterpagebycontainer(piereadyrows))

  }
  }


  async function findmostcommonreporttypes(mostCommonK: number, timeperiod: number) {

    setrtloadingstate("FETCHING REPORT TYPE DATA...")
    setrtbarloadingstate("FETCHING REPORT TYPE DATA...")

    const getFrequency = (array: string[]) => {
      const map: any = {};
      array.forEach((item: string) => {
         if(map[item]){
            map[item]++;
         }else{
            map[item] = 1;
         }
      });
      return map;
   };

   const getKMostCommon = (array: string[], k: number) => {
    const freqmap = getFrequency(array)
    const converteditems = Object.keys(freqmap).map((key) => { return [key, freqmap[key]] });
    const sortedfreqmap = converteditems.sort((first: any, second: any) => { return first[1] - second[1] });
    const sortedreporttypes = sortedfreqmap.map((e: any) => { return e[0] });
    return sortedreporttypes.slice(sortedreporttypes.length - k)
   }

    let reporttypes: string[] = []
    const adjustedstartingdate = WithoutTime(new Date())
    for (let t = 0; t < timeperiod; t++){
      const newdate = WithoutTime(new Date(adjustedstartingdate.setDate(adjustedstartingdate.getDate()-1)))
      const finaldate = newdate.toISOString().split('T')[0]
      const rows = await getAllPublicReports(finaldate)
      const newrows = filterpagebycontainer(rows)

      newrows.forEach((row: any) => {
        reporttypes.push(row.report_type)
      });
    }

    const mostcommon = getKMostCommon(reporttypes, mostCommonK)
    setrtloadingstate('')
    setrtbarloadingstate('')

    return mostcommon

  }


  async function parsedataforRTlineplot(timescaleint: number | undefined) {
    if (typeof timescaleint !== 'undefined'){

    setrtloadingstate("LOADING....")
    setselectedtimeframe_rtline(timescaleint)


    let rtoccurrencedata = []
    const adjustedstartingdate = WithoutTime(new Date)
    const mostcommonrt = await findmostcommonreporttypes(6, timescaleint)

    for (let t = 0; t < timescaleint; t++){
      const newdate = WithoutTime(new Date(adjustedstartingdate.setDate(adjustedstartingdate.getDate()-1)))
      const finaldate = newdate.toISOString().split('T')[0]
      const rawreps = await getAllPublicReports(finaldate)
      const reps = filterpagebycontainer(rawreps)
      const lengthnum = reps.length
      let daylst = {"date": finaldate}

      for (let j=0; j<mostcommonrt.length; j++) {
        const rtcount = (reps.filter((repsrow: any) => JSON.stringify(repsrow.report_type) === JSON.stringify(mostcommonrt[j]))).length
        const rtpercentage = rtcount / lengthnum
        const rtname: string = mostcommonrt[j]
        const newdict = {[rtname]: round(rtpercentage)}
        Object.assign(daylst, newdict)

      }
      rtoccurrencedata.push(daylst)

    }
    setrtloadingstate('')
    setrtdata(rtoccurrencedata.reverse())

    const keylst = Object.keys(rtoccurrencedata[0])
    setmostcommonrt(Object.keys(rtoccurrencedata[0]))


  }
  }


  async function parsedataforRTbar(timescaleint: number | undefined) {
    if (typeof timescaleint !== 'undefined') {

    setrtbarloadingstate("LOADING...")

    if (timescaleint == 2) setselectedtimeframe_rtbar("Today")
    if (timescaleint > 2) setselectedtimeframe_rtbar(`Previous ${timescaleint} Days`)

    const mostcommonrt = await findmostcommonreporttypes(12, timescaleint)
    let finalbardata = []

    let barreadyrows: any = []
    //const adjustedstartingdate = WithoutTime(new Date(rawcurrentday.setDate(rawcurrentday.getDate()+1)))
    const currentday = new Date()
    const adjustedstartingdate = WithoutTime(new Date())

    for (let t = 0; t < timescaleint; t++){
      const newdate = WithoutTime(new Date(adjustedstartingdate.setDate(adjustedstartingdate.getDate()-1)))
      const finaldate = newdate.toISOString().split('T')[0]
      const rows = await getAllPublicReports(finaldate)
      const newrows = filterpagebycontainer(rows)

      newrows.forEach((row: any) => {
        barreadyrows.push(row)
      });
    }

    for (let j=0; j<mostcommonrt.length; j++) {
      const rtcount = (barreadyrows.filter((repsrow: any) => JSON.stringify(repsrow.report_type) === JSON.stringify(mostcommonrt[j]))).length
      const rtpercentage = rtcount / barreadyrows.length
      const rtname: string = mostcommonrt[j]
      const newdict = {"report_type": rtname, "percentage": round(rtpercentage)}
      finalbardata.push(newdict)

    }

    setrtbarloadingstate('')
    setrtbardata(finalbardata)
  }
  }


  function filterpagebycontainer(rows: any[]) {
    const newrows = rows.filter(row => filteredcontainers.includes(row.container_id))
    return newrows

  }

  function handlecontainerclick(inputcontainer: string) {
    if (inputcontainer === "ALL") {
      setfilteredcontainers([1,2,3])
    }
    if (inputcontainer === "South Africa") {
      setfilteredcontainers([3])
    } 
    if (inputcontainer === "Nigeria") {
      setfilteredcontainers([1])
    }
    if (inputcontainer === "Kenya") {
      setfilteredcontainers([2])
    }
    setfilteredcontainerselection(inputcontainer)
  }


  function tickspacing(inputdata: any[], timeframe: number) { 
    let spacingint = timeframe / 10
    if (timeframe < 15) spacingint = 1
    let emptystr = ''

    inputdata.forEach((row, index) => {
    if (index % spacingint != 0) row.date = emptystr += ' '
    return row
    })

    return inputdata
  }


    return (
      <div>
      <ToggleBarContainer>
        <Button size="large" href={"/news"}>
          NEWS
        </Button>
        <Button color="primary" size="large" href={"/fusionCenter"}>
          HOME
        </Button>
        <Button size="large" href={"/outliers"}>
          OUTLIERS
        </Button>
      </ToggleBarContainer>
      <ToggleBarContainer>
      <Button onClick={() => handlecontainerclick("ALL")} >
          ALL
      </Button>
      <Button onClick={() => handlecontainerclick("South Africa")} >
          South Africa
      </Button>
      <Button onClick={() => handlecontainerclick("Kenya")} >
          Kenya
      </Button>
      <Button onClick={() => handlecontainerclick("Nigeria")} >
          Nigeria
      </Button>
      </ToggleBarContainer>
      <ToggleBarContainer>
          COUNTRY: {filteredcontainerselection}
      </ToggleBarContainer>
      <div style={{height: "40px"}} />
      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '50px' }} >
        WELCOME!
      </div>
      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '30px' }} >
        Today is {currentday_spelledout}
      </div>
      <div style={{height: "30px"}} />
      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '30px' }} >
        There have been {todayrows.length} incidents so far today.
      </div>
      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }} >
        {todayrows.filter(row => Violent.includes(row.report_type)).length} are violent.
      </div>
      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }} >
        {todayrows.filter(row => NonViolent.includes(row.report_type)).length} are non-violent.
      </div>
      <div style={{ height: 350, width: '100%'}}>
          <div style={{ height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            Daily Amount of Incidents, Previous {selectedtimeframe_dailyoccurr} Days
          </div>
          <div style={{ height: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
            {loadingstate}
          </div>
          <Chart data={dailyoccurrencedata}>
            <ArgumentAxis>
              <Title text="TEST" />
            </ArgumentAxis>
            <ValueAxis>
              <Title text="TEST" />
            </ValueAxis>
            <LineSeries name="Total Reports" valueField="daily_occurrence" argumentField="date" />
            <LineSeries name="Violent Reports" valueField="violent_occurrence" argumentField="date" />
            <LineSeries name="Non-Violent Reports" valueField="nonviolent_occurrence" argumentField="date" />
            <Legend />
            <EventTracker />
            <Tooltip />
          </Chart>
          <ToggleBarContainer>
          <Button size="small" onClick={() => parsedatafordailyoccurrence(7)} >
            7D
          </Button>
          <Button size="small" onClick={() => parsedatafordailyoccurrence(30)} >
            30D
          </Button>
          <Button size="small" onClick={() => parsedatafordailyoccurrence(90)} >
            90D
          </Button>
        </ToggleBarContainer>
        <ToggleBarContainer>
          <TextField size="small" label="Custom Lookback" margin="dense" helperText="Enter number of days" onChange={(e) => setcustomtimeframe(parseInt(e.target.value))} InputProps={{endAdornment: <Button onClick={(e) => parsedatafordailyoccurrence(customtimeframe)} > Enter </Button>}} >
          </TextField>
        </ToggleBarContainer>
      <div style={{ height: 250, width: '75%'}}>
          <div style={{ height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            Composition of Incidents by Category, {selectedtimeframe_pie}
          </div>
          <div style={{ height: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
            {pieloadingstate}
          </div>
        <Chart data={[
          {"category": "Violent", "count": pierows.filter(row => Violent.includes(row.report_type)).length},
          {"category": "Non-Violent", "count": pierows.filter(row => NonViolent.includes(row.report_type)).length},
          {"category": "Hazard", "count": pierows.filter(row => Hazard.includes(row.report_type)).length},
          {"category": "Other", "count": pierows.filter(row => Other.includes(row.report_type)).length},
          ]}>
          <PieSeries valueField="count" argumentField="category" />

          <Legend />
        </Chart>
        <ToggleBarContainer>
        <Button size="small" onClick={() => parsedataforpie(1)} >
            TODAY
          </Button>
          <Button size="small" onClick={() => parsedataforpie(7)} >
            7D
          </Button>
          <Button size="small" onClick={() => parsedataforpie(30)} >
            30D
          </Button>
          <Button size="small" onClick={() => parsedataforpie(90)} >
            90D
          </Button>
        </ToggleBarContainer>
        <ToggleBarContainer>
          <TextField size="small" label="Custom Lookback" margin="dense" helperText="Enter number of days" onChange={(e) => setcustomtimeframe(parseInt(e.target.value))} InputProps={{endAdornment: <Button onClick={(e) => parsedataforpie(customtimeframe)} > Enter </Button>}} >
          </TextField>
        </ToggleBarContainer>
        </div>

        <div style={{ height: 450}} />
        <div style={{ height: 350, width: '100%'}}>
          <div style={{ height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            % of Incidents by Report Type Over Time, Previous {selectedtimeframe_rtline} Days
          </div>
          <div style={{ height: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
            {rtloadingstate}
          </div>
          <Chart data={rtdata}>
            <ArgumentAxis />
            <ValueAxis />
            <LineSeries name={`% ${mostcommonrt[1]}`} valueField={mostcommonrt[1]} argumentField="date" />
            <LineSeries name={`% ${mostcommonrt[2]}`} valueField={mostcommonrt[2]} argumentField="date" />
            <LineSeries name={`% ${mostcommonrt[3]}`} valueField={mostcommonrt[3]} argumentField="date" />
            <LineSeries name={`% ${mostcommonrt[4]}`} valueField={mostcommonrt[4]} argumentField="date" />
            <LineSeries name={`% ${mostcommonrt[5]}`} valueField={mostcommonrt[5]} argumentField="date" />
            <LineSeries name={`% ${mostcommonrt[6]}`} valueField={mostcommonrt[6]} argumentField="date" />
            <Legend />
            <EventTracker />
            <Tooltip />
          </Chart>
          <ToggleBarContainer>
          <Button size="small" onClick={() => parsedataforRTlineplot(7)} >
            7D
          </Button>
          <Button size="small" onClick={() => parsedataforRTlineplot(30)} >
            30D
          </Button>
          <Button size="small" onClick={() => parsedataforRTlineplot(90)} >
            90D
          </Button>
        </ToggleBarContainer>
        <ToggleBarContainer>
          <TextField size="small" label="Custom Lookback" margin="dense" helperText="Enter number of days" onChange={(e) => setcustomtimeframe(parseInt(e.target.value))} InputProps={{endAdornment: <Button onClick={(e) => parsedataforRTlineplot(customtimeframe)} > Enter </Button>}} >
          </TextField>
        </ToggleBarContainer>

        </div>
        <div style={{ height: 400}} />
        <div style={{ height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            % of Incidents by Report Type, {selectedtimeframe_rtbar}
          </div>
          <div style={{ height: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>
            {rtbarloadingstate}
          </div>
          <Chart data={rtbardata}>
            <ArgumentAxis />
            <ValueAxis />
            <BarSeries valueField="percentage" argumentField="report_type" />
          </Chart>
          <ToggleBarContainer>
        <Button size="small" onClick={() => parsedataforRTbar(2)} >
            TODAY
          </Button>
          <Button size="small" onClick={() => parsedataforRTbar(7)} >
            7D
          </Button>
          <Button size="small" onClick={() => parsedataforRTbar(30)} >
            30D
          </Button>
          <Button size="small" onClick={() => parsedataforRTbar(90)} >
            90D
          </Button>
        </ToggleBarContainer>
        <ToggleBarContainer>
          <TextField size="small" label="Custom Lookback" margin="dense" helperText="Enter number of days" onChange={(e) => setcustomtimeframe(parseInt(e.target.value))} InputProps={{endAdornment: <Button onClick={(e) => parsedataforRTbar(customtimeframe)} > Enter </Button>}} >
          </TextField>
        </ToggleBarContainer>

      </div>
      </div>


    )


};