import { default as React,
  useState, useEffect } from "react";
import styled from "styled-components";
import { Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button, } from "@material-ui/core";

const Container = styled.div`
max-height: 100px;
overflow: hidden;
position: relative;
`;

const SeeMore = styled.a`
position: absolute;
right: 0;
bottom: 0;
color: gray;
text-decoration: none;
padding-left: 2em;
background-image: linear-gradient(to right, #fff0 0, white 2em);

&:hover {
 text-decoration: underline;
}
`;

const FullDescription = styled(DialogContentText)`
white-space: pre-line;
color: white;
`;
export interface FormattedRisk {
  id: number,
  name: string,
  assets: string,
  eventTypes: string,
  riskInterval: string,
  createdAt: Date,
  severity: number,
  chance: number,
  concerns: string,
  consequences: string,
  containers: string,
  regions: string,
  suggestions: string,
  comments: string
  needsReview: string,
}

export default ({props} : { props:FormattedRisk }) => {
const [ expanded, setExpanded ] = useState<boolean>(false);
const [ consequenceText, setConsequenceText ] = useState<string>(props.consequences);

useEffect(() => {
  setConsequenceText(props.consequences)
},[props])


const expand = (event: React.SyntheticEvent) => {
 event.preventDefault();
 setExpanded(true);
};

const onClose = () => setExpanded(false);

const expansion: React.ReactNode = (
 <>
   <SeeMore onClick={expand}>View</SeeMore>
   <Dialog open={expanded}
           onClose={onClose}>
     <DialogTitle>
       Relevant Consequences
     </DialogTitle>
     <DialogContent>
         <FullDescription>
           {consequenceText}
         </FullDescription>
     </DialogContent>
     <DialogActions>
       <Button onClick={onClose} color="primary" autoFocus>Close</Button>
     </DialogActions>
   </Dialog>
 </>
);

return (
 <Container>
   {consequenceText}
   {expansion}
 </Container>
);
};
