import styled from "styled-components";
import { boxShadow, device } from "../utils";
import { Badge } from "@material-ui/core";
import Spin from "antd/lib/spin";
import Radio from "antd/lib/radio";

export const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export const VerticallyCenteredRow = styled(Row)`
    align-items: center;
`;

export const RoundButton = styled.button`
    z-index: 20;
    position: fixed;
    border-radius: 50%;
    box-shadow: 0px 4px 4px ${boxShadow};
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: none;
`;

interface SpinnerProps {
    background: string;
}

export const LoadingSpinner = styled(Spin)`
    .ant-spin-dot-item {
        background-color: ${(props: SpinnerProps) => props.background} !important;
    }
`;

interface BottomGradientProps {
    background: string;
}

export const BottomGradient = styled.div`
    height: 90px;
    background: linear-gradient(180deg,
        transparent 0%, ${(props: BottomGradientProps) => props.background} 100%);
    };
    width: 100%;
    position: absolute;
    bottom: 18px;
    left: 0;
    @media ${device.mobile} {
        background: linear-gradient(180deg,
            transparent 0%, ${(props: BottomGradientProps) => props.background} 100%);
    }
`;

// interface ModalHeaderProps {
//     color: string;
// }

export const ModalHeader = styled.div`
    height: 30px;
    font-size: 16px;
    color: ${(props) => props.color};
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;
    padding-bottom: 10px;
`;

interface RadioGroupProps {
    selectedColor: string;
    selectedTextColor: string;
    textColor: string;
}

export const RadioGroup = styled(Radio.Group)`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    border: none;

    .ant-radio-group {
        border-radius: 18px;
    }
    .ant-radio-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        color: ${(props: RadioGroupProps) => props.textColor};
        background: transparent !important;
        transition: none;
    }
    .ant-radio-button-wrapper::hover {
        border: none;
        background: transparent !important;
    }
    .ant-radio-button-wrapper::focus {
        border: none;
        background: none !important;
    }
    .ant-radio-button-wrapper-checked {
        height: 32px;
        background: ${(props: RadioGroupProps) => props.selectedColor} !important;
        border-radius: 6px;
        border: none;
        color: ${(props: RadioGroupProps) => props.selectedTextColor} !important;
        margin: 4px;
    }
`;

export const RadioButton = styled(Radio.Button)`
    width: 50%;
    border-radius: 18px;
    height: 40px;
    ::before {
        background: transparent !important;
        border: none;
    }
    ::after {
        background: transparent !important;
        border: none;
    }
`;

interface FormFieldContainerProps {
    background: string;
}

export const FormFieldContainer = styled.div`
    width: 100%;
    min-height: 37px;
    height: 40px;
    background: ${(props: FormFieldContainerProps) => props.background};
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    overflow: hidden;
`;

interface FilterContainerProps {
    background: string;
    boxshadow?: string;
}

export const FilterContainer = styled.div`
    background: ${(props: FilterContainerProps) => props.background};
    width: 95%;
    margin-top: 70px;
    border-radius: 5px;
    padding: 20px;
    position: absolute;
    left: calc(100% + 10px);
    box-shadow: ${(props: FilterContainerProps) => "0px 0px 8px " + props.boxshadow};
    z-index: 100;

    @media ${device.mobile} {
        left: 50%;
        margin-left: -47.5%;
        max-height: calc(100vh - 200px);
    }
`;

interface DotProps {
    background: string;
}

export const Dot = styled(Badge)`
    .MuiBadge-colorPrimary {
        background-color: ${(props: DotProps) => props.background};
    }
`;

export const CloseButton = styled.img`
    position: absolute;
    right: 17px;
    top: 17px;
    padding: 3px;
    cursor: pointer;
    height: 28px;
    transition: 0.3s;

    :active {
        opacity: 0.4;
    }
`;

export const EditButton = styled.img`
    position: absolute;
    right: 55px;
    top: 20px;
    cursor: pointer;
    height: 18px;
    transition: 0.3s;

    :active {
        opacity: 0.4;
    }
`;

interface AddReportButtonProps {
    background: string;
}

export const AddReportButton = styled(RoundButton)`
    background: ${(props: AddReportButtonProps) => props.background};
    position: absolute;
    width: 50px;
    height: 50px;
    top: calc(73vh - 100px);
    right: 10px;

    :active {
        width: 52px;
        height: 52px;
        bottom: 119px;
        right: 9px;
    }

    @media ${device.mobile} {
        bottom: 25px;
        left: 25px;

        :active {
            width: 52px;
            height: 52px;
            bottom: 24px;
            right: 24px;
        }
    }
`;

export const PlusImage = styled.img`
    height: 27px;
`;
