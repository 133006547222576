import React, { useEffect, useState, useRef } from "react";
import { Button, FormControl, InputLabel, Select, Input, MenuItem, Checkbox, FormLabel, TextField, Box, IconButton } from "@material-ui/core";
import { DataGrid, ColDef, ValueGetterParams, CellParams, numberComparer } from "@material-ui/data-grid";
import { Form, FormContainer, Section, ToggleBarContainer,  } from "./styled";
import { getAllDefaultRegions, getPendingTrips, getSummaryData, getTravelUserById, getTripById, getTripDestinations, compileDestinationOutputs, fetchUniversalGeometry } from "../types/api/admin";
import {makeStyles} from '@material-ui/styles';
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  LineSeries,
  BarSeries
} from '@devexpress/dx-react-chart-material-ui';
import { stringify } from "querystring";
import { AnalystGeometry, DestinationOutputs, TravelDestination, TravelPriorityFeedItem, AnalystNote, TravelTrip, TravelUniversalGeometry, TravelIndex, TravelUser } from "../types/travelApp";
import ReactMapboxGl from "react-mapbox-gl";
import * as MapboxGl from "mapbox-gl";
import { FeatureCollection } from "geojson";
import { Layer, Feature, Source, Marker } from 'react-mapbox-gl';
import { DeckGL } from "@deck.gl/react";
import { StaticMap } from "react-map-gl";
import ViewStateProps from "@deck.gl/core/lib/deck";
import { GeoJsonLayer, LineLayer, IconLayer } from "@deck.gl/layers";
import DrawControl from 'react-mapbox-gl-draw';
import { Redirect } from "react-router-dom";

//import { Slider } from "antd";
import Slider from "@material-ui/core/Slider";
import { approveTrip } from "../types/api/admin";
import { useHistory } from "react-router-dom";
import { TextInput, CheckInput, RoundButton, SearchButtonContainer } from "./styled";
import {
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import { now } from "moment";
import { TravelAccommodation } from "../types/travelApp";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { S3Key } from "../utils";
import { center } from "@turf/turf";


const Geosuggest = require("react-geosuggest").default;


export const DisplayTravelPendingTrips = () => {

    const styles = makeStyles((theme) => ({
        root: {
          borderLeft: 0,
          borderRight: 0,
          borderBottom: 0,
          color: 'black',
          backgroundColor: 'grey'
        },
      }));
    const classes = styles()

    const [pendingTrips, setPendingTrips] = useState<TravelTrip[]>([]);
    //const [travelOwner, setTravelOwner] = useState<any[]>();
    const [currentTripInfo, setCurrentTripInfo] = useState<string[]>([]);
    const [tripLoadingStatus, setTripLoadingStatus] = useState<string>("none selected");
    const [tripCompilingStatus, setTripCompilingStatus] = useState<string>("");

    const [kdeNonviol, setKdeNonviol] = useState<FeatureCollection>();
    const [kdeViol, setKdeViol] = useState<FeatureCollection>();
    const [rtProp, setRtProp] = useState<JSON[]>();

    const [destinationsLeft, setDestinationsLeft] = useState<number>();
    const [currentDestination, setCurrentDestination] = useState<TravelDestination>();
    const [currentDestinationIndex, setCurrentDestinationIndex] = useState<number>();

    const [tripOutputs, setTripOutputs] = useState<any[]>();
    const [tripDestinations, setTripDestinations] = useState<TravelDestination[]>();

    const [mapCenter, setMapCenter] = useState<[number, number]>([50,50]);

    const [selectedFeatureIndexes] = useState([]);

    const [kdeMode, setKdeMode] = useState<string>("viol");
    const [mapMode, setMapMode] = useState<string>("scroll");

    const [destIndices, setDestIndices] = useState<TravelIndex[]>();

    // Destination submission inputs
    const [noteText, setNoteText] = useState<string>("");
    const [noteTitle, setNoteTitle] = useState<string>("")
    const [analystNotes, setAnalystNotes] = useState<AnalystNote[]>([]);

    const [radiusVal, setRadiusVal] = useState<number>(0.75);
    const [currentUserId, setCurrentUserId] = useState<number>();
    const [currentUserProf, setCurrentUserProf] = useState<TravelUser>();
    const [currentTripId, setCurrentTripId] = useState<number>();
    const [recompileLoading, setRecompileLoading] = useState<string>("");

    // Saving final info in state for submission
    const [savedTripOutputs, setSavedTripOutputs] = useState<any[]>();
    const [submitTripOutputs, setSubmitTripOutputs] = useState<any[]>();

    const [negateKdeViol, setNegateKdeViol] = useState<number>(0);
    const [negateKdeNonViol, setNegateKdeNonViol] = useState<number>(0);

    const [approvalLoading, setApprovalLoading] = useState<string>("");

    const [universalGeom, setUniversalGeom] = useState<any[]>([])
    const [addedGeom, setAddedGeom] = useState<any[]>([])
    const [analystGeom, setAnalystGeom] = useState<AnalystGeometry[]>([])

    const [menuOpen, setMenuOpen] = useState<number>(0);
    const [addFormTitle, setAddFormTitle] = useState<string>("");
    const [addFormType, setAddFormType] = useState<string>("");
    const [newsSchema, enableNewsSchema] = useState<number>(0);
    const [openGeomInfo, setOpenGeomInfo] = useState<number>(0);
    const [openAddedGeomInfo, setOpenAddedGeomInfo] = useState<number>(0);

    const [showUniv, setShowUniv] = useState<number>(0);
    const [showKde, setShowKde] = useState<number>(0);

    const [geomInfo, setGeomInfo] = useState<string[]>([]);
    const [selectedFeat, setSelectedFeat] = useState<Feature>();

    const [userAccommodations, setUserAccommodations] = useState<TravelAccommodation[]>([]);

    const [feedItems, setFeedItems] = useState<TravelPriorityFeedItem[]>([]);

    const [includeReason, setIncludeReason] = useState<string>("");

    const [openLink, setOpenLink] = useState<number>(0);
    const [openLinkSelection, setOpenLinkSelection] = useState<number>(0);

    const [selectedLinkHost, setSelectedLinkHost] = useState<TravelPriorityFeedItem | null>();
    const [linkType, setLinkType] = useState<string>("");
    const [linkTargetOptions, setLinkTargetOptions] = useState<any[]>([]);
    const [linkTarget, setLinkTarget] = useState<any>();

    const [subError, setSubError] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [showSearchBar, updateShowSearchBar] = useState<boolean>(false);
    const [openAddResult, setOpenAddResult] = useState<number>(0);
    const [searchResLoc, setSearchResLoc] = useState<number[]>([0,0])

    const [loadingGeomViewAdd, setLoadingGeomViewAdd] = useState<string>("")


    const CloseWhiteIcon = S3Key + "close-white.png";
    const SearchWhiteIcon = S3Key + "search-white.png";
    const geosuggestEl = useRef<any>(null);


    useEffect(() => {

        const callData = async () => {

            const pending = await getPendingTrips();
            setPendingTrips(pending);

            const universalGeom = await fetchUniversalGeometry();
            setUniversalGeom(universalGeom)
        }

        callData();

    }, []);

    const MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoidmVyc3RhYW4iLCJhIjoiY2s0YW12d3lsMDVjczNtcW5ramN0YzZ5byJ9.LaDF5JL90rFiTxgMerv_Yg"
    
    const ICON_MAPPING = {
      marker: {x: 0, y: 0, width: 128, height: 128, mask: true}
    };

    const layers = [new GeoJsonLayer({
      id: `kde`,
      //@ts-ignore
      data: (showKde == 1) ? ((kdeMode === "nonviol") ? kdeNonviol : kdeViol) : [],
      //mode: drawMode === 'line' ? DrawLineStringMode :
                 //drawMode === 'polygon' ? DrawPolygonMode :  DrawPointMode,
      selectedFeatureIndexes,
      getLineWidth: 0,
      getFillColor: ((kdeMode === "nonviol") ? [0,0,255] : [255,0,0]),
      opacity: .001,
      //visible: addRisk !== undefined || changeGeo,
      //pickable: addRisk !== undefined || changeGeo,
      //onEdit: ({ updatedData } : any) => {
          //if(updatedData.features[0]) dispatch(mapActions.setGeography(updatedData.features[0].geometry));
      //}
  }),
      new IconLayer({
        id: 'icon-layer',
        data: userAccommodations,
        pickable: true,
        // iconAtlas and iconMapping are required
        // getIcon: return a string
        iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
        iconMapping: ICON_MAPPING,
        getIcon: d => 'marker',
        sizeScale: 15,
        getPosition: d => d.point.coordinates as [number, number],
        getSize: 5,
        getColor: [255,255,255]
      }),
      new GeoJsonLayer({
        id: `unigeom`,
        //@ts-ignore
        data: (showUniv == 1) ? universalGeom : [],
        //mode: drawMode === 'line' ? DrawLineStringMode :
                   //drawMode === 'polygon' ? DrawPolygonMode :  DrawPointMode,
        //selectedFeatureIndexes,
        getLineWidth: 5,
        getFillColor: (geom: any) => {
          if (geom.properties.type === "safety_polygon" || geom.properties.type === "safety_point") return [0,255,0]
          else if (geom.properties.type === "violent_polygon" || geom.properties.type === "violent_point" ) return [255,0,0]
          else if (geom.properties.type === "nonviolent_polygon" || geom.properties.type === "nonviolent_point" ) return [0,0,255]
          else if (geom.properties.type === "geonews_point" ) return [50,50,100]
          else return [50,50,50]
        },
        opacity: .02,
        pickable: true,
        onClick: (info, event) => {
          // @ts-ignore
          const feat = info.object
          // @ts-ignore
          const props = info.object.properties
          handleGeomClick(props.id.toString(), props.type, props.category, props.description, props.tags, props.time_bins, feat as Feature)

        }
        //visible: addRisk !== undefined || changeGeo,
        //pickable: addRisk !== undefined || changeGeo,
        //onEdit: ({ updatedData } : any) => {
            //if(updatedData.features[0]) dispatch(mapActions.setGeography(updatedData.features[0].geometry));
        //}
      }),
      new IconLayer({
        id: 'unipoints',
        data: (showUniv == 1) ? universalGeom.filter((geom) => (geom.properties.type === "safety_point" || geom.properties.type === "violent_point" || geom.properties.type === "nonviolent_point" || geom.properties.type === "geonews_point" || geom.properties.type === "analystnote_point")) : [],
        pickable: true,
        // iconAtlas and iconMapping are required
        // getIcon: return a string
        iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
        iconMapping: ICON_MAPPING,
        getIcon: d => 'marker',
        sizeScale: 15,
        // @ts-ignore
        getPosition: d => d.geometry.coordinates,
        getSize: 5,
        opacity: .05,
        getColor: (geom: any) => {
            if (geom.properties.type === "safety_polygon" || geom.properties.type === "safety_point") return [0,255,0]
            else if (geom.properties.type === "violent_polygon" || geom.properties.type === "violent_point" ) return [255,0,0]
            else if (geom.properties.type === "nonviolent_polygon" || geom.properties.type === "nonviolent_point" ) return [0,0,255]
            else if (geom.properties.type === "geonews_point" ) return [50,50,100]
            else return [50,50,50]
        },
        onClick: (info, event) => {
          // @ts-ignore
          const feat = info.object
          // @ts-ignore
          const props = info.object.properties
          handleGeomClick(props.id.toString(), props.type, props.category, props.description, props.tags, props.time_bins, feat as Feature)

        }
        }),
        new GeoJsonLayer({
          id: `geom`,
          //@ts-ignore
          data: addedGeom,
          //mode: drawMode === 'line' ? DrawLineStringMode :
                     //drawMode === 'polygon' ? DrawPolygonMode :  DrawPointMode,
          //selectedFeatureIndexes,
          getLineWidth: 5,
          getFillColor: (geom: any) => {
            if (geom.properties.type === "safety_polygon" || geom.properties.type === "safety_point") return [0,255,0]
            else if (geom.properties.type === "violent_polygon" || geom.properties.type === "violent_point" ) return [255,0,0]
            else if (geom.properties.type === "nonviolent_polygon" || geom.properties.type === "nonviolent_point" ) return [0,0,255]
            else if (geom.properties.type === "geonews_point" ) return [50,50,100]
            else return [50,50,50]
          },
          opacity: .1,
          pickable: true,
          onClick: (info, event) => {
            // @ts-ignore
            const feat = info.object
            console.log(feat)
            // @ts-ignore
            const props = info.object.properties
            console.log(props)
            handleAddedGeomClick(props.id.toString(), props.type, props.category, props.description, props.tags, props.time_bins, feat as Feature)
  
          }
          //visible: addRisk !== undefined || changeGeo,
          //pickable: addRisk !== undefined || changeGeo,
          //onEdit: ({ updatedData } : any) => {
              //if(updatedData.features[0]) dispatch(mapActions.setGeography(updatedData.features[0].geometry));
          //}
        }),
        new IconLayer({
          id: 'points',
          data: addedGeom.filter((geom) => (geom.properties.type === "safety_point" || geom.properties.type === "violent_point" || geom.properties.type === "nonviolent_point" || geom.properties.type === "geonews_point" || geom.properties.type === "analystnote_point")),
          //pickable: true,
          // iconAtlas and iconMapping are required
          // getIcon: return a string
          iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
          iconMapping: ICON_MAPPING,
          getIcon: d => 'marker',
          sizeScale: 15,
          // @ts-ignore
          getPosition: d => d.geometry.coordinates,
          getSize: 5,
          opacity: .5,
          onClick: (info, event) => {
            // @ts-ignore
            const feat = info.object
            // @ts-ignore
            const props = info.object.properties
            handleGeomClick(props.id.toString(), props.type, props.category, props.description, props.tags, props.time_bins, feat as Feature)
  
          },
          
          getColor: (geom: any) => {
              if (geom.properties.type === "safety_polygon" || geom.properties.type === "safety_point") return [0,255,0]
              else if (geom.properties.type === "violent_polygon" || geom.properties.type === "violent_point" ) return [255,0,0]
              else if (geom.properties.type === "nonviolent_polygon" || geom.properties.type === "nonviolent_point" ) return [0,0,255]
              else if (geom.properties.type === "geonews_point" ) return [50,50,100]
              else return [50,50,50]
          }
          })
  ]

    async function resetFields() {
      setTripCompilingStatus("");
      setTripLoadingStatus("");
      setKdeNonviol(undefined);
      setKdeViol(undefined);
      setRtProp(undefined);
      setFeedItems([]);
      setAnalystNotes([]);
      setNegateKdeNonViol(0);
      setNegateKdeViol(0);
      setRadiusVal(.75)
    }

    async function handleReviewTrip(cellValues: any) {

      // Reset fields
      await resetFields();

      // Set currently selected trip
      const data = cellValues.data;
      const tripinfo: string[] = [data.trip_name, data.user_name]
      setCurrentTripInfo(tripinfo)

      // Fetch user, trip, and destination info
      setTripCompilingStatus("Loading user and trip information...")
      
      const traveluser: TravelUser = (await getTravelUserById(data.user_id))[0];
      const trip: TravelTrip = await getTripById(data.trip_id);
      const destinations: TravelDestination[] = await getTripDestinations(data.trip_id);

      console.log(traveluser)

      setCurrentUserProf(traveluser);

      setCurrentTripId(data.trip_id);
      setCurrentUserId(data.user_id);

      setTripDestinations(destinations)

      if (destinations.length > 0) {

        // Compile
        setTripCompilingStatus("Compiling outputs...")
        const compiledOutputs = await compileDestinationOutputs(data.user_id, data.trip_id, .75);

        console.log(compiledOutputs)

        setTripOutputs(compiledOutputs)
        setCurrentDestinationIndex(0)
        setDestinationsLeft(destinations.length)

      } else {

        setTripCompilingStatus("No destinations found yet for this trip!")

      }
      
    }

    async function recompileDestOutputs() {

      if (typeof currentUserId !== "undefined" && typeof currentTripId !== "undefined" && typeof currentDestination !== "undefined") {

        setRecompileLoading("RECOMPILING... HOLD ON!")

        const compiledOutputs = await compileDestinationOutputs(currentUserId, currentTripId, radiusVal)

        const output = (await compiledOutputs.filter((output: any) => output.id == currentDestination.id))[0]

        setRtProp(output.rt_prop);
        if (typeof output.kde_violent === "string") setKdeViol(JSON.parse(output.kde_violent));
        if (typeof output.kde_nonviolent === "string") setKdeNonviol(JSON.parse(output.kde_nonviolent));

        setRecompileLoading("")

      } else {
        console.log("cant recompile")
      }

    }

    async function saveDestinationOutputs() {

      if (currentUserId && currentTripId && currentDestination) {

        const currentStateOutputs = {
          outputs: {
            id: currentDestination.id,
            user_id: currentUserId,
            trip_id: currentTripId,
            kde_violent: (negateKdeViol == 0) ? kdeViol : null,
            kde_nonviolent: (negateKdeNonViol == 0) ? kdeNonviol : null,
            rt_prop: rtProp,
            indices: destIndices,
            analyst_notes: analystNotes,
            secondary: {
              analyst_geometry: analystGeom,
              combined_feed: feedItems,
              // @ts-ignore
              city_insights: tripOutputs[currentDestinationIndex].secondary.city_insights,
              // @ts-ignore
              city_vendors: tripOutputs[currentDestinationIndex].secondary.city_vendors
            }
          },
          settings : {
            prevRadius: radiusVal,
            includedKdeViol: negateKdeViol,
            includedKdeNonViol: negateKdeNonViol
          }
        }
        setSavedTripOutputs([...savedTripOutputs || [], currentStateOutputs])
      }
    }

    async function deleteDestinationOutputs(dest_id: number) {
      if (savedTripOutputs) {
        const newSavedItems = savedTripOutputs.filter((destOutput) => destOutput.outputs.id !== dest_id);
        setSavedTripOutputs(newSavedItems);
      }
    }

    async function isDestPreviouslySaved(dest_id: number) {
      if (savedTripOutputs) {

        const found = savedTripOutputs.filter((destOutput) => destOutput.outputs.id === dest_id);
        
        if (found.length > 0) {
          return true;
        } else {
          return false;
        }

      } else {
        return false;
      }
    }

    async function returnPreviouslySaved(dest_id: number) {
      if (savedTripOutputs) {

        const found = savedTripOutputs.filter((destOutput) => destOutput.outputs.id === dest_id);
        
        return found[0]

      }
    }

    async function submitApproval() {

      setIsSubmitting(true)

      if (currentTripId && currentUserId && currentDestination) {

        setApprovalLoading("Submitting Trip...")

        //await saveDestinationOutputs();

        let savedDestOutputs: DestinationOutputs[] = [];
        if (savedTripOutputs) savedDestOutputs = savedTripOutputs.map((saved) => saved.outputs) as DestinationOutputs[]

        const currentStateOutputs: DestinationOutputs = {

          id: currentDestination.id,
          user_id: currentUserId,
          trip_id: currentTripId,
          // @ts-ignore
          kde_violent: (negateKdeViol == 0) ? kdeViol : null,
          // @ts-ignore
          kde_nonviolent: (negateKdeNonViol == 0) ? kdeNonviol : null,
          rt_prop: rtProp || [],
          // @ts-ignore
          indices: destIndices,
          analyst_notes: analystNotes,
          secondary: {
            analyst_geometry: analystGeom,
            combined_feed: feedItems,
            // @ts-ignore
            city_insights: tripOutputs[currentDestinationIndex].secondary.city_insights,
            // @ts-ignore
            city_vendors: tripOutputs[currentDestinationIndex].secondary.city_vendors
          }
          
        }

        const finalDestOutputs = [...savedDestOutputs, currentStateOutputs]

        console.log(finalDestOutputs)

        const res = await approveTrip(currentUserId, currentTripId, finalDestOutputs)

        return res

        //setApprovalLoading("Submitted!")

      }

      return ""

    }

    async function handleGeomClick(id: string, type: string, category: string, desc: string, tags: string, time_bins: string, feat: Feature) {

      setSelectedFeat(feat)
      setGeomInfo([id, type, category, desc, tags, time_bins])
      setOpenGeomInfo(1)

    }

    async function handleAddedGeomClick(id: string, type: string, category: string, desc: string, tags: string, time_bins: string, feat: Feature) {

      setSelectedFeat(feat)
      setGeomInfo([id, type, category, desc, tags, time_bins])
      setOpenAddedGeomInfo(1)

    }

    async function handleAddUnivAsset(feat: any) {

      const newAnalystGeom: AnalystGeometry = {
        type: feat.properties.type,
        category: feat.properties.category,
        geometry: feat,
        description: feat.properties.description,
        tags: feat.properties.tags,
        time_bins: feat.properties.time_bins,
        reason_for_including: includeReason
      }

      if (!addedGeom.includes(feat)) setAddedGeom([...addedGeom, feat])
      if (!analystGeom.includes(newAnalystGeom)) setAnalystGeom([...analystGeom, newAnalystGeom])

      closeInfoText()

    }

    async function getFillColor(type: string) {

      if (type === "safety_polygon" || type === "safety_point") return [0,255,0]
      else if (type === "violent_polygon" || type === "violent_point" ) return [255,0,0]
      else if (type === "nonviolent_polygon" || type === "nonviolent_point" ) return [0,0,255]
      else if (type === "geonews_point" ) return [50,50,100]
      else return [50,50,50]
    }



    async function handleAddNewAsset() {

      // @ts-ignore
      const drawing: FeatureCollection = drawControlRef?.current?.draw?.getAll()

      drawing.features.forEach((feat) => {

          const newfeat = {
              ...feat,
              properties: {
                  id: 'N/A',
                  type: addFormType,
                  // @ts-ignore
                  category: formCat.current.value,
                  // @ts-ignore
                  description: formDesc.current.value,
                  // @ts-ignore
                  local_review: formReview.current.value,
                  // @ts-ignore
                  news_source: formNewsSource.current.value,
                  // @ts-ignore
                  news_link: formNewsLink.current.value,
                  created_at: now(),
                  // @ts-ignore
                  time_bins: formTimeBins.current,
                  // @ts-ignore
                  tags: (formTags.current.value).split(",")
              }
          }

          setAddedGeom([...addedGeom, newfeat])

          const newAnalystGeom: AnalystGeometry = {
            // @ts-ignore
            type: addFormType,
            // @ts-ignore
            category: formCat.current.value,
            // @ts-ignore
            local_review: formReview.current.value,
            geometry: feat,
            // @ts-ignore
            description: formDesc.current.value,
            // @ts-ignore
            tags: (formTags.current.value).split(","),
            // @ts-ignore
            time_bins: formTimeBins.current
          }

          setAnalystGeom([...analystGeom, newAnalystGeom])

      })

      setAddFormTitle("")
      setAddFormType("")
      enableNewsSchema(0)
      setMenuOpen(0)

    }

    async function removeAssetFromTrip(object: Feature | FeatureCollection) {

      setAddedGeom(addedGeom.filter((geom) => geom !== object))
      setAnalystGeom(analystGeom.filter((geom) => geom.geometry !== object))

      closeInfoText()

    }

    useEffect(() => {

      const pullDestination = async () => {

        if (typeof currentDestinationIndex === "number" && tripOutputs && tripDestinations) {

          setTripCompilingStatus("Loading destination information...")

          const output = tripOutputs[currentDestinationIndex];
          const destination = tripDestinations[currentDestinationIndex];

          setUserAccommodations(destination.accommodations)

          console.log(destination)

          setCurrentDestination(destination)

          if (await isDestPreviouslySaved(destination.id)) {

            const prevSaved = await returnPreviouslySaved(destination.id);

            setRtProp(prevSaved.outputs.rt_prop)

            setKdeViol(prevSaved.outputs.kde_violent);
            setKdeNonviol(prevSaved.outputs.kde_nonviolent);

            setAnalystNotes(prevSaved.outputs.analyst_notes)
            setFeedItems(prevSaved.outputs.combined_feed)

            setRadiusVal(prevSaved.settings.prevRadius)
            setNegateKdeViol(prevSaved.settings.includedKdeViol)
            setNegateKdeNonViol(prevSaved.settings.includedKdeNonViol)

            setDestIndices(prevSaved.indices)

            await deleteDestinationOutputs(destination.id) // remove old copy of saved dest

          } else {

            const rtarray = output.rt_prop;

            setRtProp(rtarray)

            setDestIndices(output.indices);

            if (typeof output.kde_violent === "string") setKdeViol(JSON.parse(output.kde_violent));
            if (typeof output.kde_nonviolent === "string") setKdeNonviol(JSON.parse(output.kde_nonviolent));

          }

          setMapCenter([destination.point_location.coordinates[0], destination.point_location.coordinates[1]])

          setTripCompilingStatus("")
          setTripLoadingStatus("successfully compiled")

          console.log(destination.id)
          console.log(savedTripOutputs)

        }
    }

    pullDestination();

    }, [currentDestinationIndex, tripOutputs, tripDestinations]);

    const onDragEnd = ({ destination, source }: any) => {
      
      // dropped outside the list
      if (!destination) return;
  
      const newItems = arrayMove(feedItems, source.index, destination.index);

      console.log(newItems)
  
      setFeedItems(newItems);
    };


    const handleSliderChange = (event: any, newValue: any) => {
      setRadiusVal(newValue);
    };
    

    const pendingcolumns: ColDef[] = [
        { field: 'trip_name', headerName: 'Trip', width: 300 },
        { field: 'user_name', headerName: 'User', width: 100 },
        //{ field: 'Trip Owner', headerName: 'Trip Owner', width: 100,  valueGetter: getTripOwnerName},
        { field: 'Review', renderCell: (cellValues) => {
            return (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleReviewTrip(cellValues)}
              >
                REVIEW
              </Button>
            );
          } },
      ];

      function arrayMove(arr: any[], old_index: number, new_index: number) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
        };

      async function handleMenu(mode: string) {

        switch (mode) {
            case "safety_polygon":
                enableNewsSchema(0)
                setOpenGeomInfo(0)
                setAddFormTitle("Add a Safety Polygon")
                setAddFormType(mode)
                setMenuOpen(2)
                break;

            case "violent_polygon":
                enableNewsSchema(0)
                setOpenGeomInfo(0)
                setAddFormTitle("Add a Violent Polygon")
                setAddFormType(mode)
                setMenuOpen(2)
                break;

            case "nonviolent_polygon":
                enableNewsSchema(0)
                setOpenGeomInfo(0)
                setAddFormTitle("Add a Non-Violent Polygon")
                setAddFormType(mode)
                setMenuOpen(2)
                break;
            case "safety_point":
                enableNewsSchema(0)
                setOpenGeomInfo(0)
                setAddFormTitle("Add a Safety Point")
                setAddFormType(mode)
                setMenuOpen(1)
                break;
            case "violent_point":
                enableNewsSchema(0)
                setOpenGeomInfo(0)
                setAddFormTitle("Add a Violent Point")
                setAddFormType(mode)
                setMenuOpen(1)
                break;
            case "nonviolent_point":
                enableNewsSchema(0)
                setOpenGeomInfo(0)
                setAddFormTitle("Add a Non-Violent Point")
                setAddFormType(mode)
                setMenuOpen(1)
                break;
            case "analystnote_point":
                enableNewsSchema(0)
                setOpenGeomInfo(0)
                setAddFormTitle("Add an Analyst Note Point")
                setAddFormType(mode)
                setMenuOpen(1)
                break;
            case "geonews_point":
                setOpenGeomInfo(0)
                setAddFormTitle("Add a Geolocated News Point")
                setAddFormType(mode)
                enableNewsSchema(1)
                setMenuOpen(1)
                break;
        }
    }

    async function closeInfoText() {
          //setOpenReportInfo(0)
          setIncludeReason("")
          setOpenAddedGeomInfo(0)
          setOpenGeomInfo(0)
      }

    const Map = ReactMapboxGl({
      accessToken: MAPBOX_ACCESS_TOKEN
    });

    let drawControlRef = useRef<DrawControl>(null);

    const viewportState = useRef<ViewStateProps>({
          // @ts-ignore
          width: 998,
          height: 600,
          latitude: 32.591153863146296,
          longitude: 9.533802457923096,
          zoom: 0.908387181524258,
          bearing: 0,
          pitch: 0,
          altitude: 1.5,
          maxZoom: 20,
          minZoom: 0,
          maxPitch: 60,
          minPitch: 0
      });


    const formDesc = useRef("");
    const formCat = useRef("");

    const formReview = useRef("");

    const formNewsSource = useRef("");
    const formNewsLink = useRef("");

    const formTags = useRef("");
    const formTimeBins = useRef(["morning", "afternoon", "evening"]);

    async function closeMenu() {
      // @ts-ignore
      formTimeBins.current = ["morning", "afternoon", "evening"]
      setAddFormTitle("")
      setAddFormType("")
      enableNewsSchema(0)
      setMenuOpen(0)
    }


    useEffect(() => {

      let newFeedItems: TravelPriorityFeedItem[] = [];

      let count = 0

      analystNotes.forEach((note) => {

        newFeedItems.push({
          id: count,
          header: note.title,
          text: note.text,
          type: "analyst_note",
          links: note.links
        })

        count += 1

      })

      destIndices?.forEach((index) => {
        
        newFeedItems.push({
          id: count,
          header: index.fullname,
          text: index.score,
          type: "index",
          severity: index.severity,
          links: index.links,
          description: index.description
        })

        count += 1

      })

      setFeedItems(newFeedItems)

    }, [analystNotes, currentDestinationIndex, tripOutputs, tripDestinations, destIndices]);


    async function getLinkTargets() {

      switch (linkType) {

        case "index":
          setLinkTargetOptions(feedItems.filter((item) => item.type === "index"))
          break;
        case "geometry":
          setLinkTargetOptions(analystGeom)
          console.log(analystGeom)
          break;
        case "news_headline":
          // do some
          break;
        case "other_analyst_note":
          setLinkTargetOptions(feedItems.filter((item) => item.type === "analyst_note"))
          break;

      }

      setOpenLinkSelection(1)

    }

    async function submitLinkTarget(target: any) {

      const host = selectedLinkHost
      const targetType = linkType

      console.log("submitLinkTarget")

      console.log(target)
      console.log(host)
      console.log(targetType)

      // add host id to target item

      if (typeof host!.id === "number") {

        console.log("host has id")

        const prevLinks = target.links
        let newLinks: number[] = []

        if (target.links) {
          newLinks = [...prevLinks, host!.id]
        } else {
          newLinks = [host!.id]
        }

        let newTarget = target
        newTarget.links = newLinks

        switch (targetType) {

          case ("index" || "other_analyst_note"):
            const rest: TravelPriorityFeedItem[] = feedItems.filter((item) => item != target)
            console.log("CHANGED ITEMS BY LINK")
            console.log([...rest, newTarget])
            setFeedItems([...rest, newTarget])
            break;
          case ("geometry"):
            const restofgeom: AnalystGeometry[] = analystGeom.filter((geom) => geom != target)
            console.log("CHANGED ITEMS BY LINK")
            console.log([...restofgeom, newTarget])
            setAnalystGeom([...restofgeom, newTarget])
            break;
          
        }

        setOpenLinkSelection(0)
        setOpenLink(0)
        setSelectedLinkHost(null)
        setLinkTargetOptions([])
        setLinkTarget(undefined)
        setLinkType("")
        setSelectedLinkHost(null)

      }

    }


    async function deleteFeedItem(feedItem: TravelPriorityFeedItem) {

      const newItems: TravelPriorityFeedItem[] = feedItems.filter((item) => item != feedItem)

      setFeedItems(newItems)

    }


    function approvalRedirect() {
      console.log("REDIRECTING")
      window.location.reload();
    }


    const onSuggestSelect = (e: any) => {

      if (e && e.location) {

          const location = e.location

          setSearchResLoc([location.lng, location.lat])

          // @ts-ignore
          viewportState.current.latitude = location.lat

          // @ts-ignore
          viewportState.current.longitude = location.lng

          // @ts-ignore
          viewportState.current.zoom = 10

          // setInitialViewState({
          //     longitude: location.lng,
          //     latitude: location.lat,
          //     zoom: 14,
          //     pitch: 0,
          //     bearing: 0
          // })

          geosuggestEl.current.clear();
          geosuggestEl.current.blur();
          updateShowSearchBar(false);

          setOpenAddResult(1)
      }

  }

  const EARTH_CIR_METERS = 40075016.686;
  const degreesPerMeter = 360 / EARTH_CIR_METERS;

  function toRadians(degrees: number) {
    return degrees * Math.PI / 180;
  };

  function latLngToBounds(lat: number, lng: number, zoom: number, width: number, height: number){
    const metersPerPixelEW = EARTH_CIR_METERS / Math.pow(2, zoom + 8);
    const metersPerPixelNS = EARTH_CIR_METERS / Math.pow(2, zoom + 8) * Math.cos(toRadians(lat));

    const shiftMetersEW = width/2 * metersPerPixelEW;
    const shiftMetersNS = height/2 * metersPerPixelNS;

    const shiftDegreesEW = shiftMetersEW * degreesPerMeter;
    const shiftDegreesNS = shiftMetersNS * degreesPerMeter;


    return {
      north: lat+shiftDegreesNS,
      south: lat-shiftDegreesNS,
      east: lng+shiftDegreesEW,
      west: lng-shiftDegreesEW
    }
  }


  async function addAssetsInView() {

    setLoadingGeomViewAdd("Adding Assets, One Moment ...")

    const searchState = viewportState.current

    // @ts-ignore
    const bounds: any = latLngToBounds(searchState.latitude, searchState.longitude, searchState.zoom, searchState.width, searchState.height)

    console.log(bounds)

    // Add points

    const points = universalGeom.filter((geom) => (geom.properties.type === "safety_point" || geom.properties.type === "violent_point" || geom.properties.type === "nonviolent_point" || geom.properties.type === "geonews_point" || geom.properties.type === "analystnote_point"))

    const boundedPoints = points.filter((point) => (point.geometry.coordinates[0] < bounds.east && point.geometry.coordinates[0] > bounds.west) && (point.geometry.coordinates[1] < bounds.north && point.geometry.coordinates[1] > bounds.south))

    console.log(boundedPoints)

    // Add polygons

    const polys = universalGeom.filter((geom) => (geom.properties.type === "safety_polygon" || geom.properties.type === "violent_polygon" || geom.properties.type === "nonviolent_polygon"))

    const boundedPolys = polys.filter((poly) => (center(poly).geometry.coordinates[0] < bounds.east && center(poly).geometry.coordinates[0] > bounds.west) && (center(poly).geometry.coordinates[1] < bounds.north && center(poly).geometry.coordinates[1] > bounds.south))

    console.log(boundedPolys)

    setIncludeReason("")

    let newAddedGeom: any[] = []
    let newAnalystGeoms: AnalystGeometry[] = []

    boundedPoints.forEach((feat) => {

      const newAnalystGeom: AnalystGeometry = {
        type: feat.properties.type,
        category: feat.properties.category,
        geometry: feat,
        description: feat.properties.description,
        tags: feat.properties.tags,
        time_bins: feat.properties.time_bins,
        reason_for_including: includeReason
      }

      if (!addedGeom.includes(feat)) newAddedGeom.push(feat)
      if (!analystGeom.includes(newAnalystGeom)) newAnalystGeoms.push(newAnalystGeom)
    })

    boundedPolys.forEach((feat) => {

      const newAnalystGeom: AnalystGeometry = {
        type: feat.properties.type,
        category: feat.properties.category,
        geometry: feat,
        description: feat.properties.description,
        tags: feat.properties.tags,
        time_bins: feat.properties.time_bins,
        reason_for_including: includeReason
      }

      if (!addedGeom.includes(feat)) newAddedGeom.push(feat)
      if (!analystGeom.includes(newAnalystGeom)) newAnalystGeoms.push(newAnalystGeom)
    })

    setAddedGeom([...addedGeom, ...newAddedGeom])
    setAnalystGeom([...analystGeom, ...newAnalystGeoms])

    setLoadingGeomViewAdd("")

  }


  return (

    <div>
        <ToggleBarContainer>
            <Button size="large" color="primary" >
                Pending Trips
            </Button>
            <Button size="large" href="/travelApp" >
                Home
            </Button>
            <Button size="large" href="/travelAreas" >
                Countries & Cities
            </Button>
            <Button size="large" href="/travelActiveTrips" >
                Active Trips
            </Button>
        </ToggleBarContainer>
        <div style={{height: 300}}>
        <DataGrid rows={pendingTrips} columns={pendingcolumns} pageSize={100} rowsPerPageOptions={[5]} className={classes.root} />
        </div>
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px', height: '50px'}} >
        {tripCompilingStatus}
        </div>
        {tripLoadingStatus === "successfully compiled" && <div>
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px', height: '50px'}} >
            Trip Selected: {currentTripInfo[0]}, for User: {currentTripInfo[1]}
        </div>
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px', height: '50px'}} >
            Destination Name: {currentDestination?.name}, Destinations Remaining: {destinationsLeft} / {tripDestinations?.length.toString()}
        </div>
        <ToggleBarContainer>
          {typeof currentDestinationIndex === "number" && currentDestinationIndex > 0 && <Button onClick={async () => {
            if (typeof destinationsLeft === "number") {
              await resetFields();
              setDestinationsLeft(destinationsLeft + 1);
              setCurrentDestinationIndex(currentDestinationIndex - 1);
            }
          }}>
            PREVIOUS
            </Button>}
          {typeof destinationsLeft === "number" && destinationsLeft > 1 && <Button onClick={async () => {
            if (typeof currentDestinationIndex === "number") {
              await saveDestinationOutputs();
              setDestinationsLeft(destinationsLeft - 1);
              setCurrentDestinationIndex(currentDestinationIndex + 1);
              await resetFields();
            }
          }}>
          NEXT
          </Button>}
        </ToggleBarContainer>
        <div style={{height: 500}}>
          <div style={{ height: '100%', width: '100%', position: 'relative' }} >
          <DeckGL
            height="100%"
            width={(menuOpen > 0) ? '65%' : '100%'}
            controller
            layers={(menuOpen > 0) ? [] : layers}
            initialViewState={{
              longitude: mapCenter[0],
              latitude: mapCenter[1],
              zoom: 15,
              pitch: 0,
              bearing: 0
            }}
            // @ts-ignore
            viewState={viewportState.current.value}
            onViewStateChange={(viewState) => {
              viewportState.current = viewState.viewState;
          }}
          >

          { menuOpen > 0 && <Map
              // eslint-disable-next-line react/style-prop-object
              style="mapbox://styles/mapbox/satellite-streets-v9"
              containerStyle={{
                  display: "flex",
                  width: '100%',
                  height: "100%",
                  position: "sticky",
                  right: 10,
                  top: 80,
                  bottom: 10,
                  borderRadius: 10
              }}
              // @ts-ignore
              center={[viewportState.current.longitude as number, viewportState.current.latitude as number]}
              //onStyleLoad={onStyleLoad}
              // @ts-ignore
              zoom={[viewportState.current.zoom]}
              >
                
            {<DrawControl 
                ref={drawControlRef}
                userProperties={true}
                //onDrawCreate={onDrawCreate}
                //onDrawUpdate={onDrawUpdate}
                controls={{
                "point": (menuOpen == 1) ? true : false,
                "line_string": false, 
                "polygon": (menuOpen == 2) ? true : false,
                "trash": (menuOpen > 0) ? true : false,
                "combine_features": false,
                "uncombine_features": false
                }}
            />}

          </Map>}

          { menuOpen == 0 && 
          <StaticMap width="100%" height="90%" mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle={"mapbox://styles/mapbox/satellite-streets-v9"} />
          }

          {/* <SearchButtonContainer>
          <RoundButton onClick={(e) => updateShowSearchBar(!showSearchBar)}>
              {showSearchBar ? (
                  <img src={CloseWhiteIcon} alt="Close" width="18px" />
              ) : (
                  <img src={SearchWhiteIcon} alt="Search" width="18px" />
              )}
          </RoundButton>
          </SearchButtonContainer>
          {showSearchBar && (
          <div>
          <Geosuggest ref={geosuggestEl} placeholder="Search for Places" onSuggestSelect={(e: any) => onSuggestSelect(e)} />
          </div>
          )} */}

          </DeckGL>
          {menuOpen > 0 && <div style={{float: "right"}}>
              <div style={{ alignItems: 'left', display: 'flex', justifyContent: 'left', fontSize: '20px', height: '50px'}} >
              {addFormTitle}
              </div>

              <FormControl>
              <FormLabel component="legend" style={{ margin: "10px 0" }}>
                Description
                </FormLabel>
                <TextInput
                    id="geomdesc"
                    variant="outlined"
                    inputRef={formDesc}
                    //value={addFormDesc}
                    //onChange={(e) => setAddFormDesc(e.target.value)}
                />
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                {(newsSchema == 0) ? "Category (ex. Hotel, Restaurant)" : "News Headline" }
                </FormLabel>
                <TextInput
                    id="geomcateg"
                    variant="outlined"
                    inputRef={formCat}
                    //value={geomcateg}
                    //onChange={(e) => updateMessageText(e.target.value)}
                  />
                  <FormLabel component="legend" style={{ margin: "10px 0" }}>
                    Add a Recent Review (optional)
                    </FormLabel>
                    <TextInput
                        id="geomreview"
                        variant="outlined"
                        inputRef={formReview}
                        //value={addFormDesc}
                        //onChange={(e) => setAddFormDesc(e.target.value)}
                    />
                  {/* <FormLabel component="legend" style={{ margin: "10px 0" }}>
                  Time Bins
                  </FormLabel>
                  <TextInput
                      id="geomdesc"
                      variant="outlined"
                      inputRef={formDesc}
                      //value={addFormDesc}
                      //onChange={(e) => setAddFormDesc(e.target.value)}
                /> */}
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                {"Tags (Separate with a comma)"}
                </FormLabel>
                <TextInput
                    id="geomdesc"
                    variant="outlined"
                    inputRef={formTags}
                    //value={addFormDesc}
                    //onChange={(e) => setAddFormDesc(e.target.value)}
                />
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                  Time Bins
                  </FormLabel>
                <FormGroup>
                  <FormControlLabel
                      control={
                      <CheckInput
                          name="morning"
                          defaultChecked={true}
                          onChange={(e) => {
                              // @ts-ignore
                              if ((formTimeBins.current).includes("morning") == false) {
                                  // @ts-ignore
                                  formTimeBins.current = [...formTimeBins.current, "morning"]
                              } else {
                                  const newarr = (formTimeBins.current).filter((bin) => bin !== "morning")
                                  formTimeBins.current = newarr
                              }
                          }}
                      />
                      }
                      label="Morning"
                  />
                  <FormControlLabel
                      control={
                      <CheckInput
                          name="afternoon"
                          defaultChecked={true}
                          onChange={(e) => {
                              // @ts-ignore
                              if ((formTimeBins.current).includes("afternoon") == false) {
                                  // @ts-ignore
                                  formTimeBins.current = [...formTimeBins.current, "afternoon"]
                              } else {
                                  const newarr = (formTimeBins.current).filter((bin) => bin !== "afternoon")
                                  formTimeBins.current = newarr
                              }
                          }}
                      />
                      }
                      label="Afternoon"
                  />
                  <FormControlLabel
                      control={
                      <CheckInput
                          name="evening"
                          defaultChecked={true}
                          onChange={(e) => {
                              // @ts-ignore
                              if ((formTimeBins.current).includes("evening") == false) {
                                  // @ts-ignore
                                  formTimeBins.current = [...formTimeBins.current, "evening"]
                              } else {
                                  const newarr = (formTimeBins.current).filter((bin) => bin !== "evening")
                                  formTimeBins.current = newarr
                              }
                          }}
                      />
                      }
                      label="Evening"
                  />
                  </FormGroup>
                { newsSchema == 1 && <div>
                <FormLabel component="legend" style={{ margin: "10px 0" }}>
                News Source
                </FormLabel>
                <TextInput
                    id="geomsource"
                    variant="outlined"
                    inputRef={formNewsSource}
                    //value={geomcateg}
                    //onChange={(e) => updateMessageText(e.target.value)}
                  />
                  <FormLabel component="legend" style={{ margin: "10px 0" }}>
                  News Link
                  </FormLabel>
                  <TextInput
                      id="geomnewslink"
                      variant="outlined"
                      inputRef={formNewsLink}
                      //value={geomcateg}
                      //onChange={(e) => updateMessageText(e.target.value)}
                      />
                  </div>}
                  <Button 
                  onClick={() => {handleAddNewAsset()}}
                  >
                  Add Asset to Destination
              </Button>
              <Button>

              </Button>
              <Button onClick={async() => {await closeMenu()}} >
                  Close
              </Button>
              </FormControl>
              
              
          </div>}
          </div>
          {openGeomInfo == 1 && 
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', whiteSpace: 'pre-line', color: "yellow", height: '170px'}} >
          <ToggleBarContainer>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', whiteSpace: 'pre-line', color: "yellow"}} >
          Clicked Polygon Info {'\n'} ID: {geomInfo[0]} {'\n'} {geomInfo[1]} {'\n'} Category: {geomInfo[2]} {'\n'} Description: {geomInfo[3]} {'\n'} Tags: {geomInfo[4]} {'\n'} Time Bins: {geomInfo[5]}
          </div>
          <Button onClick={() => {closeInfoText()}}>
              Close
          </Button>
          <Button onClick={() => { if (typeof selectedFeat !== "undefined") handleAddUnivAsset(selectedFeat)}} >
              Add Asset to Trip
          </Button>
          </ToggleBarContainer>
          </div>
          }
          {openGeomInfo == 1 && <div>
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              Tell the user why you chose to include this in their trip! (Optional)
            </FormLabel>
            <TextInput
            id="includereason"
            variant="outlined"
            //inputRef={formNewsLink}
            value={includeReason}
            onChange={(e) => setIncludeReason(e.target.value)}
            />
          </div>}
          {openAddedGeomInfo == 1 && 
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', whiteSpace: 'pre-line', color: "cyan", height: '170px'}} >
          <ToggleBarContainer>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', whiteSpace: 'pre-line', color: "cyan"}} >
          Clicked Polygon Info {'\n'} ID: {geomInfo[0]} {'\n'} {geomInfo[1]} {'\n'} Category: {geomInfo[2]} {'\n'} Description: {geomInfo[3]} {'\n'} Tags: {geomInfo[4]} {'\n'} Time Bins: {geomInfo[5]}
          </div>
          <Button onClick={() => {closeInfoText()}}>
              Close
          </Button>
          <Button onClick={() => { if (typeof selectedFeat !== "undefined") removeAssetFromTrip(selectedFeat)}} >
              Remove Asset from Trip
          </Button>
          </ToggleBarContainer>
          </div>
          }
          {menuOpen == 0 && <ToggleBarContainer>
            {/* <Button onClick={() => setKdeMode("safe")} color={((kdeMode === "safe") ? 'primary' : 'default')} >
              Safe
            </Button> */}
            {showKde == 1 && <Button onClick={() => setKdeMode("viol")} color={((kdeMode === "viol") ? 'primary' : 'default')} >
              Violent
            </Button>}
            {showKde == 1 &&<Button onClick={() => setKdeMode("nonviol")} color={((kdeMode === "nonviol") ? 'primary' : 'default')} >
              Non-Violent
            </Button>}
            <Button>

            </Button>
            <Button onClick={() => setShowKde(1)} color={((showKde == 1) ? 'primary' : 'default')} >
              Show KDE
            </Button>
            <Button onClick={() => setShowKde(0)} color={((showKde == 0) ? 'primary' : 'default')} >
              Hide KDE
            </Button>
            <Button>

            </Button>
            <Button onClick={() => setShowUniv(1)} color={((showUniv==1) ? 'primary' : 'default')} >
              Show preset world assets
            </Button>
            <Button onClick={() => setShowUniv(0)} color={((showUniv==0) ? 'primary' : 'default')} >
              hide world assets
            </Button>
            <Button>

            </Button>
            {showUniv == 1 && <Button onClick={() => {if (loadingGeomViewAdd == "") addAssetsInView()}}>
              {(loadingGeomViewAdd == "") ? "Add Assets in Area" : loadingGeomViewAdd}
            </Button>}
            
          </ToggleBarContainer>}
          {menuOpen == 0 && <ToggleBarContainer>
            <Button onClick={async() => await handleMenu("safety_polygon")} >
                Safety Polygon
            </Button>
            <Button onClick={async() => await handleMenu("violent_polygon")} >
                Violent Risk Polygon
            </Button>
            <Button onClick={async() => await handleMenu("nonviolent_polygon")} >
                Non-Violent Risk Polygon
            </Button>
        </ToggleBarContainer>}
        {menuOpen == 0 && <ToggleBarContainer>
            <Button onClick={async() => await handleMenu("safety_point")} >
                Safety Point
            </Button>
            <Button onClick={async() => await handleMenu("violent_point")} >
                Violent Risk Point
            </Button>
            <Button onClick={async() => await handleMenu("nonviolent_point")} >
                Non-Violent Risk Point
            </Button>
            <Button onClick={async() => await handleMenu("analystnote_point")} >
                Analyst Note Point
            </Button>
            <Button onClick={async() => await handleMenu("geonews_point")} >
                Geolocated News
            </Button>
        </ToggleBarContainer>}

        {menuOpen == 0 && <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px', height: '50px', width: '80%'}} >
        <Slider
        min={0.25}
        max={3}
        step={0.01}
        aria-labelledby="continuous-slider"
        valueLabelDisplay="auto"
        defaultValue={0.75}
        onChange={handleSliderChange}
        value={radiusVal}
        />
        <Button onClick={recompileDestOutputs}>
          RECOMPILE
        </Button>
        {recompileLoading !== "" && <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px', height: '100px'}} >
        {recompileLoading}
        </div>}
        </div>}

        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', height: '40px'}} >
        Report type break-up (this is used to make the bubble chart!)
        </div>
        <Chart data={rtProp}>
            <ArgumentAxis />
            <ValueAxis />
            <BarSeries valueField="proportion" argumentField="report_type" />
        </Chart>
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px', height: '100px'}} >
        Let's review the User's profile:
        </div>
        {typeof currentUserProf !== "undefined" && <div style={{ alignItems: 'center', display: 'flex', whiteSpace: 'pre-line', justifyContent: 'center', fontSize: '15px', height: '250px'}} >
        Name: {JSON.stringify(currentUserProf.first_name)} {'\n'} User Characteristics {'\n'} Sex: {currentUserProf.user_characteristics.sex?.toString()} {'\n'} Birthday: {currentUserProf.user_characteristics.bday?.toString()} {'\n'} Income: {currentUserProf.user_characteristics.socioecon?.toString()} {'\n'} Social Status: {currentUserProf.user_characteristics.socialstatus?.toString()} Do they identify with the LGBT community?: {currentUserProf.user_characteristics.sexualorientation?.toString()} {'\n'} {'\n'} Destination Characteristics {'\n'} Which kind of areas will they be visiting? {currentDestination?.destination_characteristics.crowded_factors?.toString()} {'\n'} On a scale of 1-10, how often will the user be out in public? {currentDestination?.destination_characteristics.time_in_public?.toString()} {'\n'} Has kids? {currentDestination?.destination_characteristics.has_children?.toString()} {'\n'} Party size? {currentDestination?.destination_characteristics.party_size?.toString()} {'\n'} Reason for going? {currentDestination?.destination_characteristics.reason_for_going?.toString()} {'\n'}
        </div>}
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', height: '100px'}} >
        Add a destination-specific analyst note here if you'd like!
        </div>
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', height: '70px'}} >
        <TextField size="medium" label="Analyst Note Title" margin="normal" onChange={(e) => setNoteTitle(e.target.value)} />
        <TextField size="medium" label="Analyst Note" margin="normal" onChange={(e) => setNoteText(e.target.value)} />
        <Button onClick={() => setAnalystNotes([...analystNotes, {title: noteTitle, text: noteText, links: []}])} >
          Add Note
        </Button>
        <Button onClick={(e) => {setAnalystNotes([]); setNoteText("")}}>
          Clear All Notes
        </Button>
        </div>
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '20px', height: '100px'}} >
        Drag and drop to create the combined feed:
        </div>
        {openLink == 1 && <div>

          <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}} >
            Add a new link
          </div>

          <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}} >
          <FormLabel component="legend" style={{ margin: "10px 0" }}>
              What kind of item would you like to attach to this Analyst Note?
            </FormLabel>
            </div>
            <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}} >
            <RadioGroup
              name="linktype"
              value={linkType}
              onChange={(e) => {
                setLinkTargetOptions([])
                setLinkType(e.target.value)
              }}
            >
              <FormControlLabel
                value="index"
                control={<Radio />}
                label="Index"
              />
              <FormControlLabel
                value="geometry"
                control={<Radio />}
                label="Map Item"
              />
              <FormControlLabel
                value="news_headline"
                control={<Radio />}
                label="News Item"
              />
              <FormControlLabel
                value="other_analyst_note"
                control={<Radio />}
                label="Another Analyst Note"
              />

            </RadioGroup>
            </div>
            <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}} >
            <Button onClick={async() => {
              await getLinkTargets()
            }} >
              Select
            </Button>
            </div>
            

            {openLinkSelection == 1 && <div>

              <div style={{height: 50}}/>
              <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}} >
                Here are the link options
                </div>

            <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}} >
            <FormLabel component="legend" style={{ margin: "10px 0" }}>
              Here are the available items for that type. Select which one you'd like to attach!
            </FormLabel>
            </div>
            <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}} >
            <RadioGroup
              name="linkTarget"
              value={linkTarget}
              onChange={(e) => {
                //setOpenLinkSelection(1)
                setLinkTarget(e.target.value)}
              }
            >

              {linkTargetOptions.map((option) => (

                  // <FormControlLabel
                  // value={option}
                  // control={<Radio />}
                  // label={option.header}
                  // />
                  <ToggleBarContainer>
                  <div>
                    {(linkType === "geometry") ? option.description : option.header}
                  </div>
                  <Button onClick={async() => await submitLinkTarget(option)} >
                    Link Item
                  </Button>
                  </ToggleBarContainer>
                
              ))}

            </RadioGroup>
            </div>
            <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}} >
            <Button onClick={() => {
              setOpenLinkSelection(0)
              setLinkTargetOptions([])
            }}>
              Close
            </Button>
            </div>

            <div style={{height: 50}}/>

            </div>}

            <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}} >
            <Button onClick={() =>{
              setSelectedLinkHost(null)
              setLinkTargetOptions([])
              setOpenLink(0)
            }} >
              Close
            </Button>
            </div>
          
        </div>}
        <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {feedItems.map((item: TravelPriorityFeedItem, index: any) => (
                <Draggable draggableId={item.id.toString()} index={index} key={item.id.toString()}  >
                  {(provided, snapshot) => (
                    <ListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      //className={snapshot.isDragging ? classes.draggingListItem : ''}
                    >
                      <ListItemText primary={item.header} secondary={item.text} />
                      <Button onClick={() => {
                        setSelectedLinkHost(item)
                        setOpenLink(1)
                        }} >
                        Link
                      </Button>
                      <Button>

                      </Button>
                      <Button onClick={async() => {await deleteFeedItem(item)}} >
                        Delete
                      </Button>
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
            
          )}
        </Droppable>
      </DragDropContext>
        {/*
        <div style={{ alignItems: 'center', display: 'flex', whiteSpace: 'pre-line', justifyContent: 'center', fontSize: '15px', height: '100px'}} >
        <TextField size="small" label="priority" margin="normal" />
        {destIndices[0]}
        </div>
        <div style={{ alignItems: 'center', display: 'flex', whiteSpace: 'pre-line', justifyContent: 'center', fontSize: '15px', height: '100px'}} >
        <TextField size="small" label="priority" margin="normal" />
        {destIndices[1]}
        </div>
        */}
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', height: '70px'}} >
          Include Violent Cloud?
          <ToggleBarContainer>
            <Button onClick={() => setNegateKdeViol(0)} color={((negateKdeViol == 0) ? 'primary' : 'default')} >
              Include
            </Button>
            <Button onClick={() => setNegateKdeViol(1)} color={((negateKdeViol == 1) ? 'primary' : 'default')} >
              Dont Include
            </Button>
          </ToggleBarContainer>
          </div>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', height: '70px'}} >
          Include Non-Violent Cloud?
          <ToggleBarContainer>
            <Button onClick={() => setNegateKdeNonViol(0)} color={((negateKdeNonViol == 0) ? 'primary' : 'default')} >
              Include
            </Button>
            <Button onClick={() => setNegateKdeNonViol(1)} color={((negateKdeNonViol == 1) ? 'primary' : 'default')} >
              Dont Include
            </Button>
          </ToggleBarContainer>
          </div>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', whiteSpace: 'pre-line', color: "red"}}  >
          {subError}
          </div>
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: '15px', height: '70px'}} >
        {destinationsLeft == 1 && isSubmitting == false && <Button onClick={async () => {
          setSubError("Submitting.... One Moment!")
          const res = await submitApproval()
          if (res === "confirmed") {
            setSubError("")
            approvalRedirect()
          } else if (res === "payment_failed") {
            setSubError("User's payment failed - your work is saved, but they will need to use a new payment method")
            approvalRedirect()
          } else {
            setIsSubmitting(false)
            console.log(res)
            setSubError(res)
          }
        }} 
        //href={"/travelPendingTrips"}
        >
          SUBMIT
        </Button>}
        </div>
        </div>
        </div>}
    </div>

  )

}